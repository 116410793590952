import React from 'react'

const TradeInPreviewQuestion = (props) => {
    const HandleReduce = () => {
        if (props.reduce.reduce_value) {
            return <>{`-${props.reduce.reduce_value}`} {props.currency ? props.currency() : ''}</>
        } else if (props.reduce.reduce_percentage) {
            return `-${props.reduce.reduce_percentage} %`
        } else {
            return ''
        }
    }

    return (
        <div className="preview-info-item d-flex align-items-center justify-content-between mb-12px">
            <div className="d-flex align-items-center">
                <div className="font-size-12 font-base text-blue-gray width-100px mr-8px mr-xl-20px">
                    {props.Question}
                </div>
                <div className="font-size-11 font-medium text-dark">
                    {props.Answer}
                </div>
            </div>
            <div className="font-size-11 font-bold text-blue">
                <HandleReduce />
            </div>
        </div>
    )
}

export default TradeInPreviewQuestion