const PaymentTypesEnum = {
  BALANCE_FILL: 1,
  C2C_PRODUCT: 2,
  C2C_PRODUCT_INSTALLMENT: 3,
  CART: 4,
  SHOP_PRODUCT: 5,
  SHOP_PRODUCT_INSTALLMENT: 6,
  VIP: 7,
  COLOR: 8,
  PRODUCT_UPDATE: 9,
  PRODUCT_AUTO_UPDATE: 10,
  PROM_PACKAGE: 11,
  TRADE_IN: 12,
  PAID_PRODUCT_ADD: 13,
  RECOVER_PRODUCT: 14,
};

const PaymentMethodsEnum = {
  balance: 1,
  card: 2,
  PromPackageOrder: 3,
  google: 4,
  apple: 5,
  points: 6,
  emoney: 7,
  tbcinstallment: 8,
  credo: 9,
  req_invoice: 10,
  amex: 11,
  emoneybalance: 12,
  mycoins: 13,
  saved_card: 14,
  tbc_mobile_bank: 15,
  tbc_bnpl: 16,
};

const PromotionIdEnum = {
  SUPER_VIP: 20,
  VIP_PLUS: 15,
  VIP: 10,
  PAYD_RENEW: 3,
  AUTO_RENEW: 2,
  COLOR: 1,
};

const PaymentMethodsIconsEnum = {
  [PaymentMethodsEnum.balance]: 'balance-icon-i',
  [PaymentMethodsEnum.card]: 'pay-card w-48px h-32px',
  [PaymentMethodsEnum.PromPackageOrder]: '',
  [PaymentMethodsEnum.google]: 'google-pay w-48px h-32px',
  [PaymentMethodsEnum.apple]: 'applePay-i w-48px h-32px',
  [PaymentMethodsEnum.points]: 'love-card-icon-i',
  [PaymentMethodsEnum.emoney]: 'paybox-i',
  [PaymentMethodsEnum.tbcinstallment]: 'tbc-installment',
  [PaymentMethodsEnum.req_invoice]: 'pay-invoice',
  [PaymentMethodsEnum.amex]: 'american-icon',
  [PaymentMethodsEnum.emoneybalance]: 'pay-emoney',
  [PaymentMethodsEnum.mycoins]: 'Crypto',
  [PaymentMethodsEnum.tbc_mobile_bank]: 'tbc-bank-icon w-24px h-24px',
  [PaymentMethodsEnum.tbc_bnpl]: 'tbc-bank-icon w-24px h-24px'
};

const CheckoutPaymentIconsEnum = {
  [PaymentMethodsEnum.card]: 'pay-card',
  [PaymentMethodsEnum.google]: 'google-pay',
  [PaymentMethodsEnum.apple]: 'applePayment',
  [PaymentMethodsEnum.points]: 'pay-ertguli',
  [PaymentMethodsEnum.emoney]: 'pay-fast-pay',
  [PaymentMethodsEnum.tbcinstallment]: 'tbc-installment',
  [PaymentMethodsEnum.req_invoice]: 'pay-invoice',
  [PaymentMethodsEnum.credo]: 'credo-pay-i',
  [PaymentMethodsEnum.amex]: 'american-icon',
  [PaymentMethodsEnum.emoneybalance]: 'pay-emoney',
  [PaymentMethodsEnum.mycoins]: 'Crypto',
  [PaymentMethodsEnum.tbc_mobile_bank]: 'tbc-installment',
  [PaymentMethodsEnum.tbc_bnpl]: 'tbc-installment',
};

const OrderShippingTypeEnum = {
  Default: 0,
  PickUpByMyself: 1,
};

export const Constants = {
  PaymentTypesEnum,
  PaymentMethodsEnum,
  PaymentMethodsIconsEnum,
  OrderShippingTypeEnum,
  CheckoutPaymentIconsEnum,
  PromotionIdEnum,
};
