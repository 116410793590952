import React from 'react'

const SimpleBanner = (props) => {
    return (
        <div className={props.parentClassName}>
            <img className={props.childClassName} src={props.src} alt="" />
        </div>
        
    )
}

export default SimpleBanner