import React from "react";

const Cookies = (props) => {
  return (
    <div className="">
      <div className="cookie-frame box-shadow py-24px px-16px px-md-30px py-md-20px bg-white fixed-bottom custom-alert d-flex flex-column flex-md-row align-items-center justify-content-center d-md-flex text-center text-md-left">
        <i className="svg-i w-52px h-52px mb-15px mb-md-0 cookies mr-0 mr-md-20px p-12px flex-shrink"></i>
        <p className="font-size-14 text-black mr-0 mr-md-24px mb-24px mb-md-0">
          {props.langs.NM_CookieNewText}
          <span className="d-inline-flex align-items-center flex-wrap justify-content-center justify-content-md-start pl-4px">
            <a
              href="https://auth.tnet.ge/ka/usercookies"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-underline d-flex align-items-center text-dark "
            >
              {props.langs.Cookie} {props.langs.CookiePolitic}
            </a>
          </span>
          <br />
        </p>
        <button
          className="btn bg-dark text-white flex-shrink border-radius-12 px-44px py-12px"
          onClick={props.processCookieAgreement}
        >
          {props.langs.Agree}
        </button>
      </div>
    </div>
  );
};
export default Cookies;
