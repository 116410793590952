import {
	useState,
	useEffect
} from 'react';

import { CONFIG, FixedLists } from 'core'
import { API, Storage } from 'services'
import { Functions, Trackers } from 'libs';
import { AppDataParser } from './appdata/AppDataParser';
import * as gtagAnalytics from "../libs/gtagAnalytics";

const Constructor = () => {
	const getLang = window.location.pathname.split('/')[1];
	const storage = Storage();
	const api = API();
	const [data, setData] = useState(null);

	
	const processUser = () => {
		// let token = storage.get(CONFIG.CACHE.TAGS.AUTH);
		let token = api.cookie._get(CONFIG.CACHE.TAGS.COOKIEAUTH)
		let user = storage.getJson(CONFIG.CACHE.TAGS.USER);
		if (!token || token.length < 20 || !user || !user.User) {
			return false;
		}
		return true;
	}
	const processBranch = (name = 'BETA') => {
		return CONFIG.HOST.branch === name;
	}
	const processPath = (path) => {
		return path.replace(':locale', getLang);
	}

	useEffect(() => {
		const processLanguage = () => {
			let cUrlPath  = window.location.pathname;
			let cUrlLang  = cUrlPath.split('/');
			let langs     = Object.keys(CONFIG.LANGS.DEFINITIONS);
			let lang      = CONFIG.LANGS.DEFAULT_LANG;
			
			if (cUrlLang.length < 2 || langs.indexOf(cUrlLang[1]) === -1) {
				if (storage.exists(CONFIG.CACHE.TAGS.LANG)) {
					let storageLang = storage.get(CONFIG.CACHE.TAGS.LANG);
					if (langs.indexOf(storageLang) !== -1) {
						lang = storageLang;
					}
				}
				window.location.href = window.location.origin + '/' + lang + cUrlPath + window.location.search;
			} else {
				storage.set(CONFIG.CACHE.TAGS.LANG, cUrlLang[1]);
			}
		}
		processLanguage();
		
		const processAppData = async () => {
			const version = await api.getAppVersion();
			if (version) {
				const res = await fetch(`${CONFIG.HOST.appDataUrl}assets/appdata/${getLang}.json?v=${version.langs_version ?? 1}`);
				if (res.status === 200) {
					const data = await res.json();
					let convertedData = new AppDataParser(data).get();
					let result = {
						getLang,
						CONFIG: CONFIG,
						api: api,
						storage,
						Functions,
						langs: convertedData.Langs,
						FixedLists,
					};
					delete convertedData.Langs;
					result.appData = convertedData;
					// console.log(result);
					setData(result);
				} else {
					setData(false);
				}
			} else {
				setData(false);
			}
		}
		processAppData();
		
		if (processBranch('PRODUCTION')) {
			Trackers.initialStart(getLang);

			if(processUser()) {
				const user = storage.getJson(CONFIG.CACHE.TAGS.USER);
				const userId = user.User.ID

				gtagAnalytics.setDefaultUser(userId)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return {
		data,
		processUser,
		processPath
	}
};

export default Constructor;
