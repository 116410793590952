import { Modal } from '../../components';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/user';
import { useMediaQuery } from '../../libs';
import parse from 'html-react-parser';
import { Cookie } from '../../services';

const ConsentSteps = {
  DefaultStep: 0,
  PlatformsStep: 1,
  PartnersStep: 2,
};


const BackArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" />
      <g mask="url(#mask0_12321_458)">
        <path d="M10 22L0 12L10 2L11.775 3.775L3.55 12L11.775 20.225L10 22Z" fill="#222222" />
      </g>
    </svg>
  );
};

export const OffersPolicyModal = ({ langs, api, ...restProps }) => {
  const [isPendingSetUserAgreement, setIsPendingSetUserAgreement] = useState(false);

  const { user, consentModalData, consentModalStep, setConsentModalStep, setConsentModalData, isOffersPolicyModalOpen, setIsOffersPolicyModalOpen } = useContext(UserContext);

  const showModal = consentModalData?.show_modal;
  const modal = showModal ? consentModalData?.modals : null;
  const offers = showModal ? consentModalData?.modals.offers : [];

  const ourAgreement = modal && modal.agreements && modal.agreements[0] ? modal?.agreements[0] : undefined;
  const ourPartnersAgreement = modal && modal.agreements && modal.agreements[1] ? modal?.agreements[1] : undefined;
  const privacyPolicyAgreement = modal && modal.agreements && modal.agreements[2] ?  modal?.agreements[2] : undefined;

  const [isEverythingSelected, setIsEverythingSelected] = useState(false);
  const [isAllPartnersSelected, setIsAllPartnersSelected] = useState(false);

  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);

  const isAgreementButtonDisabled = selectedPlatforms.length === 0 && selectedPartners.length === 0;

  const tnetPlatforms = offers?.find((offer) => offer.scope === 'TNET');
  const partnerPlatforms = offers?.find((offer) => offer.scope === 'Partners');

  const isMobile = useMediaQuery('(max-width: 768px)');

  const toggleEverythingSelected = () => {
    const platformIds = isEverythingSelected ? [] : tnetPlatforms?.products.map((product) => product.uuid);
    const partnerIds = isEverythingSelected ? [] : partnerPlatforms?.products.map((product) => product.uuid);

    setIsEverythingSelected(!isEverythingSelected);
    setIsAllPartnersSelected(!isEverythingSelected);

    setSelectedPlatforms(platformIds);
    setSelectedPartners(partnerIds);
  };

  const toggleAllPartners = () => {
    const partnerIds = isAllPartnersSelected ? [] : partnerPlatforms?.products.map((product) => product.uuid);

    setIsAllPartnersSelected(!isAllPartnersSelected);
    setSelectedPartners(partnerIds);
    setIsEverythingSelected(false);
  };

  const selectPlatform = (platformId) => {
    const platforms = selectedPlatforms.includes(platformId)
      ? selectedPlatforms.filter((platform) => platform !== platformId)
      : [...selectedPlatforms, platformId];

    setSelectedPlatforms(platforms);
    setIsEverythingSelected(false);
  };

  const selectPartner = (partnerId) => {
    const partners = selectedPartners.includes(partnerId) ? selectedPartners.filter((partner) => partner !== partnerId) : [...selectedPartners, partnerId];

    setSelectedPartners(partners);
    setIsAllPartnersSelected(false);
  };

  const agreeAllOffers = () => {
    const offersData = offers.filter(item => item.scope).map((offer) => ({
      scope: offer.scope,
      priority: offer.priority,
      productIDs: offer.products.map((product) => product.uuid),
    }));

    api
      .setUserAgreementStatus({ offers: offersData, target: modal?.target, scope: 1 })
      .then(() => {
        setIsPendingSetUserAgreement(true);
      })
      .catch(() => {
        setIsPendingSetUserAgreement(false);
      })
      .finally(() => {
        setConsentModalStep(ConsentSteps.DefaultStep)
        setConsentModalData(undefined)
        let cookie = Cookie()
        cookie._set('dataSharingConsentModalTimeout', new Date().toISOString())

      })

    setIsOffersPolicyModalOpen(false);
  };

  const notAgreeAllOffers = () => {
    const offersData = offers.filter(item => item.scope).map((offer) => ({
      scope: offer.scope,
      priority: offer.priority,
      productIDs: [],
    }));

    api
      .setUserAgreementStatus({ offers: offersData, target: modal?.target, scope: 1 })
      .then(() => {
        setIsPendingSetUserAgreement(true);
      })
      .catch(() => {
        setIsPendingSetUserAgreement(false);
      })
      .finally(() => {
        let cookie = Cookie()
        setConsentModalStep(ConsentSteps.DefaultStep)
        setConsentModalData(undefined)
        cookie._set('dataSharingConsentModalTimeout', new Date().toISOString())
      })
    ;


    setIsOffersPolicyModalOpen(false);
  };

  const agreeSelectedItems = () => {
    const partnersData = {
      scope: partnerPlatforms?.scope,
      priority: partnerPlatforms?.priority,
      productIDs: selectedPartners,
    };

    const offersData = [
      {
        scope: tnetPlatforms?.scope || 'TNET',
        priority: tnetPlatforms?.priority || 0,
        productIDs: selectedPlatforms,
      },
    ];

    if (partnerPlatforms) offersData.push(partnersData);

    api
      .setUserAgreementStatus({ offers: offersData, target: modal?.target, scope: 1 })
      .then(() => {
        setIsPendingSetUserAgreement(true);
      })
      .catch(() => {
        setIsPendingSetUserAgreement(false);
      })
      .finally(() => {
        setConsentModalStep(ConsentSteps.DefaultStep)
        setConsentModalData(undefined)
        let cookie = Cookie()
        cookie._set('dataSharingConsentModalTimeout', new Date().toISOString())
      })

    setIsOffersPolicyModalOpen(false);
  };

  if (!isOffersPolicyModalOpen) return null;

  const firstParagraphDocument = modal?.document[0]
    .replace(
      ourAgreement?.title ?? '',
      `<a href=${ourAgreement?.href ?? ''} target="_blank" class="text-decoration-underline font-size-14" style="color: #222">${ourAgreement?.title}</a>`
    )
    .replace(
      ourPartnersAgreement?.title ?? '',
      `<a href=${ourPartnersAgreement?.href ?? ''} target="_blank" class="text-decoration-underline font-size-14" style="color: #222">${ourPartnersAgreement?.title}</a>`
    );

  let content = (
    <div>
      <p className="font-size-14 font-bold mt-6px">{modal && modal.title}</p>

      <div className="mt-20px">
        {modal && (
          <>
            <p className="font-size-14">{parse(firstParagraphDocument ?? '')}</p>

            <p className="font-size-14">{modal?.document[1]}</p>

            <p className="font-size-14">
              {modal?.document[2]}{' '}
              <a href={privacyPolicyAgreement?.href} target="_blank" className="text-decoration-underline font-size-14" style={{ color: '#222' }}>
                {privacyPolicyAgreement?.title}
              </a>
            </p>
          </>
        )}

        <div className="d-flex justify-content-end flex-column-reverse flex-md-row flex-wrap">
          <div className="d-flex align-items-center">
            <button
              className="bg-white mr-3 hover-bg-white px-24px py-14px border-radius-8 w-md-100"
              style={{ border: 'none', color: '#A2A2A2', fontSize: '11px' }}
              onClick={notAgreeAllOffers}
            >
              {langs.Disagree}
            </button>

            {modal && modal.expanded && (
              <button
                className="bg-white hover-bg-white px-32px py-14px border-radius-8 w-md-100"
                style={{ border: 'none', color: '#A2A2A2', fontSize: '11px' }}
                onClick={() => setConsentModalStep(ConsentSteps.PlatformsStep)}
              >
                {langs.Modify}
              </button>
            )}
          </div>

          <button
            className="mr-12px bg-primary font-size-14 px-40px py-14px border-radius-8 w-md-100"
            style={{ border: 'none', color: '#222' }}
            onClick={agreeAllOffers}
          >
            {langs.Agree}
          </button>
        </div>
      </div>
    </div>
  );

  if (consentModalStep === ConsentSteps.PlatformsStep) {
    content = (
      <div className="">
        {modal && modal.expanded && (
          <div className="d-flex align-items-center cursor-pointer">
            <div onClick={() => setConsentModalStep(ConsentSteps.DefaultStep)}>
              <BackArrow />
            </div>

            {tnetPlatforms && <p className="font-size-14 font-medium mb-0">{tnetPlatforms.title[0]}</p>}
          </div>
        )}

        {modal && !modal.expanded && (
          <>
            <p className="font-size-14 font-bold mt-6px">{modal && modal.title}</p>

            <p className="font-size-14">{parse(firstParagraphDocument ?? '')}</p>

            <p className="font-size-14">{modal?.document[1]}</p>

            <p className="font-size-14">
              {modal?.document[2]}{' '}
              <a href={privacyPolicyAgreement?.href} target="_blank" className="text-decoration-underline font-size-14" style={{ color: '#222' }}>
                {privacyPolicyAgreement?.title}
              </a>
            </p>
          </>
        )}

        <div
          className="mt-4 overflow-auto"
          style={{ display: isMobile ? 'block' : 'grid', height: isMobile ? '190px' : 'auto', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: '16px' }}
        >
          {tnetPlatforms && tnetPlatforms.products.length > 0 && (
            <div
              className="border border-radius-12 p-16px cursor-pointer d-flex align-items-center justify-content-between"
              style={{ marginBottom: isMobile ? '12px' : 0 }}
              onClick={toggleEverythingSelected}
            >
              <p className="mb-0 font-size-14 font-medium">{langs.SelectAll}</p>

              <div className="custom-control custom-checkbox custom-checkbox-2 d-flex">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="all-platfoms"
                  checked={isEverythingSelected}
                  onChange={toggleEverythingSelected}
                  id="all-platfoms"
                />
                <label className="custom-control-label w-100 font-size-14 font-medium text-dark-gray" htmlFor="all-platfoms"></label>
              </div>
            </div>
          )}

          {tnetPlatforms &&
            tnetPlatforms.products.map((item) => (
              <div
                key={item.uuid}
                className="border border-radius-12 p-16px cursor-pointer d-flex align-items-center justify-content-between"
                style={{ marginBottom: isMobile ? '12px' : 0 }}
                onClick={() => selectPlatform(item.uuid)}
              >
                <img src={item.imageURL} alt={item.title} />

                <div className="custom-control custom-checkbox custom-checkbox-2 d-flex">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={item.uuid}
                    name={String(item.uuid)}
                    checked={selectedPlatforms.includes(item.uuid)}
                    onChange={() => selectPlatform(item.uuid)}
                  />
                  <label className="custom-control-label w-100 font-size-14 font-medium text-dark-gray" htmlFor={item.uuid}></label>
                </div>
              </div>
            ))}

          {partnerPlatforms && partnerPlatforms.products.length > 0 && (
            <div
              className="border border-radius-12 px-16px py-10px cursor-pointer d-flex align-items-center justify-content-between"
              onClick={toggleAllPartners}
            >
              <div>
                <p className="mb-0 font-size-14 font-medium">{langs.M_PartnerCompanies}</p>
                <p
                  className="mb-0 font-size-12 text-decoration-underline text-blue-100"
                  onClick={(event) => {
                    event.stopPropagation();
                    setConsentModalStep(ConsentSteps.PartnersStep);
                  }}
                >
                  {langs.M_PartnersList}
                </p>
              </div>

              <div className="custom-control custom-checkbox custom-checkbox-2 d-flex">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="partners"
                  name="partners"
                  checked={isAllPartnersSelected}
                  onChange={toggleAllPartners}
                />
                <label className="custom-control-label w-100 font-size-14 font-medium text-dark-gray" htmlFor={'partners'}></label>
              </div>
            </div>
          )}
        </div>

        <div className="mt-3 d-flex justify-content-end flex-column-reverse flex-md-row flex-wrap">
          {modal && !modal.expanded && (
            <button
              className="bg-white mr-3 hover-bg-white px-24px py-14px border-radius-8 w-md-100"
              style={{ border: 'none', color: '#A2A2A2', fontSize: '11px' }}
              onClick={notAgreeAllOffers}
            >
              {langs.Disagree}
            </button>
          )}

          <button
            className="bg-primary font-size-14 px-40px py-14px border-radius-8 w-md-100"
            style={{ border: 'none', color: '#222' }}
            onClick={agreeSelectedItems}
          >
            {langs.Agree}
          </button>
        </div>
      </div>
    );
  }

  if (consentModalStep === ConsentSteps.PartnersStep) {
    content = (
      <div className="">
        <div className="d-flex align-items-center cursor-pointer">
          <div onClick={() => setConsentModalStep(ConsentSteps.PlatformsStep)}>
            <BackArrow />
          </div>

          {partnerPlatforms && <p className="font-size-14 font-medium mb-0">{partnerPlatforms.title.join(' ')}</p>}
        </div>

        <div
          className="mt-4 overflow-auto"
          style={{
            height: isMobile ? '190px' : '400px',
          }}
        >
          <div
            className="border border-radius-12 p-16px cursor-pointer d-flex align-items-center justify-content-between"
            style={{marginBottom: '12px'}}
            onClick={toggleAllPartners}
          >
            <p className="mb-0 font-size-14">{langs.SelectAll}</p>

            <div className="custom-control custom-checkbox custom-checkbox-2 d-flex">
              <input
                type="checkbox"
                className="custom-control-input"
                id="partners"
                name="partners"
                checked={isAllPartnersSelected}
                onChange={toggleAllPartners}
              />
              <label className="custom-control-label w-100 font-size-14 font-medium text-dark-gray"
                     htmlFor={'partners'}></label>
            </div>
          </div>


          {partnerPlatforms &&
            partnerPlatforms.products.map((item) => (
              <div
                key={item.uuid}
                className="border border-radius-12 p-16px cursor-pointer d-flex align-items-center justify-content-between"
                style={{marginBottom: '12px'}}
                onClick={() => selectPartner(item.uuid)}
              >
                <p className="font-size-14 mb-0">{item.title}</p>

                <div className="custom-control custom-checkbox custom-checkbox-2 d-flex">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    name={String(item.uuid)}
                    id={item.uuid}
                    checked={selectedPartners.includes(item.uuid)}
                    onChange={() => selectPartner(item.uuid)}
                  />
                  <label className="custom-control-label w-100 font-size-14 font-medium text-dark-gray"
                         htmlFor={item.uuid}></label>
                </div>
              </div>
            ))}
        </div>

        <div className="mt-3 d-flex justify-content-end">
          <button
            className="bg-primary font-size-14 px-40px py-14px border-radius-8 w-md-100"
            style={{border: 'none', color: '#222'}}
            onClick={agreeSelectedItems}
          >
            {langs.Agree}
          </button>
        </div>
      </div>
    );
  }

  return <Modal classInner="custom-modal-inner fixed-mobile" customInner={true} showClose={false}
                Component={() => content}/>;
};
