const swiperConfig = (configName, data = {}) => {
	const config = {
	  searchHeader: {
		className: 'swiper-container w-100 ml-0',
		spaceBetween: 30,
		loop: false,
		cssMode: true,
		breakpoints: {
		  320: { touchRatio: 1, slidesPerView: 2.3, },
		  768: { touchRatio: 1, slidesPerView: 3 },
		  1200: { touchRatio: 0, slidesPerView: 3 },
		}
	  },
	  shopsSearchHeader: {
		className: 'swiper-container w-100 ml-0',
		spaceBetween: 30,
		loop: false,
		slidesPerView: 'auto',
		freeMode: true,
		cssMode: true,
	  },
	  searchTags: {
		className: 'swiper-container tags-slide ',
		slidesPerView: 'auto',
		spaceBetween: 10,
		freeMode: true,
		cssMode: true,
		navigation: {
		  nextEl: '.render-slide-next ',
		  prevEl: '.render-slide-prev'
		}
	  },
	  searchAge: {
		className: 'swiper-container tags-slide ',
		slidesPerView: 'auto',
		spaceBetween: 10,
		freeMode: true,
		navigation: {
		  nextEl: '.render-slide-next ',
		  prevEl: '.render-slide-prev'
		}
	  },
	  lastSearch: {
		className: 'swiper-container pt-28px w-100 col-12 px-md-0',
		slidesPerView: 3,
		spaceBetween: 24,
		loop: false,
		speed: 500,
		cssMode: true,
		navigation: {},
		breakpoints: {
		  320: { slidesPerView: 1.03, spaceBetween: 15 },
		  768: { slidesPerView: 2, spaceBetween: 20 },
		  1024: { slidesPerView: 3, spaceBetween: 20 }
		}
	  },
	  mainSearch: {
		className: 'swiper-container w-100',
		loop: false,
		speed: 500,
		cssMode: true,
		breakpoints: {
		  320: {slidesPerView: 2.2, spaceBetween: 8},
		  480: {slidesPerView: 3.2, spaceBetween: 16},
		  768: {slidesPerView: 3.2, spaceBetween: 16},
			800: {slidesPerView: 3.8, spaceBetween: 16},
		  1024: {slidesPerView: 3.2, spaceBetween: 16, touchRatio: 1},
		  1200: {slidesPerView: 4, spaceBetween: 16, touchRatio: 0},
		}
	  },
	  sectionsSliderOne: {
		className: 'swiper-container w-100',
		loop: false,
		spaceBetween:12,
		speed: 500,
		cssMode: true,
		breakpoints: {
		  320: {touchRatio: 1, slidesPerView: 2,},
		  480: {touchRatio: 1, slidesPerView: 4,},
		  768: {touchRatio: 1, slidesPerView: 3,},
		  1024: {touchRatio: 1, slidesPerView: 4,},
		  1200: {touchRatio: 0, slidesPerView: 5,},
		}
	  },
	  sectionsSliderTwo: {
		className: 'swiper-container w-100 pt-18px px-md-0 ',
		spaceBetween: 24,
		loop: false,
		speed: 500,
		navigation: {},
		cssMode: true,
		breakpoints: {
		  320: {slidesPerView: 2.3, spaceBetween: 15},
		  480: {slidesPerView: 2.3, spaceBetween: 15},
		  768: {slidesPerView: 3.3, spaceBetween: 20},
		  1024: {slidesPerView: 5.5, spaceBetween: 20}
		}
	  },
	  catsSection: {
		className: 'swiper-container w-100',
		loop: false,
		touchRatio: 0,
		cssMode: true,
		breakpoints: {
		  320:  {slidesPerView: 2.3, spaceBetween: 24, touchRatio: 1,},
		  475:  {slidesPerView: 3.3, spaceBetween: 24, touchRatio: 1,},
		  860:  {slidesPerView: 4.3, spaceBetween: 24, touchRatio: 1,},
		  1024: {slidesPerView: 5.3, spaceBetween: 20, touchRatio: 1,},
		  1200: {slidesPerView: 6, spaceBetween: 28, touchRatio: 0,}
		}
	  },
	  headerCategories: {
		className: 'swiper-container position-relative w-100 mr-12px swiper-header-cats d-none d-md-flex',
		loop: false,
		touchRatio: 0,
		cssMode: true,
		breakpoints: {
		  320: { slidesPerView: 'auto', spaceBetween: 12, touchRatio: 1 },
		  475: { slidesPerView: 'auto', spaceBetween: 24, touchRatio: 1 },
		  768: { slidesPerView: 'auto', spaceBetween: 24, touchRatio: 1 },
		  1024: { slidesPerView: 'auto', spaceBetween: 24, touchRatio: 1},
		  1366: { slidesPerView: 'auto', spaceBetween: 36, touchRatio: 0},
		}
	  },
	  salesSection: {
		className: 'swiper-container services-slider w-100 pt-16px col-12 px-md-0 ',
		direction: 'horizontal',
		slidesPerView: 3,
		spaceBetween: 24,
		speed: 500,
		cssMode: true,
		breakpoints: {
		  320: {slidesPerView: 1.1,spaceBetween: 15},
		  768: {slidesPerView: 2.1,spaceBetween: 15},
		  1024: {slidesPerView: 2.1,spaceBetween: 20},
		  1200: {slidesPerView: 3,spaceBetween: 20}
		}
	  },
	  servicesSection: {
		className: 'swiper-container services-slider w-100 pt-16px',
		direction: 'horizontal',
		slidesPerView: 3,
		slidesPerGroup: 3,
		spaceBetween: 30,
		speed: 500,
		cssMode: true,
		breakpoints: {
		  320: { slidesPerView: 1.1, slidesPerColumn: 2,  slidesPerGroup: 1, spaceBetween: 20 },
		  575: { slidesPerView: 2.1, slidesPerColumn: 2,  slidesPerGroup: 1, spaceBetween: 20 },
		  768: { slidesPerView: 2.1, slidesPerColumn: 2,  slidesPerGroup: 1, spaceBetween: 20 },
		  1024: { slidesPerView: 3, slidesPerColumn: 2, spaceBetween: 20 },
		}
	  },
	  vipsSection: {
		className: 'swiper-container w-100',
		loop: false,
		speed: 500,
		touchRatio: 0,
		cssMode: true,
		navigation: {},
		breakpoints: {
		  320: { slidesPerView: 2.2, spaceBetween: 16,   touchRatio: 1},
		  575: { slidesPerView: 3.7, spaceBetween: 24,   touchRatio: 1},
		  768: { slidesPerView: 5, spaceBetween: 24,   touchRatio: 1},
		  1024: { slidesPerView: 6, spaceBetween: 24,  touchRatio: 1},
		  1200: { slidesPerView: 8, spaceBetween: 24,  touchRatio: 0},
		}
	  },
	  shopsSection: {
		className: 'swiper-container w-100 pt-16px col-12 px-md-0 ',
		slidesPerView: 8,
		spaceBetween: 45,
		loop: false,
		speed: 500,
		cssMode: true,
		navigation: {},
		breakpoints: {
		  320: {slidesPerView: 2.6, spaceBetween: 20},
		  480: {slidesPerView: 3.6, spaceBetween: 20},
		  768: {slidesPerView: 4.6, spaceBetween: 20},
		  1024: {slidesPerView: 6.6, spaceBetween: 20},
		  1200: {slidesPerView: 8, spaceBetween: 20},
		}
	  },
	  newPrSectionOne: {
		className: 'swiper-container flex-1',
		slidesPerView: 1,
		spaceBetween: 24,
		loop: false,
		speed: 500,
		cssMode: true,
		breakpoints: {
		  320: { touchRatio: 0, slidesPerView: 1,  slidesPerColumnFill: 'row', slidesPerColumn: 2,     spaceBetween: 0,},
		  768: { touchRatio: 1, slidesPerView: 1 , spaceBetween: 0, },
		  1024: { touchRatio: 1, slidesPerView: 1 },
		  1366: { touchRatio: 1, slidesPerView: 1 },
		}
	  },
	  newHeaderSwiper: {
		className: 'swiper-container flex-1',
		slidesPerView: 1,
		spaceBetween: 24,
		loop: false,
		speed: 500,
		cssMode: false,
		breakpoints: {
			320: {slidesPerView: 3, spaceBetween: 20},
			480: {slidesPerView: 5.6, spaceBetween: 20},
			768: {slidesPerView: 6.6, spaceBetween: 20},
			1024: {slidesPerView: 7.6, spaceBetween: 20},
			1200: {slidesPerView: 8, spaceBetween: 20},
		  }
	  },
	  newPrSectionTwo: {
		className: 'swiper-container w-100 ',
		spaceBetween: 12,
		loop: false,
		cssMode: true,
		breakpoints: {
		  320: {touchRatio: 1, slidesPerView: 2,},
		  480: {touchRatio: 1, slidesPerView: 3,},
		  768: {touchRatio: 1, slidesPerView: 3,},
		  1024: {touchRatio: 1, slidesPerView: 4,},
		  1200: {touchRatio: 0, slidesPerView: 5,},
		}
	  },
	  financesTabs: {
		className: 'swiper-container tabs-slide',
		slidesPerView: 'auto',
		spaceBetween: 10,
		freeMode: true,
		cssMode: true,
	  },
	  productItem: {
		className: 'swiper-container w-100',
		freeMode: true,
		cssMode: true,
	  },
	  homeSlider: {
		className: 'swiper-container border-radius-md border-xl-radius-lg ',
		slidesPerView: 1,
		spaceBetween: 30,
		loop: true,
		navigation: {},
		cssMode: true,
		autoplay: {
		  delay: 3500,
		},
		on: {
		  init: () => {
			data.banner[0] && data.countBannerView(data.banner[0].banner_id)
		  },
		  realIndexChange: () => {
			if (data.ref.current !== null && !data.ref.current.destroyed) {
			  let ban = data.banner[data.ref.current.realIndex]
			  if (ban) {
				data.countBannerView(ban.banner_id)
			  }
			}
		  }
		}
	  },
	  productSlider: {
		className: 'swiper-container w-100 h-100',
		freeMode: true,
		cssMode: true,
		pagination: {
		  dynamicBullets: true,
		  bulletClass : 'swiper-pagination-bullet bg-white opacity-1',
		},
	  },
	  tabs: {
		className: 'swiper-container w-100 overflow-visible ',
		speed: 500,
		breakpoints: {
		  320:  {slidesPerView: 2, spaceBetween: 12, touchRatio: 1,},
		  500:  {slidesPerView: 3, spaceBetween: 24, touchRatio: 1,},
		  640:  {slidesPerView: 4, spaceBetween: 24, touchRatio: 1,},
		  768:  {slidesPerView: 4, spaceBetween: 24, touchRatio: 1,},
		  1024: {slidesPerView: 5, spaceBetween: 20, touchRatio: 1,},
		  1200: {slidesPerView: 3, spaceBetween: 28, touchRatio: 0,}
		}
	  },
timeleft: {
				className: 'swiper-container timeleft  overflow-visible',
				spaceBetween: 4,
				loop: false,
				breakpoints: {
					320: { touchRatio: 1, slidesPerView: 2, spaceBetween: 4 },
					768: { touchRatio: 1, slidesPerView: 3, spaceBetween: 4 },
				}
		},

	  catsSectionShop: {
		className: 'swiper-container flex-1 mx-20px mx-md-0',
		loop: false,
		touchRatio: 0,
		cssMode: true,
		breakpoints: {
		  320:  {slidesPerView: 2, spaceBetween: 12, touchRatio: 1,},
		  500:  {slidesPerView: 3, spaceBetween: 24, touchRatio: 1,},
		  640:  {slidesPerView: 4, spaceBetween: 24, touchRatio: 1,},
		  768:  {slidesPerView: 4, spaceBetween: 24, touchRatio: 1,},
		  1024: {slidesPerView: 5, spaceBetween: 20, touchRatio: 1,},
		  1200: {slidesPerView: 6, spaceBetween: 28, touchRatio: 0,}
		}
	  },
	  mainSliderShop: {
		className: 'swiper-container',
		slidesPerView: 1,
		spaceBetween: 30,
		loop: true,
		cssMode: true,
		// pagination: {
		//  dynamicBullets: true,
		//  bulletClass : 'swiper-pagination-bullet bg-white opacity-1',
		// },
	  },
	  productsSectionShop: {
		className: 'swiper-container  flex-1 col-12  overflow-hidden px-0 px-lg-15px mx-15px  mx-md-0',
		slidesPerView: 1,
		spaceBetween: 24,
		loop: false,
		cssMode: true,
		// touchRatio: 0
	  },
	  salesSectionShop:{
		className: 'swiper-container w-100 ',
		spaceBetween: 12,
		loop: false,
		cssMode: true,
		breakpoints: {
		  320: {touchRatio: 1, slidesPerView: 2,},
		  480: {touchRatio: 1, slidesPerView: 3,},
		  768: {touchRatio: 1, slidesPerView: 3,},
		  1024: {touchRatio: 1, slidesPerView: 4,},
		  1200: {touchRatio: 0, slidesPerView: 5,},
		}
	  },
	  myshopOffeers: {
		className: 'swiper-container w-100 px-15px',
		spaceBetween: 24,
		loop: false,
		cssMode: true,
		breakpoints: {
		  320: {slidesPerView: 1.1, spaceBetween: 12},
		  768: {slidesPerView: 2.1, spaceBetween: 20},
		  1024: {slidesPerView: 3, spaceBetween: 25, touchRatio:0},
	  
		}
	  },
	  shopsSectionShop: {
		className: 'swiper-container w-100 ',
		slidesPerView: 8,
		spaceBetween: 45,
		loop: false,
		speed: 500,
		cssMode: true,
		navigation: {},
		breakpoints: {
		  320: {slidesPerView: 3, spaceBetween: 20},
		  480: {slidesPerView: 4, spaceBetween: 20},
		  768: {slidesPerView: 5, spaceBetween: 20},
		  1024: {slidesPerView: 7, spaceBetween: 20},
		  1200: {slidesPerView: 8, spaceBetween: 20},
		}
	  },
	  discountGroup: {
		className: 'swiper-container services-slider w-100  flex-1 pt-16px pl-20px pl-md-40px pl-xl-40px ',
		direction: 'horizontal',
		slidesPerView: 3,
		spaceBetween: 24,
		speed: 500,
		cssMode: true,
		breakpoints: {
		  320: { slidesPerView: 2.3,  spaceBetween: 0,  touchRatio:1},
		  400: { slidesPerView: 2.6,  spaceBetween: 0,  touchRatio:1},
		  540: { slidesPerView: 4,  spaceBetween: 0,  touchRatio:1},
		  768: { slidesPerView: 4,    spaceBetween: 20, touchRatio:1},
		  1366:{ slidesPerView: 4,    spaceBetween: 20, touchRatio:0},
		}
	  },
	  similarSlider: {
		className: 'swiper-container w-100 pt-28px col-12 px-md-0',
		spaceBetween: 24,
		loop: false,
		speed: 500,
		cssMode: true,
		navigation: {},
		breakpoints: {
		  320: { slidesPerView: 1.3, spaceBetween: 15 },
		  480: { slidesPerView: 2.1, spaceBetween: 15 },
		  768: { slidesPerView: 3.1, spaceBetween: 20 },
		  1024: { slidesPerView: 4.1, spaceBetween: 20 },
		  1200: { slidesPerView: 5, spaceBetween: 20 }
		}
	  },
	  onlineProducts: {
		className: 'swiper-container w-100  col-12 px-md-0',
		spaceBetween: 24,
		loop: false,
		speed: 500,
		cssMode: true,
		navigation: {},
		breakpoints: {
		  320: { slidesPerView: 1.3, spaceBetween: 15 },
		  480: { slidesPerView: 2.1, spaceBetween: 15 },
		  768: { slidesPerView: 3.1, spaceBetween: 20 },
		  1024: { slidesPerView: 4.1, spaceBetween: 20 },
		  1200: { slidesPerView: 5, spaceBetween: 20 }
		}
	  },
	  catsSectionSearchShop: {
		className: 'swiper-container w-100',
		loop: false,
		cssMode: true,
		// touchRatio: 0,
		breakpoints: {
		  320: { slidesPerView: 2, spaceBetween: 24, },
		  640: { slidesPerView: 3, spaceBetween: 24, },
		  768: { slidesPerView: 3, spaceBetween: 24, },
		  1024: { slidesPerView: 5, spaceBetween: 20, },
		  1200: { slidesPerView: 6, spaceBetween: 28, }
		}
	  },
	  offerSliderSearchShop: {
		className: 'swiper-container col-12 px-15px sdsd',
		slidesPerView: 1,
		spaceBetween: 30,
		loop: true,
		cssMode: true,
		pagination: {
		  el: '.swiper-pagination',
		  type: 'bullets',
		  clickable:true
		}
	  },
	  popularSectionSearchShop: {
		className: 'swiper-container w-100 ',
		spaceBetween: 12,
		loop: false,
		cssMode: true,
		breakpoints: {
		  320: { touchRatio: 1, slidesPerView: 2.3, },
		  768: { touchRatio: 1, slidesPerView: 'auto' },
		  1200: { touchRatio: 0, slidesPerView: 'auto' },
		}
	  },
	  salesSectionSearchShop: {
		className: 'swiper-container w-100 flex-1 ',
		spaceBetween: 12,
		loop: false,
		cssMode: true,
		breakpoints: {
		  320: {touchRatio: 1, slidesPerView: 2,},
		  480: {touchRatio: 1, slidesPerView: 3,},
		  768: {touchRatio: 1, slidesPerView: 3,},
		  1024: {touchRatio: 1, slidesPerView: 4,},
		  1200: {touchRatio: 0, slidesPerView: 5,},
		}
		
	  },
	  galleryProductFull: {
		className: 'swiper-container border-radius-12 product-lg-container h-100',
		slidesPerView: 'auto',
	  },
	  galleryLightBox: {
		className: 'swiper-container  h-100',
		slidesPerView: 1,
		spaceBetween: 12,
		initialSlide: data.activeThumb,
		speed: 500,
	  },
	  galleryLightBoxThumbnail: {
		className: 'swiper-container w-100',
		direction: 'horizontal',
		mousewheel: true,
		spaceBetween: 12,
		slidesPerView: 'auto',
		watchSlidesProgress: true,
		freeMode: true,
		slideToClickedSlide: true,
		cssMode: true,
		initialSlide: data.activeThumb,
		
	  },
	  thumbnailProductFull: {
		className: 'swiper-container w-100',
		direction: 'horizontal',
		mousewheel: true,
		spaceBetween: 8,
		slidesPerView: 5,
		watchSlidesProgress: true,
		freeMode: true,
		slideToClickedSlide: true,
		// cssMode: true,
		initialSlide: data.activeThumb ? data.activeThumb : 0
	  },
	  tradeinPreview: {
		className: 'swiper-container h-100',
		direction: 'horizontal',
		slidesPerView: 1,
		spaceBetween: 24,
		speed: 1000,
		touchRatio: 0,
		cssMode: true,
	  },
	  tradeinInnerPreview: {
		className: 'w-100 h-100',
		slidesPerView: 1,
		cssMode: true,
	  },
	  tradeinHeader: {
		className: 'swiper-container w-100 overflow-visible',
		spaceBetween: 12,
		loop: false,
		cssMode: true,
		pagination: {
		  el: '.swiper-pagination',
		  type: 'bullets',
		  clickable:true
		},
		breakpoints: {
		  320: { touchRatio: 1, slidesPerView: 1, spaceBetween: 20},
		  768: { touchRatio: 1, slidesPerView: 2, spaceBetween: 24 },
		  1024: { touchRatio: 0, slidesPerView: 3, spaceBetween: 24   },
		  1200: { touchRatio: 0, slidesPerView: 3, spaceBetween: 64 },
		  1300: { touchRatio: 0, slidesPerView: 3, spaceBetween: 130 },
		}
	  },
	  tradeinInfo: {
		className: 'swiper-container w-100 overflow-visible',
		spaceBetween: 12,
		loop: false,
		cssMode: true,
		breakpoints: {
		  320: { touchRatio: 1, slidesPerView: 2.6, spaceBetween: 12 },
		  768: { touchRatio: 1, slidesPerView: 3, spaceBetween: 12 },
		  1024: { touchRatio: 0, slidesPerView: 3, },
		  1200: { touchRatio: 0, slidesPerView: 3, },
		}
	  },
		safetySldier: {
		className: 'swiper-container flex-1 swiper-safety-sldier',
		slidesPerView: 1,
		// loop: true,

		pagination : {
			type: 'fraction',
		}
		}
	}
  
	return {
	  swiperProps: config[configName] || {}
	}
  }
  
  export default swiperConfig
  