import { AppDataContext } from 'contexts/appData';
import { ModalsContext } from 'contexts/modals';
import React, { useContext, useState } from 'react';
import { Loader } from 'components';

import { ReactComponent as DeleteCard } from 'resources/img/icons/delete-card.svg';

const CardDeleteWarning = ({ callback, data, ...props }) => {
  const { uuid } = data;
  const { api, langs } = useContext(AppDataContext);
  const { callResponse } = useContext(ModalsContext);
  const [loading, setLoading] = useState(false);

  const onDelete = () => {
    setLoading(true);
    api
      .deleteUserCard({ UserCardID: uuid })
      .then(() => {
        callback(null, 'success');
        callback(null, 'destroy');
      })
      .catch(() => {
				callResponse({ statusCode: false })
			})
      .finally(() => setLoading(false));
  };

  return (
    <div className="custom-modal-wrapper">
      <div className="popup-content d-flex justify-content-center align-items-center flex-column">
        {loading && (
          <div className="position-absolute w-100 h-100 bg-white">
            <Loader />
          </div>
        )}

        <DeleteCard />

        <div className="font-size-16 font-bold mt-24px text-primary-text-color">{langs.NM_DeleteCard}</div>
        <div className="font-size-16 font-base mt-16px text-primary-text-color text-center px-24px">{langs.NM_DeleteCardWarning}</div>

        <div className="h-1px w-100 bg-gray-20 my-24px"></div>

        <div className="d-flex w-100">
          <div className="btn py-14px font-size-14 bg-gray-20 w-100 mr-20px" onClick={() => callback(null, 'destroy')}>
            {langs.Cancel}
          </div>
          <div className="btn py-14px font-size-14 bg-error-red text-white w-100" onClick={onDelete}>
            {langs.Delete}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDeleteWarning;
