const getMethodsConfig = (props) => {
    let langs           = props.langs
    let methods         = props.methods
    let config          = props.CONFIG

    const ReturnURL     = config.HOST.appUrl + props.getPathname().substring(1)

    // 'inTesting'
    const paymentsMethodsList = [
        {
            id: 1,
            active: true,
            style: {
                title: langs.PayWithBalance,
                icon: 'balance-icon-i',
            },
            procedures: [
                'checkBalance'
            ],
            request: {
                PaymentMethod: 'balance',
            }
        },
        {
            id: 2,
            active: true,
            style: {
                title: langs.PayWithCart,
                icon: 'pay-card w-48px h-32px'
            },
            procedures: [],
            request: {
                ReturnURL: ReturnURL,
                PaymentType: 'balance',
                PaymentMethod: 'card',
                PayWithCard: 1
            },
        },
        {
            id: 8,
            active: true,
            style: {
                title: langs.NM_PayWithSubPackage,
                icon: ''
            },
            procedures: [
                'checkPromotions'
            ],
            request: {
                
            },
        },
        {
            id: 12,
            active: true,
            style: {
                // title: langs.NM_GooglePay,
                title: 'Google Pay',
                icon: 'google-pay w-48px h-32px'
            },
            procedures: [],
            request: {
                ReturnURL: ReturnURL,
                PaymentType: 'balance',
                PaymentMethod: 'card',
                PayWithCard: 1,
                PayWith: 'google-only'
            },
        },
        {
            id: 11,
            active: true,
            style: {
                title: 'Apple Pay',
                icon: 'applePay-i w-48px h-32px'
            },
            procedures: [],
            request: {
                ReturnURL: ReturnURL,
                PaymentType: 'balance',
                PaymentMethod: 'card',
                PayWithCard: 1,
                PayWith: 'apple-only'
            },
        },
        // {
        //     id: 3,
        //     active: true,
        //     style: {
        //         title: langs.PayWithErguli,
        //         icon: 'love-card-icon-i'
        //     },
        //     procedures: [],
        //     request: {
        //         ReturnURL: ReturnURL,
        //         PaymentType: 'balance',
        //         PaymentMethod: 'points',
        //         PayWithCard: 1,
        //         PayWith: 'points',
        //     },
        // },
        {
            id: 4,
            active: false,
            style: {
                title: langs.payWithExpress,
                icon: 'paybox-i'
            },
            procedures: [],
            request: {
                PaymentType: 'emoney',
            }
        },
        {
            id: 5,
            active: false,
            style: {
                title: langs.payWithEmoneyBalance,
                icon: ''
            },
            procedures: [],
            request: {
                PaymentType: 'emoneybalance',
            }
        },
        {
            id: 6,
            active: false,
            style: {
                title: langs.BuyWithSpace,
                icon: ''
            },
            procedures: [],
            request: {
                PaymentType: 'space',
            }
        },
        {
            id: 7,
            active: false,
            style: {
                title: langs.TbcInstallment,
                icon: ''
            },
            procedures: [],
            request: {
                PaymentType: 'tbcinstallment',
            }
        },
       
    ]

    if (props.procedures) {
        props.procedures.map((key) => {
            let index = paymentsMethodsList.findIndex(o => o.id === key.method)
            if (index > -1 && !paymentsMethodsList[index].procedures.includes(key.procedure)) {
                paymentsMethodsList[index].procedures.push(key.procedure)
            }
            return key
        })
    }

    return paymentsMethodsList.filter(o => (o.active && methods.includes(o.id)))
}

export default getMethodsConfig
