import React from 'react'
import PropTypes from 'prop-types'

DeleteIcon.propTypes = {
    className: PropTypes.string
}

export default function DeleteIcon({ className = '' } = '') {
    return (
        <span className={`svg-icon svg-md-icon ${className}`}>

            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.997" viewBox="0 0 12 11.997">
            <g id="Group_4491" dataname="Group 4491" transform="translate(-26 -204)">
                <g id="Group_4490" dataname="Group 4490" transform="translate(-5 -9)">
                <path id="Stroke_97" dataname="Stroke 97" d="M0,0V1" transform="translate(36 219.995)" fill="none" stroke="#ff665e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path id="Stroke_100" dataname="Stroke 100" d="M8,0V6.3a.684.684,0,0,1-.667.7H.667A.684.684,0,0,1,0,6.3V0" transform="translate(33 216.997)" fill="none" stroke="#ff665e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path id="Stroke_101" dataname="Stroke 101" d="M0,0H10" transform="translate(32 216.998)" fill="none" stroke="#ff665e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path id="Stroke_102" dataname="Stroke 102" d="M0,2V.667A.667.667,0,0,1,.667,0H3.333A.667.667,0,0,1,4,.667V2" transform="translate(35 214)" fill="none" stroke="#ff665e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path id="Stroke_97-2" dataname="Stroke 97" d="M0,0V1" transform="translate(38 219.995)" fill="none" stroke="#ff665e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                </g>
            </g>
            </svg>

        </span>
    )
}








