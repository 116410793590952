import React from 'react'
import { EmptyStates } from 'components'
const OffersEmpty = (props) => {
    const Icon = () => {
        return (
            <div className=""></div>
        )
    }

    const Title = () => {
        return (
            <div className="mb-16px font-size-16 font-bold-caps text-dark">
                {props.title}
            </div>
        )
    }
    return <EmptyStates {...{
        Icon, 
        Title, 
        classIcon:"svg-i empty-offers mb-24px",
        classContainer:'mt-8px px-15px',
        classInner: "border-radius-md bg-white pt-100px pb-200px"
    }} />
}

export default OffersEmpty