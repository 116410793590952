import React from 'react'

const Component = (props) => {
    return (
        <div className="d-flex justify-content-between  pt-24px">
            <button type="button" className="btn bg-transparent font-base font-size-12 line-height-20 font-weight-normal text-blue-gray px-0"
                onClick={(e) => props.changeStep(e, 0, props.values)}>
                {props.currentStep === 1 ? props.langs.ReturnBack : props.langs.Close}
            </button>
            {props.currentStep === 1 ?
                <button type={props.currentStep === 1 ? "submit" : "button"}
                    className={`btn bg-gradient font-bold-caps font-size-13 font-weight-bold text-white px-40px py-16px ${props.values.AgreeTerms !== 1 ? 'disabled' : ''}`}
                    disabled={props.values.AgreeTerms !== 1}
                >
                    {props.langs.Send}
                </button>
                :
                <button type="button" className="btn bg-gradient font-bold-caps font-size-13 font-weight-bold text-white px-40px py-16px"
                    disabled={props.values.Amount < 0 || props.values.Period < 3 || props.values.Period > 18}
                    onClick={(e) => props.changeStep(e, 1, props.values)}>
                    {props.langs.Continue}
                </button>
            }
        </div>
    )
}

export default Component