import { DateAndTime } from './parsers/_DateAndTime';
import { LocationsParser } from './parsers/_LocationsParser';
import { AttrsParser } from './parsers/_AttrsParser';
import { BrandsParser } from './parsers/_BrandsParser';
// import { ModelsParser } from './parsers/_ModelsParser';
import { Catsparser } from './parsers/_CatsParser';
import { TransactionsParser } from './parsers/_TransactionsParser';
import { WarrantyPeriodsParser } from './parsers/_WarrantyPeriodsParser';
export class AppDataParser {
    data = {}

    constructor(d) {
        this.data = {
            DateAndTime : new DateAndTime().get(),
            Locs: d.Locs ? (new LocationsParser(d.Locs)).convert().get() : null,
            Cats: d.Cats ? (new Catsparser(d.Cats)).convert().get() : null,
            Attrs: d.Attrs ? (new AttrsParser(d.Attrs)).convert().get() : null,
            Brands: d.CatBrands ? (new BrandsParser(d.CatBrands)).convert().get() : null,
            // Models: d.Models ? (new ModelsParser(d.Models)).convert().get() : null,
            Langs: d.Langs ? d.Langs : null,
            LegalForms: d.LegalForms ? d.LegalForms : null,
            TransactionTypes: d.TransactionGroupsAndTypes ? 
                (new TransactionsParser(d.TransactionGroupsAndTypes.Data.Types)).convert().get()
            : null,
            TransactionGroups: d.TransactionGroupsAndTypes ? 
                (new TransactionsParser(d.TransactionGroupsAndTypes.Data.Groups)).convert().get()
            : null,
            OrderStatuses: d.OrderStatuses ? d.OrderStatuses : null,
            ReportReasons: d.ReportReasons ? d.ReportReasons : null,
            BlockReasons: d.BlockReasons ? d.BlockReasons : null,
            SellOnlineCats: d.SellOnlineCats ? d.SellOnlineCats : null,
            WarrantyPeriods: d.WarrantyPeriods ? (new WarrantyPeriodsParser(d.WarrantyPeriods)).convert().get() : null,
            CatSections: d.CatSections ? d.CatSections : null,
        }   
    }

    get() {
        // console.log(this.data)
        return this.data;
    }
}
