import React, { useEffect, useState } from 'react';

import { CategoriesIcon } from 'components';
import { API } from '../../../services';

const MobileCategories = (props) => {
  const api = API();

  const [jobsCategories, setJobsCategories] = useState([]);
  const [showJobsCategories, setShowJobsCategories] = useState(false);

  useEffect(() => {
    api.getJobsCategories().then((response) => {
      setJobsCategories(response.data);
    });
  }, []);

  const jobsMainCategory = jobsCategories.find((jobCategory) => jobCategory.parent_id === 0);
  const childCategories = jobsCategories.filter((category) => category.parent_id !== 0);

  const clickJobsMainCategory = () => {
    window.open(jobsMainCategory?.url, '_blank');
  };

  return (
    <div className={`home-categories`}>
      <div className="search-filter bg-white border-radius h-100">
        <div className="filter-header d-flex align-items-center justify-content-between font-size-12 font-medium-caps font-weight-normal text-grey-light px-20px py-24px">
          {props.langs.Categories}
          <div className="btn bg-white rounded-circle p-8px" onClick={(e) => props.toggleMenu(e)}>
            <i className="svg-i close-gray-i"></i>
          </div>
        </div>
        <div className="show-modal-content">
          <div className="search-filter-body">
            <ul className="cats-list d-flex flex-column align-items-start  pr-16 pt-12px pb-28px">
              {props.category > 0 && (
                <li className="cats-item mt-40px border-none">
                  <div
                    className="text-decoration-none d-flex align-items-center pl-24px parent"
                    onClick={(e) => props.categorySelect(e, props.appData.Cats.List[props.category].ParentId)}
                  >
                    <span className="cats-arrow">
                      <i className="svg-i arrow-filter"></i>
                    </span>
                    <span className="mr-10px font-size-14 font-medium-caps text-dark-dark">{props.appData.Cats.List[props.category].Title}</span>
                    <i className="svg-i svg-i-sm arrow-down-dark-i ml-auto"></i>
                  </div>
                </li>
              )}

              {props.appData.Cats.Hierarchy[props.category] !== null &&
                props.appData.Cats.Hierarchy[props.category] !== undefined &&
                !showJobsCategories &&
                props.appData.Cats.Hierarchy[props.category].map((CatID, i) => (
                  <li
                    className="cats-item py-21px my-2px w-100 d-flex align-items-start justify-content-between px-20px"
                    key={i}
                    onClick={(e) => props.categorySelect(e, CatID)}
                  >
                    <div className="text-decoration-none d-flex flex-1 align-items-start ">
                      <CategoriesIcon CatID={CatID} className="cats-icon mr-30px" />
                      <span
                        className={`home-cats-item-text mr-10px font-size-14 font-medium-caps text-dark-dark w-100 ${
                          props.params.CatID === CatID ? 'active' : ''
                        }`}
                      >
                        {props.appData.Cats.List[CatID].Title}
                        <span className="font-size-12 font-base text-blue-gray">{` (${props.appData.Cats.List[CatID].ProductsCount})`}</span>
                      </span>
                    </div>
                    <button className="btn p-8px bg-middle-gray" onClick={(e) => props.goToCategory(e, CatID)}>
                      <i className="svg-i cats-arr ml-auto"></i>
                    </button>
                  </li>
                ))}

              {props.category === 0 && jobsMainCategory && !showJobsCategories && (
                <li
                  className="cats-item py-21px my-2px w-100 d-flex align-items-start justify-content-between px-20px"
                  onClick={(event) => {
                    event.preventDefault();
                    setShowJobsCategories(true);
                  }}
                >
                  <div className="text-decoration-none d-flex flex-1 align-items-start">
                    <CategoriesIcon CatID={9999} className="cats-icon mr-30px" />
                    <span className={`home-cats-item-text mr-10px font-size-14 font-medium-caps text-dark-dark w-100`}>
                      {jobsMainCategory?.title}{' '}
                      <span className="font-size-12 font-base text-blue-gray">{`(${
                        jobsMainCategory && jobsMainCategory.products_count ? jobsMainCategory?.products_count : 0
                      })`}</span>
                    </span>
                  </div>
                  <button className="btn p-8px bg-middle-gray" onClick={clickJobsMainCategory}>
                    <i className="svg-i cats-arr ml-auto"></i>
                  </button>
                </li>
              )}

              {showJobsCategories && (
                <li className="cats-item mt-40px border-none">
                  <div
                    className="text-decoration-none d-flex align-items-center pl-24px parent"
                    onClick={(event) => {
                      event.preventDefault();
                      setShowJobsCategories(false);
                    }}
                  >
                    <span className="cats-arrow">
                      <i className="svg-i arrow-filter"></i>
                    </span>
                    <span className="mr-10px font-size-14 font-medium-caps text-dark-dark">{jobsMainCategory?.title}</span>
                    <i className="svg-i svg-i-sm arrow-down-dark-i ml-auto"></i>
                  </div>
                </li>
              )}

              {showJobsCategories &&
                childCategories.map((category, i) => (
                  <li
                    className="cats-item py-21px my-2px w-100 d-flex align-items-start justify-content-between px-20px"
                    key={i}
                    onClick={(event) => {
                      event.preventDefault();
                      window.open(category?.url, '_blank');
                    }}
                  >
                    <div className="text-decoration-none d-flex flex-1 align-items-start ">
                      <CategoriesIcon CatID={category.id} className="cats-icon mr-30px" />
                      <span className={`home-cats-item-text mr-10px font-size-14 font-medium-caps text-dark-dark w-100`}>
                        {category?.title}
                        <span className="font-size-12 font-base text-blue-gray">{` (${category?.products_count})`}</span>
                      </span>
                    </div>
                  </li>
                ))}

            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileCategories;
