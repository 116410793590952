import CONFIG from 'core/config';
/**
 * Converts Georgian chars to english corresponding chars
 * @param str
 * @returns
 */
const convertUrlComponent = (str) => {
	if (!str) {
		return '';
	}
	str = str.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '');
	let geo = ['ა','ბ','გ','დ','ე','ვ','ზ','თ','ი','კ','ლ','მ','ნ','ო','პ','ჟ','რ','ს','ტ','უ','ფ','ქ','ღ','ყ','შ','ჩ','ც','ძ','წ','ჭ','ხ','ჯ','ჰ'];
	let eng = ['a','b','g','d','e','v','z','T','i','k','l','m','n','o','p','j','r','s','t','u','f','q','R','y','sh','ch','c','Z','w','W','x','j','h'];
	geo.map((e, j) => {
		str = str.replace(new RegExp(e, 'g'), eng[j]);
		return '';
	});
	let rus = ['а','б','в','г','д','е','ё','ж','з','и','й','к','л','м','н','о','п','р','с','т','у','ф','х','ц','ч','ш','щ','ъ','ы','ь','э','ю','я'];
	let engRus = ['a','b','v','g','d','e','yo','zh','z','i','y','k','l','m','n','o','p','r','s','t','u','f','x','ts','ch','sh','sh','y','i','y','e','yu','ya'];
	rus.map((e, j) => {
		str = str.toLowerCase().replace(new RegExp(e, 'g'), engRus[j]);
		return '';
	});
	return str;
}
const seoUrlEncode = (str) => {
	return str.trim()
	.replace(new RegExp('%', 'g'),'')
	.replace(',', '')
	.replace(/\//g, '-')
	.replace(/ /gi, '-')
	.replace('--', '-')
	.replace(/[^a-zა-ჰа-я0-9\-_ ]/i,'')
	.replace(/!/g, '%21')
	.replace(/'/g, '%27')
	.replace(/\(/g, '%28')
	.replace(/\)/g, '%29')
	.replace(/\fifqi/g, '%2A')
	.replace(/~/g, '%7E')
	.replace(/%20/g, '+');
}
const urlCleaner = (str) => {
	return str.trim()
	.replace(new RegExp('%', 'g'),'')
	.replace(',', '')
	.replace(/\//g, '')
	.replace(' + ', '-')
	.replace(/ /gi, '-')
	.replace('--', '-')
	.replace(/[^a-zა-ჰа-я0-9\-_ ]/i,'')
	.replace(/!/g, '')
	.replace(/'/g, '')
	.replace(/\(/g, '')
	.replace(/\)/g, '')
	.replace(/\fifqi/g, '')
	.replace(/~/g, '')
	.replace(/%20/g, '');
}
const formatMoney = (n, c, d, t) => {
	var a = isNaN(c = Math.abs(c)) ? 2 : c, 
		l = d === undefined ? "." : d, 
		m = t === undefined ? "," : t, 
		s = n < 0 ? "-" : "", 
		i = parseInt(n = Math.abs(+n || 0).toFixed(a)) + "", 
		j = i.length > 3 ? i.length % 3 : 0;
		return s + (j ? i.substr(0, j) + m : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + m) + (a ? l + Math.abs(n - i).toFixed(a).slice(2) : "");
}
/**
 * Transforms key value array to object properties
 * @param d Data - key value array
 * @param o object to work on
 * @param m Properties Map
 * @returns Generic object
 */
const transformToObject = (d, o, m) => {
	m = m || o['propMap'] || [];
	m.forEach(p => {
		if (d && (d.hasOwnProperty(p.json) || (Array.isArray(p.json) && getValueIfExist(d, p.json)))) {
			const jsonValue = (d[p.json] || d[p.json] === 0) ? d[p.json] : getValueIfExist(d, p.json);
			if (Array.isArray(jsonValue)) {
				for (let i in jsonValue) {
					if (jsonValue.hasOwnProperty(i)) {
						o[p.js] = o[p.js] || [];
						o[p.js].push(transformType(p['dataType'], jsonValue[i]));
					}
				}
			} else {
				o[p.js] = transformType(p['dataType'], jsonValue);
			}
		}
	});
	return o;
}
/**
 * Converts variable to provided type
 * @param p Variable
 * @param t Type [int, string, boolean, intArray, stringArray]
 * @param additionalType For serializedArrays Type [int, string, boolean, intArray, stringArray]
 */
const transformType = (t, v, ad) => {
	t = t || 'string'; ad = ad || 'string';
	if (v === null || v === 'null' || v === undefined) {
		return '';
	}
	if (t === 'string') {
		return String(v);
	}
	if (t === 'int') {
		return parseInt(v, 10);
	}
	if (t === 'float') {
		return parseFloat(v);
	}
	if (t === 'boolean' || t === 'bool') {
		return (isNaN(v)) ? (v ? true : false) : parseInt(v, 10) === 1;
	}
	if (t === 'arrayFromSerialized') {
		return unserializeArrayString(v, null, ad);
	}
	if (t === 'serializedFromArray') {
		return serializeArrayString(v, null);
	}
	return new t(v);
}

/**
 * Checks if props are exist in obj and [@returns] value of obj[props[0]][props[n]]
 * It comes handy when you have something like this: AppData.get('EstateTypes')['TypesList'][productData.estateTypeID]['estateTypeTitle'],
 * @param obj parent object
 * @param props obj's properties
 */
const getValueIfExist = (obj, props) => {
	if (!obj || !Array.isArray(props)) {
		return '';
	}
	props.every((prop) => {
		if (obj.hasOwnProperty(prop) && obj[prop]) {
			obj = obj[prop];
			return true;
		} else {
			obj = '';
			return false;
		}
	});
	return obj;
}

/**
 * Unserializes Array
 * @param s
 * @param [d]
 * @returns
 */
const serializeArrayString = (s, d) => {
	d = d || '.';
	return s.join('.');
}
/**
 * Serializes Array
 * @param s
 * @param [d]
 * @param [t]
 * @returns
 */
const unserializeArrayString = (s, d, t) => {
	d = d || '.'; t = t || null;
	let a = s.split(d);
	return t ? a.map(x => transformType(t, x)) : a;
}
/**
 * Return seo prefix for category
 * @param c
 * @returns p (string)
 */
const urlPrefix = (c) => {
	if (c === null || c === undefined) {
		return '';
	}
	let prefix = "iyideba-"
	if (c === CONFIG.COMMON.RENT_CAT_ID) {
		prefix = "qiravdeba-"
	}else if (CONFIG.COMMON.SERVICES_CAT_IDS.indexOf(c) !== -1) {
		prefix = ""
	}
	return prefix
}
/*
* Returns Sum from an array with property value
* @param data
* @param key
* */
const sumProperty = (data, key) => {
	return data.reduce((a, b) => a + (parseInt(b[key]) || 0), 0);
}

const sortArrayByNumberOrTitle = (a, b) => {
	if (a.sortOrder > 0 || b.sortOrder > 0) {
		return (a.sortOrder < b.sortOrder) ? -1 : (a.sortOrder > b.sortOrder) ? 1 : 0;
	} else {
		return (a.attrTitle < b.attrTitle) ? -1 : (a.attrTitle > b.attrTitle) ? 1 : 0;
	}
}

/**
* Checks if object is empty
* @param e object
* return boolean
*/
const checkObjEmpty = (e) => {
	return (e && Object.keys(e).length > 0) ? true : false
}
/**
* Return object length
* @param e object
* return int
* return 0 on error
*/
const objLength = (e) => {
	return e ? Object.keys(e).length : 0
}
/**
* Checks if array is empty
* @param e array
* return boolean
*/
const checkArrEmpty = (e) => {
	return (e && e.length > 0) ? true : false
}
/**
* Generates random int from min and max
* @param min int
* @param min int
* return int
*/
const randomInt = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}
/**
* Randomises Array
* @param a array
* return array
*/
const shuffleArray = (a) => {
	for (let i = a.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[a[i], a[j]] = [a[j], a[i]];
	}
	return a
}
/**
* Checkes and pases Numeric Value
* @param v value
* return processed value
*/
const checkNumeric = (v) => {
	if (/^\d*\.?\d*$/.test(v.toString())) {
		return v;
	}
	return ''
}
/**
* checks String length
* @param v element
* @param m max length
* return processed value
*/
const checkStringLength = (v, m) => {
	if (v.toString().length > m) {
		return v.substring(0, m);
	}
	return v
}
/**
* Return current page url
*/
const currentPage = () => {
	return window.location.pathname + window.location.search
}

const formatPhoneNumber = (phoneNumber, format) => {
	try {
		let cleaned = ('' + phoneNumber)
			.replaceAll("*", "0")
			.replace(/\D/g, '')
		;
		if (cleaned.substr(0, 3) === "995") {
			cleaned = cleaned.substr(3, cleaned.length);
		}
		let match = cleaned.match(/^(\w{3})(\w{2})(\w{2})(\w{2})$/);
		switch (format) {
			default:
				return ([
					match[1],
					match[2],
					match[3],
					match[4],
				].join(''));
			case 1:
				return ([
					match[1],
					' ',
					match[2],
					'-',
					match[3],
					'-',
					match[4],
				].join(''));
			case 2:
				return ([
					'995',
					match[1],
					match[2],
					match[3],
					match[4],
				].join(''));
			case 3:
				match = cleaned.match(/^(\w{3})(\w{3})(\w{3})$/);
				return ([
					match[1],
					' ',
					match[2],
					'-',
					match[3]
				]).join('');
			case 4:
				match = cleaned.match(/^(\w{3})(\w{3})(\w{3})$/);
				return ([
					match[1],
					match[2],
					match[3]
				]).join('');
		}
	} catch (ex) {}
	return phoneNumber;
}
const redirectWindow = (assign = true, getLang = 'ka', linkType = null, whereTo = '', more = '') => {
	let type = linkType === null ? 'register' : linkType ? 'login' : 'logout';
	let url = `${CONFIG.HOST.authUrl}${getLang}/user/${type}?Continue=${encodeURIComponent(whereTo)}${more}`;
	if (assign) {
		window.location.assign(url);
	} else {
		window.location.href = url;
	}
}

function generateId(length){
	const a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
	const b = [];
	for (let i=0; i<length; i++) {
		let j = (Math.random() * (a.length-1)).toFixed(0);
		b[i] = a[j];
	}
	return b.join("");
}

const getFileSize = (sizeInBytes) => {
	if(!sizeInBytes) return null;

	const bytesInMb = 1048576;
	const bytesInKb = 1024;

	return sizeInBytes > bytesInMb ? (sizeInBytes / bytesInMb).toFixed(2) + ' MB' : (sizeInBytes / bytesInKb).toFixed(0) + ' KB';
}

const debounce = (func, delayTime= 300) => {
	let timeout;
	return function(...args) {
		const context = this; //get the context when the func is invoked
		clearTimeout(timeout); //cleared everytime user types in
		timeout = setTimeout(function() {
			func.apply(context, args); //execute the function
		}, delayTime);
	}
}

const Functions = {
	convertUrlComponent,
	seoUrlEncode,
	urlCleaner,
	formatMoney,
	sumProperty,
	transformToObject,
	transformType,
	getValueIfExist,
	serializeArrayString,
	unserializeArrayString,
	urlPrefix,
	sortArrayByNumberOrTitle,
	checkObjEmpty,
	objLength,
	checkArrEmpty,
	randomInt,
	shuffleArray,
	checkNumeric,
	checkStringLength,
	currentPage,
	formatPhoneNumber,
	redirectWindow,
	generateId,
	getFileSize,
	debounce,
};

export default Functions;