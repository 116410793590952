import { getCookie, setCookie } from 'react-use-cookie';

const Cookie = () => {
  const _get = (name) => {
    return getCookie(name);
  }
  const _set = (name, value, options) => {
    return setCookie(name, value, options);
  }
  const _remove = (name, options = {}) => {
    return setCookie(name, '', {...options, ...{days: 0 }});
  }
  const _clear = () => {
    document.cookie.split(";").forEach((c) => {
			document.cookie = c
				.replace(/^ +/, "")
				.replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
		});
  }
  return { _get, _set, _remove, _clear };
}
export default Cookie;