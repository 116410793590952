export class WarrantyPeriodsParser {
  data;
  convertedData;
  constructor(data) {
    this.data = data;
    return this;
  }
  convert() {
    this.convertedData = [];
    Object.entries(this.data).map(([k, v]) => {
      return this.convertedData.push({
        value: parseInt(k),
        label: v
      });
    });
    return this;
  }

  get() {
    return this.convertedData || null;
  }
}
