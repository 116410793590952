import React, {useContext} from 'react'
import { EmptyStates } from 'components'
import { AppDataContext } from 'contexts/appData';
const ShopsEmpty = () => {
    const { langs } = useContext(AppDataContext);
    const Icon = () => {
        return (
            <div className=""></div>
        )
    }

    const Title = () => {
        return (
            <div className="mb-16px font-size-16 font-bold-caps text-dark">
                {langs.NM_empty_shop_title}
            </div>
        )
    }
    const Description = () => {
        return (
            <div className="max-width-356px text-center font-size-14 font-base text-blue-gray">
                {langs.NM_empty_shop_descr}
            </div>
        )
    }
    return <EmptyStates {...{Icon, Title, Description,  classIcon:"svg-i empty-search mb-24px" , classContainer:'mt-8px', classInner: " pt-100px pb-200px "}} />
}

export default ShopsEmpty