import React from 'react'
const LogOutIcon = (className = '') => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="5" fill="#F8F9FD" />
            <path d="M16 19L19 16M19 16L16 13M19 16L8 16M13 21C13 21.9319 13 22.3978 13.1522 22.7654C13.3552 23.2554 13.7443 23.6448 14.2344 23.8478C14.6019 24 15.0681 24 16 24H20.8C21.9201 24 22.48 24 22.9078 23.782C23.2841 23.5902 23.5905 23.2844 23.7822 22.908C24.0002 22.4802 24 21.9201 24 20.8V11.2C24 10.0798 24.0002 9.51986 23.7822 9.09204C23.5905 8.71572 23.2841 8.40973 22.9078 8.21799C22.48 8 21.9201 8 20.8 8H16C15.0681 8 14.6019 8 14.2344 8.15224C13.7443 8.35523 13.3552 8.74456 13.1522 9.23462C13 9.60216 13 10.0681 13 11" stroke="#A4AEC1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </span>
)

export default LogOutIcon
