import React, {useState, useRef} from 'react'

const CreateDiscount = (props) => {
    const [discount, setDiscount]           = useState('');
    const [price, setPrice]                 = useState('');
    const [amount, setAmount]               = useState('');
    const discountInput                     = useRef(null);
    const priceInput                        = useRef(null);

    const handlePercentageChange = (e) => {
        let value = e.target.value
        if (/^\d*\.?\d*$/.test(value.toString()) && (!value || (value.length < 6 && parseFloat(value) < 100))) {
            setDiscount(e.target.value)
            setPrice('')
            setAmount(props.data.price ? (parseFloat(value * props.data.price) / 100).toFixed(2) : null)
        }
        discountInput.current.focus();
    }
    const handlePriceChange = (e) => {
        let value = e.target.value
        if (/^\d*\.?\d*$/.test(value.toString()) && (parseFloat(value) < parseFloat(props.data.price) || !value)) {
            setPrice(e.target.value)
            setDiscount('')
            setAmount(props.data.price ? parseFloat(100 * value / props.data.price).toFixed(2) : null)
        }
        priceInput.current.focus();
    }
    const success = (e) => {
        e.preventDefault() || e.stopPropagation();
        props.callback({
            productID: props.data.productID,
            productPrice: props.data.price, 
            discount: discount,
            price: price
        }, 'success')
        props.callback(null, 'destroy')
    }

    return (
        <div className="custom-modal-wrapper">
            <div className="py-32px ">
                <div className='text-center font-size-18 font-bold-caps'>
                    {props.langs.NM_CreateDiscount}
                </div>
                <div className='mt-12px font-size-16px font-base text-dark text-center'>
                    {`${props.langs.NM_WriteDiscountPercentage}`}
                </div>
                {(props.data.price && props.data.qnt.length === 1) ?
                    <div className='mt-12px font-size-12 font-base text-dark text-center'>
                        {`${props.langs.Cost}: ${props.data.price}`}
                    </div>
                    : <></>
                }
            </div>
            <div className="pb-32px text-center d-flex flex-column align-items-center justify-content-cenetr px-24px px-md-64px">
                <input type="text" className="form-control w-md-320px" 
                    ref={discountInput} 
                    value={discount} 
                    onChange={handlePercentageChange}  
                    placeholder={props.langs.NM_percent}
                />
                {props.data.qnt.length === 1 &&
                    <div className='w-100 w-md-320px'>
                        <input type="text" className="form-control mt-12px w-md-320px"
                            ref={priceInput} 
                            value={price}
                            onChange={handlePriceChange}    
                            placeholder={props.langs.Amount}
                        />
                        {(price !== '' && amount !== '') &&
                            <div className='font-size-14 text-blue-gray text-left mt-6px'>
                                {props.langs.NM_costPercent} {amount}%
                            </div>
                        }
                        {discount !== '' &&
                            <div className='font-size-14 text-blue-gray text-left mt-6px'>
                                {props.langs.NM_costPercent} {amount} {props.currency()}
                            </div>
                        }
                    </div>
                }
            </div>
            <div className="pb-28px mt-0 d-flex justify-content-center">
                <button type="button" className="font-size-14 font-base-caps px-64px py-16px btn bg-middle-gray mx-8px text-blue-gray" 
                    disabled={(!discount && !price)}
                    onClick={success}>
                    {props.langs.NM_Create}
                </button>
                <button type="button" className="font-size-14 font-base-caps px-64px py-16px btn bg-middle-gray mx-8px text-blue-gray" 
                    onClick={() => props.callback(null, 'destroy')}>
                    {props.langs.Cancel}
                </button>
            </div>
        </div>
    )
}

export default CreateDiscount