import React from 'react'

const Success = (props) => {
    return (
        <div className="pr-24px">
            {/* <i className="svg-i success-messages mx-auto"></i> */}
            {props.langs.SuccessMessage}
        </div>
    )
}

export default Success