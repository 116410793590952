import React from 'react'

const ImageHandler = (props) => {
    let defaultImg = 'https://www.mymarket.ge/beta/templates/assets/img/no-image.png'
    
    const error = (e) => {
        e.onError = null;
        e.target.src = defaultImg
    }

    const load = (e) => {
        // e.target.src = props.src
    }

 	return (
        <img className={props.className} src={props.src ? props.src : defaultImg} alt={props.alt} 
            onError={error} onLoad={load} onClick={props.onClick ?? null}/>
 	)
    
}

export default ImageHandler
