import React from 'react'
import PropTypes from 'prop-types'

Search.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
}

export default function Search({ className = '', width = 14, height = 14 }) {
    return (
        <span className="">
            <svg className="" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14">
                <path id="Path_2722" data-name="Path 2722" d="M170.892-32a6.892,6.892,0,0,1,6.892,6.892,6.859,6.859,0,0,1-1.171,3.844l1.011,1.058a1.3,1.3,0,0,1,0,1.827,1.275,1.275,0,0,1-1.716.089l-.1-.089L174.8-19.432a6.861,6.861,0,0,1-3.91,1.215A6.892,6.892,0,0,1,164-25.108,6.892,6.892,0,0,1,170.892-32Zm0,2.584a4.308,4.308,0,0,0-4.308,4.307,4.308,4.308,0,0,0,4.308,4.307,4.308,4.308,0,0,0,4.308-4.307A4.308,4.308,0,0,0,170.892-29.416Z" transform="translate(-164 32)" fill="#ffd102"></path>~
                </svg>
        </span>
    )
}









