import React from 'react';

const ProductInfo = (props) => {
  const { langs, product } = props
  return (
    <>
			<h2 className="font-size-16 font-size-lg-20px text-dark font-medium mb-12px d-none d-m-flex">
				{product.title}
			</h2>
			<div className="px-15px px-m-0 mb-12px d-none d-m-flex">
				<span className=" font-size-12 font-base text-blue-gray mr-4px">
					{langs?.NM_InstallmentProductID}:
				</span>
				<span className=" font-size-12 font-medium text-blue-gray">
					{product.product_id}
				</span>
			</div>
			{/* <div className="galeery-descr mb-24px  font-size-12 font-base text-blue-gray d-none d-m-flex">
				თუ საჭიროება იქნება დამატებითი ტექსტი დაიწება 124234 ფოტოების ზემოთ
			</div> */}
		</>
  )
}

export default ProductInfo;