
import React from 'react'
const FillBalance = (className = '') => (
  <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center tradein-icon ${className}`}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill="#4A6CFA" fillOpacity="0.2" />
      <rect x="8" y="11.2729" width="7.9999" height="1.45453" rx="0.727263" fill="#4A6CFA" />
      <rect x="11.2715" y="16" width="7.9999" height="1.45453" rx="0.727263" transform="rotate(-90 11.2715 16)" fill="#4A6CFA" />
    </svg>

  </span>
)

export default FillBalance









