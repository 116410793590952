import React from 'react';
import star from '../../../../src/resources/img/icons/star.svg'

const Box = (props) => {
	const {
		item,
		langs,
		deleteItem,
		setDefault,
		setEditItem
	} = props;

	return (
		<div className='account-box-cont col-12 col-sm-6 col-md-6 px-12px'>
			<div className={`account-info-box ${item.is_default ? 'active' : ''}`}>
				<div className="account-info-box-fill p-24px">
					<div className="account-info-box-elements">
						<span className='font-size-14 text-primary-text-color'>
							{langs.AccountBoxName}:
						</span>
						<p className='font-size-16 font-weight-bold'>
							{item.owner_fullname}
						</p>
						<span className='font-size-14 text-primary-text-color'>
							{langs.APP_account_number}:
						</span>
						<p className='font-size-16 font-weight-bold'>
							{item.iban}
						</p>
						<div className="adress-edit-pen cursor-pointer"
							onClick={setEditItem} title={langs.Edit}>
							<i className="svg-i edit-i "></i>
						</div>
					</div>
					<div className={`d-flex justify-content-between`}>
						<button className="btn btn-link font-base font-w-500 text-black text-decoration-underline font-size-12 px-0" onClick={deleteItem}>
							{langs.APP_delete}
						</button>
						<button
							className={`btn bg-market-color font-size-12 font-w-500 px-12px d-flex align-items-center justify-content-center ${item.is_default ? ' ' : 'active'}`}
							onClick={setDefault}
						>
							<img src={star} alt='star' className='pr-8px' />
							<div className='make-basic-text'>
								{langs.NN_MakeItBasic}
							</div>
							<div className='next-text'>
								{langs.NM_Basic}
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Box;