import React, {useState} from 'react'

const CommunicationPoll = (props) => {
	const [pollState, setPollState] = useState(props.data.pollAction !== 2 ? 0 : 1)

	let optionSelected = 0;

	const submitPollResult = () => {
		let textElement = document.getElementById("messageOther")
		let description = (textElement && textElement.value) ? textElement.value : ''

		if (((optionSelected === 26 || optionSelected === 'other') && description === '') || !props.data.pollAction) {
			return;
		}
		props.callback({
			pollAction: props.data.pollAction, 
			optionSelected: optionSelected === 'other' ? 0 : optionSelected, 
			description
		}, 'success')
		props.callback(null, 'destroy')
	}

	const SelectReason = () => {
		return (
			<div className="position-relative custom-modal-wrapper ">
				<div className="popup-content pt-md-40px px-md-40px content-scroll">
					<h3 className="font-bold-caps text-left font-size-18 pb-32px">
						{props.data.title ? props.data.title : props.langs.NM_TellUsReason}
					</h3>
					{Object.entries(props.data.list).map(([k, v], i) => (
						<div className="custom-control custom-radio d-flex align-items-center justify-content-start mb-16px" key={i}>
							<input name="response" type="radio" id={`radio-${k.attrID}-${k + 1}`} className="custom-control-input" 
								onClick={(e) => { 
									optionSelected = k !== 'other' ? parseInt(k) : k
								}} />
							<label className="custom-control-label font-size-14 font-medium text-dark-gray" 
								htmlFor={`radio-${k.attrID}-${k + 1}`}>
								{v}
							</label>
						</div>
					))}
				</div>
				<div className="popup-footer d-flex justify-content-between mt-40px px-64px pt-24px">
					<button className="btn bg-gradient font-bold-caps font-size-13 text-white px-40px py-16px" 
						onClick={(e) => (optionSelected === 26 || optionSelected === 'other') ? setPollState(2) : submitPollResult()}>
						{props.langs.Send}
					</button>
				</div>
			</div>
		)
	}

	const OtherReason = () => {
		return (
			<div className="position-relative custom-modal-wrapper">
				<div className="popup-content  px-md-64px content-scroll">
					<h3 className="font-bold-caps font-size-18 pb-32px">
						{`${props.langs.Reason}: ${props.langs.Other}`}
					</h3>
					<textarea id="messageOther" className="form-control text-dark font-size-14 font-base" 
						placeholder={`${props.langs.NM_TellUsReason}...`}>
					</textarea>
				</div>
				<div className="popup-footer d-flex justify-content-between mt-40px px-md-64px  py-24px">
					<button className="btn bg-transparent font-base font-size-13 text-blue-gray px-0" 
						onClick={(e) => setPollState(1)}>
						{props.langs.ReturnBack}
					</button>
					<button className="btn bg-gradient font-bold-caps font-size-13 text-white  px-40px py-16px" 
						onClick={submitPollResult}>
						{props.langs.Send}
					</button>
				</div>
			</div>
		)
	}

	if (pollState === 1) {
		return <SelectReason />
	} else if (pollState === 2) {
		return <OtherReason />
	}
}

export default CommunicationPoll