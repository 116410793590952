import React, {useEffect, useContext} from 'react'
import { ModalsContext } from 'contexts/modals';
import { AppDataContext } from 'contexts/appData';
import { Success, Custom } from './components'

const SnackBar = (props) => {
	const { langs }                 = useContext(AppDataContext);
	const { snackbar, destroy }     = useContext(ModalsContext);

	useEffect(() => {
		if (snackbar) {
			setTimeout(() => destroy(true), 5000)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [snackbar])

	return (
		<>
			{snackbar && 
				<div className="bg-dark text-white  font-size-12 font-base border-radius-md position-fixed bottom-80px bottom-lg-10px right-10px z-index-9999999999">
					<button className="btn p-8px close-popup position-absolute top-8px right-8px" onClick={() => destroy(true)}>
						<i className="svg-i close-gray-i"></i>
					</button>
					<div className="p-24px">
						{snackbar.type ?
							snackbar.type === 'custom' ? 
								<Custom {...{ text: snackbar.statusMessage }}/>
							:
								<></>
						:
							<Success {...{ langs }}/>
						}
					</div>
				</div>
			}
		</>
	)
}

export default SnackBar