import React, { useState, useEffect, useContext, useRef } from 'react';

import { CONFIG } from 'core';
import { BannersContext } from 'contexts/banners';

import { HomeSlider, VideoBanner, AdoBanner, SimpleBanner } from 'components';

const Banner = (props) => {
	const { bannersData, getBanner, resolution, countBannerView, adBlock } = useContext(BannersContext)
	const [banner, setBanner] = useState(null);
	const sliderRef = useRef(null);

	const initiateBanner = () => {
		let banData = false
		if (resolution[0] <= CONFIG.TOSPLIT.PRODUCT_BANNERS.RESPONSIVE_RESOLUTION && props.mobileName) {
			banData = getBanner(props.mobileName)
		} else if (props.groupName) {
			banData = getBanner(props.groupName)
		} else {
			banData = getBanner(props.name)
		}
		
		if (banData) {
			banData.type = parseInt(banData.type)
			setBanner(banData)
		}
	}

	useEffect(() => {
		if (bannersData) initiateBanner()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resolution, bannersData])

	if (adBlock) {
		return <></>
	}
	else if (banner === false) {
		return props.name === 'NM_Video' ? <></> :
		(
			<div className={props.parentClassName}>
				<div className={props.childClassName}></div>
			</div>
		)
	}
	else if (banner === null) {
		return (
			<div className={props.parentClassName}></div>
		)
	} 
	else if (window.ado !== undefined && [CONFIG.BANNER_TYPES.ADO, CONFIG.BANNER_TYPES.CONNECT].includes(banner.type)
	) {
		return <AdoBanner {...{...props, ...banner}} />
	}
	else if (props.name === 'NM_SL') {
		return <HomeSlider ref={sliderRef} {...{
			banner,
			countBannerView,
			parentClassName: props.parentClassName,
			resolution,
			CONFIG
		}} />
	}
	else if ( window.ado !== undefined && [CONFIG.BANNER_TYPES.VAST, CONFIG.BANNER_TYPES.VIDEO].includes(banner.type)) {
		return <VideoBanner {...{
			api: props.api,
			childClassName: props.childClassName,
			parentClassName: props.parentClassName, 
			banner
		}} />
	}
	else if (props.name === 'NM_POPUP_Cappy' || props.name === 'NM_PIC') {
		return <SimpleBanner {...{
			parentClassName: props.parentClassName,
			childClassName: props.childClassName,
			src: banner[0]?.banner_content
		}} />
	} 
	else {
		return <></>
	}
}

export default Banner;