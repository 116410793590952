import { useContext } from 'react';
import { HistoryContext }   from 'contexts/history';
const ProductSort = (props) => {
  const destroy = (e) => {
      props.callback(e, 'destroy');
      props.callback(e, 'afterDestroy');
  }
	const { langs } = props
  const { params, assignParam }          = useContext(HistoryContext);
  const sorting = {
		orderDate: { descId: 1, ascId: 2 },
		price: { descId: 3, ascId: 4 },
		id: { descId: 5, ascId: 6 },
		views: { descId: 7, ascId: 8 },
		quantity: { descId: 9, ascId: 10 },
		createDate: { descId: 11, ascId: 12 },
		endDate: { descId: 13, ascId: 14 },
		discount: { descId: 14, ascId: 15 },
		promotions: { descId: 16, ascId: 17 },
    create: { descId: 18, ascId: 19 },
    update: { descId: 20, ascId: 21 },
    end: { descId: 22, ascId: 23 },
	};
  let sortingList = [
		{
			name: 'id',
			title: `${langs.PlaceTitle} / ID / SKU`,
		},
		{
			name: 'quantity',
			title: langs.NM_Stock,
		},
		{
			name: 'price',
			title: langs.Price,
		},
		{
			name: 'views',
			title: langs.Views,
		},
		{
			name: 'promotions',
			title: langs.Promotion,
		},
    {
			name: 'create',
			title: langs.Create_date,
		},
    {
			name: 'update',
			title: langs.UpdatedDate,
		},
    {
			name: 'end',
			title: langs.EndDate,
		},
		
	];
	const setSortingState = (e, field) => {
		e.preventDefault() || e.stopPropagation();
		let newParams = {
			StatusID: params.StatusID ? params.StatusID : 1
		}

		if (!field || !sorting[field]) {
			field = 'price';
		}

		newParams['SortID'] = sorting[field].descId === parseInt(params['SortID']) ? sorting[field].ascId : sorting[field].descId
		assignParam(newParams, true)
	}
  const beforedestroy = (e) => {
    destroy()
    setSortingState(e)
}
  return (
    <>
      <div className="custom-modal-wrapper content-scroll">
				<div className="modal-content-box mb-24px px-md-64px">
					<h3 className="font-size-14 font-size-md-16 font-bold-caps text-dark text-left mb-24px  pt-12px">
						sort by
					</h3>

          {sortingList.map((item, i) => (
						<div className="font-size-14 font-medium text-dark mb-12px cursor-pointer" key={i} 	onClick={e => beforedestroy(e, item.name)} >
									{item.title}
						</div>
					))}
				</div>
			</div>

    </>
  )
}

export default ProductSort
