import React, { useState } from 'react';
import RatingGroup from '../../../src/modules/orders/components/reviewModal/RatingGroup';
import ReviewPhotosModal from '../../../src/modules/orders/components/reviewPhotosModal/ReviewPhotosModal';

const Ratings = (props) => {
  const { openDetailsModal, averageRating, ratePercentage, totalCount, gallery } = props;

  const [isReviewPhotosOpen, setIsReviewPhotosOpen] = useState(false);
  const [orderProductId, setOrderProductId] = useState(null);

  let maxRate = 5;
  let rates = [];

  const reviewImages =
    gallery?.reduce((acc, item) => {
      item.photos.forEach((el) => {
        acc.push({ image: el, data: item });
      });

      return acc;
    }, []) ?? [];

  for (let i = maxRate; i > 0; i--) {
    rates.push(
      <div className="row justify-content-center m-0" key={i}>
        <div className="side">
          <div className="font-size-12 font-medium text-dark-gray d-inline-flex">
            {i} <i className="svg-i rate-bg my-auto ml-4px"></i>
          </div>
        </div>
        <div className="middle my-auto">
          <div className="bar-container">
            <div className="bar-percent" style={{ width: `${ratePercentage && ratePercentage[i] ? ratePercentage[i] : 0}%` }}></div>
          </div>
        </div>
        <div className="side right">
          <div className="font-size-12 font-medium text-dark-gray">{`${ratePercentage && ratePercentage[i] ? ratePercentage[i] : 0}%`}</div>
        </div>
      </div>,
    );
  }

  return (
    <div className="col-md-12 d-flex justify-content-center justify-content-lg-between flex-wrap px-0 pb-0px pb-md-25px">
      {isReviewPhotosOpen && (
        <ReviewPhotosModal
          onClose={() => {
            setIsReviewPhotosOpen(false);
            setOrderProductId(null);
          }}
          orderProductId={orderProductId}
          reviewAllImages={reviewImages}
          openDetailsModal={openDetailsModal}
          {...props}
        />
      )}
      <div className="d-flex col-md-4 px-md-0 align-items-center  min-width-410px pb-24px pb-md-0px">
        <div className="pl-25px pl-sm-0px">
          <span className="font-size-md-64 font-size-53 font-weight-bold text-dark d-flex justify-content-center mb-0px">
            {!isNaN(averageRating) ? parseFloat(averageRating?.toFixed(1)) : 0}
          </span>
          <div className="statement-details-section d-flex align-items-center d-flex justify-content-center mb-10px">
            <RatingGroup rating={averageRating} width={16} height={16} onRate={() => {}} itemClassname="mr-8px" />
          </div>
          <span className="font-size-10 text-blue_2 d-flex justify-content-center">
            {totalCount ?? 0} {props.langs.NM_ReviewCount}
          </span>
        </div>
        <div className=" text-left col-md-7 d-flex flex-column justify-content-center">{rates}</div>
      </div>

      <div className="d-flex flex-wrap px-0 review-upload-images">
        {reviewImages.slice(0, 4).map((review, reviewIndex) => (
          <div className="m-6px w-md-120px h-md-120px h-72px w-72px cursor-pointer" key={reviewIndex}>
            <img
              className="h-100 w-100 object-fit-cover rounded"
              src={review.image.thumbs}
              onClick={() => {
                setIsReviewPhotosOpen(true);
                // setOrderProductId(review.order_product_id)
              }}

              // alt={`Photo ${photoIndex + 1}`}
            />
          </div>
        ))}

        {reviewImages.length > 5 && (
          <div
            className="m-6px w-md-120px h-md-120px position-relative h-72px w-72px cursor-pointer bg-opacity"
            onClick={() => {
              setIsReviewPhotosOpen(true);
              // setOrderProductId(review.order_product_id)
            }}
          >
            <img
              className="h-100 w-100 object-fit-cover rounded"
              src={reviewImages[5].image.thumbs}
              // alt={`Photo ${review.photo.length + 1}`}
            />
            <span className="position-absolute top-26px left-26px left-md-50px top-md-50px z-index-1 text-white">+{reviewImages.length}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ratings;
