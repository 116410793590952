import {
	CONFIG
} from 'core';
import {
	Storage,
	Request,
	Cookie,
	Response,
	Map
} from 'services';
import { getClientId, getSessionId } from '../libs/helpers';

const API = () => {
	const lang = window.location.pathname.split('/')[1]
	const request = Request();
	const storage = Storage();
	const cookie = Cookie();

	const updateTokenCheck = (res) => {
		let resData = res.data;
		if (resData && resData.updateToken && resData.updateToken.length > 10) {
			const expirationDate = new Date();
			expirationDate.setDate(expirationDate.getDate() + 7)
			cookie._set(CONFIG.CACHE.TAGS.COOKIEAUTH, resData.updateToken, {
				path: "/",
				domain: CONFIG.HOST.branch === 'LOCAL' ? 'localhost' : '.mymarket.ge',
				expires: expirationDate
			});
			request._setHeaders(resData.updateToken);
		}
	}
	const unsetStorageData = () => {
		storage.remove(CONFIG.CACHE.TAGS.AUTH);
		storage.remove(CONFIG.CACHE.TAGS.USER);
		storage.remove(CONFIG.CACHE.TAGS.SYNC);
		cookie._remove(CONFIG.CACHE.TAGS.COOKIEAUTH);
		sessionStorage.clear();
		return true;
	}
	const uploadFiles = async (files, userID, uploadedFiles = 0, thumbsTypeID = 0, typeID = false) => {
		var data = new FormData();
		Object.keys(files).map(i => {
			return data.set(`Files[${i}]`, files[i]);
		});

		data.set('UploadedFiles', uploadedFiles);
		data.set('Func', 'UploadPhotos'); //fucntion
		data.set('ThumbsTypeID', thumbsTypeID); //0 - zoomin, 1 - zoomout thumbs
		typeID && data.set('Type', typeID);
		data.set('UserID', userID); //user id
		data.set('SiteID', CONFIG.COMMON.SITE_ID); //site id

		var res = await request._post(CONFIG.HOST.staticUrl, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		return res.data.StatusCode === 1 ? res.data.Data : false;
	}
	const uploadPhotoForSearch = async (file, userID) => {
		let data = new FormData();
		data.set('Files[0]', file[0]);
		data.set('UserID', userID);
		data.set('SiteID', CONFIG.COMMON.SITE_ID);
		data.set('Func', 'UploadPhotos');
		let res = await request._post(CONFIG.HOST.staticUrl, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		return res.data.StatusCode === 1 ? res.data.Data : false
	}
	const getMainSections = async (config) => {
		if (!config) {
			return false
		}
		let query = Map().sectionsQueryPrepare(config)
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_SECTIONS + (query && '?' + query)));
		updateTokenCheck(res);
		return Response(res);
	}
	const getShops = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_SHOPS + (query && '?' + query)));
		updateTokenCheck(res);
		return Response(res);
	}
	const getUserShop = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_USER_SHOP));
		updateTokenCheck(res);
		return Response(res);
	}
	const getUserInfo = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_USER_INFO));
		updateTokenCheck(res);
		return Response(res);
	}
	const getUserDetails = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_USER_DETAILS));
		updateTokenCheck(res);
		return Response(res);
	}
	const getUserData = async (type, id) => {
		let res = await request._get(request._build(`${CONFIG.API.METHODS[type ? `GET_SHOPS` : `GET_USER`]}/${id}`));
		updateTokenCheck(res);
		return Response(res);
	}
	const getProducts = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.GET_PRODUCTS), data);
		updateTokenCheck(res);
		return Response(res);
	}
	const checkout = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.CHECKOUT), data);
		updateTokenCheck(res);
		return Response(res);
	}
	const getChekoutDetails = async (query = false) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_CHECKOUT_DETAILS + (query ? `?${query}` : '')));
		updateTokenCheck(res);
		return Response(res);
	}
	const getShopProducts = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_SHOP_PRODUCTS + (query && '?' + query)));
		updateTokenCheck(res);
		return Response(res);
	}
	const getUserTradeInOrders = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_USER_TRADEIN_ORDERS + (query && '?' + query)));
		updateTokenCheck(res);
		return Response(res);
	}
	const getShopTradeInOrders = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_SHOP_TRADEIN_ORDERS + (query && '?' + query)));
		updateTokenCheck(res);
		return Response(res);
	}
	const getTradeInOrder = async (id) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_TRADEIN_ORDER + CONFIG.COMMON.DS + id));
		updateTokenCheck(res);
		return Response(res);
	}
	const disableProducts = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.DISABLE_PRODUCTS), {
			PrIDs: data
		});
		updateTokenCheck(res);
		return Response(res);
	}
	const enableProducts = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.ENABLE_PRODUCTS), {
			PrIDs: data
		});
		updateTokenCheck(res);
		return Response(res);
	}
	const deleteProducts = async (data) => {
		let res = await request._delete(request._build(CONFIG.API.METHODS.DELETE_PRODUCTS), {
			data: {
				PrIDs: data
			}
		});
		updateTokenCheck(res);
		return Response(res);
	}
	const discountProducts = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.DISCOUNT_PRODUCTS), data);
		updateTokenCheck(res);
		return Response(res);
	}
	const recoverProducts = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.RECOVER_PRODUCTS), {
			PrIDs: data
		});
		updateTokenCheck(res);
		return Response(res);
	}
	const UpdateProductQuantity = async (productID, Quantity) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.UPDATE_PRODUCT_QUANTITY.replace("%", productID)), {
			Quantity: Quantity
		});
		updateTokenCheck(res);
		return Response(res)
	}
	const getNotifications = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_NOTIFICATIONS + (query && '?' + query)));
		updateTokenCheck(res);
		return Response(res);
	}
	const getNotification = async (id) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_NOTIFICATIONS + CONFIG.COMMON.DS + id));
		updateTokenCheck(res);
		return Response(res);
	}
	const getNotificationsCount = async (query = false) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_NOTIFICATIONSCOUNT + (query ? '?' + query : '')));
		return Response(res);
	}
	const getProductInfo = async (product_id, mode = false) => {
		let method = mode ? CONFIG.API.METHODS.GET_USER_PRODUCT : CONFIG.API.METHODS.GET_PRODUCT
		let res = await request._get(request._build(method + CONFIG.COMMON.DS + product_id));
		updateTokenCheck(res);
		return Response(res);
	}
	const getProductStatusCounts = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_STATUS_COUNTS));
		updateTokenCheck(res);
		return Response(res);
	}
	const getProductCategories = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_PRODUCT_CATEGORIES));
		updateTokenCheck(res);
		return Response(res);
	}
	const getLastSync = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_LAST_SYNC));
		updateTokenCheck(res);
		return Response(res);
	}
	const syncUser = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.SYNC_USER), data);
		updateTokenCheck(res);
		return Response(res);
	}
	const getHelp = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_HELP));
		updateTokenCheck(res);
		return Response(res);
	}
	const getHelpSend = async (data) => {
		return Response(await request._post(request._build(CONFIG.API.METHODS.GET_HELP_SEND), data));
	}

	const getTerms = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_TERMS));
		updateTokenCheck(res);
		return Response(res);
	}
	const getCaptcha = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_CAPTCHA));
		updateTokenCheck(res);
		return Response(res, 'captcha');
	}
	const getOrders = async (query, type = false, id = false) => {
		let url,
			orderID = id ? `/${id}` : '',
			urlQuery = query ? `?${query}` : ''

		if (type === 'user') {
			url = CONFIG.API.METHODS.GET_USER_ORDERS
		} else if (type === 'shop') {
			url = CONFIG.API.METHODS.GET_SHOP_ORDERS
		} else if (type === 'c2c') {
			url = CONFIG.API.METHODS.GET_C2C_ORDERS
		}

		let res = await request._get(request._build(url + orderID + urlQuery));
		updateTokenCheck(res);
		return Response(res)
	}
	const sendContact = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.CONTACT), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const requestPasswordChange = async (data) => {
		let res = await request._put(request._build(CONFIG.API.METHODS.CHANGE_PASSWORD), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const requestProfileChange = async (data) => {
		let res = await request._put(request._build(CONFIG.API.METHODS.UPDATE_ACCOUNT), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const getAddress = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_ADDRESS));
		updateTokenCheck(res);
		return Response(res, 'address');
	}
	const postAddAddress = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.ADD_ADDRESS), data);
		updateTokenCheck(res);
		return res.data;
	}
	const putAddressUpdate = async (id, data) => {
		let res = await request._put(request._build(CONFIG.API.METHODS.UPDATE_ADDRESS + id), data);
		updateTokenCheck(res);
		return res.data;
	}
	const deleteAddress = async (id) => {
		let res = await request._delete(request._build(CONFIG.API.METHODS.DELETE_ADDRESS + id));
		updateTokenCheck(res);
		return Response(res);
	}
	const setDefaultAddress = async (id) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.DEFAULT_ADDRESS + id));
		updateTokenCheck(res);
		return Response(res);
	}
	const addShop = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.ADD_SHOP), data);
		updateTokenCheck(res);
		return Response(res);
	}
	const editShop = async (data) => {
		let res = await request._put(request._build(CONFIG.API.METHODS.EDIT_SHOP), data);
		updateTokenCheck(res);
		return Response(res);
	}
	const addProduct = async (data) => {
		const [clientId, sessionId] = await Promise.all([getClientId(), getSessionId()])

		let res = await request._post(request._build(CONFIG.API.METHODS.ADD_PRODUCT), {...data, ...(clientId ? {client_id: clientId}: {}), ...(sessionId ? {session_id: sessionId}: {})});
		updateTokenCheck(res);
		return Response(res);
	}
	const editProduct = async (data) => {
		let res = await request._put(request._build(CONFIG.API.METHODS.EDIT_PRODUCT), data);
		updateTokenCheck(res);
		return Response(res);
	}
	const getDeliveryDetails = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.DELIVERY_DETAILS + '?' + query));
		updateTokenCheck(res);
		return Response(res);
	}
	const getProductDelivery = async (query, PrID) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.PRODUCT_DELIVERY.replace("%", PrID) + '?' + query));
		updateTokenCheck(res);
		return Response(res);
	}
	const getProductNumber = async (productID, captchaToken) => {
		const [clientId, sessionId] = await Promise.all([getClientId(), getSessionId()]);
		let url = CONFIG.API.METHODS.GET_PRODUCT + CONFIG.COMMON.DS + productID + '/showfullnumber' + `?captcha_token=${captchaToken}`

		if(clientId && sessionId) {
			url = CONFIG.API.METHODS.GET_PRODUCT + CONFIG.COMMON.DS + productID + '/showfullnumber' + `?captcha_token=${captchaToken}&client_id=${clientId}&session_id=${sessionId}`
		}

		let res = await request._get(request._build(url));
		// let res = await request._get(request._build(CONFIG.API.METHODS.GET_PRODUCT + CONFIG.COMMON.DS + productID + '/showfullnumber' + `?captcha_token=${captchaToken}`));
		updateTokenCheck(res);
		return Response(res)
	}
	const showUserNumber = async (userID, captchaToken) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.SHOW_USER_NUMBER.replace("%", userID) + `?captcha_token=${captchaToken}` ));
		updateTokenCheck(res);
		return Response(res)
	}
	const sendMessageToUser = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.SEND_PRODUCT_MESSAGE), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const sendOfferPrice = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.GET_PRODUCT + CONFIG.COMMON.DS + data.PrID + '/offerprice'), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const sendPollResults = async (productID, responseID, descriptionID = 0, description = '') => {
		let res = await request._post(request._build(CONFIG.API.METHODS.GET_PRODUCT + CONFIG.COMMON.DS + productID + '/showfullnumberresponse'), {
			response_code: responseID,
			response_description_id: descriptionID,
			response_description: description
		});
		updateTokenCheck(res);
		return Response(res)
	}
	const getBanners = async () => {
		return fetch(CONFIG.API.METHODS.GET_BANNERS).then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				throw new Error('adBlock');
			}
		})
			.then((responseJson) => {
				return responseJson
			})
			.catch((error) => {
				// console.log(error)
				return false
			});
	}
	// Transactions
	const getUserByEmail = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.FIND_USER + '?' + query));
		updateTokenCheck(res);
		return Response(res)
	}
	const transferMoneyToUser = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.TRANSFER_MONEY), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const getUserTransactions = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_USER_TRANSACTIONS + '?' + query));
		updateTokenCheck(res);
		return Response(res)
	}
	const getUserConversations = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_USER_CONVERSATIONS + (query ? '?GetBlocked=1' : '')));
		updateTokenCheck(res);
		return Response(res)
	}
	const getUserMessages = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_USER_MESSAGES + '?' + query));
		updateTokenCheck(res);
		return Response(res)
	}
	const sendUserMessageChat = async (data) => {
		const [clientId, sessionId] = await Promise.all([getClientId(), getSessionId()])

		let res = await request._post(request._build(CONFIG.API.METHODS.SEND_USER_MESSAGE), {...data, ...(clientId ? {client_id: clientId}: {}), ...(sessionId ? {session_id: sessionId}: {})});
		updateTokenCheck(res);
		return Response(res)
	}
	const deleteUserConversation = async (query) => {
		let res = await request._delete(request._build(CONFIG.API.METHODS.DELETE_USER_MESSAGE + '?' + query));
		updateTokenCheck(res);
		return Response(res);
	}
	const blockUser = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.BLOCK_USER), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const unblockUser = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.UNBLOCK_USER), data);
		updateTokenCheck(res);
		return Response(res)
	}
	// Check phone number
	const checkUserPhone = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.USER_CHECK_PHONE), data);
		updateTokenCheck(res);
		return {
			data: res.data.data ? res.data.data : {},
			statusCode: (res.data.statusCode === 200 || res.data.statusCode === 417) ? res.data.statusCode : false,
			statusMessage: res.data.statusMessage ? res.data.statusMessage : ''
		}
	}
	const checkProfilePhone = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.USER_PROFILE_PHONE), data);
		updateTokenCheck(res);
		return {
			data: res.data.data ? res.data.data : {},
			statusCode: (res.data.statusCode === 200 || res.data.statusCode === 417) ? res.data.statusCode : false,
			statusMessage: res.data.statusMessage ? res.data.statusMessage : ''
		}
	}
	const sendUserSMS = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.USER_SEND_SMS), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const checkUserSMSCode = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.USER_CHECK_SMS), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const getEstimateViews = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_ESTIMATE_VIEWS + '?' + query));
		updateTokenCheck(res);
		return Response(res)
	}
	const getProductsStatistics = async (productID, query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.PRODUCT_STATISTICS.replace("%", productID) + '?' + query));
		updateTokenCheck(res);
		return Response(res)
	}
	const getProductGroups = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_PRODUCT_GROUPS + '?' + query));
		updateTokenCheck(res);
		return Response(res)
	}
	const getProductGroupsDetails = async (groupID) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_GROUPS_DETAILS + '?GroupID=' + groupID));
		updateTokenCheck(res);
		return Response(res)
	}
	const saveProductGroups = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.SAVE_PRODUCT_GROUPS), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const removeGroup = async (query) => {
		let res = await request._delete(request._build(CONFIG.API.METHODS.REMOVE_GROUP + '?' + query));
		updateTokenCheck(res);
		return Response(res)
	}
	const removeProductFromGroup = async (query) => {
		let res = await request._delete(request._build(CONFIG.API.METHODS.REMOVE_PRODUCT_GROUP + '?' + query));
		updateTokenCheck(res);
		return Response(res)
	}
	// Reviews
	const addReview = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.ADD_REVIEW), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const getReviews = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_REVIEWS + '?' + query));
		updateTokenCheck(res);
		return Response(res)
	}

	const getReview = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_REVIEW + '?' + query));
		// let res = await request._get(request._build(CONFIG.API.METHODS.GET_REVIEW));
		// updateTokenCheck(res);
		return res.data
	}

	// Payment
	const payment = async (data) => {
		const [clientId, sessionId] = await Promise.all([getClientId(), getSessionId()])

		let res = await request._post(request._build(CONFIG.API.METHODS.PAYMENT), {...data, ...(clientId ? {client_id: clientId} : {}), ...(sessionId ? {session_id: sessionId} : {}) });
		updateTokenCheck(res);
		return Response(res);
	}

	const makeOrder = async (
		userAddressID,
		paymentMethod  = false,
		shippingDuration = false,
		isVisa = false,
		productID = false,
		shippingType = null,
		offerID = false,
		comment,
		additionalParams,
	) => {
		let paymentType =  productID ?  'express_checkout' : 'cart_order';

		const [clientId, sessionId] = await Promise.all([getClientId(), getSessionId()])

		let params = {
			UserAddressID: userAddressID,
			ReturnURL: CONFIG.HOST.appUrl + lang + '/orders/user?pay=1',
			PaymentType: paymentType,
			PaymentMethod: paymentMethod.METHOD,
			IsVisa: isVisa ? 1 : 0,
			PrID: parseInt(productID ? productID : 0),
			Quantity: 1,
			Comment: comment,
			TypeID: paymentMethod.TYPE_ID ? paymentMethod.TYPE_ID : 0,
			ShippingType: shippingType === false ? 1 : 0,
			...(clientId ? {client_id: clientId} : {}),
			...(sessionId ? {session_id: sessionId} : {}),
			...additionalParams
		}
		if (shippingDuration !== false) {
			params.ShippingDuration = shippingDuration;
		}
		if (offerID !== null) {
			params.OfferID = offerID;
		}

		if (paymentMethod.METHOD === 'google') {
			params.PayWith = "google-only";
			params.PaymentMethod = "card";

		}
		if (paymentMethod.METHOD === 'apple') {
			params.PayWith = "apple-only";
			params.PaymentMethod = "card";
		}

		let res = await request._post(request._build(CONFIG.API.METHODS.PAYMENT), params);
		updateTokenCheck(res);
		return res.data;
	}
	// proms
	const getPromPackages = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_PROMS + (query ? '?' + query : '')));
		updateTokenCheck(res);
		return Response(res)
	}
	const addPromVip = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.PROM_ADD_VIP), data);
		updateTokenCheck(res);
		return Response(res);
	}
	const addPromColor = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.PROM_ADD_COLOR), data);
		updateTokenCheck(res);
		return Response(res);
	}
	const addPromAutoUpdate = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.PROM_ADD_AUTO_UPDATE), data);
		updateTokenCheck(res);
		return Response(res);
	}
	//sugestions
	const getSuggestions = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_SUGGESTIONS + (query ? '?' + query : '')))
		updateTokenCheck(res);
		return Response(res)
	}

	const sendCustomPackageRequest = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.SEND_CUSTOM_PACKAGE_REQUEST), data)
		updateTokenCheck(res);
		return Response(res)
	}
	// Product Activation
	const sendProductActivationCode = async (productID, data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.PRODUCT_PHONE_CODE.replace("%", productID)), data)
		updateTokenCheck(res);
		return Response(res)
	}
	const activateProduct = async (productID, data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.PRODUCT_ACTIVATION.replace("%", productID)), data)
		updateTokenCheck(res);
		return Response(res)
	}
	// Templates / Drafts
	const getTemplates = async (templateID = false, query = false) => {
		let fullQuery = (templateID ? '/' + templateID : '') + (!templateID ? (query ? '?Template=1' : '?Template=0') : '')
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_TEMPLATES + fullQuery))
		updateTokenCheck(res);
		return Response(res)
	}
	const saveTemplates = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.SAVE_TEMPLATES), data)
		updateTokenCheck(res);
		return Response(res)
	}
	const removeTemplates = async (templateID) => {
		let res = await request._delete(request._build(CONFIG.API.METHODS.DELETE_TEMPLATES.replace("%", templateID)));
		updateTokenCheck(res);
		return Response(res)
	}
	// Add Lead Rebank
	const addLeadBank = async (productID, data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.ADD_LEAD_BANK.replace("%", productID)), data)
		updateTokenCheck(res);
		return Response(res)
	}
	// Group
	const getGroupProducts = async (productID) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GROUP_PRODUCTS.replace("%", productID)));
		updateTokenCheck(res);
		return Response(res)
	}
	const getGroupDiscountProducts = async (productID) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GROUP_DISCOUNT_PRODUCTS.replace("%", productID)));
		updateTokenCheck(res);
		return Response(res)
	}
	const getSavedSearches = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_SAVED_SEARCHES));
		updateTokenCheck(res);
		return Response(res)
	}
	const saveSearch = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.SAVE_SEARCH), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const removeSavedSearch = async (data) => {
		let res = await request._delete(request._build(CONFIG.API.METHODS.REMOVE_SAVED_SEARCH.replace("%", data.SavedSearchID)));
		updateTokenCheck(res);
		return Response(res)
	}
	// TradeIn
	const tradeInModels = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.TRADEIN_MODELS + (query ? '?' + query : '')))
		updateTokenCheck(res);
		return Response(res)
	}
	const tradeInQuestions = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.TRADEIN_QUESTIONS + (query ? '?' + query : '')))
		updateTokenCheck(res);
		return Response(res)
	}
	const tradeInCreateOrder = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.TRADEIN_CREATE_ORDER), data)
		updateTokenCheck(res);
		return Response(res)
	}
	const changeTradeInOrderStatus = async (orderID, statusID) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.CHANGE_TRADEIN_STATUS.replace("%", orderID)), {
			StatusID: statusID
		});
		updateTokenCheck(res);
		return Response(res)
	}
	const TradeInOfferPrice = async (orderID, price) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.TRADEIN_OFFER_PRICE.replace("%", orderID)), {
			Price: price
		});
		updateTokenCheck(res);
		return Response(res)
	}
	const TradeInOfferPriceResponse = async (orderID, response) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.TRADEIN_OFFER_PRICE_RESPONSE.replace("%", orderID)), {
			Response: response
		});
		updateTokenCheck(res);
		return Response(res)
	}

	const getInstallments = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_INSTALLMENTS + (query ? '?' + query : '')))
		updateTokenCheck(res);
		return Response(res);
	}
	// app
	const storeAppRequest = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.STORE_APP_REQUEST), data)
		updateTokenCheck(res);
		return Response(res)
	}
	// Banner
	const countBannerViews = async (data) => {
		const instance = request._create();
		delete instance.defaults.headers.authorization;
		return await instance.post(CONFIG.TOSPLIT.MY_ADS_BANNERS_URL, data)
	}
	// Invoice
	const generateInvoice = async (data) => {
		let response = await request._post(request._build(CONFIG.API.METHODS.GENERATE_INVOICE), data)
		return response
	}

	// Shop Orders Status Change
	const changeShopOrderStatus = async (orderID, data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.CHANGE_ORDER_STATUS.replace("%", orderID)), data)
		updateTokenCheck(res);
		return Response(res)
	}
	//
	const getMonthChanges = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_MONTH_CHARGES));
		updateTokenCheck(res);
		return Response(res);
	}
	const specialPopup = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_SPECIAL_POPUP));
		updateTokenCheck(res);
		return Response(res);
	}
	// TBC SLOWWW REQUESTS
	const checkTbcConnect = async (data) => {
		return Response(await request._post("https://api2.myhome.ge/api/" + lang + '/tbcConnect/check', data))
	}
	const sendTbcConnectData = async (data) => {
		return Response(await request._post("https://api2.myhome.ge/api/" + lang + '/tbcConnect/saveUserResp', data))
	}
	const getPromPrices = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_PROM_PRICES));
		updateTokenCheck(res);
		return Response(res);
	}
	// Similar
	const getSimilarProducts = async (productID, query) => {
		let res = await request._get(
			request._build(
				CONFIG.API.METHODS.PRODUCT_SIMILAR.replace("%", productID) + (query ? '?' + query : '')
			)
		)
		updateTokenCheck(res);
		return Response(res);
	}
	// End
	/*Support Ukraine*/
	const sendDonation = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.DONATION), data);
		updateTokenCheck(res);
		return Response(res)
	}
	// End
	const getModelAttrs = async (modelID) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_MODEL_ATTRS + '?ModelID=' + modelID))
		updateTokenCheck(res);
		return Response(res)
	}
	const returnProduct = async (orderID, data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.RETURN_PRODUCT.replace("%", orderID)), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const getGiftsCount = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_GIFTS_COUNT))
		updateTokenCheck(res);
		return Response(res)
	}

	const generateProductTitle = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_PRODUCT_TITLE + (query ? '?' + query : '')))
		updateTokenCheck(res);
		return Response(res);
	}

	const deactivateUser = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.DEACTIVATE_USER), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const getAppVersion = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.APP_VERSION))
		return res.data;
	}
	const callRequest = async (data) => {
		return Response(await request._post(CONFIG.API.METHODS.CALL_REQUEST.replace("%", lang), data));
	}

	const checkFeedBack = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.FEEDBACK_CHECK));
		updateTokenCheck(res);
		return Response(res)
	}

	const getFeedBackAnswers = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.FEEDBACK_ANSWERS + (query ? '?' + query : '')));
		updateTokenCheck(res);
		return Response(res)
	}

	const addFeedBack = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.FEEDBACK_ADD), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const getAppModels = async (query) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_PRODUCT_MODELS + (query ? '?' + query : '')))
		updateTokenCheck(res);
		return Response(res)
	}
	const getOfferPrice = async (query, type = false, id = false) => {
		let url,
			orderID = id ? `/${id}` : '',
			urlQuery = query ? `?${query}` : ''

		if (type === 'user') {
			url = CONFIG.API.METHODS.GET_OFFER_PRICE_USER
		} else if (type === 'seller') {
			url = CONFIG.API.METHODS.GET_OFFER_PRICE_SELLER
		}
		let res = await request._get(request._build(url + orderID + urlQuery));
		updateTokenCheck(res);
		return Response(res)
	}
	const updateOfferStatus = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.UPDATE_OFFER_STATUSES), data);
		updateTokenCheck(res);
		return Response(res)
	}
	const updateOfferUserStatus = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.UPDATE_OFFER_USER_STATUSES), data);
		updateTokenCheck(res);
		return Response(res)
	}


	// const getOfferPriceUser = async (query) => {
	// 	let res = await request._get(request._build(CONFIG.API.METHODS.GET_OFFER_PRICE_USER + (query ? '?' + query : '')))
	// 	updateTokenCheck(res);
	// 	return Response(res)
	// }
	// const getOfferPriceSeller = async (query) => {
	// 	let res = await request._get(request._build(CONFIG.API.METHODS.GET_OFFER_PRICE_SELLER + (query ? '?' + query : '')))
	// 	updateTokenCheck(res);
	// 	return Response(res)
	// }

	const confirmOrderPickUp = async (orderID, data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.CONFIRM_ORDER_PICKUP.replace("%", orderID)), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const updateBankAccount = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.UPDATE_BANK_ACCOUNT), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const getServiceCenters = async (productID) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_PRODUCT + CONFIG.COMMON.DS + productID + '/servicecenters'));
		updateTokenCheck(res);
		return Response(res)
	}
	const getShopServiceCenters = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.SERVICE_CENTERS));
		updateTokenCheck(res);
		return Response(res)
	}

	const getBankAccounts = async () => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_BANK_ACCOUNTS));
		updateTokenCheck(res);
		return Response(res)
	}

	const addBankAccount = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.ADD_BANK_ACCOUNT), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const setBankAccountDefault = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.SET_BANK_ACCOUNT_DEFAULT), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const deleteBankAccount = async (data) => {
		let res = await request._delete(request._build(CONFIG.API.METHODS.DELETE_BANK_ACCOUNT), {data});
		updateTokenCheck(res);
		return Response(res)
	}
	const getCategoriesCounts = async (data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.CATEGORIES_COUNTS), data);
		updateTokenCheck(res);
		return Response(res);
	}

	const checkShopProductQuantity = async (productId) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.PRODUCT_QUANTITY_CHECK + `?product_id=${productId}`));
		updateTokenCheck(res);
		return Response(res);
	}

	const sendImageUploadLink = async (phoneNumber, token) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.IMAGE_UPLOAD_LINK + `?phoneNumber=${phoneNumber}&token=${token}`), {});
		return Response(res);
	}

	const getEvent = async (event, lastUpdate) => {
		let res = await request._get(request._build(CONFIG.API.METHODS.GET_QR_EVENT + `?event=${event}&lastUpdate=${lastUpdate}`));
		return Response(res);
	}

	const emitEvent = async (event, data) => {
		let res = await request._post(request._build(CONFIG.API.METHODS.EMIT_QR_EVENT + `?event=${event}`), {data: data});
		return Response(res);
	}

	const getConnectModal = async () => {
		const res = await request._post(request._build("event"), {});
		updateTokenCheck(res);
		return Response(res)
	}


	const setUserAgreementStatus = async (data) => {
		const res = await request._post(request._build('connectresponse'), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const getDataSharingConsentModal  = async () => {
		const res = await request._post(request._build("event"), {scope: 2});
		updateTokenCheck(res);
		return Response(res)
	}

	const setDataSharingConsentStatus = async (data) => {
		const res = await request._post(request._build('connectresponse'), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const addUserCard = async (data) => {
		const res = await request._post(request._build('user/card/add'), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const getUserCards = async (data) => {
		const res = await request._post(request._build('user/card/get'), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const deleteUserCard = async (data) => {
		const res = await request._post(request._build('user/card/delete'), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const setUserCardFavourite = async (data) => {
		const res = await request._post(request._build('user/card/favorite/add'), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const removeUserCardFavourite = async (data) => {
		const res = await request._post(request._build('user/card/favorite/remove'), data);
		updateTokenCheck(res);
		return Response(res)
	}

	const getPaymentMethods = async ({ paymentTypeId, amount }) => {
		const res = await request._get(request._build(CONFIG.API.METHODS.PAYMENT_METHODS + `?PaymentTypeID=${paymentTypeId}&Amount=${amount}`));
		updateTokenCheck(res);
		return Response(res)
	}

	const processPay = async (data) => {
		const res = await request._post(request._build(CONFIG.API.METHODS.PROCESS_PAYMENT), data);
		updateTokenCheck(res);
		return res.data;
	}

	const getCheckoutInfo = async (data) => {
		const res = await request._get(request._build(CONFIG.API.METHODS.CHECKOUT_INFO) + '?' + new URLSearchParams(data).toString());
		updateTokenCheck(res);
		return Response(res)
	}


	const getJobsCategories = async () => {
		let res = await request._get(request._build('myjobs-categories'));
		return Response(res);
	}

	return {
		lang,
		request,
		cookie,
		storage,
		unsetStorageData,
		uploadFiles,
		uploadPhotoForSearch,
		getMainSections,
		getShops,
		getUserShop,
		getUserInfo,
		getUserDetails,
		getUserData,
		getProducts,
		checkout,
		getChekoutDetails,
		getShopProducts,
		getUserTradeInOrders,
		getShopTradeInOrders,
		getTradeInOrder,
		disableProducts,
		enableProducts,
		deleteProducts,
		discountProducts,
		recoverProducts,
		UpdateProductQuantity,
		getNotifications,
		getNotification,
		getNotificationsCount,
		getProductInfo,
		getProductStatusCounts,
		getProductCategories,
		getLastSync,
		syncUser,
		getHelp,
		getHelpSend,
		getTerms,
		getCaptcha,
		getOrders,
		sendContact,
		requestPasswordChange,
		requestProfileChange,
		getAddress,
		postAddAddress,
		putAddressUpdate,
		deleteAddress,
		setDefaultAddress,
		addShop,
		editShop,
		addProduct,
		editProduct,
		getDeliveryDetails,
		getProductDelivery,
		getProductNumber,
		showUserNumber,
		sendMessageToUser,
		sendOfferPrice,
		sendPollResults,
		getBanners,
		getUserByEmail,
		transferMoneyToUser,
		getUserTransactions,
		getUserConversations,
		getUserMessages,
		sendUserMessageChat,
		deleteUserConversation,
		blockUser,
		unblockUser,
		checkUserPhone,
		checkProfilePhone,
		sendUserSMS,
		checkUserSMSCode,
		getEstimateViews,
		getProductsStatistics,
		getProductGroups,
		getProductGroupsDetails,
		saveProductGroups,
		removeGroup,
		removeProductFromGroup,
		addReview,
		getReviews,
		payment,
		makeOrder,
		getPromPackages,
		addPromVip,
		addPromColor,
		addPromAutoUpdate,
		getSuggestions,
		sendCustomPackageRequest,
		sendProductActivationCode,
		activateProduct,
		getTemplates,
		saveTemplates,
		removeTemplates,
		addLeadBank,
		getGroupProducts,
		getGroupDiscountProducts,
		getSavedSearches,
		saveSearch,
		removeSavedSearch,
		tradeInModels,
		tradeInQuestions,
		tradeInCreateOrder,
		changeTradeInOrderStatus,
		TradeInOfferPrice,
		TradeInOfferPriceResponse,
		getInstallments,
		storeAppRequest,
		countBannerViews,
		generateInvoice,
		changeShopOrderStatus,
		getMonthChanges,
		specialPopup,
		checkTbcConnect,
		sendTbcConnectData,
		getPromPrices,
		getSimilarProducts,
		sendDonation,
		getModelAttrs,
		returnProduct,
		getGiftsCount,
		generateProductTitle,
		deactivateUser,
		getAppVersion,
		callRequest,
		checkFeedBack,
		getFeedBackAnswers,
		addFeedBack,
		getAppModels,
		getOfferPrice,
		updateOfferStatus,
		updateOfferUserStatus,
		confirmOrderPickUp,
		updateBankAccount,
		getServiceCenters,
		getShopServiceCenters,
		getBankAccounts,
		addBankAccount,
		setBankAccountDefault,
		deleteBankAccount,
		getCategoriesCounts,
		checkShopProductQuantity,
		sendImageUploadLink,
		getEvent,
		emitEvent,
		getConnectModal,
		setUserAgreementStatus,
		getReview,
		addUserCard,
		deleteUserCard,
		getUserCards,
		setUserCardFavourite,
		removeUserCardFavourite,
		getPaymentMethods,
		processPay,
		getCheckoutInfo,
		setDataSharingConsentStatus,
		getDataSharingConsentModal,
		getJobsCategories
	}
}



export default API