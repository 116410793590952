import React from 'react'
const ShopsOrder = (className = '') => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center  ${className}`}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="6" fill="#F8F9FD" />
            <path d="M15.3333 12.6667H19.3333V14H15.3333V12.6667ZM15.3333 15.3333H19.3333V16.6667H15.3333V15.3333ZM15.3333 18H19.3333V19.3333H15.3333V18ZM12.6667 12.6667H14V14H12.6667V12.6667ZM12.6667 15.3333H14V16.6667H12.6667V15.3333ZM12.6667 18H14V19.3333H12.6667V18ZM21.4 10H10.6C10.2667 10 10 10.2667 10 10.6V21.4C10 21.6667 10.2667 22 10.6 22H21.4C21.6667 22 22 21.6667 22 21.4V10.6C22 10.2667 21.6667 10 21.4 10ZM20.6667 20.6667H11.3333V11.3333H20.6667V20.6667Z" fill="#8996AE" />
        </svg>



    </span>
)

export default ShopsOrder



