import React from 'react'
import { Form } from '../../../modules/address/components/'

const NewAddress = (props) => {
  return (
    <div className="custom-modal-wrapper content-scroll">
      <Form {...{
        api: props.data.api, 
        langs: props.langs, 
        item: {}, 
        Type: 0, 
        cityList: props.data.locsOptions, 
        actionAfterAdd: (e) => {
          props.callback(e, 'destroy')
          props.callback(e, 'afterDestroy')
        },
      }} />
    </div>
  )
}

export default NewAddress