import React, { useContext } from 'react';
import { AppDataContext } from 'contexts/appData';

import { ReactComponent as MyAuto } from 'resources/img/icons/platforms/myauto.svg';
import { ReactComponent as MyParts } from 'resources/img/icons/platforms/myparts.svg';
import { ReactComponent as MyHome } from 'resources/img/icons/platforms/myhome.svg';
import { ReactComponent as MyMarket } from 'resources/img/icons/platforms/mymarket.svg';
import { ReactComponent as MyJobs } from 'resources/img/icons/platforms/myjobs.svg';
import { ReactComponent as SuperApp } from 'resources/img/icons/platforms/superapp.svg';
import { ReactComponent as Tkt } from 'resources/img/icons/platforms/tkt.svg';
import { ReactComponent as Swoop } from 'resources/img/icons/platforms/swoop.svg';
import { ReactComponent as Livo } from 'resources/img/icons/platforms/livo.svg';

const CardPlatforms = (props) => {
  const { langs } = useContext(AppDataContext);

  return (
    <div className="text-center">
      <div className="font-bold font-size-16 mt-8px">{langs.NM_CardAddModalTitle}</div>
      <div className="mt-20px font-size-14">{langs.NM_CardAddModalSubTitle}</div>

      <div className="mt-40px px-24px px-md-30px d-flex flex-wrap gap-24px justify-content-center">
          <MyAuto />
          <MyParts />
          <MyHome />
          <MyMarket />
          <MyJobs />

          <SuperApp />
          <Tkt />
          <Swoop />
          <Livo />
      </div>

      <div className="btn mb-16px px-40px py-14px bg-rgba-blue-10 mt-40px font-size-14 font-base text-white bg-blue-accept" onClick={() => props.callback(null, 'destroy')}>
          {langs.NM_Understand}
      </div>

    </div>
  );
};

export default CardPlatforms;