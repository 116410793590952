import React, { useState, useEffect, createContext } from 'react';
import moment from 'moment';

import { Parser } from 'components';

export const AppDataContext = createContext();

export const AppDataProvider = (props) => {
	const {api, langs, getLang, appData, CONFIG, Functions, storage, FixedLists} = props;

	const [cache, setCache] 					= useState(null);
	const [searchImage, setSearchImage] 		= useState(false);
	const [liveCallerData, setLiveCallerData] 			= useState(null);
	const { detect } = require('detect-browser');
	const browser = detect();

	const currency = (type = CONFIG.CURRENCIES.LARI) => {
		switch (type) {
			case CONFIG.CURRENCIES.LARI:
				return <Parser html={CONFIG.COMMON.LARI} />
			case CONFIG.CURRENCIES.USD:
				return <Parser html={CONFIG.COMMON.USD} />
			default:
				return <Parser html={CONFIG.COMMON.LARI} />
		}
	}

  const getMainParentCatID = (id) => {
		let obj = appData.Cats.List.find((pID) => {
			return (pID && pID.CatID === parseInt(id))
		});
		return obj ? (obj.ParentId === 0 ? obj.CatID : getMainParentCatID(obj.ParentId)) : id;
	}

	const prefix = (CatID) => {
		let seoCatTitle = ''
		if (CatID && parseInt(CatID) > 0 && appData.Cats.List[CatID]) {
			seoCatTitle = `${appData.Cats.List[CatID].SeoLink}/`
		}
    return `/${CatID}/${seoCatTitle}`
	}

	const productPrefixUrl = (product) => {
		let seoCatTitle = ''
		let seoPrTitle = `${product.seoTitle ? `${product.seoTitle}` : ''}`
		let seoOfferType = `${product.vipSeo ? `${product.vipSeo}` : ''}`

		if (product.catId && parseInt(product.catId) > 0 && appData.Cats.List[product.catId]) {
			seoCatTitle = `${appData.Cats.List[product.catId].SeoLink}/`
		} else if (product.cat_id && parseInt(product.cat_id) > 0 && appData.Cats.List[product.cat_id]) {
			seoCatTitle = `${appData.Cats.List[product.cat_id].SeoLink}/`
			if (!seoPrTitle && (product.lang_data?.title || product.title)) {
				seoPrTitle = `${Functions.seoUrlEncode(Functions.convertUrlComponent(product.lang_data?.title ?? product.title))}`
			}
			if (!seoOfferType && product.vip) {
				seoOfferType = `${FixedLists.seoOfferType(product.vip)}`
			}
		}

		return `/${getLang}/pr/${product.product_id}/${seoCatTitle}${seoPrTitle}${seoOfferType ? `?${seoOfferType}` : ''}`;
  };

	const auctionProductPrefixUrl = (product) => {
		let seoCatTitle = ''
		let seoPrTitle = `${product.seoTitle ? `${product.seoTitle}` : ''}`
		let seoOfferType = `${product.vipSeo ? `${product.vipSeo}` : ''}`

		if (product.catId && parseInt(product.catId) > 0 && appData.Cats.List[product.catId]) {
			seoCatTitle = `${appData.Cats.List[product.catId].SeoLink}/`
		} else if (product.cat_id && parseInt(product.cat_id) > 0 && appData.Cats.List[product.cat_id]) {
			seoCatTitle = `${appData.Cats.List[product.cat_id].SeoLink}/`
			if (!seoPrTitle && (product.lang_data?.title || product.title)) {
				seoPrTitle = `${Functions.seoUrlEncode(Functions.convertUrlComponent(product.lang_data?.title ?? product.title))}`
			}
			if (!seoOfferType && product.vip) {
				seoOfferType = `${FixedLists.seoOfferType(product.vip)}`
			}
		}

		return `/${getLang}/lot/${product.product_id}/${seoCatTitle}${seoPrTitle}${seoOfferType ? `?${seoOfferType}` : ''}`;
	};

	const checkLocalStorageKey = (name, hours, reset = true) => {
		if (storage.exists(name)) {
			let localStorageKey = JSON.parse(storage.get(name))
			if (localStorageKey.status === 0) {
				if (moment(new Date()).diff(new Date(localStorageKey.date), 'hours') > hours) {
					storage.set(name, JSON.stringify({ date: new Date(), status: 1 }))
					return true;
				}
				return false;
			}
			return false;
		}
		if (reset) {
			setLocalStorageKey(name)
		}
		return true;
	}

	const setLocalStorageKey = (name) => {
		storage.set(name, JSON.stringify({ date: new Date(), status: 1 }))
	}

	const noOptionsMessage = () => {
		return langs.noOptionsMessage
	}

	useEffect(() => {
  	if (storage.exists("SearchImage")) {
     	let imageStorage = storage.getJson("SearchImage");
			if (imageStorage.image  && imageStorage.key) {
				setSearchImage(imageStorage)
			}
  	}
		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storage.ls.SearchImage]);

	return (
		<AppDataContext.Provider value={{
			api,
			CONFIG,
			Functions,
			langs,
			appData,
			getLang,
			prefix, 
			currency, 
			productPrefixUrl,
			auctionProductPrefixUrl,
			getMainParentCatID, 
			cache, 
			setCache, 
			searchImage, 
			setSearchImage,
			checkLocalStorageKey,
			setLocalStorageKey,
			noOptionsMessage,
			isSafari: ['safari', 'ios'].includes(browser.name) ? true : false,
			isIos: ['ios'].includes(browser.os.toLowerCase()) ? true : false,
			liveCallerData,
			setLiveCallerData
		}}>
			{props.children}
		</AppDataContext.Provider>
	);
};
export default AppDataProvider;