import React from 'react'
const AddStatement = (className) => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="6" fill="#F8F9FD" />
            <path d="M16.0007 9.33331C12.3207 9.33331 9.33398 12.32 9.33398 16C9.33398 19.68 12.3207 22.6666 16.0007 22.6666C19.6807 22.6666 22.6673 19.68 22.6673 16C22.6673 12.32 19.6807 9.33331 16.0007 9.33331ZM18.6673 16.6666H16.6673V18.6666C16.6673 19.0333 16.3673 19.3333 16.0007 19.3333C15.634 19.3333 15.334 19.0333 15.334 18.6666V16.6666H13.334C12.9673 16.6666 12.6673 16.3666 12.6673 16C12.6673 15.6333 12.9673 15.3333 13.334 15.3333H15.334V13.3333C15.334 12.9666 15.634 12.6666 16.0007 12.6666C16.3673 12.6666 16.6673 12.9666 16.6673 13.3333V15.3333H18.6673C19.034 15.3333 19.334 15.6333 19.334 16C19.334 16.3666 19.034 16.6666 18.6673 16.6666Z" fill="#8996AE" />
        </svg>

    </span>
)

export default AddStatement