import React from 'react'
import PropTypes from 'prop-types'

StarIcon.propTypes = {
    className: PropTypes.string
}

export default function StarIcon({ className = '' } = '') {
    return (
        <span className={`svg-icon svg-md-icon d-flex ${className}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <path id="Stroke_40" dataname="Stroke 40" d="M6,0,7.714,4.286H12L9.067,7.632,10.286,12,6,9.429,1.714,12,3.2,7.632,0,4.286H4.286Z" transform="translate(1 1)" fill="#d7dce4" stroke="#d7dce4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
            </svg>

        </span>
    )
}





