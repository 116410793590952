import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone';

import { Response } from 'components'

const Reviewsvg = (className) => (
	<span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
		<svg id="Rate_-_Star" data-name="Rate - Star" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path id="Rate-Full" d="M13.421.922l3,6.5,6.153.669A1.584,1.584,0,0,1,23.934,9.23a1.637,1.637,0,0,1-.522,1.719l-4.906,4.134,1.518,6.95a1.633,1.633,0,0,1-.617,1.664,1.543,1.543,0,0,1-1.736.068L12,20.228,6.319,23.763a1.544,1.544,0,0,1-1.736-.069,1.635,1.635,0,0,1-.619-1.664l1.524-6.948L.582,10.949A1.637,1.637,0,0,1,.066,9.233,1.584,1.584,0,0,1,1.418,8.091l6.151-.669,3-6.5a1.56,1.56,0,0,1,2.847,0Z" fill="#cad7e5" />
		</svg>

	</span>
)

const AddReview = (props) => {
	// console.log(props)
	const maxPhotos = 5
	let reviewsItem = props.data.reviewsItem
	let reviewsStar = props.data.reviewsStar
	const [files, setFiles] = useState([]);
	const [filesTriger, setFilesTriger] = useState(0);

	const [responseModal, setResponseModal] = useState(false);

	const [data, setData] = useState({
		ItemTypeID: reviewsItem.product_id ? 1 : 2,
		ItemID: reviewsItem.product_id ? reviewsItem.product_id : reviewsItem.shop_id ? reviewsItem.shop_id : 0,
		Rate: reviewsStar,
		Review: '',
		Photos: ''
	});

	const close = () => {
		setFiles([])
		setFilesTriger(0)
		setData({})
		props.close();
	}

	const processFiles = async acceptedFiles => {
		let action = await uploadImagesToStatic(acceptedFiles)
		if (action) {
			let newlyUploaded = acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
			setFiles(files.concat(newlyUploaded))
		} else {

		}
	}

	const uploadImagesToStatic = async (files) => {
		const response = await props.api.uploadFiles(files, props.user.ID)
		// console.log(data)
		if (data) {
			let newList = data.Photos !== '' ? data.Photos.split(',') : [];
			data.Photos = newList.concat(response.FilesList).join(',')
			// console.log(data.Photos)
			return data
		}
		return false
	}

	const deleteFile = (e, index) => {
		e.preventDefault() || e.stopPropagation();
		let reorder = files
		reorder.splice(index, 1)
		setFiles(reorder)
		setFilesTriger(filesTriger + 1)
	}

	// const mainFile = (e, index) => {
	//     e.preventDefault() || e.stopPropagation();
	//     let mainItem = files[index]
	//     let reorder = files
	//     reorder.splice(index, 1)
	//     reorder.unshift(mainItem);
	//     setFiles(reorder)
	//     setFilesTriger(filesTriger + 1)
	// }

	const setReviewText = (e) => {
		data.Review = e.target.value
	}

	const setReviewRate = (n) => {
		let newData = { ...data }
		newData['Rate'] = n
		setData(newData)
	}

	const addReview = async () => {
		// console.log(data)
		if (!data) { return; }
		const response = await props.api.addReview(data)
		let modalParams = { statusCode: response.statusCode, statusMessage: response.statusMessage, close: () => setResponseModal(false) }
		if (response.statusCode) {
			modalParams['close'] = () => {
				setResponseModal(false);
				props.success();
				close();
			}
		}
		setResponseModal(modalParams);
	}

	const filesProcess = () => {
		// console.log(files)
		files.forEach(file => Object.assign(file, { preview: URL.createObjectURL(file) }));
	}

	useEffect(filesProcess, [files, filesTriger]);
	return (
		<div className="custom-modal-wrapper content-scroll">
			{responseModal &&
				<Response {...{ langs: props.langs, statusMessage: responseModal.statusMessage, statusCode: responseModal.statusCode, close: responseModal.close }} />
			}
			<div className="popup-content w-100 px-md-16">
				<div className="d-flex justify-content-center">
					{data.ItemTypeID === 1 &&
						<img src={Array.isArray(reviewsItem.images) ? reviewsItem.images[0].thumbs : reviewsItem.images} alt="" className="review-modal-img mx-auto" />
					}
					{data.ItemTypeID === 2 &&
						<img src={reviewsItem.logo} alt="" className="review-modal-img mx-auto" />
					}
				</div>
				<div className="font-bold-caps font-size-12 text-dark-gray mb-24px text-center clamp _2">
					{[1, 2].includes(data.ItemTypeID) ?
						`${props.langs.NM_SendReview} ${reviewsItem.title}`
						: ''
					}
				</div>
				<div className="review-star mb-36px">
					<div id="full-stars" className="d-flex justify-content-center">
						<div className={`rating-group ${data.Rate ? 'active' : ''}`}>
							{Array.from(Array(5).keys()).map((n, i) => (
								<label key={i} aria-label="1 star" className={`rating__label rate-star-key${i} ${data.Rate === (i+1) ? 'active' : ''}`} htmlFor="rating-1"
								onClick={(e) => setReviewRate(n)}>
								<i className="rating__icon rating__icon--star"><Reviewsvg /></i>
								<span className="font-bold-caps font-size-12 text-blue-gray">{props.langs.NM_ReviewStars[i]}</span>
							</label>
							))}
						</div>
					</div>
				</div>
				<div className="form-group mb-24px mt-40px px-12px px-md-0">
					<textarea className="form-control font-base text-dark" name="" cols="" rows="" placeholder={props.langs.NM_ReviewText}
						onChange={setReviewText} defaultValue={data.Review}></textarea>
				</div>
				{data.ItemTypeID === 1 &&
					<div className={`form_box  d-flex align-items-center justify-content-start w-100 img-upload-box border-radius-lg my-40px 
							${files.length > 0 ? 'bg-white  pb-12px pt-24px' : 'bg-transparent px-12px '}`}>
						<div className="row w-100 mx-n3px ">
							{files.map((file, i) => (
								<div className='files-preview-thumbs d-inline-block px-3px mb-6px col-3 col-md-2 ' key={i}>
									<div className="uploaded-img   h-85px  h-md-100px border">
										<img src={file.preview} alt={file.name} className="w-100 h-100 object-fit-cover" />
										{/* {i !== 0 &&
												<button className="btn choose-primary p-0" onClick={e => mainFile(e, i)}>
													<i className="svg-i svg-i-lg add-more-img"></i>
												</button>
											} */}
										<button className="btn remove-img p-0 mx-0  right-initial transform-center left-50   " onClick={e => deleteFile(e, i)}>
											<i className="svg-i svg-i img-delete-i"></i>
										</button>
									</div>
								</div>
							))}
							<Dropzone maxFiles={maxPhotos} accept="image/*" onDrop={acceptedFiles => processFiles(acceptedFiles)}>
								{({ getRootProps, getInputProps }) => (
									<div {...getRootProps({ className: `file_input_inner  bg-white cursor-pointer  mb-0  outline-none ${files.length > 0 ? 'files-preview-thumbs   h-85px  h-md-100px col-3 col-md-2 mx-3px add-more-img p-0 d-inline-block border-radius-10' : 'd-flex pb-0  p-md-40px border-radius-lg w-100'}` })} >
										<div className="uploaded-img mx-auto w-100 h-100 py-24px py-lg-0 d-flex align-items-center justify-content-center ">
											<input {...getInputProps()} name="Photos" id="Photos" />
											<div className={`${files.length > 0 ? '' : 'w-100 text-center'}`}>
												<span className={`${files.length > 0 ? 'add-more-img-i' : 'd-flex justify-content-center '}`}>
													<i className={`svg-i svg-i-lg  ${files.length > 0 ? 'add-more-img' : '  add-photo-icon-i'}`}></i>
												</span>
												<span className={`${files.length > 0 ? 'd-none' : 'font-weight-bold font-size-14 text-dark d-flex justify-content-center mt-8px '}`}>
													{props.langs.UploadPhoto}
												</span>
												<span className={`${files.length > 0 ? 'd-none' : 'font-weight-bold font-size-12 text-blue-gray d-flex justify-content-center mt-8px'}`}>
													{props.langs.NM_MaxPhotos.replace("%", maxPhotos)}
												</span>
											</div>
										</div>
									</div>
								)}
							</Dropzone>
						</div>
					</div>
				}
			</div>
			<div className={`popup-footer d-flex justify-content-between  overflow-hidden px-48px pt-24px mt-40px`}>
				<button className="btn d-flex justify-content-center align-items-center   bg-transparent font-base  font-size-13 text-blue-gray mr-md-10px pl-md-0 h-46px" onClick={close}>{props.langs.Close}</button>
				<button className="btn d-flex justify-content-center align-items-center bg-gradient font-bold-caps font-size-13 text-white px-40px h-46px" onClick={(e) => addReview()}>{props.langs.Send}</button>
			</div>
		</div>
	)
}

export default AddReview