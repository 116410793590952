import { Category } from '../models/_Category';
import { Functions } from 'libs'
export class Catsparser {

    data;
    convertedData;
    categoryHierarchy;

    constructor(data) {
        this.data = data;
        return this;
    }

    convert() {
        this.convertedData  = {};
        const Categories    = [];
        const CatsHierarchy = [];
        const Cats          = this.data;
        
        for (let i in Cats) {
            const CurrentCatID = Cats[i]['id'];
            const ParentCatID  = Cats[i]['pc'];
            const SeoCurrent = Functions.seoUrlEncode(Functions.convertUrlComponent(Cats[i]['t']))

            Categories[CurrentCatID] = new Category(Cats[i]);
            if (ParentCatID > 0) {
                const SeoParent  = this.generateSeoTree(ParentCatID, '')
                Categories[CurrentCatID].SeoLink = `${SeoParent}${SeoCurrent}`;
            } else {
                Categories[CurrentCatID].SeoLink = `${Functions.urlPrefix(CurrentCatID)}${SeoCurrent}`;
            }

            if (!CatsHierarchy[ParentCatID]) {
                CatsHierarchy[ParentCatID] = [];
            }

            CatsHierarchy[ParentCatID].push(CurrentCatID);
        }

        this.convertedData = {'List': Categories, 'Hierarchy': CatsHierarchy};
        
        return this;
    }

    generateSeoTree(parentID, string) {
        let category = this.data.find((o) => o && o.id === parentID)
        let seoLink = '';
        if (category && category.t) {
            seoLink = Functions.seoUrlEncode(Functions.convertUrlComponent(category.t));
        }
        if (category.pc > 0) {
            return this.generateSeoTree(category.pc, `${seoLink}/${string}`)
        } else {
            return `${seoLink}/${string}`
        }
    }
    
    getRaw() {
        return this.data || null;
    }
  
    get() {
        return this.convertedData || null;
    }
    
    getHierarchy() {
        return this.categoryHierarchy || null;
    }
}
