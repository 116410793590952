const StaticLinks = (props) => {
	
	return {
		Menu: [
			// { Title: props.langs.home, Link: `` },
			{ Title: props.langs.AddPr, Link: `/${props.getLang}/pr-form`, External:true  },
			{ Title: props.langs.BuyOnline, Link: `/${props.getLang}/search?Online=1`, External:true  },
			{ Title: props.langs.Used_products, Link: `/${props.getLang}/search?CondTypeID=0.2&Online=1&Page=1&SetTypeID=1`, External:true  },
			// { Title: props.langs.NM_Form_Delivery, Link: `search?DeliveryType=1.2`, External:true  },
			{ Title: props.langs.Safety_menuList, Link: `/${props.getLang}/safetylanding` , External:true },
			{ Title: props.langs.Shops, Link: `/${props.getLang}/shops`, External:true  },
			{ Title: props.langs.OpenShop, Link: `/${props.getLang}/shop`, External:true  },
			{ Title: props.langs.TradeIn, Link: `/${props.getLang}/tradein`, External:true  },
	
		],
		// MyPage: [
		// 	{ Title: props.langs.MyPrs, Link: `myproducts` },
		// 	{ Title: props.langs.AddPr, Link: `pr-form` },
		// 	{ Title: props.langs.messages, Link: `chat` },
		// 	{ Title: props.langs.FillBalance, Link: `finances` },
		// 	{ Title: props.langs.EditAccount, Link: `profile` },
		// ],
		// MyGeWebSites: [
		// 	{ Title: 'auto', Link: `https://www.myauto.ge/${props.getLang}`, Class: `auto-color` },
		// 	{ Title: 'parts', Link: `https://www.myparts.ge/${props.getLang}`, Class: `parts-color` },
		// 	{ Title: 'home', Link: `https://www.myhome.ge/${props.getLang}`, Class: `home-color` },
		// 	{ Title: 'jobs', Link: `https://www.myjobs.ge/${props.getLang}`, Class: `jobs-color` },
		// 	// {Title: 'shop', Link: `https://www.myshop.ge/${props.getLang}`, Class: `shop-color`},
		// 	{ Title: 'ads', Link: `https://www.myads.ge/${props.getLang}`, Class: `ads-color` },
		// ],
		Help: [
			{ Title: props.langs.APP_faq, Link: `/${props.getLang}/faq` },
			{ Title: props.langs.Contact, Link: `/${props.getLang}/contact` },
			{ Title: props.CONFIG.COMMON.EMAIL, Email:`/${props.getLang}/mailto:${props.CONFIG.COMMON.EMAIL}` },
			{ Title: props.langs.M_AnonymousFeedback, Link: `https://guest-anonymous-hotline.tnet.ge/` },


		],
		Rules: [
			{ Title: props.langs.SiteRules, Link: `https://auth.tnet.ge/ka/rules/`, External:true  },
			{ Title: props.langs.NM_Privacy_Policy, Link: `https://auth.tnet.ge/ka/usageagreement`, External:true  },
			{ Title: props.langs.NM_ReturnPolicy, Link: `/${props.getLang}/rules/mymarketreturnpolicy`, External:true },
		],
		
		Social: [
			{ Icon: 'fb-i', Link: `https://www.facebook.com/mymarket.ge/`, Class: `` },
			{ Icon: 'insta-i', Link: `https://www.instagram.com/mymarket.ge/`, Class: `ml-10px mr-10px` },
			{ Icon: 'linkedin-i', Link: `https://www.linkedin.com/company/tnetgeorgia/mycompany/`, Class: `` },
		],
		Language: [
			{ text: props.langs.interfaceLang, NoOption:true},
			{ text: props.langs.LanguagesNames['ka'], Name: 'ka', Link: props.changeLang('ka') },
			{ text: props.langs.LanguagesNames['en'], Name: 'en', Link: props.changeLang('en') },
			{ text: props.langs.LanguagesNames['ru'], Name: 'ru', Link: props.changeLang('ru') },
		]
	}
}

export default StaticLinks