import React from 'react'
import { Link } from 'react-router-dom'
import { Parser } from 'components'
import reminder from '../../../resources/img/reminder.svg'

const Reminder = (props) => {
  const { langs, getLang, callback } = props;
  const close = () => {
    callback(null, 'destroy')
  }
  return (
    <div className="custom-modal-wrapper">
      <div className="position-relative ">
        <div className="popup-content  px-8px  content-scroll pt-44px">
          <h3 className="font-bold-caps font-size-18 pb-8px text-left">
            {langs.NM_Online_Product_Sold}
          </h3>
          <div className="text-editor-styles mt-24px">
          <Parser html={langs.NM_Online_Product_Sold_Descr} />
          </div>
          <div className="d-flex justify-content-center mt-24px">
           <img src={reminder} alt="" />
          </div>
       
        </div>
        <div className="d-flex justify-content-center mt-24px">
          <Link to={`/${getLang}/orders/c2c`} type="submit" className="btn bg-blue font-bold-caps font-size-13 text-white  px-24px py-16px" onClick={close}>
           {langs.NM_start}
          </Link>
          <button type="submit" className="btn bg-transparent ml-12px font-bold-caps font-size-13 text-blue px-24px py-16px" onClick={close}>
            {langs.NM_Close}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Reminder