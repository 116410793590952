import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from 'contexts/user';
import { NotificationIcon, DateMoment, Parser, OutsideClickHandler } from 'components';

const Notifications = (props) => {
	const { notifications, getNotifications } = useContext(UserContext);
	
	const togglenotification = (e) => {
		e.preventDefault() || e.stopPropagation();
		if (!props.user) {
			props.Functions.redirectWindow(false, props.getLang, true, `${props.CONFIG.HOST.appUrl}${props.getPathname().substring(1)}`);
		} else {
			getNotifications()
			// if (!props.notificationDropdown) {
			// 	getNotifications()
			// }
			// props.setNotificationDropdown(!props.notificationDropdown);
			props.push(`/${props.getLang}/chat`, {}, true);
		}
	};
	return (
		<div className={`notification-box cursor-pointer mr-8px m-hidden`} 
			onClick={(e) => { togglenotification(e); }}>
			<li className={`nav-item d-flex align-items-center dropdown`} ref={props.notDropdown}>
				<i className="svg-i svg-i-sm message-i w-24px h-24px "></i>
				{notifications?.unseen_count > 0 &&
					<span className="rounded-circle badge badge-pill bg-badge-orange font-base text-white position-absolute badge-counter">
						{notifications.unseen_count}
					</span>
				}
			</li>
			<OutsideClickHandler {...props.notificationsHandlerParams}>
			<h2 className="pl-24px pt-24px mb-16px font-size-24 font-bold">
				{props.langs.messages}
			</h2>
			<div className="notification-lists">
				{notifications?.Notifications?.length > 0 &&
					<ul className="p-0 m-0">
						{notifications.Notifications.map((item, i) => {
							let className = item.type_id === 1 ? "news" : item.type_id === 2 ? "danger" : "prohibition"
							return (
								<li key={item.id} className={`notification-li notification-box ${!item.seen ? 'unread' : ''}`}>
									<div className="notification-hv d-block" 
										onClick={e => props.goToNotificationsPage(e, item.id)}
									>
										<div className="notification-item px-24px py-12px ">
											<div className="d-flex align-items-center">
												<div className={`mr-15px icon-cont rounded-circle text-center align-self-start position-relative ${className}`}>
													<NotificationIcon className={`${item.type_id === 1 ? "news" : ""}`} />
												</div>
												<div className="media-body">
													<div className="notification-text text-dark font-medium line-height-11 font-size-14  clamp _2">
														<Parser html={item.description} />
													</div>
													<div className="notification-date font-size-13 text-left mt-8px">
														<DateMoment date={item.notification_date} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</li>
							)
						})}
						<div className="see-all d-flex align-items-center justify-content-center bg-light-gray">
							<Link to={`/${props.getLang}/notifications`} className="w-50 text-center py-12px font-size-12 font-base-italic">
							  {props.langs.News}
							</Link>
							<Link to={`/${props.getLang}/chat`} className="w-50 text-center py-12px font-size-12 font-base-italic">
							  {props.langs.MyMessages}
							</Link>
						</div>
					</ul>
				}
			</div>
			</OutsideClickHandler>
		</div>
	)
}

export default Notifications;