import React, { useContext, useRef } from 'react';
import { AppDataContext } from 'contexts/appData';

import { ReactComponent as InfoIcon } from 'resources/img/icons/info-black.svg';

// Platforms
import { ReactComponent as MyAuto } from 'resources/img/icons/platforms/myauto.svg';
import { ReactComponent as MyParts } from 'resources/img/icons/platforms/myparts.svg';
import { ReactComponent as MyHome } from 'resources/img/icons/platforms/myhome.svg';
import { ReactComponent as MyMarket } from 'resources/img/icons/platforms/mymarket.svg';
import { ReactComponent as MyJobs } from 'resources/img/icons/platforms/myjobs.svg';
import { ReactComponent as SuperApp } from 'resources/img/icons/platforms/superapp.svg';
import { ReactComponent as Tkt } from 'resources/img/icons/platforms/tkt.svg';
import { ReactComponent as Swoop } from 'resources/img/icons/platforms/swoop.svg';
import { ReactComponent as Livo } from 'resources/img/icons/platforms/livo.svg';

const TnetPlatforms = () => {
  const ref = useRef(null);
  const { langs } = useContext(AppDataContext)

  return (
    <div className="payment-cards-platforms position-relative mt-12px">
      <div className="d-flex align-items-center payment-cards-text" onTouchStart={() => ref.current.classList.toggle('opacity-1')}>
        <InfoIcon className="mr-10px" />
        <div className="font-size-12 text-primary-text-color">{langs.NM_CardAddInfo}</div>
      </div>
      <div className="position-absolute z-index-3 w-100 max-width-md-510px p-24px mt-12px border border-gray-20 border-radius-10 shadow-10 bg-white payment-cards-logos" ref={ref}>
        <div className="d-flex flex-wrap gap-20px">
          <MyAuto />
          <MyParts />
          <MyHome />
          <MyMarket />
          <MyJobs />

          <SuperApp />
          <Tkt />
          <Swoop />
          <Livo />
        </div>
      </div>
    </div>
  );
};

export default TnetPlatforms;
