import React from 'react'
import { Field, ErrorMessage } from 'formik';
const CheckboxField = (props) => {
	// console.log(props)
	// styles {parentClassname, checkboxListClassname, optionClassname, fieldClassname, labelClassname, errorClassname}
	// parentLabel {name, className, title}
	// fieldName (string)
	// checkboxList [{value, title}]
	// showError (bool)
	// onChange (function)
	const compareValues = (value) => {
		if (Array.isArray(props.activeValue)) {
			return props.activeValue.includes(value) ? true : false
		}
		return String(props.activeValue) === value
	}
	return (
		<div className={props.styles?.parentClassname ?? ''}>
			{props.checkboxList?.length > 0 &&
				props.checkboxList.map((option, index) => (
					<div className={props.styles?.checkboxListClassname ?? ''} key={index}>
						<Field
							name={`${props.fieldName}`}
							type="checkbox"
							id={`checkbox-${props.fieldName}-${index}`}
							value={option.value}
							className={`${props.styles?.fieldClassname ?? ''} ${compareValues(option.value) ? 'active' : ''}`}
							onChange={props.onChange}
							checked={false}
							disabled={props.disabled ?? false}
						/>
						{props.checkboxLabel &&
							<label htmlFor={`checkbox-${props.fieldName}-${index}`} className={props.checkboxLabel.className ?? ''}>
								{option.label}
							</label>
						}
						{props.children && props.children}
					</div>
				))
			}
			{props.showChecboxError &&
				<ErrorMessage name={props.checkboxLabel.name} component="label" className={props.styles?.errorClassname ?? 'error text-danger'} />
			}
		</div>
	)
}
export default CheckboxField