import React, {
	useState,
	useEffect
} from 'react';
import OpenPlayerJS from 'openplayerjs';
import 'openplayerjs/dist/openplayer.css';
// import CONFIG from 'core/config'
import emptyVideo from 'resources/img/black.mp4'
const VideoBanner = (props) => {
	const [isLoaded, setIsLoaded] = useState(false);



	// const getVideoElement = () => {
	// 	return document.getElementById(videoID);
	// }
	// const awaitVideoPlacement = async () => {
	// 	if (getVideoElement() !== null) {

	// 		// if (props.banner.type === CONFIG.BANNER_TYPES.VAST) {
	// 		//     config = {...config, ...{
	// 		//         ads: {
	// 		//             // src: [props.banner.banner_content.replace('[timestamp]', (new Date()).getTime())]
	// 		//             src: [props.banner.banner_content],
	// 		//             loop: true,
	// 		//         }
	// 		//     }}
	// 		// } else {

	// 		// }
	// 		const player = new OpenPlayerJS(videoID, config);
	// 		// player.getElement().addEventListener('adsstart', function() {});
	// 		// player.getElement().addEventListener('adscomplete', function() {
	// 			// props.api.storage.set("VideoPreroll", JSON.stringify({ date: new Date(), status: 0 }))
	// 			// setVideoBanner(false)
	// 		// });
	// 		player.getElement().addEventListener('playererror', function(error) {
	// 			console.log(error)
	// 		});
	// 		// player.getElement().addEventListener('playerdestroyed', function() {
	// 			// props.api.storage.set("VideoPreroll", JSON.stringify({ date: new Date(), status: 0 }))
	// 			// setVideoBanner(false)
	// 		// });
	// 		// player.getElement().addEventListener('ended', function() {
	// 			// props.api.storage.set("VideoPreroll", JSON.stringify({ date: new Date(), status: 0 }))
	// 			// setVideoBanner(false)
	// 		// });
	// 		// player.getElement().addEventListener('pause', async function() {
	// 			// if (videoBanner) {
	// 				// await player.play()
	// 				// player.getElement().muted = false
	// 			// }
	// 		// });
	// 		player.init()
	// 		// setVideoBanner(true)
	// 		await player.load()
	// 		await player.play()
	// 		// player.getElement().muted = false
	// 	} else {
	// 		setTimeout(() => {
	// 			awaitVideoPlacement()
	// 		}, 500)
	// 	}
	// }

	useEffect(() => {
		let videoID = `video${props.banner.banner_id}`;
		let config = {
			controls: {
				hidePlayBtnTimer: 0,
				alwaysVisible: true,
				layers: {
					left: ['play', 'volume'],
					middle: ['progress'],
					right: [],
				}
			},
			step: 1,
			startTime: 0,
			startVolume: 0,
			ads: {
				loop: true,
				src: [
					props.banner.banner_content, 
					props.banner.banner_content, 
					props.banner.banner_content, 
					props.banner.banner_content,
					props.banner.banner_content
				],
			}
		}
		const player = new OpenPlayerJS(videoID, config);
		player.init();
		player.getElement().addEventListener('adsloaded', function (e) {
			setIsLoaded(true);
		});
		// player.getElement().addEventListener('adscomplete', function (e) {
		// 	console.log('adscomplete', e)
		// });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	awaitVideoPlacement()
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])

	// const closeVideoBanner = () => {
	//     let player = OpenPlayerJS.instances[videoID];
	//     player.volume = 0
	//     player.pause()
	//     player.destroy()
	//     props.api.storage.set("VideoPreroll", JSON.stringify({ date: new Date(), status: 0 }))
	//     setVideoBanner(false)
	// }

	return (
		<div className={`${props.parentClassName}`}style={{
				display: isLoaded ? 'block' : 'none'
			}}>
			<video className={props.childClassName} id={`video${props.banner.banner_id}`} autoPlay >
				<source src={emptyVideo} type="video/mp4" />
			</video>
			{/* <div className="close-video p-8px rounded-circle bg-rgba-dark-gray-75 position-absolute top-20px right-20px z-index-1000 cursor-pointer"
				onClick={closeVideoBanner}>
				<i className="svg-i close-white-i"></i>
			</div> */}
		</div>
	)
}

export default VideoBanner;