import { useState, useEffect, useContext, useRef } from 'react';

import { UserContext } from 'contexts/user';
import { AppDataContext } from 'contexts/appData';
import { HistoryContext } from 'contexts/history';
import { ModalsContext } from 'contexts/modals';

import Header from './Header'
import * as gtagAnalytics from "../../../libs/gtagAnalytics";
const HeaderLib = (props) => {
	const { api, CONFIG, langs, getLang, Functions } = props

	const { searchImage, setSearchImage, setLiveCallerData } = useContext(AppDataContext);
	const { params, getPathname, getPathQuery, stringify, push, replace, assignParam, changePage, remove } = useContext(HistoryContext)
	const { user, shop, notifications } = useContext(UserContext);
	const { callModal } = useContext(ModalsContext)

	const [userDropdown, setUserDropdown] = useState(false);
	const [menuDropdown, setMenuDropdown] = useState(false);
	const [tnet, setTnet] = useState(false);
	const [notificationDropdown, setNotificationDropdown] = useState(false);
	// keyword
	const [keyword, setKeyword] = useState({ active: false, old: false });
	const [ keywordPlaceholder, setKeywordPlaceholder] = useState(CONFIG.COMMON.KEYWORD_PLACEHOLDER);

	const [activeSearch, setActiveSearch] = useState(false);
	const [searchBySuggestion, setSearchBySuggestion] = useState(false);

	const [suggestions, setSuggestions] = useState(false);
	const [hoveredSugID, setHoveredSugID] = useState(-1);

	const [mobileSidebar, setMobileSidebar] = useState(null);

	const [mobileSearch, setMobileSearch] = useState(false);

	const userDropdownOutsideClickHandlerParams = {
		ref: useRef(null),
		disabled: userDropdown,
		onOutsideClick: () => {
			setUserDropdown(false);
		}
	};

	const menuDropdownOutsideClickHandlerParams = {
		ref: useRef(null),
		disabled: menuDropdown,
		onOutsideClick: () => {
			setMenuDropdown(false);
		}
	};

	const tnetDropdownOutsideClickHandlerParams = {
		ref: useRef(null),
		disabled: tnet,
		onOutsideClick: () => {
			setTnet(false);
		}
	}

	const searchParams = {
		ref: useRef(null),
		disabled: mobileSearch,
		onOutsideClick: () => {
			setMobileSearch(false);
		}
	};
	const inputRef = useRef(null);

	const notDropdown = useRef(null);
	const notificationsHandlerParams = {
		ref: useRef(null),
		toggleButton: notDropdown,
		disabled: notificationDropdown,
		className: `notification-dropdown position-absolute bg-white border-radius-10 ${notificationDropdown ? 'show' : ''}`,
		onOutsideClick: () => {
			setNotificationDropdown(false);
		}
	};

	const toggleUserDropdown = (e) => {
		e.preventDefault() || e.stopPropagation();
		setUserDropdown(!userDropdown);
	};

	const mainSearch = (e) => {
		e.preventDefault() || e.stopPropagation();
		let keyActive = keyword.active ? keyword.active : ''
		// if (!keyActive) {
		// 	return;
		// }
		if (hoveredSugID > -1 && suggestions[hoveredSugID]) {
			keyActive = suggestions[hoveredSugID]
			let newKeyword = { ...keyword };
			newKeyword.active = keyActive;
			setKeyword(newKeyword)
			setHoveredSugID(-1)
			setSuggestions([])
			setActiveSearch(true)
		}
		setTimeout(() => {
			let newParams = { Keyword: keyActive, OfferType: '', Page: 1 }
			// assignParam(newParams)
			if (getPathname().includes(`/${getLang}/search`)) {
				remove(0);
				replace(changePage("search", true), newParams);
			} else {
				push(changePage("search", true), newParams, true);
			}
			setActiveSearch(false);
		}, 200)

		gtagAnalytics.searchEvent({search_term: keyActive});
	}

	const uploadFileForSearch = async (e) => {
		e.preventDefault() || e.stopPropagation();
		let data = await api.uploadPhotoForSearch(e.target.files)
		if (data) {
			api.storage.set("SearchImage", JSON.stringify({ image: data.FilesList[0], key: data.imgKey[0] }))
			setSearchImage({ image: data.FilesList[0], key: data.imgKey[0] })
			push(`/${getLang}/search`, {})
		}
	}

	const callImageUpload = (e) => {
		e.preventDefault() || e.stopPropagation();
		callModal({
			component: 'ImageSearch',
			callback: {
				upload: uploadFileForSearch
			}
		})
	}

	const clearSearchImage = () => {
		api.storage.remove("SearchImage")
		setSearchImage(false)
	}

	const openAddDialog = (e) => {
		e.preventDefault() || e.stopPropagation();
		if (!user) {
			Functions.redirectWindow(true, getLang, true, `${CONFIG.HOST.appUrl}${getPathQuery()}`);
			return;
		}
		push(`/${getLang}/pr-form/`, {})
		// callModal({
		// 	component: 'NewProduct',
		// 	styles: {inner: "new-product"}
		// })
	}

	const goToNotificationsPage = (e, itemID) => {
		e.preventDefault() || e.stopPropagation();
		setNotificationDropdown(false);
		push(`/${getLang}/notifications/${itemID}`)
	}

	const fetchSuggestionsData = async () => {
		const apiData = await api.getSuggestions(stringify({ Keyword: keyword.active }));
		if (apiData && apiData.statusCode && apiData.data.keywords && apiData.data.keywords.length > 0) {
			setSuggestions(apiData.data.keywords);
		} else {
			setSuggestions([]);
		}
	};

	const searchKeyPress = (e) => {
		if (!suggestions || (e.keyCode !== 40 && e.keyCode !== 38)) {
			return;
		}
		let newHoverSlugID = false;
		if (e.keyCode === 40 && suggestions) {
			newHoverSlugID = hoveredSugID < suggestions.length - 1 ? hoveredSugID + 1 : 0;
		} else if (e.keyCode === 38 && suggestions) {
			newHoverSlugID = hoveredSugID > 0 ? hoveredSugID - 1 : suggestions.length - 1;
		}
		setHoveredSugID(newHoverSlugID);
	};

	const getSuggestions = () => {
		let length = keyword.active.length
		if (length >= 1 && length < 20) {
			if (!activeSearch) {
				setActiveSearch(true);
			}
			fetchSuggestionsData();
		} else {
			setSuggestions([]);
		}
	}

	const toggleMenu = (e, action = null) => {
		e && (e.preventDefault() || e.stopPropagation());
		if (mobileSidebar === null) {
			setMobileSidebar(true)
		} else {
			setMobileSidebar(action !== null ? action : !mobileSidebar)
		}
	};

	const setNewKeyword = (newValue, oldValue = null) => {
		let newKeyword = { ...keyword };
		newKeyword.old = oldValue !== null ? oldValue : newKeyword.active;
		newKeyword.active = newValue ?? false;
		setKeyword(newKeyword)
	}

	const searchInputProps = {
		disabled: searchImage,
		placeholder: langs.M_Search,
		name: "keyword",
		autoComplete: "off",
		value: keyword.active ? keyword.active : '',
		onClick: (e) => setActiveSearch(true),
		onChange: (e) => setNewKeyword(e.target.value),
		onBlur: (e) => {
			setActiveSearch(false);
			setKeywordPlaceholder(CONFIG.COMMON.KEYWORD_PLACEHOLDER);
		},
		onFocus: () => setKeywordPlaceholder(langs.NM_search),
		onKeyDown: (e) => searchKeyPress(e),
		ref: inputRef
	}

	const suggestionsProps = {
		suggestions,
		setSearchBySuggestion,
		keyword,
		setActiveSearch,
		setNewKeyword,
		hoveredSugID,
		setHoveredSugID
	}

	const notificationsProps = {
		user,
		CONFIG,
		langs, getLang,
		getPathname,
		notificationsHandlerParams,
		notificationDropdown,
		setNotificationDropdown,
		goToNotificationsPage,
		notDropdown,
		push,
		Functions
	}

	const openSearch = () => {
		setMobileSearch(true)
	}

	const authorization = () => {
		Functions.redirectWindow(true, getLang, true, `${CONFIG.HOST.appUrl}${getPathQuery()}`);
	}

	const avatarProps = {
		logo: false,
		link: '',
		photo: user.Photo ? user.Photo : false,
		gender: user.GenderID ? user.GenderID : 1,
		className: "header-profile-img",
		iClassName: "svg-i-header"
	}

	const userDropdownProps = {
		user,
		shop,
		getLang,
		langs
	}

	const tnetProps = {
		langs,
		tnet,
		setTnet
	}

	const toggleMenuDropdown = () => {
		setMenuDropdown(!menuDropdown)
	}

	const userSidebarProps = {
		toggleMenu,
		mobileSidebar
	}
	const changeLang = (lang) => {
		return `${window.location.href.replace(`/${getLang}`, `/${lang}`)}`
	}

	const callLivecallerMessage = (e) => {
		e.preventDefault() || e.stopPropagation();
		setLiveCallerData('chat')
	}
	const callLivecallerPhone = (e) => {
		e.preventDefault() || e.stopPropagation();
		setLiveCallerData('phone')
	}

	useEffect(() => {
		if (getPathname().includes(`/${getLang}/search`)) {
			if (params.Keyword && !keyword.active && params.Keyword !== keyword.active) {
				setNewKeyword(params.Keyword, params.Keyword)
			} else if (!params.Keyword) {
				setNewKeyword(false, false);
			}
		} else {
			setNewKeyword(false, false)
			if (api.storage.exists("SearchImage")) {
				clearSearchImage()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.Keyword])

	useEffect(() => {
		if (mobileSearch && inputRef.current !== null) {
			inputRef.current.focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mobileSearch]);

	useEffect(() => {
		if (searchBySuggestion) {
			setSuggestions([]);
			assignParam({ Keyword: keyword.active, Page: 1 })
			if (getPathname().includes(`/${getLang}/search`)) {
				replace(changePage("search"), { Keyword: keyword.active, Page: 1 })
			} else {
				push(changePage("search"), { Keyword: keyword.active, Page: 1 })
			}
			setSearchBySuggestion(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchBySuggestion]);

	useEffect(() => {
		if (keyword.active !== false) {
			if (keyword.active !== keyword.old) {
				// console.log(keyword)
				const timer = setTimeout(() => {
					getSuggestions()
				}, 200);
				return () => clearTimeout(timer);
			} else {
				setSuggestions([]);
			}
		} else {
			setSuggestions([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyword.active]);

	useEffect(() => {
		let body = document.body.classList;
		if (mobileSidebar) {
			body.add('menu-open')
		} else {
			body.remove('menu-open')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mobileSidebar])

	return <Header {...{
		CONFIG,
		langs,
		getLang,
		searchParams,
		searchImage,
		clearSearchImage,
		searchInputProps,
		suggestionsProps,
		notificationsProps,
		suggestions,
		keyword,
		mobileSearch,
		openSearch,
		mainSearch,
		activeSearch,
		openAddDialog,
		callImageUpload,
		user,
		shop,
		authorization,
		userDropdownOutsideClickHandlerParams,
		toggleUserDropdown,
		userDropdown,
		avatarProps,
		userDropdownProps,
		menuDropdownOutsideClickHandlerParams,
		menuDropdown,
		toggleMenuDropdown,
		toggleMenu,
		userSidebarProps,
		mobileSidebar,
		tnetDropdownOutsideClickHandlerParams,
		tnetProps,
		notifications,
		changeLang,
		callLivecallerMessage,
		callLivecallerPhone
	}} />
}

export default HeaderLib