import { useEffect } from 'react';

export const useEventListener = (event, cb) => {
	useEffect(() => {
		window.addEventListener(event, cb);
		return () => {
			window.removeEventListener(event, cb);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
