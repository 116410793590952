import { useContext } from 'react';
import { AppDataContext } from 'contexts/appData';
import moment from 'moment'

const DateMoment = (props) => {
	const {date, format, insertText} = props;
	const { getLang } = useContext(AppDataContext);

	if (getLang === 'ka') {
		moment.updateLocale('ka', {
			months : 'იანვარი_თებერვალი_მარტი_აპრილი_მაისი_ივნისი_ივლისი_აგვისტო_სექტემბერი_ოქტომბერი_ნოემბერი_დეკემბერი'.split('_'),
			monthsShort : 'იან_თებ_მარ_აპრ_მაი_ივნ_ივლ_აგვ_სექ_ოქტ_ნოე_დეკ'.split('_'),
		});
	} else if (getLang === 'ru') {
		moment.updateLocale('ru', {
			monthsShort : {
				format: 'янв._февр._мар._апр._мая_июня_июля_авг._сент._окт._нояб._дек.'.split('_'),
				standalone: 'янв._февр._март_апр._май_июнь_июль_авг._сент._окт._нояб._дек.'.split('_')
			},
		});
	}
	// .format('L')

	let dateISO = moment(date).toISOString()
	let result = moment(dateISO).locale(getLang).format(format ? format : "DD.MM.YYYY");
	if (insertText) {
		return insertText.replace('%s', result)
	}
	return result
};

export default DateMoment;
