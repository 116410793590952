import React from 'react'
import { Field, ErrorMessage } from 'formik';
const InputField = (props) => {
	// styles {parentClassname, optionsListClassname, optionClassname, fieldClassname, labelClassname, errorClassname}
	// inputLabel {name, className, title}
	// fieldName (string)
	// optionsList [{value, title}]
	// showError (bool)
	// required (bool)
	// disabled (bool)
	// placeholder (string)
	// onChange (function)

	return (
		<div className={props.styles?.parentClassname ?? ''}>
			{props.inputLabel &&
				<label htmlFor={props.fieldName} className={props.inputLabel.className ?? ''}>
					{props.inputLabel.title}
					{props.required &&
						<span className="required text-red d-inline-block ml-2px">*</span>
					}
					{props.tooltip &&
						<i className='svg-i grey-info total-tooltip-info ml-4px cursor-pointer position-relative'>
							<div className="total-tooltip pointer-events-none bg-light-blue border-radius-md d-flex align-items-start min-width-210px position-absolute absolute-x-center  px-20px py-16px top-20px z-index-1000">
								<span className="font-size-12 font-base text-dark-gray font-weight-normal text-left">
									{props.tooltip}
								</span>
							</div>
						</i>
					}
				</label>
			}
			<Field
				name={props.fieldName}
				placeholder={props.placeholder ?? ''}
				type={props.type ? props.type : 'text' }
				value={props.value}
				className={`${props.styles?.fieldClassname ?? ''}`} 
				onChange={props.onChange}
				disabled={props.disabled ?? false}
			/>
			{props.children && props.children}
			{props.showInputError &&
				<ErrorMessage name={props.fieldName} component="label" className={props.styles?.errorClassname ?? 'error text-danger'} />
			}
		</div>
	)
}
export default InputField