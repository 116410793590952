import React, { useState, useEffect, createContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import queryString from 'query-string';

export const HistoryContext = createContext();

export const HistoryProvider = (props) => {
	const history = useLocation();
	const navigate = useNavigate();

	const getQueryString = () => {
		return queryString.parse(history.search)
	}
	
	const [params, setParams]                   = useState(getQueryString());
	const [routeParams, setRouteParams] 				= useState(null);
	const [mobileMenu, setMobileMenu]           = useState(false);


	const getPathname = () => {
		return history.pathname
	}

	const generateRouteDynamicParams = () => {
		let routeParams = {}
		let pathname = getPathname().split("/").filter(Boolean)
		if (pathname.length > 2) {
			let foundPaths = []
			props.RouteList.map((route) => {
				if (route.path.includes(pathname[1])) {
					let routeNames = route.path.split("/").filter((a) => a && a !== '*')
					if (routeNames.length === pathname.length) {
						foundPaths = routeNames.splice(2);
					}
				}
				return route
			})
			if (foundPaths.length > 0) {
				pathname.splice(2).map((name, i) => {
					routeParams[foundPaths[i].replace(":", "")] = name
					return name;
				})
			}
		}
		return routeParams;
	}

	// Dev Note: use this value instead routeParams state
	const currentRouteParams = generateRouteDynamicParams();


	// const getPagename = () => {
	// 	let url = getPathname().split("/")
	// 	return url[2] ? url[2] : false
	// }

	const getPagename = () => {
		let url = window.location.pathname.split("/");
		return url[2] ? url[2] : "ka";
	}	
	
	
	

	const changePage = (page, limit = false) => {
		let url = getPathname().split("/")
		if (limit && url.length > 4) {
			url = url.slice(0, 3)
		}
		url[2] = page
		return url.join("/")
	}

	const stringify = (priority = {}, noParams = false, reverse = false) => {
		let config = reverse ? Object.assign({}, priority, params) : Object.assign({}, params, priority)
		return queryString.stringify(!noParams ? config : priority, {
			skipNull: true,
			skipEmptyString: true
		});
	}

	const push = (url, priority = {}, clean = false, state= { hash: true }) => {
		if (clean) { remove(0) }
		return navigate(url + '?' + stringify(priority), state)
	}

	const replace = (url, priority = {}, clean = false) => {
		return navigate(`${url}${!clean ? `?${stringify(priority)}` : ''}`, { hash: true, replace: true })
	}

	const remove = (type = 0, set = [], newSet = false) => {
		//type = 0 => remove All / 1 => remove Provided [] / 2 => remove All but Provided
		Object.entries(params).map(([k, v], i) => {
			if (type === 0 || (type === 1 && set.includes(k)) || (type === 2 && !set.includes(k))) {
				delete params[k]
			}
			return k;
		})
		//  Clean all / Add New / Push
		if (newSet) {
			assignParam(newSet, true)
		}
	}

	const checkConditions = (name, value) => {
		let check = 0
		if (name === 'CatID' && value === 0){
			delete params.CatID
			check++
		}

		return check > 0 ? false : true;
	}

	const assignParam = (set, pushURL = false) => {
		let check = 0
		let list = {...set}
		Object.entries(list).map(([name, value], i) => {
			if (!checkConditions(name, value)) {
				check++
			}
			return true;
		})
		
		if (check === 0) {
			setParams(Object.assign({}, params, set));
		} else {
			return false
		}

		if (pushURL) {
			push(getPathname(), set)
		}
		
	}

	const processDependencies = () => {
		const routeParams = generateRouteDynamicParams();
		setRouteParams(routeParams)

		let query = getQueryString()

		if(queryString.stringify(query) !== queryString.stringify(params)) {
			setParams(query);
		}

	}

	const getPathQuery = () => {
		return `${getPathname().substring(1)}${history.search}`
	}

	useEffect(() => {
		setMobileMenu(false)
		processDependencies()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history])

	return (
		<HistoryContext.Provider value={{
			params,
			setParams,
			routeParams,
			currentRouteParams,
			mobileMenu,
			setMobileMenu,
			getQueryString,
			getPathname,
			stringify,
			push,
			replace,
			remove, 
			assignParam, 
			changePage, 
			getPagename,
			history,
			getPathQuery
		}}>
			{props.children}
		</HistoryContext.Provider>
	)
}
export default HistoryProvider;