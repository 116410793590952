import React, {useState} from 'react'
import { Parser } from 'components'

const NotificationText = (props) => {
	const [active, setActive] = useState(false)
	const processCopy = () => {
		window.navigator.clipboard.writeText(props.data.text)
		setActive(true);
		setTimeout(() => {
			setActive(false);
		}, 2000);
	}
	return (
    <>
      <div className={`custom-modal-wrapper content-scroll ${props.styles.wrapper || ''}`}>
				<div className={`modal-content-box mb-24px px-md-64px ${props.styles.content || ''}`}>
					<h3 className={`font-size-20 font-size-md-16 font-bold-caps text-dark text-center mb-40px mt-40px ${props.styles.title || ''}`}>
						{props.data.title}
					</h3>
					<div className={`font-size-24 font-bold-caps scrolled-modal ${props.styles.text || ''} ${active ? 'text-blue' : 'text-dark'}`}>
						<Parser html={props.data.text} />
						{props.data.copy &&
							<i className="svg-i copy-black-i ml-16px cursor-pointer" onClick={processCopy}
							></i>
						}
					</div>
				</div>
			</div>
      {/* <div className={`custom-modal-actions`}>
				<div className="d-flex justify-content-center mt-5px mb-48px">
					<button type="button" className="font-size-14 font-bold-caps px-48px px-lg-100-px py-16px btn bg-gradient text-white d-block mx-auto "
						onClick={success}>
						{props.data.button ? props.data.button : props.langs.NM_ReadAgree}
					</button>
				</div>
      </div> */}
    </>
  )
}

export default NotificationText