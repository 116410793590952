import React, { useContext } from 'react';
import { RatingStars } from 'components';
import { Parser } from 'components';
import ProductSlider from './ProductSlider';
import { BannersContext } from 'contexts/banners';
import CONFIG from 'core/config';
const ListView = (props) => {
	// console.log(props.data.product.cond_type_id)
	const { resolution } = useContext(BannersContext);
	return (
		<div className="card-hover mobile-radius-0  h-170px h-md-230px d-flex w-100  px-8px pt-12px pb-12px pb-md-16px border-radius bg-white">
			{props.data.search &&
				<div className="vip-box d-flex align-items-center position-absolute top-110px top-md-22px left-13px left-md-22px" >
					{props.data.product.discount > 0 &&
						<div className="percent  bg-dark-orange text-white mr-4px  box-item text-white font-base font-size-12">
							-{parseInt(props.data.product.discount)}%
						</div>
					}
					{props.prom && <div className={props.prom.className + ' hide-on-mobile'}> {props.prom.text} </div>}
				</div>
			}

			<div className={'position-absolute d-flex flex-row top-18px hide-on-desktop left-13px'} style={{width: '100%', gap: props.prom ? '4px' : '0'}}>
			<div className={'vip-box d-flex align-items-center'}>
				{props.prom && <div className={props.prom.className} > {props.prom.text} </div>}
			</div>
				{(props.langs.CondTypes[props.data.product.cond_type_id] && props.appData.Cats.List[props.data.product.catId]?.HasCondType) &&
					<div className={`conditions-badge h-22px d-flex align-items-center bg-dark_brown text-white font-size-10 font-medium px-4px border-radius-md z-index-9
         ${(props.data.product.cond_type_id && parseInt(props.data.product.cond_type_id) === 1) ? 'bg-dark-green text-white' : 'bg-blue text-white'}`}>
						{props.langs.CondTypes[props.data.product.cond_type_id]}
					</div>
				}
			</div>
			<div className="position-relative w-120px h-126px w-md-216px h-md-180px max-width-216px">
				{(props.langs.CondTypes[props.data.product.cond_type_id] && props.appData.Cats.List[props.data.product.catId]?.HasCondType) &&
					<div className={`conditions-badge h-22px d-flex align-items-center hide-on-mobile bg-dark_brown text-white font-size-10 font-medium px-4px border-radius-md position-absolute top-8px right-4px z-index-9
         ${(props.data.product.cond_type_id && parseInt(props.data.product.cond_type_id) === 1) ? 'bg-dark-green text-white' : 'bg-blue text-white'}`}>
						{props.langs.CondTypes[props.data.product.cond_type_id]}
					</div>
				}


				<ProductSlider {...{
					product: props.data.product,
					noSwiper: props.data.noSwiper,
					activeStyle: props.activeStyle,
					isSearch: props.isSearch
				}} />

				{(props.data.product.quantity > 0 && props.data.product.shop_type_id === 1) &&
					<div className={`cashback-badges position-absolute left-8px bottom-8px d-flex position-absolute z-index-9 ${(props.data.product.isEmoney || props.data.product.isMyCoins) ? 'f-flex flex-column flex-md-row align-items-start' : ''}`}>
						{props.data.product.isVisaPay &&
							<div className=" d-flex bg-rgba-middle-gray-50 text-black mt-4px mt-md-0  box-item-badge arctic-headline font-size-10 px-12px">
								<i className="svg-i svg-i-md visa-i w-26px h-8px mr-8px"></i>
								<div className="text-black font-size-10">{props.data.product.isVisaPay.PERCENT}%</div>
							</div>
						}
						{(props.isSafari && props.data.product.isApplePay) &&
							<div className="mt-4px">
								<i className="svg-i applePayCard-i w-100px h-22px"></i>
							</div>
						}
						{props.data.product.isEmoney &&
							<div className=" d-flex bg-white text-black mr-4px  box-item arctic-headline font-size-10 px-8px">
								<i className="svg-i svg-i-md badge-emoney w-40px  mr-4px" />
								<div className="text-black font-size-10 font-bold">{props.data.product.isEmoney.PERCENT}%</div>
							</div>
						}
						{props.data.product.isMyCoins &&
							<div className="mt-4px mt-md-0">
								<div className="d-flex bg-white text-black mr-4px  box-item arctic-headline font-size-10 px-8px">
									<i className="svg-i svg-i-md Crypto  w-12px h-12px mr-4px" />
									<div className="text-black font-size-10 font-bold">{props.langs.NM_Cashback}</div>
								</div>
							</div>
						}
						{/* tbc loan */}
						{/* {
							<div className=" d-flex bg-blue-tbc text-black mr-4px  box-item arctic-headline font-size-10 px-8px">
								<i className="svg-i w-12px h-12px tbc-button-icon-i  mr-4px" />
								<div className="text-white font-size-10 font-base d-flex align-items-center">განვადება
									<span className="d-flex  ml-4px font-size-10 font-bold">-100</span>
									<span className="d-flex ml-2px">{props.currency()}</span>
								</div>
							</div>
						} */}
					</div>
				}
				{props.data.product.isInstallment &&
					<div className=" d-inline-flex mt-4px bg-blue-tbc text-black mr-4px justify-content-start box-item arctic-headline font-size-10 px-8px">
						<i className="svg-i w-12px h-12px tbc-button-icon-i  mr-6px" />
						<div className="text-white font-size-10 font-base d-flex align-items-center">
						<span className="d-flex font-size-10 font-bold">
						-{props.data.product.isInstallment.MAX_AMOUNT}
						</span>
							<span className="d-flex mr-2px">{props.currency()}</span>
							{props.langs.NM_WhenInstallment}
						</div>
					</div>
				}
			</div>
			<div className="list-view-content w-100 d-flex flex-column flex-md-row ml-12px">
				<div className="text-left w-100 mr-12px" >
					{(props.data.product.shop) ?
						<div className="text-blue-gray d-flex justify-content-between align-items-center">
							<div className="d-flex justify-content-start align-items-center ">
								<i className={`svg-i svg-i-md  mr-6px shop-icon-card`}></i>
								<span className="clamp _1 font-size-10 font-medium">
									{props.data.product.shop}
								</span>
							</div>
							{(props.data.product.shop && props.data.product.shop_type_id === 1 && parseInt(props.data.product.rates_count) > 0) &&
								<div className="rating-card-box">
									<div className="d-flex justify-content-center">
										<div className="d-flex align-items-center d-flex justify-content-center text-nLight-dark font-size-12 font-base">
											{parseFloat(props.data?.product?.rating).toFixed(1)}
											<i className="svg-i rate-active ml-4px"></i>
										</div>
									</div>
								</div>
							}
						</div>
						:
						<div className="font-base font-size-12 text-blue-gray d-flex justify-content-start align-items-center">
							<i className={`svg-i svg-i-md  mr-6px user-icon-card`}></i>
							{props.langs.PhysicalPerson}
						</div>

					}
					<div className={`mt-8px`}>
					{(parseInt(props.data.product.available_to_shipping )  === 1 || props.data.product.shop_type_id === 1) &&
							<div className="d-flex align-items-center ">
								<i className="svg-i w-18px h-18px card-online-i mr-4px"></i>
								<span className="font-size-12 font-size-md-12  font-base text-dark-green">{props.langs.NM_courierService}</span>
							</div>
						}
					</div>
					<h4 className="mt-4px mt-md-8px mb-0 mb-md-8px text-black font-base font-size-14  font-size-md-16 line-height-14 clamp _1 ">
						{props.data.product.title}
					</h4>
					<div className={`product-descr text-break  font-size-12  text-blue-gray font-base  clamp
						${resolution[0] <= CONFIG.TOSPLIT.PRODUCT_BANNERS.RESPONSIVE_RESOLUTION ? (props.data.product.title.length >= 27 ? 'm_0 ' : '_1 line-height-6') : (props.data.product.title.length >= 36 ? '_4 m_0 line-height-12' : '_5  m_2 line-height-12')}`}>
						<Parser html={props.data.product.desc.replace(/(<([^>]+)>)/gi, "")} />
					</div>

					{(props.data.product.shop && props.data.product.shop_type_id === 1 && parseInt(props.data.product.rates_count) > 0) &&
						<div className="d-flex pt-12px">
							<div className="statement-details-section pr-12px d-flex align-items-center d-flex justify-content-center ">
								<RatingStars rating={props.data.product.rating} />
							</div>
							<span className="font-size-12 text-blue_2 d-flex justify-content-center">
								{props.data.product.rates_count} {props.langs.NM_ReviewCount}
							</span>
						</div>
					}
				</div>
				<div className="w-100 mt-8px w-md-132px border-2px border-left pt-8px border-top-mobile border-rgba-blue-gray-15 flex-shrink d-flex flex-row flex-md-column justify-content-between align-items-center align-items-md-end pl-md-12px">
					<div>
						<div className="d-flex flex-md-column align-items-center align-items-md-end ">
							<div className="d-flex align-items-center text-black font-bold  font-size-14 mb-md-4px mr-4px">
								<span className="mr-3px text-right font-medium">{parseFloat(props.data.product.price) > 0 ? props.data.product.price : props.langs.PriceWithAgreement}{(props.data.product.price) > 0 &&
									props.currency()
								}</span>
							</div>
							{(parseInt(props.data.product.discount) > 0) &&
								<span className="card-text flex-shrink card-old d-inline-block position-relative price text-black opacity-25 font-medium font-size-12 ">
									<span className="mr-1px font-medium">{props.data.product.old_price}</span>
									{props.currency()}
								</span>
							}

						</div>
						{(props.data.product.sell_online === 1 && props.data.product.price >= 100) &&
							<div className="d-flex align-items-center text-blue-gray font-size-12 font-medium">{props.langs.NM_InstallmentPerMonth}<span className="text-blue ml-3px font-bold">{props.data.product.installment_price}{props.currency()}</span><span className={'font-bold'}>-{props.langs.From}</span></div>
						}
					</div>
					<div className=" d-flex align-items-center justify-content-end ">
						{
							(props.data.product.quantity > 0 && props.data.product.shop_type_id === 1) &&
							<button type="button"
								className={`btn toggle-cart bg-rgba-blue-gray-15 border-radius-xs font-family-medium p-8px my-auto ${props.checkCartPr ? 'active' : ''} `}
								onClick={props.toggleCartPr}>
								<i className="svg-i svg-i-sm cart-i"></i>
							</button>
						}
						<button type="button"
							className={`btn toggle-fav bg-rgba-blue-gray-15 border-radius-xs font-family-medium p-8px ml-4px ${props.checkFavPr ? 'active' : ''} `}
							onClick={(e) => {
								props.toggleFavPr(e);
							}}
							title={props.checkFavPr ? props.langs.RemoveFromFav : props.langs.AddToFav}
						>
							<i className="svg-i svg-i-sm favorites-i"></i>

						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ListView;