import React from 'react'
import PropTypes from 'prop-types'

NotificationIcon.propTypes = {
    className: PropTypes.string
}

export default function NotificationIcon({ className = '' } = '') {
    return (
        <span className={`svg-icon svg-md-icon ${className}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21.023" height="23" viewBox="0 0 21.023 23">
                <g id="Group_5300" dataname="Group 5300" transform="translate(-1110.989 -99)">
                    <g id="Group_5293" dataname="Group 5293" transform="translate(-184 60)">
                        <g id="Group_4589" dataname="Group 4589" transform="translate(-19 10)">
                            <path id="Stroke_210" dataname="Stroke 210" d="M4.858,0A2.429,2.429,0,0,1,2.429,2.427,2.429,2.429,0,0,1,0,0" transform="translate(1322.071 48.573)" fill="none" stroke="#0ec604" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
                            <path id="Stroke_211" dataname="Stroke 211" d="M3.167,6.312a6.333,6.333,0,0,1,12.666,0v4.734l3.067,5.37a.6.6,0,0,1-.584.942H.684A.6.6,0,0,1,.1,16.416l3.067-5.37Z" transform="translate(1315 30)" fill="none" stroke="#0ec604" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
                        </g>
                    </g>
                </g>
            </svg>
        </span>
    )
}