import React from 'react';

const Divider = ({ children, className }) => {
  if(children) {
    return (<div className={`d-flex justify-content-between align-items-center my-3 ${className}`}>
      <div className="min-height-1px w-100 bg-secondary-gray"></div>
      <div className="px-12px font-size-14">{children}</div>
      <div className="min-height-1px w-100 bg-secondary-gray"></div>
    </div>)
  }
  return <div className={`min-height-1px w-100 bg-secondary-gray my-3 ${className}`}></div>
}

export default Divider;