import Loader from "components/Loader";
import CONFIG from "core/config";
import React, { useContext, useEffect } from 'react';
import { HistoryContext } from 'contexts/history';
import { UserContext } from 'contexts/user';
import { Outlet } from "react-router-dom";

const AuthRequired = (props) => {
  const { getLang, langs, CONFIG, Functions } = props;
  const { getPathname } = useContext(HistoryContext);
  const { user, loading } = useContext(UserContext);

  // const navigateToAuth = () => {
  //   Functions.redirectWindow(true, getLang, true, `${CONFIG.HOST.appUrl}${getPathname().substring(1)}`);
  // }

  const getLoginUrl = (path) => {
    return `${CONFIG.HOST.authUrl}${getLang}/user/login?Continue=${encodeURIComponent(path)}`;
  };

  useEffect(() => {
    if(!user && !loading) {
      window.location.replace(getLoginUrl(`${CONFIG.HOST.appUrl}${getPathname().substring(1)}`));
    }
  }, [user, loading]);

  if(loading || !user) return <Loader />;


  // useEffect(() => {
  //   if (user) {
  //     window.location.reload();
  //   }
  // }, [user]);

  return <Outlet />;

  // return (
  //   <div className="wrapper">
  //     <div className="not-found-404 px-15px d-flex align-items-start justify-content-center flex-1 mt-100px mt-lg-200px">
  //       <div className="container">
  //         <div className="row">
  //           <div className="col-12 col-md-6">
  //             <div className="text-center text-md-left">
  //               <h1 className="font-size-28 font-size-md-30 font-size-lg-48 font-base-caps text-dark mb-20px">
  //                 {langs.LogInConfirm}
  //               </h1>
  //               <div className="font-size-16 font-base mb-50px">{langs.LogInConfirm}</div>
  //               <button
  //                 className="btn font-size-14 font-base-caps text-white bg-gradient border-radius-md mt-32px px-48px py-20px"
  //                 onClick={navigateToAuth}
  //               >
  //                 {langs.LogInConfirm}
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default AuthRequired;