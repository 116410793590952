import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom'
import { UserContext } from 'contexts/user';
import { Avatar, UserSidebar } from 'components';
const BottomBar = ({ lib, toggleMenu }) => {
	const { langs, getLang } = lib;
	const { user } = useContext(UserContext);
	const [activeIndex, setActiveIndex] = useState(0);

	const handleButtonClick = (e, item, index) => {
		setActiveIndex(index);
		if (item.onClick) {
			e.preventDefault();
			e.stopPropagation();
			item.onClick(e, true);
			return;
		} else {
			let body = document.body.classList;
			body.remove('filter-open');
			if (lib.mobileSidebar) {
				lib.toggleMenu()
			}
		}
	};


	const links = [
		{
			text: langs.home,
			iconClass: 'home-icon-i',
			url: '',
			onClick: () => {
				window.location.href = '/'
			}
		},
		{
			text: langs.Categories,
			iconClass: 'navbar-categories',
			url: '#',
			onClick: toggleMenu
		},
		{
			text: langs.Add,
			iconClass: 'navbar-add-icon',
			url: '/pr-form/',
		},
		{
			text: langs.Favorites,
			iconClass: 'cart-icon-i',
			url: `/favorites`,
		},
		{
			text: langs.l_profile,
			iconClass: '',
			url: '#',
			onClick: lib.toggleMenu
		},

	];

	return (
		<div className='d-flex position-fixed z-index-999 bottom-0 left-0 right-0 bg-white px-2px shadow align-items-center justify-content-between overflow-hidden d-lg-none' style={{height: '75px'}}>
			{links.map((item, index) => (
				<Link key={index}
					to={`/${getLang}${item.url}`}
					className={`cursor-pointer d-flex flex-column align-items-center no-underline bottom-bar w-76px ${index === activeIndex ? 'active' : ''
						}`}
					onClick={(e) => handleButtonClick(e, item, index)}
				>
					<div className="h-24px w-24px max-height-24px d-flex align-items-center justify-content-center mb-6px ">
						{item.iconClass ?
							<i className={`svg-i ${item.iconClass} d-flex`} style={{width: '23px', height: '23px'}}></i>
							: null}
						{item.text === langs.l_profile ?
							<div className='position-relative'>
								<Avatar
									logo={false}
									link=''
									photo={user.Photo ? user.Photo : false}
									gender={user.GenderID ? user.GenderID : 1}
									className=''
									isLoggedIn={!!user}
								/>

								{lib.notifications?.unseen_count > 0 &&
									<span
										className="rounded-circle badge badge-pill bg-badge-orange font-base text-white position-absolute badge-counter badge-burger">
										{lib.notifications.unseen_count}
									</span>
								}
							</div>

							: null}
					</div>
					<div className={`font-size-10 ${index === activeIndex ? 'text-primary-text-color font-medium' : 'text-textgray'}`}>{item.text}</div>
				</Link>
			))}
			{lib.mobileSidebar &&
				<UserSidebar {...lib.userSidebarProps} />
			}
		</div>
		// <div key={index} onClick={(e) => link.onClick(e, true)}>
		// 	<div
		// 		// to={`/${lib.getLang}${link.linkClass}`}
		// 		className={`cursor-pointer d-flex flex-column align-items-center no-underline bottom-bar w-76px 
		// 						${index === activeIndex ? 'active' : ''
		// 			}`}
		// 		onClick={() => handleButtonClick(index)}
		// 	>
		// 		<div className="w-basis-24 h-24px max-height-24px d-flex align-items-center justify-content-center mb-6px ">
		// 			{link.iconClass && (
		// 				<i
		// 					className={`svg-i w-24px h-24px ${index === activeIndex ? link.activeIconClass : link.iconClass} d-flex`}
		// 				></i>
		// 			)}
		// 			{link.text === langs.l_profile && (
		// 				<Avatar
		// 					logo={false}
		// 					link=''
		// 					photo={user.Photo ? user.Photo : false}
		// 					gender={user.GenderID ? user.GenderID : 1}
		// 					className=''
		// 				/>
		// 			)}
		// 		</div>
		// 		<div className="text-dark font-size-10">{link.text}</div>
		// 	</div>
		// </div>
		// <div className=""
		// 	onClick={closeMenu}	>
		// 	<Link
		// 		key={index}
		// 		to={`/${getLang}${link.linkClass}`}
		// 		className={`cursor-pointer d-flex flex-column align-items-center no-underline bottom-bar w-76px ${index === activeIndex ? 'active' : ''
		// 			}`}
		// 		onClick={() => handleButtonClick(index)}
		// 	>
		// 		<div className="w-basis-24 h-24px max-height-24px d-flex align-items-center justify-content-center mb-6px ">
		// 			{link.iconClass && (
		// 				<i
		// 					className={`svg-i w-24px h-24px ${index === activeIndex ? link.activeIconClass : link.iconClass} d-flex`}
		// 				></i>
		// 			)}
		// 			{link.text === langs.l_profile && (
		// 				<Avatar
		// 					logo={false}
		// 					link=''
		// 					photo={user.Photo ? user.Photo : false}
		// 					gender={user.GenderID ? user.GenderID : 1}
		// 					className=''
		// 				/>
		// 			)}
		// 		</div>
		// 		<div className="text-dark font-size-10">{link.text}</div>
		// 	</Link>
		// 	{lib.mobileSidebar &&
		// 		<UserSidebar {...lib.userSidebarProps} />
		// 	}
		// </div>
		// </div >
	)
}

export default BottomBar