import React, {useContext} from 'react'
import { AppDataContext } from 'contexts/appData'
const HowBuy = () => {
	const {langs} = useContext(AppDataContext)
	return (
		<section className="how-buy mt-40px mt-md-80px pb-64px pb-md-84px bg-white">
			<div className="icon d-flex justify-content-center mt-48px mt-md-68px ">
				<i className="svg-i how-buy-i"/>
			</div>
			<h2 className="font-size-18 font-bold line-height-11 text-dark text-center mt-16px mb-16px mb-md-40px">
				{langs.NM_HowToBuyTitle}
			</h2>
			<div className="buy-steps d-flex justify-content-center place-content-center flex-column flex-md-row flex-wrap">
				<div className="d-flex flex-column flex-sm-row mx-n12px">
					<div className="step-box d-flex flex-column align-items-center px-12px" >
						<div className="step-icon how-buy-animate deley1">
							<i className="svg-i market "/>
						</div>
						<div className="step-text font-size-13 font-medium line-height-11 text-dark mt-16px mt-md-24px">
							{langs.NM_HowToBuyBlock[0]}
						</div>
					</div>
					{/*  */}
					<div className="step-line pt-36px animate-line deley-1 d-none d-m-block">
						<i className="svg-i step-line"/>
					</div>
					{/*  */}
					<div className="step-box d-flex flex-column align-items-center px-12px" >
						<div className="step-icon how-buy-animate deley2">
							<i className="svg-i cart-svg"/>
						</div>
						<div className="step-text font-size-13 font-medium line-height-11 text-dark mt-16px mt-md-24px">
							{langs.NM_HowToBuyBlock[1]}
						</div>
					</div>
					{/*  */}
					<div className="step-line pt-36px animate-line deley-2 d-none d-m-block">
						<i className="svg-i step-line-2"/>
					</div>
					{/*  */}
				</div>
				<div className="d-flex flex-column flex-sm-row mx-n12px">
					<div className="step-box d-flex flex-column align-items-center px-12px" >
						<div className="step-icon how-buy-animate deley3">
							<i className="svg-i box"/>
						</div>
						<div className="step-text font-size-13 font-medium line-height-11 mt-16px mt-md-24px">
							{langs.NM_HowToBuyBlock[2]}
						</div>
					</div>
					{/*  */}
					<div className="step-line pt-36px animate-line deley-3 d-none d-m-block">
						<i className="svg-i step-line "/>
					</div>
					{/*  */}
					<div className="step-box d-flex flex-column align-items-center px-12px" >
						<div className="step-icon how-buy-animate deley4">
							<i className="svg-i house"/>
						</div>
						<div className="step-text font-size-13 font-medium line-height-11 text-dark mt-16px mt-md-24px">
							{langs.NM_HowToBuyBlock[3]}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default HowBuy