var hostData = {
	appUrl: process.env.REACT_APP_APP_URL,
	apiUrl: process.env.REACT_APP_API_URL,
	appDataUrl: process.env.REACT_APP_APP_DATA_URL,
	staticUrl: process.env.REACT_APP_STATIC_URL,
	authUrl: process.env.REACT_APP_AUTH_URL,
	accountUrl: process.env.REACT_APP_ACCOUNT_URL,
	branch: process.env.REACT_APP_BRANCH
}

const CONFIG = {
	HOST: hostData,
	COMMON: {
		SITE_ID      					 : 2,
		STATIC_SITE_ID 				 	 : 32,
		KEYWORD_PLACEHOLDER 	 : 'mymarket',
		SITE         					 : 'mymarket.ge',
		PHONE        					 : '(032) 280 00 35',
		EMAIL        					 : 'info@mymarket.ge',
		ID_CODE      					 : '402116474',
		ONLINE_SHOP_PHONE 			     : '(032) 2800035',
		SITE_YEAR    					 : '2020',
		PR_MAX_PHOTOS 				 	 : 12,
		PHONE_PREFIX 					 : 995,
		PRODUCTS_PER_PAGE 			 	 : 20,
		PAGINATION_LIMIT				 : 10,
		SHOPS_PER_PAGE 			     	 : 15,
		TEXT_TYPE_ID 					 : 1,
		SELECT_TYPE_ID    			 	 : 2,
		CHECKBOX_TYPE_ID  			 	 : 3,
		MIN_DOTS_NUM 					 : 12,
		DS 						 	 	 : '/',
		SUGGESTONS_DEBOUNCE_TIME 		 : 300,
		FILTER_DEBOUNCE_TIME			 : 300,
		DEFAULT_SORT_ID 				 : 1,
		DEFAULT_TYPE_ID 				 : 0,
		SCROLL_TOP_BUTTON_THRESHOLD      : 500,
		SCROLL_TOP_DURATION 			 : 500,
		LARI 							 : '<span class="lari"></span>',
		USD 							 : '<span class="usd">$</span>',
		USER_PRS_LIMIT				     : 5,
		PR_PAID_ADD_PRICE	 			 : 0.10,
		SERVICES_CAT_IDS				 : [160, 233],
		RENT_CAT_ID						 : 1038,
		PRIVATE_CAT_ID					 : 738,
		// ALCO_CAT_ID						 : 532, 
		// ALCO_CAT_ID						 : 530,
		ALCO_CAT_IDS					 : [532, 530],
		AGRO_CAT_ID						 : 302,
		CONSTRUCTION_CAT_ID				 : 250,
		GCAPTCHA_KEY: '6LfxQK8pAAAAAOPXCW9RWexMVbOE8EblsjJ4gPZ-',
		TEST_PARAM: 'test',
	},
	BANNER_TYPES: {
		SLIDER: 1,
		ADO: 7,
		CONNECT: 8,
		VAST: 6,
		VIDEO: 4
	},
	CHECKOUT: {
		EXPRESS_DELIVERY_PRICE: 10,
		EXPRESS_LOCS		  : [320],
		EXPRESS_STATUS		  : (new Date()).getHours() >= 10 && (new Date()).getHours() < 15 && ![0,6].includes(new Date().getDay()),
	},
	IMAGES: {
		LARGE_IMAGE_WIDTH  		 : 860,
		LARGE_IMAGE_HEIGHT 		 : 646,
		THUMB_IMAGE_WIDTH  		 : 173,
		THUMB_IMAGE_HEIGHT       : 130,
		CAROUSEL_IMG_SIZE_PERCENT: 80,
		DEFAULT_IMG_TYPE 	 		 : '.jpg',
		IMG_TYPES 		 		 : ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
		NOT_FOUND_SRC 			 : ' ',

		// PR_IMAGES_URL 			 : 'https://static.my.ge/mymarket/photos',
		// SHOP_IMAGES_URL  		 : 'https://static.my.ge/mymarket/shops',

		PR_IMAGES_URL 			 : hostData.staticUrl + '/photos',
		SHOP_IMAGES_URL  		 : hostData.staticUrl + '/shops',

		DEFAULT_NO_IMAGE		 : 'https://www.mymarket.ge/beta/templates/assets/img/no-image.png',
		DEFAULT_SHOP_COVER 		 : 'https://www.mymarket.ge/templates/assets/img/shop_cover.png',
		THUMBS_DIR_NAME    		 : 'thumbs',
		ORIGS_DIR_NAME 		     : 'large',
		PR_IMG_NUM_SEPARATOR	 : '_',
		PR_MAIN_IMAGE_NUM		 : 1,
		PREVIEW_GIF				 : '',
		PRELOAD_IMAGES_CNT 		 : 1,
	},
	ADDRESS_STATUSES: {
		DELETED : 0,
		NEW  	: 1,
		USED  	: 2,
	},
	PR_STATUSES: {
		INACTIVE 	: 0,
		ACTIVE   	: 1,
		BLOCKED  	: 2,
		OUTDATED 	: 3,
		DELETED  	: 4,
		LIMITED  	: 5,
		EXPIRED  	: 6,
		HIDDEN   	: 7,
		DISABLED 	: 8,
		DRAFTS 	  	: 9,
		TEMPLATES 	: 10,
	},
	OFFER_STATUSES : {
		FINISHED : 0,
		ACTIVE : 1
	},
	STATUS_CODES: {
		ERROR 	: 0,
		SUCCESS	: 1,
	},
	PROM_ORDERS: {
		SUPER_VIP 	: 20,
		VIP_PLUS 	: 15,
		VIP 		: 10,
		PAYD_RENEW	: 3,
		AUTO_RENEW	: 2,
		COLOR 		: 1,
	},
	PROM_VIPS_LIST: ['20', '15', '10'],
	PROM_COLORS_LIST: ['1'],
	PROM_UPDATES_LIST: ['3', '2'],
	PROM_TYPES: {
		PRODUCTS		: 1,
		VIP				: 2,
		VIP_PLUS		: 3,
		SUPER_VIP		: 4,
		COLOR			: 5,
		PAYD_RENEW		: 6,
		AUTO_RENEW		: 7,
		FACEBOOK_SHARE	: 8,
		BANNERS			: 9,
	},
	PROM_MAX_QUANTITY: 30,
	PROM_PRICES: {
		VIP 		: 1,
		VIP_PLUS 	: 2,
		SUPER_VIP 	: 7,
		COLOR 		: 1,
		PAYD_RENEW 	: 0.1,
		AUTO_RENEW 	: 0.15,
		FB 			: 2,
		RECOVER 	: 0.15,
		ENABLE 		: 0.15
	},
	LANGS: {
		DEFINITIONS: {
			ka : 4,
			en : 1,
			ru : 5
		},
		DEFAULT_LANG: 'ka',
		LIST: [4, 1, 5],
		VIEW: ['ka', 'en', 'ru']
	},
	VIEW_TYPES: {
		TEXT_TYPE_ID      : 1,
		SELECT_TYPE_ID    : 2,
		CHECKBOX_TYPE_ID  : 3,
		FLEX_TYPE_ID      : 4,
		MULTI_TYPE_ID     : 5,
		FROM_TO_TYPE_ID   : 6
	},
	DATA_TYPES:  {
		STRING  : 1,
		INTEGER : 2,
		DOUBLE  : 3
	},
	ATTR_TYPES: {
        ATTRIBUTE : 1,
        VALUE     : 2,
        RANGE     : 3
    },
    ORDERS_STATUSES: {
		PROCESSING 	: 0,
		RECEIVED  	: 1,
		SHIPPING  	: 2,
		DELIVERED 	: 3,
		CANCELED 	: 4,
		REFUNDED  	: 5,
		INVOICE_REQ 		: 7,
		INVOICE_SENT  		: 8,
		INVOICE_CANCELED 	: 9,
		ORDER_CANCELED 		: 12,
		ORDER_DELIVERED_BY_SELLER: 20,
    },
	API: {
		REST: {
			// API_URL : 'http://test.api.mymarket.ge',
			API_URL : hostData.apiUrl,
			APP_DATA_FOLDER : 'rest',
			APP_DATA_FILE   : 'AppData.js',
		},
		CONTROLLERS: {
			MAIN 		   : 'main',
			PRODUCTS     : 'products',
			CATEGORIES   : 'categories',
			ATTRIBUTES   : 'categories',
			SHOPS 	   : 'shops',
			API 		   : 'API',
			USER 	 	   : 'User'
		},
		METHODS: {
			UPLOAD 			: 'user/images/upload',
			GET_USER_PR 			: 'user/products',
			ADD_SHOP 	: 'user/addshop',
			EDIT_SHOP 	: 'user/updateshop',
			ADD_PRODUCT 	: 'user/addproduct',
			EDIT_PRODUCT 	: 'user/editproduct',
			GET_MAIN_PAGE_DATA 	: 'main',
			GET_PRODUCTS          : 'products',
			CHECKOUT          : 'user/checkout',
			GET_CHECKOUT_DETAILS:	'user/checkoutdetails',
			GET_PRODUCT_DETAILS   : 'GetProductData',
			GET_PRODUCT	 		: 'products',
			GET_PRODUCT_ATTRS     : 'attributes/productattributes',
			GET_PRODUCT_SHOP      : 'GetProductShop',
			GET_CATEGORIES 	    : 'categories',
			GET_FULL_CATS 		: 'GetFullCats',
			GET_CATEGORY_DETAILS  : 'GetCat',
			GET_ATTRS 		    : 'GetAttrs',
			GET_SUB_ATTRS  	    : 'GetSubAttrs',
			GET_ATTR_VALUES       : 'GetAttrValues',
			GET_FULL_ATTRS 	 	: 'GetFullAttrs',
			GET_SUGGESTIONS 		: 'keywords',
			GET_BRANDS 				: 'GetBrands',
			GET_SHOPS 		    	: 'shops',
			GET_USER 		    	: 'user',
			GET_USER_SHOP 		    : 'user/shop',
			GET_USER_INFO 		    : 'user/info',
			GET_USER_DETAILS		: 'user/details',
			GET_NOTIFICATIONS    	: 'user/getnotifications',
			GET_NOTIFICATIONSCOUNT	: 'user/getnotificationscount',
			GET_USER_CONVERSATIONS	: 'user/getconversations',
			GET_USER_MESSAGES 		: 'user/getmessages',
			SEND_USER_MESSAGE 		: 'user/sendmessage',
			DELETE_USER_MESSAGE 	: 'user/deletemessage',
			BLOCK_USER 				: 'user/blockuser',
			UNBLOCK_USER 			: 'user/unblockuser',
			SHOW_USER_NUMBER	: 'user/%/showfullnumber',

			GET_SHOP_INFO 			: 'GetShopInfo',
			GET_SHOP_PRODUCTS 		: 'user/products',
			GET_APP_DATA 			: 'MApp',
			GET_REPORT_DATA			: 'GetReportData',
			SEND_REPORT				: 'SendReport',
			GET_HELP					: 'help',
			GET_HELP_SEND				: 'help/send',
			GET_TERMS			    : 'rules',

			GET_NEW_PR_DATA 		: 'NewPrInfo',
			GET_PHONE_INDEXES		: 'GetPhoneIndexes',
			GET_EDIT_PR_DATA 		: 'EditPrInfo',
			GET_USER_PRS 			: 'GetUserProducts',
			GET_USER_TRANSACTIONS 	: 'user/gettransactions',
			GET_USER_DEPOSIT 		: 'GetUserDeposit',
			GET_PAYMENT_ORDER 		: 'GetPaymentOrder',
			GET_USER_ACCOUNT  		: 'GetUserAccount',
			GET_GROUP_PRS_DATA 		: 'GroupPrsData',
			GET_USER_FAVORITES 		: 'GetFavorites',
            GET_USER_NOTIFICATIONS	: 'GetNotifications',
			ACTIVATE_PRODUCT_INFO  	: 'ActivatePrInfo',
			ACTIVATE_PRODUCT 		: 'ActivateProduct',
			RESEND_ACTIVATION_CODE 	: 'ReSendActCode',

			GET_CAPTCHA 			: 'contactcapcha',

			AUTHORIZE				: 'Authorize',
			REGISTER 				: 'Register',
			RECOVER 				: 'Recover',
			CHANGE_PASSWORD 		: 'user/updatepassword',
			CONTACT 				: 'contact',
			UPDATE_ACCOUNT			: 'user',
			ADD_COLOR 				: 'AddColor',
			ADD_FB 					: 'AddFb',
			RENEW_PR				: 'UpdatePr',
			ADD_VIP					: 'AddVip',
			ADD_FAV 				: 'AddFav',
			REMOVE_FAV 				: 'RemoveFav',
			RECOVER_PRODUCT 		: 'RecoverProduct',
			ENABLE_PRODUCTS 		: 'user/enableproducts',
			DISABLE_PRODUCTS 		: 'user/disableproducts',
			RECOVER_PRODUCTS 		: 'user/recoverproducts',
			UPDATE_PRODUCT_QUANTITY	: 'user/products/%/updateproductquantity',
			DELETE_PRODUCTS 		: 'user/removeproducts',
			DISCOUNT_PRODUCTS		: 'user/setdiscounttoproducts',
            FIND_USER				: 'user/finduserformoneytransfer',
            TRANSFER_MONEY			: 'user/moneytransfer',
			REQUEST_INVOICE			: 'RequestInvoice',
			LOG_ERROR 				: 'LogError',
			ADD_SHOP_DATA			: 'AddShopData',
			GET_LEGAL_FORMS			: 'GetLegalForms',
			GET_SHOP_DATA			: 'GetShopData',
			EDIT_SHOP_DATA			: 'EditShopData',

			GET_LAST_SYNC			: 'user/lastsync',
			SYNC_USER				: 'user/sync',

			SET_TO_CART				: 'user/addproducttocart',
			TOGGLE_TO_CART			: 'user/toggleproducttocart',
			REMOVE_FROM_CART		: 'user/removeproductfromcart',
			CART_QUANTITY			: 'user/setcartproductquantity',
			CART_LIST				: 'user/getcartproducts',

			GET_ADDRESS				: 'user/getaddresses',
			ADD_ADDRESS				: 'user/addaddress',
			UPDATE_ADDRESS			: 'user/updateaddress/',
			DELETE_ADDRESS			: 'user/removeaddress/',
			DEFAULT_ADDRESS			: 'user/setdefaultaddress/',
			DELIVERY_DETAILS		: 'user/cart/deliverydetails',

			PAYMENT					: 'user/paymentorder',

			GET_USER_ORDERS			: 'user/orders',
			GET_SHOP_ORDERS			: 'user/shop/orders',
			GET_C2C_ORDERS			: 'user/c2corders',

			GET_USER_PRODUCT		: 'user/products',
			GET_STATUS_COUNTS		: 'user/products/statuscounts',
			GET_PRODUCT_CATEGORIES	: 'user/products/categories',

			SEND_PRODUCT_MESSAGE	: 'user/sendproductmessage',

			SENDCALLINVESTORS		: 'shops/requestCall',

			GET_REASONS 			: 'products/reportreasons',

			GET_SECTIONS 			: 'sections',

			GET_BANNERS				: 'https://static.my.ge/mymarket/js/banners.json',

			USER_CHECK_PHONE		: 'user/checkphone',
			USER_PROFILE_PHONE	: 'user/checkprofilephone',
			USER_SEND_SMS			: 'user/sendsmscode',
			USER_CHECK_SMS			: 'user/checksmscode',

			GET_ESTIMATE_VIEWS 		: 'products/estimateviews',

			PRODUCT_STATISTICS 		: 'products/%/statistics',
			PRODUCT_PHONE_CODE 		: 'products/%/resendactcode',
			PRODUCT_ACTIVATION 		: 'products/%/activateproduct',
			PRODUCT_SIMILAR 			: 'products/%/similar',
			PRODUCT_DELIVERY 			: 'products/%/deliverydetails',

			GET_PRODUCT_GROUPS 		: 'user/getproductgroups',
			GET_GROUPS_DETAILS		: 'user/getproductgroupdetails',
			SAVE_PRODUCT_GROUPS		: 'user/saveproductgroup',
			REMOVE_GROUP			: 'user/removeproductgroup',
			REMOVE_PRODUCT_GROUP	: 'user/removeproductfromgroup',

			ADD_REVIEW				: 'user/addreview',
			GET_REVIEWS				: 'reviews',
			GET_REVIEW				: 'review',

			GET_PROMS 				: 'prom/getprompackages',
			GET_PROM_PRICES 	: 'prom/getprices',

			PROM_ADD_VIP 			: 'user/addvip',
			PROM_ADD_COLOR			: 'user/addcolor',
			PROM_ADD_AUTO_UPDATE 	: 'user/promupdate',

			SEND_CUSTOM_PACKAGE_REQUEST	: 'prom/requestcustomprompackage',

			GET_TEMPLATES 			: 'user/drafts',
			SAVE_TEMPLATES			: 'user/drafts/save',
			DELETE_TEMPLATES 		: 'user/drafts/%/remove',

			ADD_LEAD_BANK 			: 'products/%/addlead',

			GROUP_PRODUCTS 			: 'products/%/groupproducts',
			GROUP_DISCOUNT_PRODUCTS : 'products/%/groupdiscountproducts',
			GET_PRODUCT_MODELS 		: 'products/models',
     	GET_SAVED_SEARCHES		: 'user/savedsearch',
			SAVE_SEARCH				: 'user/savedsearch/save',
			REMOVE_SAVED_SEARCH		: 'user/savedsearch/%/remove',

			TRADEIN_MODELS 					: 'tradein/models',
			TRADEIN_QUESTIONS 				: 'tradein/questions',
			TRADEIN_CREATE_ORDER 			: 'tradein/createorder',
			GET_USER_TRADEIN_ORDERS			: 'tradein/userorders',
			GET_SHOP_TRADEIN_ORDERS			: 'tradein/shoporders',
			GET_TRADEIN_ORDER				: 'tradein/order',
			CHANGE_TRADEIN_STATUS			: 'tradein/order/%/changestatus',
			TRADEIN_OFFER_PRICE				: 'tradein/order/%/offerprice',
			TRADEIN_OFFER_PRICE_RESPONSE	: 'tradein/order/%/offerpriceresponse',

			STORE_APP_REQUEST 		: 'storeapprequest',
			GET_INSTALLMENTS 		: 'user/getleads',


			GENERATE_INVOICE 		: 'user/reqinvoice',
			CHANGE_ORDER_STATUS 	: 'user/shop/orders/%/changestatus',
			GET_MONTH_CHARGES 		: 'user/getmonthcharges',

			GET_SPECIAL_POPUP 		: 'showpopup',

			DONATION 				: 'donate',

			GET_MODEL_ATTRS : 'models/attributes',

			RETURN_PRODUCT : 'user/orders/%/returnproduct',
			GET_GIFTS_COUNT: 'giftscount',

			GET_PRODUCT_TITLE: 'user/generateproducttitle',

			DEACTIVATE_USER: 'user/deactivate',

			APP_VERSION: 'version',

			CALL_REQUEST: 'https://accounts.tnet.ge/api/%/livecaller/callRequest',
			FEEDBACK_CHECK: 'feedback/shouldfeedbackproduct',
			FEEDBACK_ANSWERS: 'feedback/answers',
			FEEDBACK_ADD: 'feedback/add',

			GET_OFFER_PRICE_USER: 'offers/user',
			GET_OFFER_PRICE_SELLER: 'offers/seller',
			UPDATE_OFFER_STATUSES: 'offers/seller/updatestatus',
			UPDATE_OFFER_USER_STATUSES: 'offers/user/updatestatus',
			CONFIRM_ORDER_PICKUP: 'user/orders/%/confirmorderaspickedup',

			UPDATE_BANK_ACCOUNT: 'user/savebankaccount',
			SERVICE_CENTERS: 'servicecenters',

			GET_BANK_ACCOUNTS: 'user/bankaccounts',
			ADD_BANK_ACCOUNT: 'user/bankaccounts/add',
			SET_BANK_ACCOUNT_DEFAULT: 'user/bankaccounts/makedefault',
			DELETE_BANK_ACCOUNT: 'user/bankaccounts/delete',
			CATEGORIES_COUNTS: 'products/categories',

			PRODUCT_QUANTITY_CHECK: 'products/checkquantity',
			IMAGE_UPLOAD_LINK: 'event/qr_send',
			GET_QR_EVENT: 'event/get',
			EMIT_QR_EVENT: 'event/emit',
			PAYMENT_METHODS: 'user/paymentmethods',
			PROCESS_PAYMENT: 'user/pay',
			CHECKOUT_INFO: 'user/checkoutinfo',
		}
	},
	CACHE: {
		SYNC: {
			NAME						: 'LastSync',
			FAV_LIMIT      	: 50,
			CART_LIMIT 			: 50,
			LOG: {
				NEW_CART: 'Add one product to cart',
				NEW_FAV: 'Add one product to favs',
				REMOVE_CART: 'Remove one product from cart',
				REMOVE_FAV: 'Remove one product from favs',
				USER_SYNC: 'Sync all new data on User',
				EDIT_QUANTITY: 'Edit quantity of product in cart',
				CLEAR_CART: 'Remove one or all products from cart',
				RESET: 'Reset / Clear data'
			}
		},
		TAGS: {
			LANG				: 'Lang',
			CART 				: 'Cart',
    	FAV 				: 'Favorites',
			SYNC				: 'SyncData',
   		USER 				: 'User',
   		AUTH 				: 'Auth',
   		COOKIEAUTH 	: 'AccessToken',
			DRAFT 			: 'Draft',
			LIVE_CHAT  	: 'LiveChat',
    	LIVE_CALLER_HIDE_KEY    : 'LiveChatHide',
			VIEW_QUANTITY: 'ViewQuantity',
			FILTERS: 'Filters',
			CATMENU: 'CatMenu',
			KEYCATSMENU: 'KeyCatsMenu',
			COOKIE_POLICY: 'CookiePolicy',
		},
		VIEWS: {
			PRODUCTS 			: 'LastProducts',
			CATEGORIES 			: 'LastCategories'
		},
		SEARCH: {
			LAST 				: 'LastSearch',
			SAVED 				: 'SavedSearch'
		},
		SETTINGS : {
			VIEWTYPE 			: 'ViewType'
		},
		PAYMENT_TYPES: {
			BALANCE: 1,
			CART: 1,
			SECCONDARY: 1,
		},
		// TYPES: 1 - card all; 2 - installment; 3 - secondary cards; 4 - secondary installment test1
		PAYMENT_METHOD_TYPES: {
			ALL: 0,
			CARDS: 1,
			INSTALLMENTS: 2,
			SECONDARY_CARDS: 3,
			SECONDARY_INSTALLMENTS: 4,
		},
		PAYMENT_METHODS: [
			{
				ID: 2,
				METHOD: 'card',
				TITLE: 'ბარათით გადახდა',
				ICON: 'pay-card',
				TYPES: [1, 3],
				MIN: 0,
				MAX: 25000,
				TYPE_ID:  1,
			},
			{
				ID: 3,
				METHOD: 'points',
				TITLE: 'ერთგული ქულებით გადახდა',
				ICON: 'pay-ertguli',
				TYPES: [1, 3],
				MIN: 0,
				MAX: 25000,
				TYPE_ID:  1,
			},
			{
				ID: 1,
				METHOD: 'req_invoice',
				TITLE: 'ინვოისის მოთხოვნა',
				ICON: 'pay-invoice',
				TYPES: [],
				MIN: 0,
				MAX: 25000,
				TYPE_ID:  2,
			},
			{
				ID: 4,
				METHOD: 'emoney',
				TITLE: 'სწრაფი გადახდის აპარატები',
				ICON: 'pay-fast-pay',
				TYPES: [3],
				MIN: 0,
				MAX: 25000,
				TYPE_ID:  6,
			},
			{
				ID: 5,
				METHOD: 'emoneybalance',
				TITLE: 'იმანის ბალანსით გადახდა',
				ICON: 'pay-emoney',
				TYPES: [],
				MIN: 0,
				MAX: 25000,
				TYPE_ID:  6,
			},
			{
				ID: 6,
				METHOD: 'space',
				TITLE: 'სფეის განვადება',
				ICON: 'space-installment',
				TYPES: [2],
				MIN: 100,
				MAX: 25000,
				TYPE_ID:  4,
			},
			{
				ID: 7,
				METHOD: 'tbcinstallment',
				TITLE: 'TBC განვადება',
				ICON: 'tbc-installment',
				TYPES: [2, 3, 4],
				MIN: 100,
				MAX: 10000,
				TYPE_ID:  4,
			},
			// {
			// 	ID: 8,
			// 	METHOD: 'balance',
			// 	TITLE: 'ბალანსით გადახდა',
			// 	ICON: 'balance-icon-i',
			// 	TYPES: [1],
			// 	MIN: 0,
			// 	MAX: 100000,
			// 	TYPE_ID:  1,
			// },
			{
				ID: 9,
				METHOD: 'mycoins',
				TITLE: 'MyCoins Crypto გადახდა',
				ICON: 'Crypto',
				TYPES: [],
				MIN: 0,
				MAX: 25000,
				TYPE_ID:  14,
			},
			{
				ID: 10,
				METHOD: 'amex',
				TITLE: 'Bog გადახდა',
				ICON: 'american-icon',
				TYPES: [1, 3],
				MIN: 0,
				MAX: 25000,
				TYPE_ID:  1,
			},
			{
				ID: 11,
				METHOD: 'credoinstallment',
				TITLE: 'Credo განვადება',
				ICON: 'credo-installment',
				TYPES: [2, 3, 4],
				MIN: 100,
				MAX: 10000,
				TYPE_ID:  4,
			},

		],
	},
	TOSPLIT: {
		RESPONSIVE_RESOLUTION: 767,
		PRODUCT_BANNERS: {
			RESPONSIVE_RESOLUTION: 1200,
			MIN_INCREMENT: [4, 2],
			MAX_INCREMENT: [16, 12],
			GROUPED_PERCENTAGE: 50,
			GROUPED_PREFIX: 'GROUP_',
		},
		MY_ADS_BANNERS_URL : 'https://banners.myads.ge/',
		INSTALLMENT: {
			SHOPS: [10949, 6688],
			MIN_MONTH: 3,
			MONTHS_COUNT: 16,
			RATES: [
				{MIN:3, MAX: 6, RATE: 30},
				{MIN:7, MAX: 12, RATE: 50},
				{MIN:13, MAX: 18, RATE: 75},
			]
		},
		TRADEIN: {
			SHOPID: 11232,
			BRANDID: 42,
			DELIVERY_RATE: 10,
		},
		// SEGMENT_BANNERS_CATS: [878, 1166, 160, 1038, 1066, 2, 1064, 12, 1067, 1070, 7, 1065] test1
		SEGMENT_BANNERS_CATS: [472, 497, 857, 477, 962, 1040, 1069]
	},
	CURRENCIES: {
		LARI	: 3,
		USD		: 1,
	},
	DISCOUNT: {
		VISA: {
			DISCOUNT: 50,
			FREE_DELIVERY: 1,
			TOTAL_LIMIT: 400,
			SPECIAL_LIMIT: 150,
			SPECIAL_LIST: [],
			STANDART_LIST: []
		}
	},
	LIVE_CALLER: {
		ID                      : '731f570d-db14-47b6-a4d6-0f9d10b38827',
		HIDE_KEY_EXPIRE         : 60, // (minutes)
		SUCCESS_MESSAGE_TIMEOUT : 5, // (Second)
		LOGIN_CHECK_INTERVAL    : 15, // (minutes)
	},
};


export default CONFIG;

