export const setDefaultUser = (userId) => {
  window.gtag('set', {
    user_id: Number(userId),
  });
};

export const searchEvent = (data) => {
  window.gtag('event', 'search', data);
};

export const viewItemEvent = (data) => {
  window.gtag('event', 'view_item', data);
};

export const addToWishlistEvent = (data) => {
  window.gtag('event', 'add_to_wishlist', data);
};

export const removeFromWishlistEvent = (data) => {
  window.gtag('event', 'remove_from_wishlist', data);
};

export const addToCartEvent = (data) => {
  window.gtag('event', 'add_to_cart', data);
};

export const removeFromCartEvent = (data) => {
  window.gtag('event', 'remove_from_cart', data);
};

export const viewWishlistEvent = (data) => {
  window.gtag('event', 'view_wishlist', data);
};

export const viewCartEvent = (data) => {
  window.gtag('event', 'view_cart', data);
};

export const viewShopEvent = (data) => {
  window.gtag('event', 'view_shop', data);
};

export const beginCheckoutEvent = (data) => {
  window.gtag('event', 'begin_checkout', data);
};
