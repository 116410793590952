import React from 'react'
const NotificationHistory = (className = '') => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="6" fill="#F8F9FD"/>
<path d="M15.9993 22.5C16.7327 22.5 17.3327 21.9 17.3327 21.1667H14.666C14.666 21.9 15.266 22.5 15.9993 22.5ZM19.9993 18.5V15.1667C19.9993 13.12 18.9127 11.4067 16.9993 10.9533V10.5C16.9993 9.94667 16.5527 9.5 15.9993 9.5C15.446 9.5 14.9993 9.94667 14.9993 10.5V10.9533C13.0927 11.4067 11.9993 13.1133 11.9993 15.1667V18.5L10.666 19.8333V20.5H21.3327V19.8333L19.9993 18.5ZM18.666 19.1667H13.3327V15.1667C13.3327 13.5133 14.3393 12.1667 15.9993 12.1667C17.6593 12.1667 18.666 13.5133 18.666 15.1667V19.1667Z" fill="#8996AE"/>
</svg>
 </span>
)

export default NotificationHistory



