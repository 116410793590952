import { Attribute } from '../models/_Attribute';
import CONFIG from 'core/config';

export class AttrsParser {
    data = null;
    convertedData;

    constructor(data) {
        this.data = data;
        return this;
    }

    convert() {

        this.convertedData = [];
        let Attrs = this.data;
        var vAttrs = [];
        var CatAttrs = [];
        var CatAttrsData = {};
        for (let i = 0; i < Attrs['Attrs'].length; i++) {
            var cAttrID = Attrs['Attrs'][i]['a']['id'];
            var cAttr = new Attribute(Attrs['Attrs'][i]['a']);
            if (Attrs['Attrs'][i]['v']) {
                for (let j = 0; j < Attrs['Attrs'][i]['v'].length; j++) {
                    let iAttr = this.getAttrByID(Attrs['Attrs'][i]['v'][j]);
                    if (iAttr) {
                        cAttr.attrValues.push(new Attribute(iAttr));
                    }
                }
            }
            if (Attrs['Attrs'][i]['s']) {
                for (let j = 0; j < Attrs['Attrs'][i]['s'].length; j++) {
                    let iAttr = this.getAttrByID(Attrs['Attrs'][i]['s'][j]);
                    let sAttrValues = this.getAttrsByParentID(Attrs['Attrs'][i]['s'][j]);
                    if (iAttr && iAttr.tid === CONFIG.ATTR_TYPES.ATTRIBUTE) {
                        let nAttr = new Attribute(iAttr);
                        nAttr.attrValues = sAttrValues.length > 0 ? sAttrValues : [];
                        cAttr.subAttrs.push(nAttr);
                    }
                }
            }
            if (Attrs['Attrs'][i]['r']) {
                cAttr.rAttrs = [];
                for (let j = 0; j < Attrs['Attrs'][i]['r'].length; j++) {
                    let iAttr = this.getAttrByID(Attrs['Attrs'][i]['r'][j]);
                    if (iAttr) {
                        let nAttr = new Attribute(iAttr);
                        cAttr.rAttrs.push(nAttr);
                    }
                }
            }

            if (Attrs['Attrs'][i]['ranges'] && Attrs['Attrs'][i]['ranges'].length > 0) {
                for (let j = 0; j < Attrs['Attrs'][i]['ranges'].length; j++) {
                    let iAttr = this.getAttrByID(Attrs['Attrs'][i]['ranges'][j]);
                    if (iAttr) {
                        cAttr.rangeAttrs.push(new Attribute(iAttr));
                    }
                }
            }
            vAttrs[cAttrID] = cAttr;
        }
        for (let i in Attrs['CatAttrs']) {
            var cCatID = i;
            if (!CatAttrs[cCatID]) CatAttrs[cCatID] = [];
            if (!CatAttrsData[cCatID]) CatAttrsData[cCatID] = {};
            for (let j = 0; j < Attrs['CatAttrs'][i].length; j++) {
                if (!vAttrs[Attrs['CatAttrs'][i][j]['id']]) continue;
                CatAttrs[cCatID].push(Attrs['CatAttrs'][i][j]['id']);
                CatAttrsData[cCatID][Attrs['CatAttrs'][i][j]['id']] = {	
                    'isMandatory' : (Attrs['CatAttrs'][i][j]['d']['im'] === 1),
                    'inFilter' : (Attrs['CatAttrs'][i][j]['d']['sf'] === 1),
                    'sortOrder' : parseInt(Attrs['CatAttrs'][i][j]['d']['so'])
                };
            }
        }
        this.convertedData = {'PNAttrs': vAttrs, 'PCatAttrs': CatAttrs, 'PCatAttrsData': CatAttrsData};

        return this;
    }

    getAttrByID(attrID) {
        let Attrs = this.data;
        for (let i = 0; i < Attrs['Attrs'].length; i++) {
            if (Attrs['Attrs'][i]['a']['id'] === attrID) {
                return Attrs['Attrs'][i]['a'];
            }
        }
        return false;
    }

    getAttrsByParentID(attrID) {
        let Attrs = this.data;
        let result = [];
        for (let i = 0; i < Attrs['Attrs'].length; i++) {
            if (Attrs['Attrs'][i]['a']['pid'] === attrID) {
                result.push(new Attribute(Attrs['Attrs'][i]['a']))
            }
        }
        return result;
    }

    convertItem(rawItem) {
        // return new Attr(rawItem);
    }

    getRaw() {
        return this.data || null;
    }

    get() {
        // console.log(this.convertedData)
        return this.convertedData || null;
    }
}
