import { Link } from 'react-router-dom';


import { Cookie } from 'services';

const LoginRequired = (props) => {
    const { langs, getLang } = props;

    const handleYesClick = (e) => {
        const cookie = Cookie()
        cookie._set('adults_only_confirmation', true, {expires: 0})
        props.callback(e, 'destroy')
    }
    
    const handleNoClick = (e) => {
            const cookie = Cookie()
            cookie._set('app_market_version', 'v1', {expires: 0})
            props.callback(e, 'destroy')
            window.location.href = `/${getLang}`
            // window.location.assign(`/${getLang}`)
    }

    return (
        <div className="custom-modal-wrapper">
            <div className="mb-24px px-md-64px  content-scroll">
                <div className="d-flex justify-content-center my-24px">
                 <i className='svg-i logo-i'></i>
                </div>
                <div className="font-size-20 text-dark font-medium text-center">
                    {langs.AdultsOnlyStateYourAge}
                </div>
            </div>
            <div className="mt-0 mb-48px d-flex justify-content-center">
                <button type="button" className="font-size-14 font-bold-caps px-48px px-lg-100-px py-16px btn bg-gradient text-dark d-block mx-8px "
                  onClick={handleYesClick}   >
                    {langs.Yes}
                </button>
                <Link to={`/${getLang}/`} className='font-size-14 font-bold-caps px-48px px-lg-100-px py-16px btn bg-gray text-dark d-block mx-8px'
                onClick={handleNoClick}  >
                    {langs.No}
                </Link>
            </div>
        </div>
    )
}

export default LoginRequired