import React, {useState, useEffect} from 'react'
import {Formik, Field, Form, ErrorMessage} from 'formik';

import * as Yup from 'yup';
import {API} from 'services';
import { Functions } from 'libs';

import {Loader} from 'components'

const Feedback = (props) => {
	const {langs, data} = props;
	const api                                       = API();

	const [captcha, setCaptcha]	= useState({});
	const [messageState, setMessageState]           = useState(false)
	const [errorState, setErrorState]               = useState(false)
	const [submitState, setSubmitState]             = useState(false)

	const config = {
		initialValues: {
			Email: '',
			Message: '', 
			CaptchaVal: '',
			QuestionID: data.QuestionID
		},
		validationSchema: Yup.object().shape({
			Email: Yup.string().email(langs.IncorrectFormatOfEmail).required(langs.WriteYourEmail),
			Message: Yup.string().required(langs.WriteMessageText),
			CaptchaVal: Yup.string().min(5).max(5).required(`${langs.WriteCaptcha}`),
		}),
		enableReinitialize: false,
		validateOnBlur: false,
		validateOnChange: false,
	}

	const handleSubmit = async (values, {resetForm}) => {
		setSubmitState(true)
		setErrorState(false)
		values.CAPTCHATOKEN = captcha.token;
		let newCaptcha = await api.getCaptcha();
		if (newCaptcha.statusCode) {
			setCaptcha(newCaptcha.data);
		}
		let apiData = await api.getHelpSend(values);
		if ( apiData.statusCode ){
			resetForm();
			setMessageState(apiData)
		} else {
			setErrorState(apiData.statusMessage)
		}
		setSubmitState(false)
	}

	const handleChange = (e, setFieldValue) => {
		let value = Functions.checkStringLength(e.target.value, 5)
		setFieldValue("CaptchaVal", value)
}

	useEffect(() => {
		const getApiData = async () => {
			var apiData = await api.getCaptcha();
			if (apiData.statusCode) {
				setCaptcha(apiData.data);
			}
		}
		getApiData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const SuccessComponent = () => (
		<div className="position-relative pt-24px text-center">
			<div className="popup-icon d-flex align-items-center justify-content-center">
				<i className="svg-i send-offer-i"></i>
			</div>
			<div className="pb-48px">
				<div className="font-size-16 font-base text-blue-gray text-center">
					{messageState.statusCode ? langs.SuccessMessage : messageState.statusMessage ? messageState.statusMessage : langs.ErrorMessage}
				</div>
				<div className=""></div>
			</div>
		</div>
	)
	
	return (
		<div className="custom-modal-wrapper">
			{messageState ?
				<SuccessComponent />
			:
				<Formik {...config} onSubmit={handleSubmit}>
					{({ errors, status, touched, setFieldValue }) => (
					<Form autoComplete="off">
						<div className="position-relative ">
							<div className="popuo-content  px-8px  content-scroll">
								<h3 className="font-bold-caps font-size-18 pb-32px text-left">
									{langs.NM_Message}
								</h3>
								<div className="form-group col p-0">
									<Field type="text" name="Email" id="Email" className={"form-control form-control-md " + (touched.Email && errors.Email ? "border-danger" : "")} placeholder={props.langs.Email} />
									<ErrorMessage name="Email" component="label" className="error " />
								</div>
								<div className="form-group">
									<Field as="textarea" name="Message" className={"form-control pb-sm-200px text-dark font-size-14 font-base "  + (touched.Message && errors.Message ? "border-danger" : "")} 
										placeholder={langs.NM_FAQ_Describe_Placeholder}/>
									<ErrorMessage name="Message" component="label" className="error text-left"/>
								</div>
								<div className={`captcha-box faq-feedback ${touched.CaptchaVal && errors.CaptchaVal ? 'error' : ''}`}>
								<div className="input-group form-group  align-items-center mb-0">
									<Field type="text" name="CaptchaVal" id="CaptchaVal" className={"code-input form-control form-control-lg left-border-0 text-uppercase " + (touched.CaptchaVal && errors.CaptchaVal ? "border-danger" : "")} placeholder={props.langs.Code} onChange={(e) => handleChange(e, setFieldValue)} />
									<div className="input-group-prepend ">
										<span className="input-group-text p-12px border border-right-0  border-gray my-auto w-99px right-border-0 h-60px d-flex justify-content-center radius-32">
											<img className="h-100 m-auto py-0 captcha-img" src={captcha.image} alt="" />
										</span>
									</div>
								
								</div>
								<ErrorMessage name="CaptchaVal" component="label" className="error  " />
								{errorState && 
									<label className="error">{errorState}</label>
								}
								</div>
						
							</div>
							{submitState && <Loader />}
							<button type="submit" className="btn bg-blue font-bold-caps font-size-13 text-white w-100 mt-24px  px-40px py-16px" 
									disabled={submitState}>
									{langs.Send}
								</button>
						</div>
					</Form>
					)}
				</Formik>
			}
		</div>
	)
}

export default Feedback