import React, { useContext } from 'react';
import { AppDataContext } from 'contexts/appData';

const PaymentItem = ({ onChange, isSelected, isBalance, method, balance }) => {
  const { langs, currency, CONFIG, getLang } = useContext(AppDataContext);


  if (isBalance) {
    return (
      <div className="mb-8px cursor-pointer">
        <div className={`d-flex justify-content-between custom-control custom-radio border-radius-md py-24px m-auto ${isSelected ? 'active': ''}`} onClick={onChange}>
          <label
            className={`custom-control-label font-medium font-size-14 text-text-black w-100 
													${isSelected ? 'active' : ''}`}
          >
            <div className="text-left my-auto">
              <div className="font-size-14 text-text-black font-medium">{method.title}</div>
              <div className="font-size-12 text-blue_2 font-base">
                {langs.MyBalance} {balance} {currency()}
              </div>
              {!method.checkBalance && (
                <>
                  <div className="font-size-12 text-red font-base">{langs.InsufficientAmount}</div>
                  <a
                    href={`${CONFIG.HOST.appUrl}${getLang}/finances/balance`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="mr-24px my-auto font-base font-size-14 text-blue_2"
                  >
                    <u>{langs.FillBalance}</u>
                  </a>
                </>
              )}
            </div>
          </label>
          <span className="mr-24px">
            <i className={`svg-i svg-i-big ${method.icon}`}></i>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="mb-8px cursor-pointer">
      <div className={`d-flex justify-content-between custom-control custom-radio border-radius-md py-24px m-auto ${isSelected ? 'active': ''}`} onClick={onChange}>
        <label
          className={`custom-control-label font-medium font-size-14 text-text-black w-100 
													${isSelected ? 'active' : ''}`}
        >
          {method.title}
        </label>
        <span className="mr-24px">
          <i className={`svg-i svg-i-big ${method.icon}`}></i>
        </span>
      </div>
    </div>
  );
};

export default PaymentItem;
