import React from 'react';
import { Editor, RichUtils } from 'draft-js';

const TextEditor = (props) => {
	const editorState = props.editorState;
	// const contentState = editorState.getCurrentContent();

	const onChange = newState => {
		props.onChange(props.fieldName, newState);
	};

	// const focus = () => this.refs.editor.focus();

	const handleKeyCommand = command => {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			onChange(newState);
			return true;
		}
		return false;
	}

	// const toggleBlockType = blockType => {
	//     onChange(RichUtils.toggleBlockType(editorState, blockType));
	// }
	const toggleInlineStyle = inlineStyle => {
		onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
	}

	return (
		<div className="TextEditor-root">
			<InlineStyleControls
				editorState={editorState}
				onToggle={toggleInlineStyle}
			/>
			<div className="TextEditor-editor" >
				<Editor
					blockStyleFn={getBlockStyle}
					customStyleMap={styleMap}
					editorState={editorState}
					handleKeyCommand={handleKeyCommand}
					onChange={onChange}
					placeholder=""
					spellCheck={true}
				/>
			</div>
		</div>
	)
}
// Custom overrides for "code" style.
	const styleMap = {
		CODE: {
			backgroundColor: 'rgba(0, 0, 0, 0.05)',
			fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
			fontSize: 16,
			padding: 2,
		},
	};
const getBlockStyle = (block) => {
	switch (block.getType()) {
		case 'blockquote':
			return 'TextEditor-blockquote';
		default:
			return null;
	}
}


const StyleButton = (props) => {
	const onToggle = e => {
		e.preventDefault();
		props.onToggle(props.style);
	}

	let className = 'TextEditor-styleButton';
	if (props.active) {
		className += ' TextEditor-activeButton';
	}
	return (
		<span className={className} onMouseDown={onToggle}>
			{props.label}
		</span>
	)
}


const INLINE_STYLES = [
	{ label: 'B', style: 'BOLD' },
	{ label: 'I', style: 'ITALIC' },
	{ label: 'U', style: 'UNDERLINE' },
	// { label: 'Monospace', style: 'CODE' },
];
const InlineStyleControls = props => {
	let currentStyle = props.editorState.getCurrentInlineStyle()
	return (
		<div className="TextEditor-controls">
		  {INLINE_STYLES.map(type =>
			<StyleButton
				key={type.label}
				active={currentStyle.has(type.style)}
				label={type.label}
				onToggle={props.onToggle}
				style={type.style}
			/>
		  )}
		</div>
	)
}

export default TextEditor