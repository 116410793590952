import React from 'react';

const CustomSelectOption = (props) => {
	const {innerProps, value, data, label} = props;
  return (
  	<div {...innerProps} className="custom-select-option">
		{value !== null ?
			<>
				<div className="font-size-14 font-bold mb-12px">
        	{data.name} {data.number}
       	</div>
				<div className="font-size-14 font-base mb-8px">
      		{data.address}
      	</div>
				<div className="font-size-14 font-base">
					{`${data.phone}${data.secondPhone ? `, ${data.secondPhone}` : ''}`}
     		</div>
			</>
	    :
		    <div> + {label}</div>
		}
		</div>
  )
}

export default CustomSelectOption