import React from 'react'
const MyAccount = (className = '') => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="6" fill="#F8F9FD" />
            <path d="M21.3333 10.6666H10.6667C9.92668 10.6666 9.34001 11.26 9.34001 12L9.33334 20C9.33334 20.74 9.92668 21.3333 10.6667 21.3333H21.3333C22.0733 21.3333 22.6667 20.74 22.6667 20V12C22.6667 11.26 22.0733 10.6666 21.3333 10.6666ZM21.3333 20H10.6667V16H21.3333V20ZM21.3333 13.3333H10.6667V12H21.3333V13.3333Z" fill="#8996AE" />
        </svg>
    </span>
)

export default MyAccount




