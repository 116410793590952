import React, { useState, useRef, useEffect } from 'react'
import { Functions } from 'libs';
const SecurityCode = (props) => {
	const [code, setCode]           = useState('');
	const codeInput                 = useRef(null);

	const close = () => {
		props.callback(null, 'destroy');
	}

	const success = () => {
		let data = { ...props.data.resend }
		data["SecurityCode"] = codeInput.current.value
		props.callback(data, 'success');
		close()
	}

	const handleChange = (e) => {
		setCode(Functions.checkStringLength(e.target.value, 6))
	}
	useEffect(() => {
		codeInput.current.focus();
	}, [code]);

	return (
    <>
      <div className="custom-modal-wrapper">
				<div className="px-md-80px">
					<h2 className="font-size-21 font-bold-caps text-center pt-24px text-dark mb-32px ">
						{props.data.header ? props.data.header : props.langs.SecurityCode}
					</h2>
					<span className="d-flex text-center font-size-14 font-base text-blue-gray mb-40px ">
						{props.data.title}
					</span>
					<div className="form-group  px-20px">
						<label htmlFor="code" className="font-size-14 font-medium text-blue-gray ">
							{props.langs.SecurityCode}
							<span className="required text-blue">*</span>
						</label>
						<input type="text" className="form-control border-radius-sm "ref={codeInput} value={code} onChange={handleChange} />
					</div>
				</div>
			</div>
      <div className="mb-16px px-20px px-md-100px d-flex justify-content-center">
				<button className="font-size-13 w-100 font-bold-caps py-16px btn bg-gradient text-white d-block mx-auto"
					type="button" disabled={code === ''} onClick={success}>
					{props.langs.Transfer}
				</button>
			</div>
    </>
  )
}

export default SecurityCode