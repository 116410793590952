import React, {  useEffect, useContext, memo } from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from 'contexts/user';
import { HistoryContext } from 'contexts/history';
import { AppDataContext } from 'contexts/appData';
import { ModalsContext } from 'contexts/modals';
import Logout from 'components/global/Logout';

import openShop from '../../src/resources/img/open-shop.svg';
import isShop from '../../src/resources/img/rocket.png';

import {
	EditAccount,
	AddStatement,
	LogOutIcon,
	MyAdress,
	MyAccount,
	MyCarts,
	MyFaforites,
	Mymessage,
	MyOrders,
	// NotificationHistory,
	Statements,
	ShopsOrder,
	MyFinnaces,
	MyShop,
	MyGroups,
	Avatar,
	Tradein,
	InnerInstallment,
	FillBalance,
	BidsAndOffers,
	Tooltip,
		Card,
} from 'components'

const UserSidebar = (props) => {
	const { langs, getLang, CONFIG, Functions } = useContext(AppDataContext)
	const { getPathname, push } = useContext(HistoryContext)
	const { user, shop, tradeInOrders, notifications, accountDetails, userBalance } = useContext(UserContext);
	const { callModal } = useContext(ModalsContext);
	const url = getPathname()

	// const [balance, setBalance] = useState(userBalance);
	const balance = userBalance;
	const mobileSidebar = props.mobileSidebar ? props.mobileSidebar : null
	const toggleMenu = (e, action = null) => {
		if (mobileSidebar !== null) {
			props.toggleMenu(e, action)
		}
	}

	const list = [
		{
			title: `${langs.AddPr}`,
			params: {
				to: `/${getLang}/pr-form`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100d-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   ',
			},
			component: <AddStatement />,
			type: null
		},
		{
			title: `${langs.MyPrs}`,
			params: {
				to: `/${getLang}/myproducts`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: <Statements />,
			type: null
		},
		{
			title: langs.NM_MyCards,
			params: {
				to: `/${getLang}/mycards`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100 seller  '
			},
			component: <MyAccount />,
			type: null,

		},
		{
			title: `${langs.NM_ReceivedOffers}`,
			params: {
				to: `/${getLang}/offer-price/seller`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100 seller  '
			},
			component: <BidsAndOffers />,
			type: shop === null,

		},
		{
			title: `${langs.ShopOrders}`,
			params: {
				to: `/${getLang}/orders/shop`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: ShopsOrder(),
			type: (shop !== null && shop.TypeID === 1)
		},
		{
			title: `${langs.SoldOrders}`,
			params: {
				to: `/${getLang}/orders/c2c`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: <ShopsOrder />,
			type: shop === null
		},
		{
			title: `${langs.MyFinaces}`,
			params: {
				to: `/${getLang}/finances`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  w-100   ',

			},
			// balance: Balance(),
			component: <MyFinnaces />,
			type: null
		},
		{
			name: 'msg',
			title: `${langs.MyMessages}`,
			params: {
				to: `/${getLang}/chat`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: <Mymessage />,
			type: null
		},
		// {
		// 	title: `${langs.News}`,
		// 	params: {
		// 		to: `/${getLang}/notifications`,
		// 		route: '#',
		// 		className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  w-100   '
		// 	},
		// 	component: NotificationHistory(),
		// 	type: null
		// },
		{
			title: `${langs.MyGroups}`,
			params: {
				to: `/${getLang}/mygroups`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: <MyGroups />,
			type: (shop !== null && shop.TypeID === 1)
		},
		// {
		//     title: `${langs.ShopMembers}`,
		//     params: {
		//         to: `/${getLang}/shopsmember`,
		//         route: '',
		//         className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  d-none '
		//     },
		//     component: ShopsMember(),
		// },
		{
			title: `${langs.MyShop}`,
			params: {
				to: `/${getLang}/shop`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  w-100   '
			},
			component: <MyShop />,
			type: shop !== null
		},
		{
			title: `${langs.TradeIn}`,
			params: {
				to: `/${getLang}/mytradein`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100 pb-24px  '
			},
			component: <Tradein />,
			// !! to make variable boolean (not NULL)
			type: !!(tradeInOrders || (shop && shop.ID === CONFIG.TOSPLIT.TRADEIN.SHOPID))
		},
		{
			title: `${langs.NM_InnerInstallment}`,
			params: {
				to: `/${getLang}/installment`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  w-100   '
			},
			component: <InnerInstallment />,
			type: shop !== null && (shop.ID === 10949 || shop.ID === 6688)
		},
		{
			title: `${langs.MyFavorites}`,
			params: {
				to: `/${getLang}/favorites`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-bold text-greymiddle bottom-list pt-24px border-top text-decoration-none  w-100   '
			},
			component: <MyFaforites />,
			type: null
		},
		{
			title: `${langs.MyCart}`,
			params: {
				to: `/${getLang}/mycart`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: <MyCarts />,
			type: null
		},
		{
			title: `${langs.MyOrders}`,
			params: {
				to: `/${getLang}/orders/user`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: <MyOrders />,
			type: null
		},
		{
			title: `${langs.NM_senTOffers}`,
			params: {
				to: `/${getLang}/offer-price/user`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: <BidsAndOffers />,
			type: null
		},
		{
			title: `${langs.Addresses}`,
			params: {
				to: `/${getLang}/address`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  w-100 '
			},
			component: <MyAdress />,
			type: null
		},
		// {
		// 	title: `${langs.NM_MyAccounts}`,
		// 	params: {
		// 		to: `/${getLang}/account/list`,
		// 		route: '#',
		// 		className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  w-100  '
		// 	},
		// 	component: MyAdress(),
		// 	type: null
		// },
		{
			title: `${langs.NM_MyAccounts}`,
			params: {
				to: `/${getLang}/account`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  w-100 pb-16px  '
			},
			component: <MyAccount />,
			type: shop === null,
			tooltip: true
		},
		{
			title: `${langs.EditAccount}`,
			params: {
				to: `/${getLang}/profile`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100  pt-24px  border-top'
			},
			component: <EditAccount />,
			type: null
		}

	]

	const changeLang = (lang) => {
		return `${window.location.href.replace(`/${getLang}`, `/${lang}`)}`
	}

	const handleFillBalance = () => {
	  push(`/${getLang}/finances`);
		if(mobileSidebar) toggleMenu();
	}

	useEffect(() => {
		if(user) {
			accountDetails();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user])

	// useEffect(() => {
	// 	if (user && userBalance !== balance) {
	// 		accountDetails();
	// 		setBalance(userBalance)
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [user, userBalance])
	return (
		<div className={`mr-16px mr-lg-20px ${mobileSidebar ? 'user_profile sidebar-scroll bg-white' : 'user_menu mb-48px mb-lg-100px'}`}>
			{mobileSidebar &&
				<div className="btn m-sidebar-close bg-white rounded-circle top-50px right-40px  p-8px" onClick={toggleMenu}>
					<i className="svg-i  close-gray-i"></i>
				</div>
			}
			<div>
				{user &&
					<div className={`sidebar-container px-16px  bg-white mb-0 ${mobileSidebar ? 'border-radius-0  border-0 pt-20px' : 'border-radius-md '}`}>
						<div className={`sidebar-head pt-20px border-radius-md ${mobileSidebar ? 'bg-white border-0' : ''}`}>
							<div className="d-flex align-items-center">
								<div className={`avatar mr-8px`}>
									<Avatar
										logo={false}
										link=''
										photo={user.Photo ? user.Photo : false}
										gender={user.GenderID ? user.GenderID : 1}
										isLoggedIn={!!user}
										isHeaderDropdown={true}
										className={'max-width-48px max-height-48px'}
									/>
								</div>
								<div className="overflow-hidden max-width-180px">
									<div className="font-bold font-size-16 text-truncate user-name">
										{(!user.Name || user.Name === null || user.Name === 'undefined') ? user.Username : `${user.Name} ${user.Lastname}`}
									</div>
									<div className={`text-blue font-size-12 font-medium user-id    ${mobileSidebar ? 'text-left' : ''} `}>
										{`ID ${user.ID}`}
									</div>
								</div>
							</div>
							<div className="balanance px-16px py-12px border border-radius mt-24px">
								<div className="d-flex justify-content-between align-items-center">
									<div className="d-block">
										<div className="font-base font-size-12 text-greymiddle mb-4px">{langs.BalanceAmount}</div>
										<div className="h-28px font-size-20 font-bold">
											{balance}
										</div>
									</div>
									{/*<Link to={`/${getLang}/finances`}>*/}
										<div onClick={handleFillBalance} className="d-flex align-items-center font-size-12 font-base text-greymiddle cursor-pointer">
											{langs.Fill}
											<div className="ml-8px">
												<FillBalance />
											</div>
										</div>
									{/*</Link>*/}
								</div>
							</div>
						</div>
						<hr className='hr d-none'/>
						<div className="">
							<ul className="list-group py-20px">
								{list.map((item, i, array) => (
									(item.type || item.type === null) &&
									<li className={`list-group-item d-flex position-relative align-items-center bg-transparent border-0 py-0  px-0 my-16px ${array.length - 1 === i ? 'mt-3' : ''} ${(item.params.route && url.indexOf(item.params.to) !== -1) || (item.params.route === "" && url === item.params.to) ? 'active font-bold' : ''}`}
										key={i} onClick={toggleMenu}>
										{/* {item.tooltip ?
											<Tooltip 
												{...{
													storageName: "SidebarTooltip",
													className: `${mobileSidebar ? "bottom" : "right"} small-info-tooltip text-white font-size-12 bg-nmbluedark d-flex  justify-content-between`,
													title:langs.NM_UpdatedFunctional,
													text:langs.NM_OfferToBuyer
												}}
											/>
											: null
										} */}
										<Link {...item.params}>
											<span className="mr-12px position-relative">
												{item.component}
												{(item.name === 'msg' && notifications?.unseen_count > 0) &&
													<span className="rounded-circle badge badge-sidebar badge-pill bg-badge-orange font-base text-white position-absolute badge-counter">
														{notifications.unseen_count}
													</span>
												}
											</span>
											{item.title}
											{/* <span>{item.balance}</span> */}
										</Link>
									</li>
								))}
								<li className="list-group-item d-flex position-relative align-items-center bg-transparent border-0 py-0  px-0 my-6px">
									<Logout {...{
										element: 'div',
										className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   cursor-pointer',
										onClick: (e) => toggleMenu(e, false)
									}} >
										<span className="mr-24px leave">
											<LogOutIcon />
										</span>
										{langs.LogOut}
									</Logout>
								</li>
							</ul>
						</div>
					</div>
				}
			</div>


			<div className="open-shop-container">
				{/* auth */}
				{(mobileSidebar && !user) &&
					<div className="d-flex flex-column  px-30px my-56px">
						<h2 className="font-size-18 font-bold-caps text-dark-gray mb-8px ">{langs.MyPage}</h2>
						<div className="mobile-auth d-flex align-items-center justify-content-center mt-28px ">
							<button className="btn text-white bg-primary w-100s border-solid-1 border-transparent border-radius-sm font-size-12 font-medium-caps py-12px  font-weight-normal  mr-12px"
								onClick={() => {
									Functions.redirectWindow(true, getLang, true, `${CONFIG.HOST.appUrl}${getPathname().substring(1)}`);
								}}>
								{langs.LogIn}
							</button>
							<button className="btn text-greymiddle w-100s bg-transparent border-solid-1 border-gray border-radius-sm font-size-12 font-medium-caps py-12px font-weight-normal"
								onClick={() => {
									Functions.redirectWindow(true, getLang, null, `${CONFIG.HOST.appUrl}${url.substring(1)}`);
								}}>
								{langs.Register}
							</button>
						</div>
					</div>
				}
				{user &&
					<div className="open-shop d-flex flex-column align-items-center justify-content-center position-relative pt-0  mt-100px mt-lg-130px mb-50px mb-lg-0">
						<div className={`open-shop-img ${user && shop ? 'is-shop' : ''}`}>
							{(user && !shop) ? <img src={openShop} alt="" /> : <img src={isShop} alt="" />}
						</div>
						<div className="open-shop-box w-100  border-radius-lg p-24px">
							<span className="open-shop-descr d-flex justify-content-center stardd_descr  text-center text-greymiddle font-size-12 font-base pb-25px pt-56px">
								{(user && !shop) ? `${langs.OpenShopTxt}` : `${langs.AdvertisingPackagesTxt}`}
							</span>
							<div onClick={toggleMenu}>
								<Link className={`btn btn-block  border-radius font-size-12 font-bold-caps py-15px ${user && shop ? 'bg-gradient text-white' : 'bg-gray text-blue'}`}
									to={user && shop ? `/${getLang}/subscriptions` : `/${getLang}/shop`} >
									{user && shop ? `${langs.AdvertisingPackages}` : `${langs.OpenShop}`}
								</Link>
							</div>
						</div>
					</div>
				}
			</div>
			{mobileSidebar &&
				<div>
					<div className="sidebar-langbar top d-flex mx-30px py-30px">
						{
							CONFIG.LANGS.VIEW.map((lang, i) => (
								<a key={i} href={`${changeLang(lang)}`} className={`btn mr-20px p-10px rounded-circle d-flex align-items-center justify-content-center bg-middle-gray`}>
									<i className={`svg-i ${lang}`}></i>
								</a>
							))
						}
					</div>

					<div className="sidebar-footer my-40px  d-flex flex-column align-items-start px-30px">
						<div className="mb-24px" onClick={toggleMenu}>
							<Link to={`/${getLang}/terms`} className="font-size-14 font-base text-dark">{langs.SiteRules} </Link>
						</div>
						<div className="mb-40px" onClick={toggleMenu}>
							<Link to={`/${getLang}/contact`} reloadDocument className="font-size-14 font-base text-dark">{langs.Contact}</Link>
						</div>
					</div>
				</div>
			}
		</div>
	)
}

export default memo(UserSidebar);
