import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'resources/styles/index.scss';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import CONFIG from './core/config';

Sentry.init({
  dsn: 'https://58cee8a976517e11a3884d9f18138451@o4505269284044800.ingest.us.sentry.io/4507424666288128',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  enabled: CONFIG.HOST.branch === 'PRODUCTION',
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  allowUrls: [/https?:\/\/((api|www)\.)?mymarket\.ge/],
  tracePropagationTargets: [/https:\/\/api\.mymarket\.ge/, /https:\/\/mymarket\.ge/],
  // Session Replay
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.05, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  initialScope: (scope) => {
    const user = JSON.parse(localStorage.getItem('User'));

    if(user) {
      scope.setUser({
        username: `${user.User.Name} ${user.User.Lastname}`,
        email: user.User.Email
      })
    }

    return scope
  },
  ignoreErrors: [
    'UnhandledRejection',
    'Event `Event` (type=error) captured as exception',
    'EncodingError: The source image cannot be decoded.',
    'Method not found',
    'Failed to execute \'removeChild\' on \'Node\': The node to be removed is not a child of this node.',
    'Load failed',
    'The string did not match the expected pattern.',
    'The object can not be found here.',
    'timeout exceeded.',
    'Event `Event` (type=unhandledrejection) captured as promise rejection',
    'Internal error when calculating storage usage.',
    'JSON Parse error: Unexpected identifier \'_xx_gemius_gettfpdata_xx_\'',
    'AbortError: The operation was aborted.',
    /Failed to read a named property 'Element' from 'Window'/,
    '/Java exception was raised during method invocation/',
    'jb',
    'JSON Parse error: Unexpected identifier "setImmediate$0"',
    'Failed to execute \'startRendering\' on \'OfflineAudioContext\': cannot startRendering when an OfflineAudioContext is closed',
    'InvalidStateError: Failed to execute \'startRendering\' on \'OfflineAudioContext\': cannot startRendering when an OfflineAudioContext is closed'
  ],
  beforeSend: (event, hint) => {
    const error = hint.originalException;
    if (error instanceof Error) {
      if (
        // adOcean
        error.message.match(/contentWindow.document/) ||
        error.message.match(/elementFromPoint/) ||

        // iframes
        error.message.match(/contentDocument/) ||

        // autocomplete search typing (it cancel previous request)
        error.message.match(/captured as promise rejection/) ||

        // reCaptcha
        error.message.match(/promise rejection captured/) ||

        // adBlocker
        error.message.match(/NetworkError/i) ||
        error.message.match(/Network Error/i) ||
        error.message.match(/Fetch Error/i) ||
        error.message.match(/XHR Error/i) ||
        error.message.match(/adsbygoogle/i) ||
        error.message.match(/Failed to fetch/i) ||
        error.message.match(/Request aborted/i) ||
        error.message.match(/The object can not be found here./i) ||
        error.message.match(/timeout exceeded/i) ||
        error.message.match(/Event `Event` (type=unhandledrejection) captured as promise rejection/i) ||
        error.message.match(/Internal error when calculating storage usage./i) ||
        error.message.match(/Unexpected token '_', "_xx_gemius"... is not valid JSON/i) ||
        error.message.match(/JSON Parse error: Unexpected identifier "_xx_gemius_gettfpdata_xx_"/gi) ||
        error.message.match(/AbortError: The operation was aborted./i) ||
        error.message.match(/AbortError: The play/i) ||
        error.message.match(/Failed to read a named property 'Element' from 'Window'/i) ||
        error.message.match(/Java exception was raised during method invocation'/i) ||
        error.message.match(/jb'/i) ||
        error.message.match(/JSON Parse error: Unexpected identifier "setImmediate$0"'/i) ||
        error.message.match(/Failed to execute 'startRendering' on 'OfflineAudioContext': cannot startRendering when an OfflineAudioContext is closed/i) ||
        error.message.match(/InvalidStateError: Failed to execute 'startRendering' on 'OfflineAudioContext': cannot startRendering when an OfflineAudioContext is closed/i)
      ) {
        return null
      }
    }
    return event;
  },
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

reportWebVitals();
