import React, {useRef, useState} from "react";
import { Parser } from 'components';
const Second = (props) => {
  const {langs, cancel, nextStep} = props;
  const [proceed, setProceed] = useState(false);
  const ConfirmReturnPolicy = useRef();
  const onScroll = () => {
    if (ConfirmReturnPolicy.current) {
      const { scrollTop, scrollHeight, clientHeight } = ConfirmReturnPolicy.current;
      if ((scrollTop + clientHeight + 50) >= scrollHeight) {
        setProceed(true);
      }
    }
  };
  return (
    <div>
      <h2 className="font-size-18 font-bold text-center pt-28px pb-36px text-nLight-dark ">
        <Parser html={langs.NM_ConfirmReturnPolicyTitle}/>
      </h2>
      <div className="ConfirmReturnPolicy mb-48px font-size-16 font-medium text-dark px-12px" 
        onScroll={onScroll} ref={ConfirmReturnPolicy}>
         <Parser html={langs.NM_ConfirmReturnPolicy}/>
      </div>
      <div>
        {proceed && 
        <div className="mb-16px px-20px px-md-100px d-flex justify-content-center">
          <button className="return-item-btn-actions font-size-16 w-100 font-medium font-weight-normal py-16px btn bg-middle-gray text-dark d-block mr-12px"
            type="button" onClick={nextStep}>
            {langs.Continue}
          </button>
          <button className="return-item-btn-actions font-size-16 w-100 font-medium font-weight-normal py-16px btn bg-middle-gray text-dark d-block "
            type="button" onClick={cancel}>
            {langs.NM_Quit}
          </button>
        </div>
        }
      </div>
    </div>
  )
}
export default Second