import React from 'react'
const MyShop = (className = '') => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="6" fill="#F8F9FD" />
            <path d="M22.599 13.9267L21.899 11.0133C21.7524 10.4133 21.2324 10 20.6257 10H11.3657C10.7657 10 10.239 10.42 10.099 11.0133L9.39903 13.9267C9.23903 14.6067 9.38569 15.3 9.81236 15.8467C9.86569 15.92 9.93903 15.9733 9.99903 16.04V20.6667C9.99903 21.4 10.599 22 11.3324 22H20.6657C21.399 22 21.999 21.4 21.999 20.6667V16.04C22.059 15.98 22.1324 15.92 22.1857 15.8533C22.6124 15.3067 22.7657 14.6067 22.599 13.9267ZM20.6057 11.3267L21.3057 14.24C21.3724 14.52 21.3124 14.8 21.139 15.02C21.0457 15.14 20.8457 15.3333 20.5124 15.3333C20.1057 15.3333 19.7524 15.0067 19.7057 14.5733L19.319 11.3333L20.6057 11.3267ZM16.6657 11.3333H17.9724L18.3324 14.3467C18.3657 14.6067 18.2857 14.8667 18.1124 15.06C17.9657 15.2333 17.7524 15.3333 17.479 15.3333C17.0324 15.3333 16.6657 14.94 16.6657 14.46V11.3333ZM13.659 14.3467L14.0257 11.3333H15.3324V14.46C15.3324 14.94 14.9657 15.3333 14.4724 15.3333C14.2457 15.3333 14.039 15.2333 13.879 15.06C13.7124 14.8667 13.6324 14.6067 13.659 14.3467ZM10.6924 14.24L11.3657 11.3333H12.679L12.2924 14.5733C12.239 15.0067 11.8924 15.3333 11.4857 15.3333C11.159 15.3333 10.9524 15.14 10.8657 15.02C10.6857 14.8067 10.6257 14.52 10.6924 14.24ZM11.3324 20.6667V16.6467C11.3857 16.6533 11.4324 16.6667 11.4857 16.6667C12.0657 16.6667 12.5924 16.4267 12.979 16.0333C13.379 16.4333 13.9124 16.6667 14.519 16.6667C15.099 16.6667 15.619 16.4267 16.0057 16.0467C16.399 16.4267 16.9324 16.6667 17.5324 16.6667C18.0924 16.6667 18.6257 16.4333 19.0257 16.0333C19.4124 16.4267 19.939 16.6667 20.519 16.6667C20.5724 16.6667 20.619 16.6533 20.6724 16.6467V20.6667H11.3324Z" fill="#8996AE" />
        </svg>

    </span>
)

export default MyShop







