import React, { useState, useEffect, useRef } from 'react'
import {SwiperSlide} from 'swiper/react';
import { Slider, SliderNav, TradeInPreviewQuestion, ImageHandler, GalleryOpener} from "components";
import { getSwiperConfig } from 'core';
const TradeInPreview = (props) => {
	const tradeInGallery                                 	= useRef(null);
	const [photos, setPhotos] 			                    = useState([]);
	const [activeThumb, setActiveThumb] 			        = useState(0);
	const [openGallery, setOpenGallery] 			        = useState(null);

	useEffect(() => {
		setTimeout(() => {
			if (tradeInGallery.current !== null && !tradeInGallery.current.destroyed) {
				tradeInGallery.current.on('click', (e) => {
					setOpenGallery(tradeInGallery.current.realIndex)
				});
				tradeInGallery.current.on('slideChange', () => {
					setActiveThumb(tradeInGallery.current.realIndex);
				});
			}
		}, 1000)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tradeInGallery.current])

	useEffect(() => {
		if (tradeInGallery !== null && tradeInGallery.controller) {
			tradeInGallery.slideTo(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.routeParams.id]);

	useEffect(() => {
		if (props.Photos) {
			setPhotos(Array.isArray(props.Photos) ? props.Photos : props.Photos.split(','))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.Photos]);
	return (
		<div className="flex-1 d-flex flex-column justify-content-between  border-radius-sm bg-white px-12px px-xl-24px pb-16px pt-24px h-100 ">
			<div>
				<div className="preview-img  bg-middle-gray border-radius-xs position-relative">
					{photos.length > 0 &&
						<>
							<Slider {...getSwiperConfig('tradeinInnerPreview')} customSwiper={true} ref={tradeInGallery} >
								{photos.map((img, i) => (
									<SwiperSlide className="swiper-slide h-100 d-flex align-items-center w-100" key={i}>
										<ImageHandler {...{
											src: img.thumbs ? img.thumbs : img,
											alt: '',
											className: "w-100 h-100 object-fit-cover border-radius-xs"
										}} />
									</SwiperSlide>
								))}
							</Slider>
							<div className="preview-nav">
								<SliderNav ref={tradeInGallery} />
							</div>
						</>
					}
				</div>
				<div className="preview-title font-size-14 font-bold text-dark mt-16px mb-32px text-truncate">
					{props.ModelTitle} {props.SpecTitle}
				</div>
				{props.Answers.map((answer, index) => {
					let reduce = null;
					// if (props.conditionsData) {
					//     let data = props.conditionsData.find(o => o.answer.answer_id === answerID)
					//     reduce = props.answer.shops_conditions[0]
					// } else {
						reduce = answer.shop_condition
					// }
					let Question = answer.question_and_answer.question.lang_data.title
					let Answer   = answer.question_and_answer.lang_data.title
					return (
						<TradeInPreviewQuestion {...{Question, Answer, reduce, currency: props.currency}} key={index}/>
					)
				})}
			</div>
			{props.ShowFooter &&
				<div className="preview-footer mt-22px  border-top border-middle-gray pt-12px">
					{props.offeredPrice &&
					<div className="preview-sum ">
							<span className="font-size-14 font-black text-blue">
								{`${props.langs.Sum} : `}
							</span>
						<span className="font-size-14 font-black text-blue">
								{`${props.offeredPrice} `}{props.currency()}
							</span>
					</div>
					}
					<div className="courier-service font-size-12 font-base text-blue-gray mt-4px">
						{props.CONFIG ? props.CONFIG.TOSPLIT.TRADEIN.DELIVERY_RATE : 0} {props.currency()} {props.langs.NM_TradeIn_DeliveryRate}
					</div>
				</div>
			}
			{
				openGallery !== null &&
				<GalleryOpener {...{
					images: photos,
					activeThumb:activeThumb,
					setOpenGallery:setOpenGallery,
					totalImages:photos.length
				}} />
			}
		</div>
	)
}

export default TradeInPreview;