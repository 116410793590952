import {useEffect} from 'react';
import axios from 'axios';
import { CONFIG } from 'core';
import { Cookie } from 'services';

const Request = () => {
  let lang = window.location.pathname.split('/')[1];
  // let storage = Storage();
  let authCookie = Cookie()._get(CONFIG.CACHE.TAGS.COOKIEAUTH);

  const headers = () => {
    // storage.exists(CONFIG.CACHE.TAGS.AUTH) ? storage.get(CONFIG.CACHE.TAGS.AUTH) :
    axios.defaults.headers.authorization = (authCookie && authCookie.length > 10) ? authCookie : false;

    // window.gtag('get', 'G-7DGG6YVBRZ', 'client_id', (clientId) => {
    //   axios.defaults.headers['gtag_client_id'] = clientId
    // })
    //
    // window.gtag('get', 'G-7DGG6YVBRZ', 'session_id', (sessionId) => {
    //   axios.defaults.headers['gtag_session_id'] = sessionId
    // })
  }

  const _setHeaders = (headers) => {
    axios.defaults.headers.authorization = headers
  }

  const _build = (path) => {
    return CONFIG.API.REST.API_URL + lang + CONFIG.COMMON.DS + path;
  }

  const _get = (path) => {
    return axios.get(path )
  }
  const _post = (path, data, headers = {}) => {
    return axios.post(path, data, headers)
  }
  const _put = (path, data) => {
    return axios.put(path, data)
  }
  const _delete = (path, data) => {
    return axios.delete(path, data)
  }
  const _create = () => {
    return axios.create()
  }

  useEffect(() => {
    headers();
  })
  return {
    _build,
    _get,
    _post,
    _put,
    _delete,
    _create,
    _setHeaders
  }
}
export default Request