import React, { useState, useEffect, createContext } from 'react';
export const ModalsContext = createContext();

export const ModalsProvider = (props) => {
  const [modalActive, setModalActive]     = useState(false)
  const [modalLoading, setModalLoading]   = useState(true)

  const [modal, setModal]                 = useState(false)
  const [snackbar, setSnackbar]           = useState(false)

  const modalStyle = (action) => {
    if (action) {
      document.body.classList.add('modal-active')
    } else {
      document.body.classList.remove('modal-active')
    }
  }

  const callResponse = (data, snackbarMode = false) => {
    if (snackbarMode && data.statusCode) {
      setModal(false)
      setSnackbar(data)
    } else {
      setModal({component: 'Response', data, styles: {inner: `fixed-mobile`}})
      setModalActive(true)
    }
  }
  
  const callLoading = () => {
    if (modal?.styles?.inner) {
      setModal({styles: modal.styles})
    } else {
      setModal(false)
    }
    setModalLoading(true)
  }

  const destroy = (snackbarMode) => {
    if (typeof snackbarMode == "boolean" && snackbarMode === true) {
      return setSnackbar(false)
    }
    setModal(false)
    setModalLoading(true)
    setModalActive(false)
  }

  const callModal = (theProps) => {
    // console.log(theProps)
    let processedProps = {...theProps}
    processedProps.callback = {...theProps.callback, ...{destroy}}
    processedProps.styles = {...theProps.styles, ...{inner: `fixed-mobile ${theProps.styles?.inner}`}}
    setModal(processedProps)
    setModalActive(true)
    setModalLoading(false)
    if (theProps.bodyOffActive === true) {
      modalStyle(false)
    }
  }

  useEffect(() => {
    // console.log('bodyStyleChange')
    modalStyle(modalActive)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalActive])

  return (
    <ModalsContext.Provider value={{modalActive, modalLoading, modal, callModal, callResponse, callLoading, snackbar, destroy}}>
      {props.children}
    </ModalsContext.Provider>
  )
}
export default ModalsProvider;