import React from 'react'
const ProveLoan = (props) => {
    // let spaceLink = `https://space.ge/space-loan-mymarket/?utm_source=MyMarket&utm_medium=Button&utm_campaign=SpaceXMymarket&utm_content=Button+1`
    const processLink = (e) => {
		let tbcLink = `https://tbccredit.ge/e-commerce/mymarket?utm_source=mymarket_detailedpage_popup&utm_medium=button&utm_campaign=tbccredit_mymarket&product=${props.data.product.title + (parseFloat(props.data.product.price) > 99.99 ? '&price=' + props.data.product.price : '')}`
		if (props.data.resolution) {
			window.open(tbcLink, '_blank').focus();
		} else {
			props.callback(e, 'callTbcCreditForm', true)
		}
	}
    return (
        <div className="custom-modal-wrapper">
            <h2 className="font-size-20px font-base text-dark mb-8px text-center pt-24px">{props.langs.NM_LoanTitle}</h2>
            <div className="font-size-14 font-base text-dark-gray text-center">{props.langs.NM_LoanDescr}</div>
            <div className="mt-32px px-12px">
                <div className="content pb-24px row">
                    <div className="col-12  px-6px">
                        <div className="px-22px pb-32px pt-48px d-flex flex-column justify-content-center align-items-center border-radius-md border">
                            <div className="icon svg-i v2-tbc "></div>
                            <div className="mt-16px  pb-16px  border-bottom px-8px">
                                {props.langs.NM_TbcLoan}
                            </div>
                            <div className="font-size-14 font-base text-dark text-center  pt-16px ">
                                {props.langs.NM_TbcLoanText}
                            </div>
                            <div className="text-decoration-none d-block mt-64px bg-blue-tbc border-radius-10 cursor-pointer" 
                                onClick={processLink}>
                                <div className="d-flex align-items-center justify-content-center px-20px h-46px">
                                    <div className="d-flex justify-content-center align-items-center border-right border-white pr-12px">
                                        <i className="svg-i tbc-button-icon-i flex-shrink"></i>
                                    </div>
                                    <div className="w-100 d-flex px-20px justify-content-center align-items-center border-radius-10  font-size-13 font-size-sm-13 font-weight-bold text-white font-bold">
                                        {props.langs.NM_ProveTbcLoan}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12 col-md-6 px-6px mt-24px mt-md-0">
                        <div className="px-22px pb-32px pt-48px d-flex flex-column justify-content-center align-items-center border-radius-md border">
                            <div className="icon svg-i v2-space "></div>
                            <div className="mt-16px  pb-16px  border-bottom font-bold-caps px-8px">
                                {props.langs.NM_SpaceLoan}
                            </div>
                            <div className="font-size-14 font-base-caps text-dark text-center  pt-16px ">
                                {props.langs.NM_SpaceLoantext}
                            </div>
                            <a className="text-decoration-none d-block mt-64px bg-space border-radius-10" href={spaceLink} target='_blank' rel="noopener noreferrer" onClick={(e) => props.callback(e, 'destroy')}>
                                <div className="  d-flex align-items-center  justify-content-center px-20px  h-46px ">
                                    <div className="d-flex justify-content-center align-items-center  pr-12px">
                                        <i className="svg-i tbc-button-icon-i flex-shrink"></i>
                                    </div>
                                    <div className="w-100 d-flex px-20px justify-content-center align-items-center border-radius-10  font-size-13 font-size-sm-13 font-weight-bold text-white font-bold">
                                        {props.langs.NM_ProveSpaceLoan}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ProveLoan
