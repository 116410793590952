import React, { useContext } from "react";
import { SyncContext } from 'contexts/sync';
import { AppDataContext } from 'contexts/appData';
import { HistoryContext } from 'contexts/history';
import { UserContext } from 'contexts/user';
import { RouteList } from 'modules';
import { Trackers } from 'libs';
const Logout = (props) => {
  const { api, getLang, CONFIG, Functions } 	= useContext(AppDataContext);
  const { syncChanges }	= useContext(SyncContext);
  const { getPathQuery, getPathname } = useContext(HistoryContext);
  const { user } = useContext(UserContext);

  const clearStorage = () => {
    //console.log(user)
    localStorage.removeItem('SyncData'); 
    setTimeout(() => {
      Trackers.signOutClickStream(user);
      const auth = api.cookie._get(CONFIG.CACHE.TAGS.COOKIEAUTH);
      api.unsetStorageData();
      let pathname = getPathname().split("/").filter(Boolean);
      let matchPath = RouteList.find((route) => {
        if (route.path.includes(pathname[1])) {
          let routeNames = route.path.split("/").filter((a) => a && a !== '*')
          if (routeNames.length === pathname.length && routeNames[1] === pathname[1]) {
            return routeNames;
          }
        }
        return false;
      });
      // console.log(matchPath);
      Functions.redirectWindow(false, getLang, false, 
        `${CONFIG.HOST.appUrl}${(matchPath && matchPath.private) ? getLang : getPathQuery()}`,`&AccessToken=${auth}`
      );
    }, 100);
  }

  const callLogout = async (e) => {
		e && (e.preventDefault() || e.stopPropagation());
    if (props.onClick){
      props.onClick();
    }
    await syncChanges().then(clearStorage);
	}

  return (
    <props.element className={props.className} onClick={callLogout}>
      {props.children}
    </props.element>
  )
}

export default Logout;