import React, { useState, useEffect, useContext, createContext } from 'react';

import { HistoryContext } from './history';
import { UserContext } from './user';

import {Trackers} from 'libs';
import moment from 'moment';
import { AppDataContext } from './appData';

export const SyncContext = createContext();

export const SyncProvider = (props) => {
	const {api, appData, CONFIG} = props;
	const syncConfig 				= CONFIG.CACHE.SYNC;
	const { params, getPathname }	= useContext(HistoryContext);
	const { user }	= useContext(UserContext);
	const { getLang } = useContext(AppDataContext)

	const [syncData, setSyncData] = useState({
		Cart: { List: [], Total: 0 }, 
		Favorites: { List: [], Total: 0 }
	});

	const buildTotalCount = (list = []) => {
		return list.length > 0 ? list.filter((e) => (e.Quantity > 0 || e.Action === 1)).length : 0;
	}

	const buildData = (list = [], total = null) => {
		return { 
			List: list, 
			Total: total !== null ? total : buildTotalCount(list) 
		}
	}

	const getNewTime = (date) => {
		return parseInt(moment(date).format('X'))
	}

	const buildSyncData = (cartList = [], cartTotal = null, favList = [], favTotal = null) => {
		return {
			Cart: buildData(cartList, cartTotal), 
			Favorites: buildData(favList, favTotal)
		}
	}

	const setStorageData = (data) => {
		api.storage.set(CONFIG.CACHE.TAGS.SYNC, JSON.stringify(data));
	}

	const getStorageData = (name) => {
		return api.storage.get(name) ? JSON.parse(api.storage.get(name)) : []
	}

	const setNewSyncData = (data) => {
		setSyncData(data)
		setStorageData(data)
	}

	const processData = () => {
		// ProcessOld
		if (api.storage.get(CONFIG.CACHE.TAGS.CART) || api.storage.get(CONFIG.CACHE.TAGS.FAV)) {
			let processedData = buildSyncData(
				getStorageData(CONFIG.CACHE.TAGS.CART),
				CONFIG.CACHE.TAGS.CART.length,
				getStorageData(CONFIG.CACHE.TAGS.FAV),
				CONFIG.CACHE.TAGS.FAV.length
			);
			api.storage.remove(CONFIG.CACHE.TAGS.CART);
			api.storage.remove(CONFIG.CACHE.TAGS.FAV);
			api.storage.remove(CONFIG.CACHE.SYNC.NAME);
			
			setStorageData(processedData);
			setSyncData(processedData)
		} else if (api.storage.get(CONFIG.CACHE.TAGS.SYNC)) {
			let processedData = getStorageData(CONFIG.CACHE.TAGS.SYNC)
			if (processedData && processedData.Cart && processedData.Cart.List && processedData.Cart.List.List) {
				processedData = {
					Cart: { List: [], Total: 0 }, 
					Favorites: { List: [], Total: 0 }
				}
			}
    	setSyncData(processedData)
  	} else {
			setStorageData(syncData);
		}
	}

	const mapper = (data) => {
		let cart = data.Cart.map((e) => (
			{ PrID: e.product_id, Quantity: e.quantity, Sync: getNewTime(data.LastSyncDate) }
		));
		let fav = data.Favorites.map((o) => (
			{ PrID: o, Action: 1, Sync: getNewTime(data.LastSyncDate) }
		));
		return buildSyncData(cart, cart.length, fav, fav.length)
	}

	const getType = (type) => {
		return type ? 'Cart' : 'Favorites'
	}

	const getIndex = (list, PrID) => {
		return list.findIndex(e => (e.PrID === PrID || e === PrID))
	};

	const toggleItem = (type = null, PrID, product = null) => {
		if(!user) {
			window.location.href = `${CONFIG.HOST.authUrl}${getLang}/user/login?Continue=${encodeURIComponent(window.location.href)}`;
			return;
		}

		if (!PrID || type === null) {return false;}
		let dataToSend, log;
		let data = {...syncData};
		let listType = getType(type);
		let index = getIndex(data[listType].List, PrID);
		if (index >= 0) {
			let entry = data[listType].List[index];
			let entryProperty = 'Quantity' in entry ? 'Quantity' : 'Action';
			let check = entry[entryProperty] >= 1
			entry[entryProperty] = check ? 0 : 1;
			entry['Sync'] = null;
			runTrackers(check ? true : false, type, product)
			dataToSend = entry;
			log = type ?
				(check ? syncConfig.LOG.REMOVE_CART : syncConfig.LOG.NEW_CART) :
				(check ? syncConfig.LOG.REMOVE_FAV : syncConfig.LOG.NEW_FAV);
		} else {
			if (data[listType].List.length < (type ? CONFIG.CACHE.SYNC.CART_LIMIT : CONFIG.CACHE.SYNC.FAV_LIMIT)) {
				dataToSend = type ? { PrID: PrID, Quantity: 1, Sync: null } : { PrID: PrID, Action: 1, Sync: null };
				log = type ? syncConfig.LOG.NEW_CART : syncConfig.LOG.NEW_FAV;
				data[listType].List.push(dataToSend)
				runTrackers(true, type, product)
			}
		}
		data[listType].Total = buildTotalCount(data[listType].List)
		setNewSyncData(data)
		if (user) {
			syncChanges(dataToSend, listType, log)
		}
	}

	const runTrackers = (action, type, product) => {
		if (!product) {return false}
		let data = {
			PrID: product.product_id,
			PrName: product.title,
			PrCategory: appData.Cats.List[product.catId] ? appData.Cats.List[product.catId].Title : product.catId,
			PrQuantity: 1,
			PrPrice: product.price,
			PrPriceInUsd: null,
			PrCode: product.product_code
		}

		// add
		if (action) {
			if (type) {
				// cart
				Trackers.pixelAddToCart(product)
				Trackers.connectAddToCart(data)
				Trackers.gaAddProduct(data)
				Trackers.addToCartClickStream(data)


			} else {
				// favs
				Trackers.connectAddToWishList(data)

			}
		} else {
			// remove
			if (type) {
				// cart
				Trackers.connectRemoveFromCart({PrID: data.PrID})
			} else {
				// favs
				Trackers.connectRemoveFromWishList({PrID: data.PrID})
			}
		}
	}

	const checkItem = (type = null, PrID) => {
		if (!PrID || type === null) {return false;}
		let listType = getType(type)
		let index = getIndex(syncData[listType].List, PrID)

		return (index >= 0 && (syncData[listType].List[index].Quantity > 0 || syncData[listType].List[index].Action > 0)) ? true : false
	}

	const getProductQuantity = (PrID) => {
		let index = getIndex(syncData.Cart.List, PrID);
		return index >= 0 ? syncData.Cart.List[index].Quantity : 0
	}

	const resetSyncData = (cart = true, fav = true) => {
		let data = buildSyncData(
			cart ? [] : syncData.Cart.List, cart ? 0 : syncData.Cart.Total, 
			fav ? [] : syncData.Favorites.List, fav ? 0 : syncData.Favorites.Total
		)
		setNewSyncData(data)
		syncChanges(null, null, syncConfig.LOG.RESET)
	}

	const clearCart = (listToRemove, clearAll = false) => {
		let newData = {...syncData}
		newData.Cart.List = newData.Cart.List.map(inCart => {
			let remap = { PrID: inCart.PrID, Quantity: 0, Sync: null }
			return (clearAll || getIndex(listToRemove, inCart.PrID) > -1) ? remap : inCart
		})
		newData.Cart.Total = buildTotalCount(newData.Cart.List)
		setNewSyncData(newData);
		let sncData = [...newData.Cart.List].filter(e => e.Quantity === 0)
		if(user) {
			syncChanges(sncData, 'Cart', syncConfig.LOG.CLEAR_CART)
		}
	}

	const changeQuantity = (PrID, i) => {
		let index = getIndex(syncData.Cart.List, PrID)
		let newData = {...syncData}
		if (newData.Cart.List[index].Quantity + i >= 0) {
			newData.Cart.List[index].Quantity += i;
		} else {
			newData.Cart.List[index].Quantity = 0
		}
		newData.Cart.List[index].Sync = null;
		
		setNewSyncData(newData);
		if(user) {
			syncChanges(newData.Cart.List[index], 'Cart', syncConfig.LOG.EDIT_QUANTITY)
		}
	}

	const syncChanges = async (product = null, type = null, log = null) => {
		let processData = {}
		if (product && type !== null) {
			processData[type] = Array.isArray(product) ? product : [product]
		} else {
			processData.Cart = syncData.Cart.List.slice(0, syncConfig.CART_LIMIT);
			processData.Favorites = syncData.Favorites.List.slice(0, syncConfig.FAV_LIMIT)
		}
		if (log) {
			processData.Log = log
		}
		updateData(processData)
	}

	const updateData = async (dataToSent) => {
		let apiData = await api.syncUser(dataToSent)
		if (apiData.statusCode) {
			setNewSyncData(mapper(apiData.data))
			// updateLastSync(apiData.data.LastSyncDate)
		}
	}

	useEffect(() => {
		processData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (params && params.StatusID && params.OrderID && params.Operator && (parseInt(params.StatusID) === 3 || parseInt(params.StatusID) === 2) && getPathname().indexOf("orders") !== -1) {
   		resetSyncData(true, false)
		}
		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	useEffect(() => {
		// console.log(user)
		if (user) {
			syncChanges(null, null, syncConfig.LOG.USER_SYNC)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.ID])

	return (
		<SyncContext.Provider value={{
			syncData,
			toggleItem,
			checkItem,
			getProductQuantity,
			changeQuantity,
			clearCart,
			syncChanges
		}}>
			{props.children}
		</SyncContext.Provider>
	)
}
export default SyncProvider;