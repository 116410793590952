import React from 'react'
import { Modal } from 'components'

const Component = (props) => {
    // props
    // statusCode
    // statusMessage

    const close = () => {
        props.close();
    }

    const Header = () => {
        return (
            <div className="py-32px text-center">
                {!props.statusCode ? 
                    <div className="p-12px">
                        <i className="svg-i error-message mx-auto"></i>
                    </div>
                    :
                    <div className="p-12px">
                    <i className="svg-i success-messages mx-auto"></i>
                </div>
                }
            </div>
        )
    }

    const Content = () => {
        return (
            <div className="pb-48px">
                <div id="" className="font-size-16 font-base text-blue-gray text-center">
                    {props.statusMessage ? props.statusMessage : (props.statusCode ? props.langs.SuccessMessage : props.langs.ErrorMessage)}
                </div>
                <div className="" id=""></div>
            </div>
        )
    }

    const Actions = () => {
        return (
            <div className="pb-28px mt-0 d-flex justify-content-center">
                <button type="button" className="font-size-14 font-base-caps px-64px py-16px btn bg-middle-gray  mx-8px text-blue-gray" onClick={e => close()}>{props.langs.Close}</button>
            </div>
        )
    }

    return <Modal {...{Header, Content, Actions, close, classInner: "fixed-mobile"}} />
}

export default Component
