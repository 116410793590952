import React from "react";
import { Parser } from 'components';
const First = (props) => {
  const {langs, cancel, nextStep} = props;
  return (
    <div>
      <h2 className="font-size-18 font-bold text-center pt-28px pb-36px text-nLight-dark ">
         <Parser html={langs.NM_ConfirmReturn} />
      </h2>
      <div>
        <div className="mb-16px px-20px px-md-100px d-flex justify-content-center">
          <button className="return-item-btn-actions font-size-16 w-100 font-medium font-weight-normal py-16px btn bg-middle-gray text-dark d-block mr-12px"
            type="button" onClick={nextStep}>
            {langs.Yes}
          </button>
          <button className="return-item-btn-actions font-size-16 w-100 font-medium font-weight-normal py-16px btn bg-middle-gray text-dark d-block "
            type="button" onClick={cancel}>
            {langs.No}
          </button>
        </div>
      </div>
    </div>
  )
}
export default First