import React from 'react';

export default function NotificationCard() {
	return (
		<div className="notification-card-placeholder d-flex">
			<div className="d-flex rounded-circle animate"></div>
			<div className="d-flex ml-15px flex-1 flex-column">
				<div className="animate d-flex border-radius-sm mt-5px" style={{width: '100%', height: '10px'}}></div>
				<div className="animate d-flex border-radius-sm mt-10px" style={{width: '40%', height: '10px'}}></div>
			</div>
			<div className="d-flex ml-15px">
				<div className="animate d-flex border-radius-sm mt-5px" style={{width: '80px', height: '10px'}}></div>
			</div>
		</div>
	)
};