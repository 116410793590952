import React from 'react';

const ToastContainer = ({ children }) => {
	return (
			<div className="d-flex flex-column position-fixed bottom-10px right-10px z-index-9999999999">
				{children}
			</div>
	);
};

export default ToastContainer;