import { CONFIG } from "core";
import { useState, useEffect, useContext } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import { AppDataContext } from 'contexts/appData';
import { UserContext } from 'contexts/user';
import { HistoryContext } from 'contexts/history';
import { BannersContext } from 'contexts/banners';
import { ModalsContext } from 'contexts/modals';

import { Trackers } from 'libs';
import StaticLinks from './StaticLinks'
import Footer from './Footer';

const FooterLib = (props) => {
	const {api, CONFIG, appData, getLang, langs, Functions} = props

	const { user, getUserData, notifications } = useContext(UserContext);
	const { params, getPathname, getPagename, assignParam } = useContext(HistoryContext)
	const { 
		prefix, 
		getMainParentCatID, 
		// checkLocalStorageKey 
	} = useContext(AppDataContext);
	const { resolution } = useContext(BannersContext)
	const { callModal, callResponse } = useContext(ModalsContext)

	const [categoryList, setCategoryList] = useState(null);
	// const [catfish, setCatfish] = useState(false);
	// const [securityFishing, setSecurityFishing] = useState(false);
	const [checkTrack, setCheckTrack] = useState(false);
	const [checkLiveChat, setCheckLiveChat] = useState(false);
	const [cookieAgreement, setCookieAgreement] = useState(false);
	// const [showAppModal, setShowAppModal] = useState(false);
	// const [specialPopup, setSpecialPopup] = useState(false);

	const scrollToTop = () => {
		scroll.scrollToTop({
			smooth: true,
			duration: 100,
			delay: 0,
		});
	}

	const openAddDialog = (e) => {
		e.preventDefault() || e.stopPropagation();
		if (!user) {
			Functions.redirectWindow(true, getLang, true, `${CONFIG.HOST.appUrl}${getPathname().substring(1)}`);
			return;
		}
		callModal({
			component: 'NewProduct',
			styles: {inner: "new-product"}
		})
	}

	const changeLang = (lang) => {
		return `${window.location.href.replace(`/${getLang}`, `/${lang}`)}`
	}

	const closeModal = (name, state) => {
		state(false)
		api.storage.set(name, JSON.stringify({ date: new Date(), status: 0 }))
	}

	// const closeCatfish = () => {
	// 	closeModal('Catfish', setCatfish)
	// }

	const processCookieAgreement = () => {
		api.storage.set(CONFIG.CACHE.TAGS.COOKIE_POLICY, JSON.stringify(true))
		setCookieAgreement(false)
	}

	// const closeAppModal = () => {
	//     setShowAppModal(false)
	//     api.storage.set("SwitchToApp", JSON.stringify({ date: new Date(), status: 0 }))
	//     document.body.classList.remove('modal-active')
	// }

	// const closeSpecialPopup = () => {
	//     setSpecialPopup(false)
	//     api.storage.set("SpecialPopup", JSON.stringify({ date: new Date(), status: 0 }))
	// }

	const processCategories = () => {
		let result = [];
		let n = 0
		result[n] = []
		if (appData.Cats.Hierarchy[0] !== null && appData.Cats.Hierarchy[0] !== undefined) {
			appData.Cats.Hierarchy[0].map((CatID, i) => {
				result[n].push({ CatID: CatID, Title: appData.Cats.List[CatID].Title })
				if ((i + 1) % 6 === 0) { n++; result[n] = [] }
				return 0;
			})
		}
		setCategoryList(result)
	}

	// const tbcConnectStatus = async () => {
	// 	const response = await api.checkTbcConnect({
	// 		siteId: CONFIG.COMMON.SITE_ID,
	// 		userId: user.ID,
	// 		withoutHTML: 1
	// 	})
	// 	if (response.data) {
	// 		tbcConnectDependancies(response.data)
	// 		if (response.data.showDialog === 1) {
	// 			callModal({
	// 				component: 'TBCConnect',
	// 				data: {
	// 					text: response.data.dialog,
	// 					button: resolution[0] <= CONFIG.TOSPLIT.PRODUCT_BANNERS.RESPONSIVE_RESOLUTION,
	// 				},
	// 				styles: {
	// 					container: `tbc-connect-container ${resolution[0] <= CONFIG.TOSPLIT.PRODUCT_BANNERS.RESPONSIVE_RESOLUTION ? "full-height" : ""}`,
	// 					dialog: "tbc-connect",
	// 					inner: "",
	// 					close: "d-none"
	// 				},
	// 				backdrop: true,
	// 				bodyOffActive: true,
	// 				callback: {
	// 					success: (e) => onTbcResponse(e)
	// 				}
	// 			})
	// 		} else if (response.data.agreed === 1) {
	// 			api.storage.set('TBCConnect', JSON.stringify({ agreed: 1 }))
	// 		}
	// 	}
	// }
	
	// const onTbcResponse = async (value) => {
	// 	const response = await api.sendTbcConnectData({
	// 		siteId: CONFIG.COMMON.SITE_ID,
	// 		userId: user.ID,
	// 		agreed: value,
	// 		popup: true
	// 	})
	// 	if (response && response.data.agreed === 1) {
	// 		api.storage.set('TBCConnect', JSON.stringify({ agreed: 1 }))
	// 	}
	// }

	const processModals = () => {
		// if (checkLocalStorageKey("SecurityFishing", 8) && !securityFishing) {
		// 	setSecurityFishing(true)
		// 	callModal({
		// 		component: 'SecurityFishing',
		// 		callback: {
		// 			preDestroy: closeModal('SecurityFishing', setSecurityFishing)
		// 		}
		// 	})
		// }
		// setSpecialPopup(checkLocalStorageKey("SpecialPopup", 1))
		// if (resolution[0] <= CONFIG.TOSPLIT.PRODUCT_BANNERS.RESPONSIVE_RESOLUTION) {
		// 	setCatfish(checkLocalStorageKey("Catfish", 6))
		// 	// setCatfish(checkLocalStorageKey("setShowAppModal", 24))
		// }
		if (!api.storage.tryParse(CONFIG.CACHE.TAGS.COOKIE_POLICY)) {
			setCookieAgreement(true)
		}
	}

	const trackConnect = () => {
		let page = getPagename()
    let mainCategory = getMainParentCatID(params.CatID)
    if (page === 'search' && params.CatID && !params.Keyword) {
      let categoryName = appData.Cats.List[params.CatID] ? appData.Cats.List[params.CatID].Title : ''
      let mainCategoryName = (mainCategory && appData.Cats.List[mainCategory]) ? appData.Cats.List[mainCategory].Title : ''
      Trackers.connectPageView({ categoryName, mainCategoryName })
    } else if (page !== 'pr' && page !== 'product' && !(page === 'search' && params.Keyword)) {
      Trackers.connectPageView()
    }
	}

	const trackViews = () => {
    if (window._paq) {
      trackConnect()
			if (!params.AccessToken && !params.Username && !params.Password) {
				Trackers.gaView()
				Trackers.tagView()
				Trackers.pixelView()
				// Trackers.pageViewClickStream()
			}
    }
	}

	useEffect(() => {
		if (params.StatusID && params.OrderID && params.Operator) {
			let tracker = false;
			if (parseInt(params.StatusID) === 3 || parseInt(params.StatusID) === 2) {
				getUserData()
				tracker = true;
				callResponse({ statusCode: true, statusMessage: false });
			} else {
				callResponse({ statusCode: false, statusMessage: false });
			}
			assignParam({ StatusID: null, OrderID: null, Operator: null, Payment: tracker ? 'BankSuccess' : 'BankFailure' }, true)
		} else {
			const timer = setTimeout(() => {
				trackViews()
			}, 500)
			return () => clearTimeout(timer);
			// if (!getPagename() && !Functions.checkObjEmpty(params)) {
			// 	console.log('homepage')
			// 	trackViews()
			// } else if (Functions.checkObjEmpty(params) && (Functions.objLength(params) > 1 || (Functions.objLength(params) === 1 && !params.Page))) {
			// 	console.log('params changed')
			// 	console.log(params)
			// 	trackViews()
			// } else if (getPagename() && (!Functions.checkObjEmpty(params) || Functions.objLength(params) === 1)) {
			// 	console.log('page changed')
			// 	console.log(getPagename())
			// 	trackViews()
			// }
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, getPagename()])

	useEffect(() => {
		processCategories()
		processModals()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (notifications && notifications.show_popup) {
			callModal({
				component: 'ReminderOnline',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications])

	const tbcConnectDependancies = (data) => {
		Trackers.connectSetCustomer({data, user})
		api.storage.set('TBCConnect', JSON.stringify({ agreed: data.agreed }))
	}

	useEffect(() => {
		if (user && user.ID && CONFIG.HOST.branch === 'PRODUCTION'){
			if (!checkTrack) {
				setCheckTrack(true)
				// tbcConnectStatus()
			}
			// Trackers.liveCallerCheck()
			// let key = JSON.parse(api.storage.get('TBCConnect'))
			// if (!key || parseInt(key.agreed) === 0) {
				
			// } else {}
		}
		else if (user && !checkLiveChat) {
			setCheckLiveChat(true)
			Trackers.liveCallerCheck()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user])

	const staticLinks = StaticLinks({langs, CONFIG, getLang, changeLang})
	return <Footer {...{
		langs, getLang, prefix,
		CONFIG,
		getPathname,
		staticLinks,
		scrollToTop,
		categoryList,
		openAddDialog,
		// catfish,
		// closeCatfish,
		closeModal,
		cookieAgreement,
		processCookieAgreement,
		resolution
	}} />
}

export default FooterLib
