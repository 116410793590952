import React from 'react'

const Component = (props) => {
    return (
        <div className="">
            <h5 className="m-0 font-bold-caps font-size-21 font-weight-bold text-dark">
                {props.langs.NM_installment_title}
            </h5>
            {props.currentStep === 0 &&
                <span className="font-base font-size-12 font-weight-normal text-blue-gray">
                    {props.langs.RequestLoanDescription}
                </span>
            }
        </div>
    )
}

export default Component