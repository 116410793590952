const checkAvailableMethods = (props) => {
    // console.log(props)
    let amount              = props.amount
    let methods             = props.methods
    let userData            = props.userData   
    let result              = []

    if (!methods || methods.length <= 0 || !userData || !userData.ID) {
        return result
    }

    const testUserIDS       = [
        2192429,
        4111633,
        3747191
    ]

    const checkBalance = () => {
        return parseFloat(userData.AmountData.Amount) >= parseFloat(amount) ? true : false
    }

    const checkPromotions = () => {
        return (userData.PromPackageServices !== null && userData.PromPackageServices.length > 0) ? true : false
    }

    const checkTesting = () => {
        return testUserIDS.includes(parseInt(userData.ID))
    }
    
    methods.map((method, i) => {
        if (method.procedures.length > 0) {
            method.procedures.map((procedure) => {
                switch (procedure) {
                    case 'checkBalance':
                        method.checkBalance = checkBalance()
                    break;
                    case 'inTesting':
                        if (!checkTesting()) {
                            delete methods[i]
                            methods.filter(o => o)
                        }
                    break;
                    case 'checkPromotions':
                        method.checkPromotions = checkPromotions()
                    break;
                    default:
                        return method
                }
                return method
            })
        }
        return method
    })

    return methods;
    
}

export default checkAvailableMethods