import React from 'react'
import {Modal} from 'components'
import Select from "react-select";
const Component = (props) => {


    const close = () => {
        props.close();
    }

    const statusChange = () => (
        <div className="custom-modal-wrapper">
                <div className="py-24px px-m-84px">
                    <i className="svg-i send-offer-i mx-auto"></i>
                    <h2 className="font-size-18 font-size-m-21 font-bold-caps text-dark mt-16px text-center">{props.langs.NM_ChangeStatus}</h2>
                    <div className="select-box mt-32px mb-40px px-40px">
                    <Select
                            name="statuses"
                            className="sg-selectbox-container text-nowrap w-100"
                            classNamePrefix="sg-selectbox"
                            isSearchable={false}
                            noOptionsMessage={() => props.langs.NM_NothingFound}
                            options={props.StatusList}
                            value= {props.StatusList.find(o => o.value === props.StatusID)}
                            onChange={(value) => {
                                props.setStatusID(value.value)
                            }}
                        />
                    </div>
                    <button className="btn font-size-14 font-bold-caps text-white h-60px d-flex align-items-center justify-content-center bg-primary w-100" onClick={()=>{props.saveStatusID(); close()}}>{props.langs.Save}</button>
                </div>
        </div>
    )

    return <Modal {...{customInner: true, Component: statusChange, close, classInner: "fixed-mobile"}} />
}

export default Component