import React from 'react'
const View = (className = '') => (
  <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center  ${className}`}>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M1.3125 7.99998C1.3125 11.68 4.29917 14.6666 7.97917 14.6666C11.6592 14.6666 14.6458 11.68 14.6458 7.99998C14.6458 4.31998 11.6658 1.33331 7.97917 1.33331C4.29917 1.33331 1.3125 4.31998 1.3125 7.99998ZM6.6725 10.4666H5.80583C5.46583 10.4666 5.3325 10.34 5.3325 10.02V5.97998C5.3325 5.65998 5.46583 5.53331 5.80583 5.53331H6.66583C7.00583 5.53331 7.13917 5.65998 7.13917 5.97998V10.02H7.14583C7.14583 10.34 7.0125 10.4666 6.6725 10.4666ZM10.1925 10.4666H9.3325C8.9925 10.4666 8.85917 10.34 8.85917 10.02V5.97998C8.85917 5.65998 8.9925 5.53331 9.3325 5.53331H10.1925C10.5325 5.53331 10.6658 5.65998 10.6658 5.97998V10.02C10.6658 10.34 10.5325 10.4666 10.1925 10.4666Z" fill="#A4AEC1"/>
</svg>


  </span>
)

export default View