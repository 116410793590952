import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select'
import { AppDataContext } from 'contexts/appData';
import { ModalsContext } from 'contexts/modals';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FocusError } from 'components';

const AddressForm = (props) => {
	const { langs, api, appData } = useContext(AppDataContext);
	const { callResponse } = useContext(ModalsContext);
	let statusCode = false;

	const findSelectedValue = () => {
		let selectedValue = appData.Locs.SelectList.filter((option) => parseInt(option.value) === props.item.city)
		return selectedValue && selectedValue.length === 1 ? selectedValue[0] : ''
	}

	const getValidationSchema = (validationSchema = {}) => {
		let validationSchemaList = {
			LocID: Yup.number()
				.required(`${langs.LocExTitle}`),
			FullName: Yup.string()
				.min(2, `${langs.WriteFullName}`)
				.max(50, `${langs.WriteFullName}`)
				.required(`${langs.WriteFullName}`),
			Address: Yup.string()
			    .min(5, `${langs.WriteDetLoc}`)
			    .max(200, `${langs.WriteDetLoc}`)
			    .required(`${langs.WriteDetLoc}`),
			PersonalNumber: Yup.string()
				.matches(/^[0-9]+$/, `${langs.IncorrectFormat}`)
				.test('len', `${langs.IncorrectFormat}`, 
					(val) => val && ((parseInt(props.Type) === 0 && val.length === 11) || (parseInt(props.Type) === 1 && val.length >= 9 && val.length <= 11))
				) 
				.required(`${langs.WritePersonalNumber}`),
			Phone: Yup.string()
				.matches(/^[0-9]+$/, `${langs.IncorrectFormat}`)
				.test('len', `${langs.IncorrectFormatOfPhoneNumber}`, val => val && val.length === 9)
				.required(`${langs.WriteYourPhone}`),
			SecondPhone: Yup.string().notRequired()
				.matches(/^[0-9]+$/, `${langs.IncorrectFormat}`)
				// .test('len', `${langs.IncorrectFormatOfPhoneNumber}`, val => val && val.length === 9)
		}
		return Yup.object().shape({...validationSchema, ...validationSchemaList })
}

	const [config, setConfig] = useState({
		id: 'address',
		title: `${langs.Address}`,
		initialValues: {
			LocID: props.item && props.item.city ? props.item.city : '',
			FullName: props.item && props.item.fullname ? props.item.fullname : '',
			Address: props.item && props.item.address ? props.item.address : '',
			PersonalNumber: props.item && props.item.personalNumber ? props.item.personalNumber : '',
			Phone: props.item && props.item.phone ? props.item.phone : '',
			SecondPhone: props.item && props.item.secondPhone ? props.item.secondPhone : '',
		},
		validationSchema:getValidationSchema(),
		enableReinitialize: true,
	});

	const handleAddress = (values, { setSubmitting, resetForm }) => {
		setSubmitting(true);
		const addressAction = async () => {
			let action;
			if (props.item.id) {
				action = await api.putAddressUpdate(props.item.id, values);
			} else {
				values["UserTypeID"] = [0, 1].includes(parseInt(props.Type)) ? parseInt(props.Type) : 0
				action = await api.postAddAddress(values);
			}

			statusCode = action.statusCode === 200 ? true : false
			callResponse({ statusCode: statusCode, statusMessage: action.statusMessage });
			setSubmitting(false);
			if (action.statusCode === 200) {
				if (props.item.id) {
					props.actionAfterEdit(props.item.id, values)
				} else {
					resetForm();
					props.actionAfterAdd(action.data, values)
				}
			}
		};
		addressAction();
	}
	
	useEffect(() => {
		if (['0', '1'].includes(props.Type)) {
			setConfig({ ...config, validationSchema: getValidationSchema() })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.Type])
	return (
		<>
		<Formik {...config} onSubmit={handleAddress}>
			{
				({ errors, status, touched, isSubmitting, handleChange, setFieldValue }) => {
					const checkNumber = (e) => {
		        e.preventDefault();
		        const { value } = e.target;
		        if (/^\d*\.?\d*$/.test(value.toString())) {
		          setFieldValue(e.target.name, value);
		        }
		      }
					return(
						<Form autoComplete="off">
							<div className="add-address-content right-side border-radius-sm bg-white">
								<div className="p-24px p-md-40px">
									<div className="mb-35px">
										<h1 className="text-dark-market-color font-size-14 font-weight-bold">
											{props.item.id ? `${langs.EditUserAddress}` : `${langs.AddNewUserAddress}`}
										</h1>
									</div>
									<div className="row mt-24px">
										<div className="col-md-12">
											<div className="form-group m-0">
												<label className="font-size-12 text-text_label">
													{parseInt(props.Type) > 0 ? langs.OrgName : `${langs.Name} ${langs.SurName}`}
													<span className="text-blue">*</span>
												</label>
												<Field className="form-control font-base" name="FullName" type="text" />
												<ErrorMessage name="FullName" component="label" className="error text-danger" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 mt-15px">
											<div className="form-group m-0">
												<label className="font-size-12 text-text_label">{langs.LocEx} <span className="text-blue">*</span></label>
												<Select ref={props.cityRef ? props.cityRef : null}
													name="LocID"
													className="border-radius-sm bg-white"
													classNamePrefix="selectbox "
													placeholder=""
													noOptionsMessage={() => "No values available!"}
													options={appData.Locs.SelectList}
													defaultValue={findSelectedValue()}
													onChange={
														option => {
															handleChange("LocID")(option.value.toString());
														}
													}
												/>
												<ErrorMessage name="LocID" component="label" className="error text-danger" />
											</div>
										</div>
										<div className="col-md-6 mt-15px">
											<div className="form-group m-0">
												<label className="font-size-12 text-text_label">
													{parseInt(props.Type) > 0 ? `${langs.IDCode}` : langs.PersonalNumber}
													<span className="text-blue">*</span>
												</label>
												<Field className="form-control font-base" name="PersonalNumber" type="text" maxLength="11" onChange={(e) => {checkNumber(e)}} />
												<ErrorMessage name="PersonalNumber" component="label" className="error text-danger" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 mt-15px">
											<div className="form-group m-0">
												<label className="font-size-12 text-text_label">{langs.PhoneNumber}  <span className="text-blue">*</span></label>
												<Field className="form-control font-base" name="Phone" type="text" maxLength="9" onChange={(e) => {checkNumber(e)}} />
												<ErrorMessage name="Phone" component="label" className="error text-danger" />
											</div>
										</div>
										<div className="col-md-6 mt-15px">
											<div className="form-group m-0">
												<label className="font-size-12 text-text_label">{langs.NM_SecondPhoneNumber}</label>
												<Field className="form-control font-base" name="SecondPhone" type="text" maxLength="9" onChange={(e) => {checkNumber(e)}} />
												<ErrorMessage name="SecondPhone" component="label" className="error text-danger" />
											</div>
										</div>
									</div>
									<div className="row mt-15px">
										<div className="col-md-12">
											<div className="form-group m-0">
												<label className="font-size-12 text-text_label">{langs.Address}   <span className="text-blue">*</span></label>
												<Field className="form-control font-base" name="Address" type="text" />
												<ErrorMessage name="Address" component="label" className="error text-danger" />
											</div>
										</div>
									</div>
								</div>
								<div className="footer-btn p-25px">
									<div className="col-md-12 d-flex justify-content-between">
										<button type="reset" className="btn btn-no-outline hover-underline  font-size-12 text-blue-gray font-base font-weight-normal p-0" >
											{langs.Cancel}
										</button>
										<button type="submit" className="btn bg-gradient border-radius-sm font-size-13 font-weight-bold text-white" disabled={isSubmitting}>{langs.Save}</button>
									</div>
								</div>
							</div>
							<FocusError />
						</Form>
					)
				}
			}
		</Formik>
		</>
	)
} 

export default AddressForm
