
import React from 'react'
const InnerInstallment = (className) => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
       <svg xmlns="http://www.w3.org/2000/svg" width="14.81" height="14.802" viewBox="0 0 14.81 14.802">
            <g id="Group_14059" data-name="Group 14059" transform="translate(-518.1 -543.213)">
                <path id="Stroke_430" data-name="Stroke 430" d="M13.01,5.32a1.639,1.639,0,0,1-.418,1.009l-6.261,6.5a.591.591,0,0,1-.835,0L.173,7.509a.591.591,0,0,1,0-.835L6.679.419A1.642,1.642,0,0,1,7.687,0h4.731a.593.593,0,0,1,.592.592Z" transform="translate(519 544.113)" fill="none" stroke="#bdc4d2" strokeLinecap="round" strokeLinejoin="round" stroke-miterlimit="10" strokeWidth="1.8" />
                <path id="Fill_431" data-name="Fill 431" d="M1.859.929A.929.929,0,1,1,.929,0a.928.928,0,0,1,.929.929" transform="translate(528.293 545.971)" fill="#bdc4d2" />
            </g>
        </svg>
    </span>
)

export default InnerInstallment
