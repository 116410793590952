import React from 'react';
import { Parser } from 'components';
import WarrningImg from '../../../resources/img/icons/warrningsvg.svg';

const Warning = (props) => {

	return (
    <div className="custom-modal-wrapper content-scroll bg-white">
      <div className=" rounded-0 text-decoration-none text-black w-100">
        <div className='p-20px pb-30px'>
          <div className='text-center'>
            <img src={WarrningImg} alt="" />
            <h1 className='pb-24px pt-10px font-size-32'>{props.data.title}</h1>
          </div>
          <div className='text-editor-styles'>
            <Parser html={props.data.text} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Warning