import React, { useState, useEffect } from 'react';
import * as yup from "yup";
import Icons from 'components/icons/Icons';
const Feedback = (props) => {
  const { langs, Close } = props;
 

  return (
  <div className={"livecaller bottom-md-100px position-fixed d-flex flex-column right-md-32px right-0   bottom-0 top-md-auto top-0 left-md-auto left-0 w-basis-md-390px min-width-md-390px max-width-md-390px w-100 rounded-md-desk bg-white livecaller-shadow overflow-hidden z-index-1000"}>
    {/* popup header start */}
    <div className="pt-40px px-16px pb-24px">
    
      <div onClick={Close} title={langs.Close} className="position-absolute right-8px top-8px z-inex-9 cursor-pointer align-items-center justify-content-center">
        <Icons icon="liveCaller_minimize" className="d-flex" />
      </div>
    </div>
    <div className="flex-grow-1">
      <p class="text-center font-bold mb-0 px-40px">{langs.NM_LiveColerFeedback}</p>
      <div className="pt-20px h-100 d-flex flex-column px-20px pb-15px bg-white">
      <div className="mt-md-25px mt-auto">
        <a href="https://www.surveymonkey.com/r/83DHMLK" target="_blank">
          <button className='h-40px text-white font-size-16 font-bold rounded bg-gray-650 border-solid-0 w-100'>{langs.Accept}</button>
          </a>
        </div>
      </div>
    </div>
   
  </div>
  )
}

export default Feedback;
