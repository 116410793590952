import React, {useRef} from 'react'

const ImageSearch = (props) => {
	const imageSearchFile = useRef(null)

	const selectFileForSearch = () => {
		imageSearchFile.current.click()
	}

	const upload = (e) => {
		props.callback(e, 'upload')
		props.callback(null, 'destroy')
	}

	return (
		<div className="mt-64px mt-md-0">
			<input type="file" accept='image/*' id='selectImageForSearch' 
				ref={imageSearchFile} className="d-none" hidden 
				onChange={upload} 
			/>
			<div className="position-relative">
				<div className="popup-content px-md-40px py-md-40px">
					<div className="d-flex flex-column align-items-center border-radius-lg border-market-color border-2px border-dashed-2 px-24px py-24px px-lg-64px py-lg-48px">
						<div className="d-flex justify-content-center">
							<i className="svg-i seacrh-img-i"></i>
						</div>
						<div className="font-bold font-size-14 font-weight-bold text-dark mt-16px mb-32px text-center">
							{props.langs.UploadPhotoForImageSearch}
						</div>
						<button className="btn bg-gradient border-radius-lg font-bold-caps font-size-13 text-white px-24px py-14px" 
							onClick={selectFileForSearch}>
							{props.langs.Upload}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImageSearch