import React, { useState } from 'react'

import { API } from 'services';

import { Modal, Response} from 'components';
import { Phone, Header } from './components';

const Verification = (props) => {
    // console.log(props)
    const api                                               = API();
    const { langs }                                         = props
    
    const [data, setData]                                   = useState(props.data);
    // const [responseModal, setResponseModal]                 = useState(false);
    const [codeSendTimer, setCodeSendTimer]                 = useState(false);

    const close = () => {
        props.close();
    }

    const success = (e) => {
        props.success(e);
        close()
    }

    const productActivationSMS = async (values) => {
        const apiData = await api.sendProductActivationCode(data.productID, { Phone: values.Phone })
        if (apiData.statusCode) {
            setCodeSendTimer(60)
        }
        // setData(values)
    }

    const productFormSMS = async (values) => {
        const apiData = await api.sendUserSMS({ Phone: values.Phone })
        if (apiData.statusCode) {
            setCodeSendTimer(60)
            let newData = {...data}
            newData["smsData"] = apiData.data
            if (values.Phone !== data.finalData.Phone) {
                newData.finalData.Phone = values.Phone
            }
            setData(newData)
        }
    }

    const sendVerificationSMS = (e, values) => {
        e && (e.preventDefault() || e.stopPropagation());
        if (!codeSendTimer) {
            if (data.productID) {
                productActivationSMS(values)
            } else if (data.action === 'sendUserSMS') {
                productFormSMS(values)
            }
        }
        
    }

    const handlePhoneSubmit = async (values) => {
        if (data.action === 'sendUserSMS') {
            let newData = {...data}
            newData["SmsCode"] = values.SmsCode
            setData(newData)
            success(newData)
        }
        // setData(values)
        // if (values.SmsCode && data.productID) {
        //     let response = await api.activateProduct(data.productID, {
        //         Phone: values.Phone,
        //         SmsCode: values.SmsCode,
        //     })
        //     let modalParams = { statusCode: response.statusCode, statusMessage: response.statusMessage, close: () => setResponseModal(false) }
        //     if (response.statusCode) {
        //         modalParams['close'] = () => { setResponseModal(false); success() }
        //     }
        //     setResponseModal(modalParams);
        // }
    }

    const ActiveComponent = parentProps => {
        switch (props.data.component) {
            case 'Phone':
                return <Phone {...parentProps} />
            default:
                close()
                return <></>
        }
    }

    const Component = () => {
        return (
            <div className="custom-modal-wrapper">
                {props.responseModal &&
                    <Response {...{ 
                        langs, 
                        statusMessage: props.responseModal.statusMessage, 
                        statusCode: props.responseModal.statusCode, 
                        close: props.responseModal.close 
                    }} />
                }
                <Header {...{
                    langs, 
                    title: props.data.title
                }} />
                <ActiveComponent {...{
                    langs,
                    data,
                    close,
                    handlePhoneSubmit,
                    sendVerificationSMS,
                    codeSendTimer,
                    setCodeSendTimer
                }} />
            </div>
        )
    }

    return <Modal {...{ customInner: true, Component, close, classInner: "fixed-mobile" }} />
}

export default Verification
