import React from 'react'
const OptionAddress = (props) => {
	const customStyleSet = { 
		margin: '8px', 
		padding: '24px', 
		cursor: 'pointer', 
		backgroundColor: 'rgb(241, 243, 246, 0.5)', 
		borderRadius: '20px' 
	}
	const AddNewOption = () => {
		return (
			<div className="d-flex align-items-center">
				<span className="my-auto mr-8px">
					<i className="svg-i svg-i-sm add-statement-i"></i>
				</span>
				{props.label}
			</div>
		)
	}

	const DefaultOption = () => {
		return (
			<>
				<div className="font-size-14 font-bold mb-12px">{props.data.name} {props.data.number}</div>
				<div className="font-size-14 font-base mb-8px">{props.data.address}</div>
				<div className="font-size-14 font-base">{props.data.phone}</div>
			</>
		)
	}

	const EmptyOption = () => {
		return (
			<div>{props.label}</div>
		)
	}

    return !props.isDisabled ?
		<div {...props.innerProps} style={customStyleSet} className="custom-adress-select-tradein-option">
			{props.value === 'AddNewOption' &&  <AddNewOption />}
			{props.value !== null ? <DefaultOption /> : <EmptyOption />}
		</div>
	: null;
}

export default OptionAddress