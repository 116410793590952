import React from 'react'
const BidsAndOffers = (className) => (
  <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="5" fill="#F8F9FD" />
      <path d="M22.2734 15.7199L16.2734 9.71992C16.0334 9.47992 15.7 9.33325 15.3334 9.33325H10.6667C9.93337 9.33325 9.33337 9.93325 9.33337 10.6666V15.3333C9.33337 15.6999 9.48004 16.0333 9.72671 16.2799L15.7267 22.2799C15.9667 22.5199 16.3 22.6666 16.6667 22.6666C17.0334 22.6666 17.3667 22.5199 17.6067 22.2733L22.2734 17.6066C22.52 17.3666 22.6667 17.0333 22.6667 16.6666C22.6667 16.2999 22.5134 15.9599 22.2734 15.7199ZM16.6667 21.3399L10.6667 15.3333V10.6666H15.3334V10.6599L21.3334 16.6599L16.6667 21.3399Z" fill="#8996AE" />
      <path d="M12.3334 13.3333C12.8857 13.3333 13.3334 12.8855 13.3334 12.3333C13.3334 11.781 12.8857 11.3333 12.3334 11.3333C11.7811 11.3333 11.3334 11.781 11.3334 12.3333C11.3334 12.8855 11.7811 13.3333 12.3334 13.3333Z" fill="#8996AE" />
    </svg>
  </span>
)

export default BidsAndOffers