const MinorTBCTooltip = (props) => {
  const { text } = props;
  return (
    <div className="installment-tooltip d-flex align-items-start position-absolute px-15px py-16px">
      <i className="svg-i svg-i-lg tooltip-info-i flex-shrink mr-10px"></i>
      <span className="font-size-12 font-base is-lowercase text-dark-gray font-weight-normal text-left">
        {text}
      </span>
    </div>
  )
}

export default MinorTBCTooltip