import React from 'react'
import { Link } from 'react-router-dom'

const Component = (props) => {
    return (
        <div className="top-info edit-address-head flex-row  d-flex justify-content-between align-items-center w-100 mt-24px mb-24px px-15px px-lg-0">
			<div className="d-flex">
				<ul className="d-flex align-items-start align-items-md-center ml-12px">
					<li className="mr-40px">
						<Link to={`/${props.getLang}/address/${props.routeParams.action !== undefined ? props.routeParams.action : ''}?Type=0`} 
							className={`info-link font-size-14 font-bold-caps text-blue-gray ${parseInt(props.params.Type) === 0 ? 'active' : ''}`}>
							{props.langs.PhysicalPerson}
						</Link>
					</li>
					<li className="">
						<Link to={`/${props.getLang}/address/${props.routeParams.action !== undefined ? props.routeParams.action : '' }?Type=1`} 
							className={`info-link font-size-14 font-bold-caps text-blue-gray ${parseInt(props.params.Type) === 1 ? 'active' : ''}`}>
							{props.langs.LegalPerson}
						</Link>
					</li>
				</ul>
			</div>
		</div>
    )
}

export default Component