import React, { useState, useEffect, useContext } from 'react';
import { HistoryContext } from 'contexts/history';
import { BannersContext } from 'contexts/banners';
import { Banner } from 'components';
import { CONFIG } from 'core';
import { Storage } from 'services';
import { useMediaQuery } from "libs";

const FooterFixedBanner = () => {
	const {adBlock, resolution, bannerTimeout, checkTimeout, getBanner} = useContext(BannersContext)
	const {history} = useContext(HistoryContext);
	const [adoFooter, setAdoFooter] = useState(false);
	const storage = Storage();

	const isMobile = useMediaQuery('(max-width: 768px)')

	const bannerClose = () => {
		bannerTimeout('AdoFooter')
		setAdoFooter(false);
		window.removeEventListener('scroll', scrollHandler);
	}

	const checkHeader = () => {
		if (!checkTimeout('AdoHeader')) {
			setAdoFooter(true);
			window.removeEventListener('storage', checkHeader);
		}
	}

	const scrollHandler = () => {
		if (window.pageYOffset <= 200) {
			setAdoFooter(false);
		} else if (checkTimeout('AdoFooter')) {
			setAdoFooter(true);
		} else {
			return () => {
				window.removeEventListener('scroll', scrollHandler);
			}
		}
	}

	// const refreshBanner = () => {
	// 	let bannerData = getBanner('AdoFooter')
	// 	let element = document.querySelector('[data-position="AdoFooter"]')
	// 	if (bannerData && element) {
	// 		element.id = bannerData.banner_content
	// 		window.ado.refresh(bannerData.banner_content)
	// 	}
	// }

	useEffect(() => {
		let lang = storage.get('Lang');
		if (checkTimeout('AdoFooter')) {
			if ([`/${lang}`, `/${lang}/`].includes(history.pathname)) {
				if(isMobile) return setAdoFooter(false);

				window.addEventListener('storage', checkHeader);
				if (checkTimeout('AdoHeader')) {
					window.addEventListener('scroll', scrollHandler);
					setAdoFooter(false);
				} else {
					setAdoFooter(true);
				}
			} else {
				setAdoFooter(true);
			}
		}
		return () => {
			window.removeEventListener('scroll', scrollHandler);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.pathname]);


	return (
			<>
				{(!adBlock && adoFooter && !!storage.tryParse(CONFIG.CACHE.TAGS.COOKIE_POLICY)) &&
						<div
							className={`nm-ado-footer flex overflow-hidden ${resolution[0] <= CONFIG.TOSPLIT.PRODUCT_BANNERS.RESPONSIVE_RESOLUTION ? 'nm-ado-mobile' : ''}`}>
							<div id="closeButton"
									 className="close-video p-4px rounded-circle bg-white position-absolute top-8px right-8px z-index-1000 cursor-pointer"
									 onClick={bannerClose}>
								<i className="svg-i close-black-i w-14px h-14px"></i>
							</div>
							<Banner {...{
								isOn: true,
								name: 'AdoFooter',
								mobileName: 'AdoFooter',
								groupName: false,
								parentClassName: 'nm-af',
								childClassName: ""
							}}/>
						</div>
				}
			</>
	)
}

export default FooterFixedBanner;