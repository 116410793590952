import React from 'react';

const RatingStars = (props) => {
  const maxStars = 5;
  const currentRate = props.rating ? parseFloat(props.rating).toFixed(1) : 0;

  const totalList = [];
  const activeList = [];

  const onClick = (star) => {
    if (!props.onClick) {
      return false;
    }
    props.onClick(star);
  };

  for (var i = 0; i < maxStars; i++) {
    let n = i + 1;
    totalList.push(<i className="svg-i rate-bg" key={i} onClick={() => onClick(n)}></i>);
  }

  for (var o = 0; o < currentRate; o++) {
    activeList.push(<i className={`svg-i rate-${parseFloat(o + 0.5).toFixed(1) === currentRate ? 'half' : 'active'}`} key={o}></i>);
  }
  return (
    <div className={`review-section ${props.className}`}>
      <div className="rate-back d-flex position-relative">
        {totalList}
        {activeList.length > 0 ? <div className="rate d-flex position-absolute">{activeList}</div> : ''}
      </div>
    </div>
  );
};

export default RatingStars;
