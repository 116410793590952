import React from 'react'
const MyGroups = (className = '') => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="6" fill="#F8F9FD"/>
<path d="M19.9993 10.6667V14H11.9993V10.6667H19.9993ZM19.9993 9.33334H11.9993C11.266 9.33334 10.666 9.93334 10.666 10.6667V14C10.666 14.7333 11.266 15.3333 11.9993 15.3333H19.9993C20.7327 15.3333 21.3327 14.7333 21.3327 14V10.6667C21.3327 9.93334 20.7327 9.33334 19.9993 9.33334ZM19.9993 18V21.3333H11.9993V18H19.9993ZM19.9993 16.6667H11.9993C11.266 16.6667 10.666 17.2667 10.666 18V21.3333C10.666 22.0667 11.266 22.6667 11.9993 22.6667H19.9993C20.7327 22.6667 21.3327 22.0667 21.3327 21.3333V18C21.3327 17.2667 20.7327 16.6667 19.9993 16.6667Z" fill="#8996AE"/>
</svg>


    </span>
)

export default MyGroups

