import React from 'react'
import PropTypes from 'prop-types'

AddGroup.propTypes = {
    className: PropTypes.string
}

export default function AddGroup({ className = '' } = '') {
    return (
        <span className={`svg-icon svg-md-icon ${className}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                <g id="Group_13219" data-name="Group 13219" transform="translate(-974.5 -214.5)">
                    <path id="Stroke_112" data-name="Stroke 112" d="M0,0H6" transform="translate(975.5 218.5)" fill="none" stroke="#" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
                    <path id="Stroke_113" data-name="Stroke 113" d="M0,0V6" transform="translate(978.5 215.5)" fill="none" stroke="#" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
                </g>
            </svg>
        </span>
    )
}
