import React from 'react'
const Mymessage = (className = '') => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="6" fill="#F8F9FD" />
            <path d="M21.334 11.1353V19.1353H11.4473L10.6673 19.9153V11.1353H21.334ZM21.334 9.802H10.6673C9.93398 9.802 9.33398 10.402 9.33398 11.1353V21.5287C9.33398 22.122 10.054 22.422 10.474 22.002L12.0007 20.4687H21.334C22.0673 20.4687 22.6673 19.8687 22.6673 19.1353V11.1353C22.6673 10.402 22.0673 9.802 21.334 9.802Z" fill="#8996AE" />
        </svg>


    </span>
)

export default Mymessage

