import { ReactComponent as VisaCard } from 'resources/img/icons/visa-card.svg';
import { ReactComponent as MasterCard } from 'resources/img/icons/mastercard-card.svg';

export const useCardIcon = () => {

  const visaRegex = /^4[0-9]{15}$/;
  const masterCardRegex = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/;

  const getCardIcon = (number) => {
    if(!number) return () => null;
    const isVisa = visaRegex.test(number.replaceAll('*', 0));
    const isMasterCard = masterCardRegex.test(number.replaceAll('*', 0));
    if (isVisa) return VisaCard;
    else if (isMasterCard) return MasterCard;
    return () => null;
  };

  return { getCardIcon };
};