import React, { useState, useEffect, useContext } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { AppDataContext } from 'contexts/appData';
import { ModalsContext } from 'contexts/modals';
import * as Yup from 'yup';

const InvoiceForm = (props) => {
	const { api, CONFIG, Functions } = useContext(AppDataContext);
	const { callResponse } = useContext(ModalsContext);

	const close = () => {
		props.callback(null, 'destroy');
	}

	const success = () => {
		close()
	}

	const getInitialValues = () => ({
		OrgName: '',
		IDCode: '',
		UserID: '',
		ServiceID: '',
		Email: '',
		CcEmails1: '',
		CcEmails2: '',
		CcEmails3: '',
		Amount: '',
		Comment: ''
	})

	const [data, setData] = useState(getInitialValues());

	const getValidationSchema = () => {
		let validationSchema = {
			Amount: Yup.string().matches(/^[0-9]{1,5}([.][0-9]{1,2})?$/, `${props.langs.IncorrectFormat}`)
			.required(props.langs.TypeCorrectAmount)
			.test('len', `${props.langs.IncorrectFormat}`, val => val && parseInt(val.charAt(0)) !== 0),
			Email: Yup.string().email(props.langs.WriteYourEmail),
			CcEmails1: Yup.string().email(props.langs.WriteYourEmail),
			CcEmails2: Yup.string().email(props.langs.WriteYourEmail),
			CcEmails3: Yup.string().email(props.langs.WriteYourEmail)
		}
		return Yup.object().shape(validationSchema)
	}

	const config = {
		initialValues: data,
		validationSchema: getValidationSchema(),
		enableReinitialize: true
	}

	const handleSubmit = async (values) => {
		setData(values)
		let CcEmails = []
		if (values.CcEmails1) {CcEmails.push(values.CcEmails1)}
		if (values.CcEmails2) {CcEmails.push(values.CcEmails2)}
		if (values.CcEmails3) {CcEmails.push(values.CcEmails3)}
		let finalData = {
			ServiceID: 2,
			Email: values.Email,
			CcEmails: CcEmails,
			Amount: values.Amount,
			Comment: values.Comment
		}
		let response = await api.generateInvoice(finalData)
		callResponse({ 
			statusCode: (response && response.data.statusCode === 74) ? true : false, 
			statusMessage: (response && response.data.statusMessage) ? response.data.statusMessage : false, 
			close: () => {
				success()
			} 
		});
	}

	const fetchData = async () => {
		const apiData = await api.getUserShop()
		if (apiData.statusCode) {
			setData({
				OrgName: apiData.data.title,
				IDCode: apiData.data.description.id_code,
				UserID: apiData.data.user_id,
				ServiceID: props.langs.InvoiceDefaultService.replace('%s', CONFIG.COMMON.SITE),
				Email: apiData.data.description.email ? apiData.data.description.email : props.data.userEmail,
				CcEmails1: '',
				CcEmails2: '',
				CcEmails3: '',
				Amount: '',
				Comment: ''
			})
		}
	}

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const FormComponent = ({values, setFieldValue}) => {
		return (
			<Form autoComplete="off">
				<div className="row m-0 d-flex product-details-card border-top-0 pb-10px mt-24px justify-content-between">
					<div className="col-12 col-md-6 p-0 pr-md-24px">
						<div className="form-group p-0 mb-15px">
							<label htmlFor="OrgName" className="font-medium font-size-12 font-weight-normal text-text_label">
								{props.langs.OrgName}
									<span className="required text-blue"> *</span>
							</label>
							<Field name="OrgName" disabled={true}
								className="h-48px outline-none position-relative border-radius-10 border-solid-1 border-gray  w-100 px-12px form-control"/>
							<ErrorMessage name="OrgName" component="label"
								className="error position-absolute top-80px left-0"/>
						</div>
						<div className="form-group p-0 mb-15px">
							<label htmlFor="ServiceID" className="font-medium font-size-12 font-weight-normal text-text_label">
								{props.langs.InvoiceService}
									<span className="required text-blue"> *</span>
							</label>
							<Field name="ServiceID" disabled={true}
								className="h-48px outline-none position-relative border-radius-10 border-solid-1 border-gray  w-100 px-12px form-control"/>
							<ErrorMessage name="ServiceID" component="label"
								className="error position-absolute top-80px left-0"/>
						</div>
					</div>
					<div className="col-12 col-md-6 p-0 ">
						<div className="form-group p-0 mb-15px">
							<label htmlFor="IDCode" className="font-medium font-size-12 font-weight-normal text-text_label">
								{props.langs.IDCode}
									<span className="required text-blue"> *</span>
							</label>
							<Field name="IDCode" disabled={true}
								className="h-48px outline-none position-relative border-radius-10 border-solid-1 border-gray  w-100 px-12px form-control"/>
							<ErrorMessage name="IDCode" component="label"
								className="error position-absolute top-80px left-0"/>
						</div>
						<div className="form-group p-0 mb-15px">
							<label htmlFor="UserID" className="font-medium font-size-12 font-weight-normal text-text_label">
								{props.langs.UserID}
									<span className="required text-blue"> *</span>
							</label>
							<Field name="UserID" disabled={true}
								className="h-48px outline-none position-relative border-radius-10 border-solid-1 border-gray  w-100 px-12px form-control"/>
							<ErrorMessage name="UserID" component="label"
								className="error position-absolute top-80px left-0"/>
						</div>
					</div>
					<div className="col-12 col-md-6 p-0 pr-md-24px">
						<div className="form-group p-0 mb-15px">
							<label htmlFor="Email" className="font-medium font-size-12 font-weight-normal text-text_label">
								{props.langs.NM_InvoiceReceiverEmail}
									<span className="required text-blue"> *</span>
							</label>
							<Field name="Email" disabled={true}
								className="h-48px outline-none position-relative border-radius-10 border-solid-1 border-gray  w-100 px-12px form-control"/>
							<ErrorMessage name="Email" component="label"
								className="error position-absolute top-80px left-0"/>
						</div>
						<div className="form-group p-0 mb-15px">
							<label htmlFor="CcEmails2" className="font-medium font-size-12 font-weight-normal text-text_label">
								{props.langs.NM_InvoiceAdditionalEmail} 2
							</label>
							<Field name="CcEmails2"
								className="h-48px outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px"/>
							<ErrorMessage name="CcEmails2" component="label"
								className="error position-absolute top-175px left-0"/>
						</div>
					</div>
					<div className="col-12 col-md-6 p-0 ">
						<div className="form-group p-0 mb-15px">
							<label htmlFor="CcEmails1" className="font-medium position-relative font-size-12 font-weight-normal text-text_label">
								{props.langs.NM_InvoiceAdditionalEmail} 1
							</label>
							<Field name="CcEmails1"
								className="h-48px outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px"/>
							<ErrorMessage name="CcEmails1" component="label"
								className="error position-absolute top-80px left-0"/>
						</div>

						<div className="form-group p-0 mb-15px">
							<label htmlFor="CcEmails3" className="font-medium font-size-12 font-weight-normal text-text_label">
								{props.langs.NM_InvoiceAdditionalEmail} 3
							</label>
							<Field name="CcEmails3"
								className="h-48px outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px"/>
							<ErrorMessage name="CcEmails3" component="label"
								className="error position-absolute top-175px left-0"/>
						</div>
					</div>
					<div className="col-3 col-md-3 p-0 pr-12px pr-md-24px">
						<div className="form-group p-0 mb-15px">
							<label htmlFor="Amount" className="font-medium font-size-12 font-weight-normal text-text_label">
								{props.langs.Amount}
								<span className="required text-blue"> *</span>
							</label>
							<Field type="text" name="Amount"
								className="h-48px outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px"
								onChange={(e) => {
									setFieldValue("Amount", Functions.checkStringLength(Functions.checkNumeric(e.target.value), 8))
								}}/>
							<ErrorMessage name="Amount" component="label"
								className="error position-absolute top-80px left-0"/>
						</div>
					</div>
					<div className="col-9 col-md-9 p-0 ">
						<div className="form-group p-0 mb-15px">
							<label htmlFor="Comment" className="font-medium font-size-12 font-weight-normal text-text_label">
								{props.langs.Comment}
							</label>
							<Field type="text" name="Comment"
								className="h-48px outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px"/>
							<ErrorMessage name="Comment" component="label"
								className="error position-absolute top-80px left-0"/>
						</div>
					</div>
				</div>
				<hr className="m-0 text-gray-transparent"/>
				<div className="d-flex justify-content-center pt-24px">
					<button type="submit"
						className={`btn bg-gradient font-bold-caps w-100 font-size-13 font-weight-bold text-white px-40px py-16px ${!values.Amount ? 'disabled' : ''}`}
						disabled={!values.Amount}
					>
						{props.langs.NM_SendRequest}
					</button>
				</div>
			</Form>
		)
	}

	return (
		<div className="custom-modal-wrapper p-15px">
			<div className="">
				<h5 className="m-0 mb-5px font-bold-caps font-size-14 font-weight-bold text-dark">
					{props.langs.NM_AskForInvoice}
				</h5>
				<span className="font-base font-size-12 font-weight-normal text-blue-gray mt-6px mb-24px">
					{props.langs.InvoiceVATInfo}
				</span>
			</div>
			<Formik {...config} onSubmit={handleSubmit} component={FormComponent} />
		</div>
	)
}

export default InvoiceForm