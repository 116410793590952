import React, { useEffect, useState } from 'react'
import { Field, Form, ErrorMessage, FormikProvider, useFormik } from 'formik';
import IBAN from 'iban';
import Select from "react-select";
import * as Yup from 'yup';
import { API } from 'services';
import { Divider } from 'components';

const AddAccountNumber = (props) => {
	const { langs, callback, data } = props;
	const api = API();

	const [bankAccounts, setBankAccounts] = useState([]);
	const [bankAccountsLoading, setBankAccountsLoading] = useState(true);
	const [newAccount, setNewAccount] = useState(false);

	const bankAccountsList = bankAccounts?.map(el => ({ label: el.iban, value: el.iban })) ?? []

	const validationSchema = Yup.object().shape({
		OrderID: Yup.string().required(langs.APP_required),
		UserBankAccount: Yup.string().when([], {
			is: () => newAccount,
			then: Yup.string().notRequired(),
			otherwise: Yup.string().required(langs.APP_required),
		}),
		OwnerFullName: Yup.string().when([], {
			is: () => newAccount,
			then: Yup.string().required(langs.APP_required),
			otherwise: Yup.string().notRequired(),
		}),
		BankAccount: Yup.string().when([], {
			is: () => newAccount,
			then: Yup.string().test('IBAN_CHECK', langs.WriteBankAccount, (e) => { return IBAN.isValid(e) }),
			otherwise: Yup.string().notRequired(),
		})
	});

	const handleSubmit = async (values, { resetForm }) => {
		const apiData = await api.updateBankAccount({
			BankAccount: values.UserBankAccount ? values.UserBankAccount: values.BankAccount,
			OrderID: values.OrderID,
			OwnerFullName: values.OwnerFullName,
		});
		if (apiData.statusCode) {
			resetForm();
			callback(null, "success");
		} else {
			callback(null, "fail");
		}
		callback(null, "destroy");
	}

	const formik = useFormik({
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		initialValues: {
			OrderID: data.orderId,
			OwnerFullName: data.OwnerFullName ?? '',
			UserBankAccount: '',
			BankAccount: ''
		}
	});

	useEffect(() => {
		api.getBankAccounts()
				.then((res) => {
					setBankAccounts(res.data)
				})
				.finally(() => setBankAccountsLoading(false))
	}, []);

	return (
		<div className="custom-modal-wrapper">
			<FormikProvider value={formik}>
				<Form autoComplete="off" onSubmit={formik.handleSubmit}>
					<div className="position-relative">
						<div className="popup-content content-scroll ">
							<h3 className="font-bold-caps font-size-20 mb-4px text-left">
								{langs.NM_Online_Add_Iban}
							</h3>
							<div className="font-size-14 text-left">{langs.NM_Online_Added_Iban_Modal_Text}</div>

							<Divider/>

							<div className="select-box w-100">
								<Select
										name="accountNumber"
										className="sg-selectbox-container text-nowrap w-100"
										classNamePrefix="sg-selectbox"
										isSearchable={false}
										placeholder="ანგარიშის ნომერი"
										noOptionsMessage={() => props.langs.NM_NothingFound}
										options={bankAccountsList}
										isLoading={bankAccountsLoading}
										isDisabled={bankAccountsLoading}
										value={bankAccountsList.find(item => item.value === formik.values.UserBankAccount) ??	 ''}
										onChange={(option) => {
											formik.setFieldValue('UserBankAccount', option.value);
											setNewAccount(false);
										}}
								/>
								<ErrorMessage name="UserBankAccount" component="label" className="error text-left"/>
							</div>

							<Divider>{langs.Or}</Divider>

							<button
									type="button"
									className="btn bg-transparent p-0 text-blue font-size-14 font-bold  mb-24px d-flex align-items-center"
									onClick={() => {
										setNewAccount(prev => !prev);
										formik.resetForm();
									}}
							>
								<div className="svg-i add-address-i w-24px h-24px mr-4px"></div>
								{langs.NM_NewAccountAdd}
							</button>

							{newAccount && (<><div className="form-group">
								<Field
										className="form-control font-base"
										name="OwnerFullName"
										type="text"
										placeholder={langs.NM_AccountName}
								/>
								<ErrorMessage name="OwnerFullName" component="label" className="error text-left"/>
							</div>
							<div className="form-group">
								<Field
										name="BankAccount"
										className={"form-control  text-dark font-size-14 font-base "}
										placeholder={langs.BankAccount}
								/>
								<ErrorMessage name="BankAccount" component="label" className="error text-left"/>
							</div> </>)}

						</div>

						<div className="d-flex justify-content-end mt-24px flex-wrap flex-sm-nowrap">
							<button
									onClick={() => callback(null, "destroy")}
									className="btn bg-secondary-gray-10 font-size-14 text-black px-40px py-14px mobile-100"
							>
								{langs.Cancel}
							</button>
							<button
									type="submit"
									className="btn bg-primary hover-bg-primary-80 font-size-14 text-black px-40px py-14px ml-sm-3 mt-sm-0 mt-3 mobile-100"
							>
								{langs.Save}
							</button>
						</div>
					</div>
				</Form>
			</FormikProvider>
		</div>
	)
}

export default AddAccountNumber