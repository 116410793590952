import React from 'react'
import CONFIG from 'core/config';
const Component = (props) => {
    return (
        <div className="adress-box-cont col-12 col-sm-6 col-md-6 col-lg-4 px-12px">
			<div className="adress-info-box">
				<div className="delete-icon adress-delete" onClick={(e) => props.setDelete(e, props.item.id)} title={props.langs.Delete}>
					<props.DeleteIcon />
				</div>
				<div className="adress-info-box-fill p-25px">
					<div className="adress-info-box-elements">
						{parseInt(props.item.status) === CONFIG.ADDRESS_STATUSES.NEW &&
							<div className="adress-edit-pen cursor-pointer"
								 onClick={(e) => props.setEditItem(e, props.item)} title={props.langs.Edit}>
								<i className="svg-i edit-i "></i>
							</div>
						}
						{/* {props.item.isDefault ? 
							<div className={`star-icon ${((props.item.isDefault && !props.state.isDefault) || (props.item.id === props.state.isDefault)) ? 'active' : ''}`} 
								onClick={(e) => props.setDefault(e, props.item.id)} title={props.langs.DefaultAddress}>
								<props.StarIcon />
							</div>
						: ''} */}

						<div className="adress-info-title text-truncate">
							<span className="font-bold-caps font-size-14 text-dark">{`${props.item.fullname}`}</span>
						</div>
						<div className="adress-info-person">
							<span className="text-gray-light font-size-12 font-base">{`${props.langs.PersonalNumberShort} ${props.item.personalNumber}` }</span>
						</div>
						<div className="adress-details ">
							<span className="text-dark font-size-14 font-base mt-8px clamp _2 line-height-11">{`${props.findSelectedValue(props.item.city)}, ${props.item.address}`}...</span>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}
export default Component