import React, {useState, useRef} from 'react';
import { OutsideClickHandler } from 'components'
const Dropdown = React.forwardRef((props, ref) => {
  const {mainClass, toggleIconClass, toggleButtonClass, children} = props;
  const [dropdown, setDropdown] = useState(false);
  const toggleButton = useRef(null);
  const outsideClickProps = {
    ref,
    toggleButton,
		disabled: dropdown,
    className: `sl-actions-list table-actions-dropdown dropdown-menu dropdown-menu-right ${dropdown ? 'show' : ''} `,
		onOutsideClick: () => {
			setDropdown(false);
		}
	};
  const onDotsClick = (e) => {
    e.preventDefault() || e.stopPropagation();
    setDropdown(!dropdown);
  }
  return (
    <div className={`sl-actions drop_dd dropdown-custom dropdown ${mainClass ?? ''} ${dropdown ? 'show' : ''}`}>
			<button ref={toggleButton} className={`${toggleButtonClass ?? ''} sl-action-list-controll p-8px bg-gray w-32px h-32px  dropdown-toggle btn`}
        onClick={onDotsClick}>
				<i className={toggleIconClass ?? "svg-i dots-i"}></i>
			</button>
      <OutsideClickHandler {...outsideClickProps}>
        {children}
      </OutsideClickHandler>
		</div>
  )
})

export default Dropdown;