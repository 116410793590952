import React from 'react'
const TbcCreditForm = (props) => {
    let tbcLink = `https://tbccredit.ge/fcl?[0].product=${props.data.Product.title}${(parseFloat(props.data.Product.price) > 99.99 ? `&[0].price=${props.data.Product.price}` : '')}&[0].productid=${props.data.Product.product_id}&referersite=mymarket&utm_source=mymarket&utm_medium=button_detailed_page&utm_campaign=tbccredit_ecosystem_iframe`
    return (
        <div className="custom-modal-wrapper d-flex flex-column flex-md-row">
            <div className="flex-1">
                <div className="font-size-14 text-dark font-base scrolled-modal">
                    <iframe src={tbcLink} title={`TBC Credit Form`} sandbox={`allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox`} width="100%" height="500" frameBorder="0"/>
                </div>
            </div>
        </div>
    )
}

export default TbcCreditForm