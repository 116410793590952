import React, { useContext, useState } from 'react';
import { AppDataContext } from 'contexts/appData';
import { BannersContext } from 'contexts/banners';
import CONFIG from 'core/config'
import auto from 'resources/img/icons/auto.svg';
import home from 'resources/img/icons/home.svg';
import market from 'resources/img/icons/market.svg';
import parts from 'resources/img/icons/parts.svg';
import swoop from 'resources/img/icons/swoop.svg';
import tkt from 'resources/img/icons/tkt.svg';
import livo from 'resources/img/icons/livo.svg';
// import saba from 'resources/img/icons/saba.svg';
import myjobs from 'resources/img/icons/myjobs.svg'
import superapp from 'resources/img/icons/supaerapp.svg'
import TnetLogo from 'components/icons/TnetFooter'

const TnetPlatforms = (props) => {
    const { isIos } = useContext(AppDataContext);
    const { resolution } = useContext(BannersContext);
    let mobileLinks = isIos ? 'https://apps.apple.com/us/app/super-app-tnet/id6444474250' : 'https://play.google.com/store/apps/details?id=ge.tnet.superapp&pli=1'
    let detectDevice = resolution[0] <= CONFIG.TOSPLIT.RESPONSIVE_RESOLUTION ? mobileLinks : 'https://superapp.tnet.ge/'
    const [open, setOpen] = useState(false)
    const OpenLogos = () => {
        setOpen(!open)
    }
    const TNetLinks = [
        { icon: auto, to: 'https://www.myauto.ge/' },
        { icon: parts, to: 'https://www.myparts.ge/', },
        { icon: home, to: 'https://www.myhome.ge/' },
        { icon: market, to: 'https://www.mymarket.ge/' },
        { icon: myjobs, to: 'https://myjobs.ge/' },
        { icon: superapp, to: detectDevice },
        { icon: tkt, to: 'https://tkt.ge/' },
        { icon: swoop, to: 'https://www.swoop.ge/' },
        { icon: livo, to: 'https://livo.ge/' },
        // { icon: saba, to: 'https://saba.com.ge/' },


    ];

    return (
        <div className="container px-0 px-lg-15px my-32px">
            <div className="footer-logo-box  border d-flex align-items-center position-relative z-index-997">
                <div className={`head d-flex justify-content-between align-items-center `} onClick={OpenLogos}>
                    <div className="tnet-footer-logo w-130px mr-24px  d-flex align-items-center  border-radius-64">
                        <TnetLogo />
                    </div>
                    <div className={`drop-arrow  ${open ? 'up' : ''}`}>
                        <i className={`svg-i arrow-down-black-i w-8px h-6px`}></i>
                    </div>
                </div>
                <div className={`platform-logos  justify-content-start mx-n12px  flex-wrap w-100 ${open ? 'active' : ''}`}>
                    {TNetLinks.map((item, key) => (
                        <div className="px-12px item" key={key}>
                            <div className=" py-20px d-flex">
                                <a href={item.to} target='_blank' rel="noopener noreferrer" className={"logos-hover cursor-pointer h-20px hover-bg-gray-450 d-flex align-items-center justify-content-center rounded transition-all"}>
                                    <img src={item.icon} alt="" className='max-height-26px w-100  h-100 object-fit-contain' />
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default TnetPlatforms
