import React, { useContext } from 'react'
import { AppDataContext } from 'contexts/appData';
import { ModalsContext } from 'contexts/modals';
import { HistoryContext } from 'contexts/history';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import IBAN from 'iban';
const AccountForm = (props) => {
	const {
		callback = false,
		data = null
	} = props;
	console.log(data);
	const { langs, api, getLang } = useContext(AppDataContext);
	const { callResponse, destroy } = useContext(ModalsContext);
	const { push, getPagename, routeParams } = useContext(HistoryContext);

	const config = {
		id: 'account',
		initialValues: {
			OwnerFullName: data ? data.owner_fullname : '',
			BankAccount: data ? data.iban : '',

		},
		validationSchema: Yup.object().shape({
			OwnerFullName: Yup.string()
				.required(langs.APP_required),
			BankAccount: Yup.string().required(langs.APP_required)
				.test('IBAN_CHECK', langs.NM_BankAccountInvalid,
					(e) => { return IBAN.isValid(e) }
				)
		}),
		enableReinitialize: true,
	};

	const processReset = (e) => {
		e && (e.preventDefault() || e.stopPropagation());
		if (getPagename() === 'account') {
			push(`/${getLang}/account/list`, {})
		} else {
			destroy()
		}
	}

	const handleAccount = async (values, { setSubmitting, resetForm }) => {
		setSubmitting(true);
		let finalData = values;
		if (routeParams.action === 'edit') {
			finalData.BankAccountID = data.iban;
		}
		const addAccount = await api.addBankAccount(values);
		if (addAccount.statusCode) {
			resetForm();
			if (callback) {
				callback(values)
			}
		}
		callResponse({
			statusCode: addAccount.statusCode,
			message: addAccount.statusCode ? true : false,
		});
		setSubmitting(false);
	}
	return (
		<div className='account-box-cont col-12 col-sm-6 col-md-6 px-12px'>
			<div className="account-info-box">
				<Formik {...config} onSubmit={handleAccount}>
					{({ isSubmitting }) => (
						<Form autoComplete="off">
							<div className="account-info-box-fill p-40px">
								<div className="account-info-box-elements">
									<div className="form-group m-0">
										<label className="font-size-12 text-text_label">
											{langs.NM_AccountName}:
											<span className="text-blue">*</span>
										</label>
										<Field className="form-control font-base" name="OwnerFullName" type="text" />
										<ErrorMessage name="OwnerFullName" component="label" className="error text-danger" />
									</div>
									<div className="form-group m-0">
										<label className="font-size-12 text-text_label">
											{langs.APP_account_number}
											<span className="text-blue">*</span>
										</label>
										<Field className="form-control font-base" name="BankAccount" type="text"
											disabled={routeParams.action === 'edit'}
										/>
										<ErrorMessage name="BankAccount" component="label" className="error text-danger" />
									</div>
								</div>
								<div className={`pt-32px d-flex justify-content-between`}>
									<button type="reset" className="btn btn-link font-base font-weight-normal font-size-12 px-0 text-blue-gray"
										onClick={processReset}
									>
										{langs.Reject}
									</button>
									<button type="submit" disabled={isSubmitting}
										className={`btn bg-gradient text-black font-bold-caps ml-12px `}>
										{langs.Save}
									</button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	)
}
export default AccountForm