import React from 'react'
import PropTypes from 'prop-types'

CloseModal.propTypes = {
    className: PropTypes.string
}

export default function CloseModal({ className = '' } = '') {
    return (
        <span className={`d-flex ${className}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12.657" height="12.657" viewBox="0 0 12.657 12.657">
                <g id="Group_5441" dataname="Group 5441" transform="translate(-517.172 -973.172)">
                    <line id="Line_141" dataname="Line 141" x1="7" y2="7" transform="translate(520 976)" fill="none" stroke="#d7dce4" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                    <line id="Line_142" dataname="Line 142" x2="7" y2="7" transform="translate(520 976)" fill="none" stroke="#d7dce4" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                </g>
            </svg>
        </span>
    )
}











