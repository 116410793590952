import React from 'react'
import { Form } from '../../../modules/account/components/'

const NewAccount = (props) => {
  return (
    <div className="custom-modal-wrapper content-scroll cols-none">
      <Form {...{
        callback: (e) => {
          props.callback(e, 'destroy')
          props.callback(e, 'afterDestroy')
        },
      }} />
    </div>
  )
}

export default NewAccount