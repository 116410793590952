import React from 'react'

const Custom = (props) => {
    return (
        <div className="pr-24px">
            {props.text}
        </div>
    )
}

export default Custom