import React from 'react'
const View = (className = '') => (
  <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center  ${className}`}>
 <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.07375 2.29998C5.80708 2.29998 5.59375 2.08665 5.59375 1.81998C5.59375 1.55331 5.80708 1.33331 6.07375 1.33331H9.92708C10.1937 1.33331 10.4071 1.54665 10.4071 1.81331C10.4071 2.07998 10.1937 2.29998 9.92708 2.29998H6.07375Z" fill="#A4AEC1"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.33404 11.3523C9.33404 10.1456 10.1474 9.33227 11.354 9.33227H13.314C13.4674 9.33227 13.614 9.3456 13.754 9.37227C13.7674 9.21227 13.7807 9.05227 13.7807 8.8856C13.7807 5.69227 11.1874 3.09894 8.0007 3.09894C4.81404 3.09894 2.2207 5.69227 2.2207 8.8856C2.2207 12.0723 4.81404 14.6656 8.0007 14.6656C8.56737 14.6656 9.10737 14.5723 9.62737 14.4256C9.4407 14.1123 9.33404 13.7389 9.33404 13.3123V11.3523ZM8.0007 9.1656C7.72737 9.1656 7.5007 8.93894 7.5007 8.6656V5.33227C7.5007 5.05894 7.72737 4.83227 8.0007 4.83227C8.27404 4.83227 8.5007 5.05894 8.5007 5.33227V8.6656C8.5007 8.93894 8.27404 9.1656 8.0007 9.1656Z" fill="#A4AEC1"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.36 10C10.5067 10 10 10.5067 10 11.3533V13.3133C10 14.16 10.5067 14.6667 11.36 14.6667H13.3133C14.16 14.6667 14.6667 14.16 14.6667 13.3133V11.3533C14.6667 10.5067 14.16 10 13.3133 10H11.36ZM11.5533 13.76C11.34 13.76 11.1667 13.5867 11.1667 13.3733V11.2933C11.1667 11.08 11.34 10.9067 11.5533 10.9067C11.7733 10.9067 11.9467 11.08 11.9467 11.2933V13.3733C11.9467 13.5867 11.7733 13.76 11.5533 13.76ZM13.1133 13.76C12.9 13.76 12.72 13.5867 12.72 13.3733V11.2933C12.72 11.08 12.9 10.9067 13.1133 10.9067C13.3267 10.9067 13.5 11.08 13.5 11.2933V13.3733C13.5 13.5867 13.3267 13.76 13.1133 13.76Z" fill="#A4AEC1"/>
</svg>


  </span>
)

export default View