import React from 'react'
const EditAccount = (className = '') => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="5" fill="#F8F9FD" />
            <path d="M21 12.9999C20.9999 12.1612 20.7889 11.336 20.3864 10.6003C19.9838 9.86449 19.4026 9.24174 18.6963 8.78947C17.99 8.3372 17.1812 8.06991 16.3445 8.01212C15.5078 7.95434 14.67 8.10802 13.9082 8.4589C13.1464 8.80978 12.4851 9.3465 11.9852 10.0199C11.4853 10.6934 11.1628 11.4819 11.0475 12.3127C10.9321 13.1434 11.0277 13.9897 11.3252 14.7738C11.6227 15.558 12.1126 16.2548 12.75 16.8C11.6188 17.393 10.6714 18.284 10.0104 19.3769C9.34937 20.4697 8.99996 21.7227 9 22.9999C9 23.2651 9.10531 23.5194 9.29285 23.7069C9.48038 23.8945 9.73478 23.9999 10 23.9999C10.2652 23.9999 10.5196 23.8945 10.7072 23.7069C10.8947 23.5194 11 23.2651 11 22.9999C11 21.6738 11.5268 20.402 12.4645 19.4643C13.4022 18.5266 14.6739 17.9999 16 17.9999C17.3261 17.9999 18.5978 18.5266 19.5355 19.4643C20.4732 20.402 21 21.6738 21 22.9999C21 23.2651 21.1053 23.5194 21.2928 23.7069C21.4804 23.8945 21.7348 23.9999 22 23.9999C22.2652 23.9999 22.5196 23.8945 22.7072 23.7069C22.8947 23.5194 23 23.2651 23 22.9999C23.0009 21.7228 22.652 20.4698 21.9913 19.3769C21.3306 18.2839 20.3832 17.3927 19.252 16.8C19.8008 16.3307 20.2413 15.7478 20.5431 15.0917C20.8449 14.4357 21.0008 13.722 21 12.9999ZM16 15.9999C15.4067 15.9999 14.8266 15.8239 14.3333 15.4943C13.8399 15.1647 13.4555 14.696 13.2284 14.1479C13.0013 13.5997 12.9419 12.9967 13.0576 12.4147C13.1734 11.8328 13.4591 11.2981 13.8787 10.8786C14.2982 10.459 14.8327 10.1733 15.4147 10.0575C15.9966 9.94178 16.5999 10.0011 17.1481 10.2282C17.6962 10.4553 18.1647 10.8398 18.4944 11.3332C18.824 11.8265 19 12.4066 19 12.9999C19 13.7956 18.6839 14.5586 18.1213 15.1213C17.5587 15.6839 16.7956 15.9999 16 15.9999Z" fill="#A4AEC1" />
        </svg>
    </span>
)

export default EditAccount