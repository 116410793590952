import React, { useContext } from 'react';
import { useCardIcon, useMediaQuery } from 'libs';
import { Checkbox } from 'components';
import { AppDataContext } from 'contexts/appData';

import { ReactComponent as ErrorInfo } from 'resources/img/icons/error-info.svg';
import { ReactComponent as RightBlue } from 'resources/img/icons/arrow-blue-right.svg';

const PaymentCardItem = ({ card, method, isSelected, onChange, saveCard, onSaveCard, onCardChange, isExpired }) => {
  const { langs } = useContext(AppDataContext)
  const { getCardIcon } = useCardIcon();
  const CardIcon= getCardIcon(card?.card_mask);
  const isMobile = useMediaQuery('(max-width: 768px)')


  if (card) {
    return (
      <div className="mb-8px cursor-pointer">
        <div className={`d-flex align-items-center justify-content-between custom-control custom-radio border-radius-md py-24px m-auto ${isSelected ? 'active': ''}`} onClick={onChange}>
          <label
            className={`custom-control-label mr-24px ${isSelected ? 'active' : ''}`}
          >
           <CardIcon className="mr-14px" />
            <div className={`font-medium font-size-14 text-text-black ${isExpired ? 'opacity-40': ''}`}>{isMobile ? card.card_mask.slice(-8) : card.card_mask}</div>
            {isExpired && <ErrorInfo className="ml-12px"/>}
          </label>
          {(isSelected || isExpired) && (
            <div className="d-flex align-items-center justify-content-center font-size-12 text-blue-100 mr-24px" onClick={onCardChange}>
              <div className="clamp _2">{langs.NM_ChangeCard}</div>
              <RightBlue className="ml-8px flex flex-shrink-0" />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="mb-8px cursor-pointer">
      <div className={`d-flex justify-content-between align-items-center custom-control custom-radio border-radius-md py-24px m-auto ${isSelected ? 'active': ''}`} onClick={onChange}>
        <label
          className={`custom-control-label font-medium font-size-14 text-text-black 
													${isSelected ? 'active' : ''}`}
        >
          {method.title}
        </label>
        {isSelected ? (
          <Checkbox
            name="SaveCard"
            className="mr-24px h-32px font-size-12"
            checked={saveCard}
            onChange={(e) => onSaveCard(e.target.checked)}
            label={langs.NM_SaveCard}
          />
        ) : (
          <span className="mr-24px">
            <i className={`svg-i svg-i-big ${method.icon}`}></i>
          </span>
        )}
      </div>
    </div>
  );
};

export default PaymentCardItem;
