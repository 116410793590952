import React, {useState} from 'react'
import { Formik, Form } from 'formik';

import * as Yup from 'yup';
import { API } from 'services';
import { Modal, Response } from 'components';

import Select from "react-select";
const OrderStatusChange = (props) => {
    const api = API();
    const [responseModal, setResponseModal] = useState(false);
    const statusList = [
        {value: 2, label: props.appData.OrderStatuses[2]},
        {value: 3, label: props.appData.OrderStatuses[3]}
    ]
    const close = () => {
        props.close();
    }

    const success = () => {
        props.success();
        close()
    }

    const getInitialValues = () => ({
        StatusID: ''
    })

    const [data, setData] = useState(getInitialValues());

    const getValidationSchema = () => {
        let validationSchema = {
            StatusID: Yup.string().required(props.langs.NM_WriteOrderStatus),
        }
        return Yup.object().shape(validationSchema)
    }

    const config = {
        initialValues: data,
        validationSchema: getValidationSchema(),
        enableReinitialize: true
    }

    const handleSubmit = async (values) => {
        setData(values)
        let finalData = { ...values}
        if (finalData.StatusID && finalData.StatusID > props.data.status_id) {
            let response = await api.changeShopOrderStatus(props.data.order_id, finalData)
            let modalParams = { 
                statusCode: response.statusCode, 
                statusMessage: response.statusMessage, 
                close: () => {
                    setResponseModal(false); 
                    close()
                }
            }
            if (response.statusCode) {
                modalParams['close'] = () => { 
                    setResponseModal(false); 
                    success() 
                }
            }
            setResponseModal(modalParams);
        }
    }
    const Component = () => {
        return (
            <div className="custom-modal-wrapper">
                {responseModal &&
                    <Response {...{ langs: props.langs, statusMessage: responseModal.statusMessage, statusCode: responseModal.statusCode, close: responseModal.close }} />
                }
                <Formik {...config} onSubmit={handleSubmit} >
                    {({ values, setFieldValue}) => (
                        <Form>
                            <div className="py-24px px-m-84px">
                                <i className="svg-i send-offer-i mx-auto"></i>
                                <h2 className="font-size-18 font-size-m-21 font-bold-caps text-dark mt-16px text-center">
                                    {props.langs.NM_ChangeStatus}
                                </h2>
                                <div className="select-box mt-32px mb-40px px-40px">
                                    <Select
                                        name="StatusID"
                                        className="sg-selectbox-container text-nowrap w-100"
                                        classNamePrefix="sg-selectbox"
                                        isSearchable={false}
                                        noOptionsMessage={() => props.langs.NM_NothingFound}
                                        options={statusList.filter(o => o.value > props.data.status_id)}
                                        value={statusList.find(o => o.value === values.StatusID)}
                                        onChange={(value) => {
                                            setFieldValue('StatusID', value.value)
                                        }}
                                    />
                                </div>
                                <button type="submit" className="btn font-size-14 font-bold-caps text-white h-60px d-flex align-items-center justify-content-center bg-primary w-100"
                                    disabled={!values.StatusID || values.StatusID < props.data.status_id}>
                                    {props.langs.Save}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }

    return <Modal {...{customInner: true, Component, close, classInner: "fixed-mobile"}} />
}

export default OrderStatusChange;