import React from 'react'
import {Loader} from 'components'
import {
    Response,
    NewProduct,
    TBCConnect,
    CommunicationPoll,
    OfferPrice,
    BuyPromotion,
    ProveLoan,
    MessageToUser,
    LoginRequired,
    SecurityFishing,
    TbcCreditForm,
    CreateDiscount,
    ImageSearch,
    NewAddress,
    KnowMore,
    PhoneVerification,
    TransactionDetails,
    Confirmation,
    Payment,
    SecurityCode,
    InvoiceForm,
    FlexiblePackage,
    PasswordCheck,
    InfoUsageAgreement,
    ReturnItem,
    Feedback,
    ProductStats,
    BlockedReason,
    PhoneConfirmation,
    ProductDateInfo,
    ProductSort,
    FeedbackOnline,
    NotificationText,
    AddAccountNumber,
    ReminderOnline,
    AddReview,
    ConcurentionAgancy,
    ReturnConditionsModal,
    NewAccount,
    PhotoMobile,
    AddCard,
    CardDeleteWarning,
    Warning,
    CardPlatforms,
    PaymentOptions,
    // RTransactionDetails,
    // RBlockedReason,
    // RPhoneConfirmation,
    // RAddReview,
    // RLoanReBank,
    // RInstallmentForm
} from 'components/modals'

const Component = (props) => {
    // console.log(props)
    const modalsList = [
        {Name: 'Response',Component: Response},
        {Name: 'NewProduct',Component: NewProduct},
        {Name: 'TBCConnect',Component: TBCConnect},
        {Name: 'CommunicationPoll',Component: CommunicationPoll},
        {Name: 'OfferPrice',Component: OfferPrice},
        {Name: 'BuyPromotion',Component: BuyPromotion},
        {Name: 'ProveLoan',Component: ProveLoan},
        {Name: 'MessageToUser',Component: MessageToUser},
        {Name: 'LoginRequired',Component: LoginRequired},
        {Name: 'SecurityFishing',Component: SecurityFishing},
        {Name: 'TbcCreditForm',Component: TbcCreditForm},
        {Name: 'CreateDiscount',Component: CreateDiscount},
        {Name: 'ImageSearch',Component: ImageSearch},
        {Name: 'NewAddress',Component: NewAddress},
        {Name: 'KnowMore',Component: KnowMore},
        {Name: 'PhoneVerification',Component: PhoneVerification},
        {Name: 'TransactionDetails',Component: TransactionDetails},
        {Name: 'ConcurentionAgancy',Component:ConcurentionAgancy},
        {Name: 'ReturnConditionsModal',Component:ReturnConditionsModal},
        {Name: 'Confirmation',Component: Confirmation},
        {Name: 'Payment',Component: Payment},
        {Name: 'SecurityCode',Component: SecurityCode},
        {Name: 'InvoiceForm',Component: InvoiceForm},
        {Name: 'FlexiblePackage',Component: FlexiblePackage},
        {Name: 'PasswordCheck',Component: PasswordCheck},
        {Name: 'InfoUsageAgreement',Component: InfoUsageAgreement},
        {Name: 'ReturnItem',Component: ReturnItem},
        {Name: 'Feedback',Component: Feedback},
        {Name: 'ProductStats',Component: ProductStats},
        {Name: 'BlockedReason',Component: BlockedReason},
        {Name: 'PhoneConfirmation',Component: PhoneConfirmation},
        {Name: 'ProductDateInfo', Component: ProductDateInfo},
        {Name: 'ProductSort', Component: ProductSort},
        {Name: 'FeedbackOnline',Component: FeedbackOnline},
        {Name: 'NotificationText',Component: NotificationText},
        {Name: 'AddAccountNumber',Component: AddAccountNumber},
        {Name: 'ReminderOnline',Component:ReminderOnline},
        {Name: 'ConcurentionAgancy',Component:ConcurentionAgancy},
        {Name: 'ReturnConditionsModal',Component:ReturnConditionsModal},
        {Name: 'NewAccount', Component: NewAccount},
        {Name: 'PhotoMobile', Component: PhotoMobile},
        {Name: 'AddCard', Component: AddCard},
        {Name: 'PaymentOptions', Component: PaymentOptions},
        // {Name: 'TransactionDetails',Component: RTransactionDetails},W
        // {Name: 'BlockedReason',Component: RBlockedReason},
        // {Name: 'PhoneConfirmation',Component: RPhoneConfirmation},
        // {Name: 'CreateDiscount',Component: RCreateDiscount},
        {Name: 'AddReview',Component: AddReview},
        {Name: 'CardDeleteWarning',Component: CardDeleteWarning},
        {Name: 'Warning',Component: Warning},
        {Name: 'CardPlatforms',Component: CardPlatforms},
        // {Name: 'OfferPrice',Component: ROfferPrice},
        // {Name: 'LoanReBank',Component: RLoanReBank},
        // {Name: 'InstallmentForm',Component: RInstallmentForm},
    ]
    let Modal = modalsList.find(o => o.Name === props.component)?.Component
    return (Modal && !props.modalLoading) ? <Modal {...props} /> : <Loader/>
}
export default Component
