import React, { Suspense } from 'react';
import ImageLoader from 'components/icons/ImageLoader'
import {useImage} from 'react-image'
import noImage from 'resources/img/no-image.png'
const ImageComponent = (props) =>{
	const {src} = useImage({
		srcList: [props.src, noImage]
	})
	return <img className={props.className} src={src} alt={props.alt} />
}

const LazyImage = (props) => {
 	return (
		<Suspense fallback={<ImageLoader/>}>
			<ImageComponent {...props} />
		</Suspense>
 	)
	
}

export default LazyImage