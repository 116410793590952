import React, { createContext, useState, useMemo, useContext } from 'react';
import { Toast, ToastContainer } from "components";


const generateId = () => {
	return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};

const ToastsContext = createContext({});

const ToastsProvider = ({ children }) => {

	const [toasts, setToasts] = useState([]);

	const open = (message) => {
		setToasts((currentToasts) => [
			...currentToasts,
			{id: generateId(), message},
		]);
	};

	const close = (id) => {
		setToasts((currentToasts) => {
					return currentToasts.filter((toast) => toast.id !== id);
				}
		);
	};

	const clear = () => {
	  setToasts([]);
	};

	const contextValue = useMemo(() => ({ open, clear, toasts }), []);


	return (
			<ToastsContext.Provider value={contextValue}>
				{children}
				<ToastContainer>
					{[...toasts].reverse().map((toast) => (
							<Toast key={toast.id} message={toast.message} onClose={() => close(toast.id)} />
					))}
				</ToastContainer>
			</ToastsContext.Provider>
	);
};

export const useToasts = () => {
  return useContext(ToastsContext);
}

export default ToastsProvider;