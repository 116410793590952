import React from 'react'
const UpdateBtn = props => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${props.className ? props.className : ''}`}>
<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M4.37517 8.91882C4.81217 8.91882 4.98717 9.1859 4.63717 9.54267L3.32517 10.8801C4.01077 11.5957 4.88012 12.1002 5.83379 12.3359C6.78746 12.5716 7.78695 12.5289 8.71794 12.2127C9.64893 11.8966 10.4738 11.3197 11.0983 10.5481C11.7228 9.7765 12.1216 8.84132 12.2492 7.84952C12.2931 7.63103 12.4149 7.4369 12.5912 7.30469C12.7674 7.17248 12.9854 7.11158 13.2031 7.13378C13.4208 7.15597 13.6227 7.25967 13.7698 7.42483C13.9168 7.58999 13.9986 7.80487 13.9992 8.0279V8.20629C13.746 9.90778 12.8982 11.4587 11.6118 12.5732C10.3255 13.6878 8.68732 14.2909 6.99917 14.2715C5.14169 14.258 3.3588 13.5247 2.01217 12.2205L0.61217 13.6476C0.26217 14.0044 0.000168753 13.826 0.000168753 13.3806V9.36428C-0.00333015 9.30486 0.00557768 9.24535 0.0263013 9.18968C0.047025 9.13402 0.079094 9.08347 0.120393 9.04138C0.161691 8.99928 0.21128 8.96659 0.265885 8.94546C0.32049 8.92434 0.378869 8.91526 0.437169 8.91882H4.37517ZM9.62417 5.35107C9.18717 5.35107 9.01217 5.084 9.36217 4.72722L10.6742 3.38982C9.98844 2.67412 9.11893 2.16963 8.16511 1.93405C7.21129 1.69848 6.21168 1.74133 5.28063 2.05773C4.34959 2.37412 3.52471 2.95126 2.90036 3.72314C2.27602 4.49501 1.87742 5.43044 1.75017 6.42241C1.71641 6.65028 1.59801 6.8562 1.41946 6.99756C1.24091 7.13893 1.01588 7.20491 0.790938 7.18186C0.565995 7.15881 0.358354 7.0485 0.210977 6.87374C0.063599 6.69899 -0.0122343 6.47317 -0.000830698 6.24301V6.06564C0.2523 4.36416 1.10015 2.81325 2.3865 1.69868C3.67285 0.584122 5.31102 -0.0189977 6.99917 0.000456346C8.85665 0.0139633 10.6395 0.747192 11.9862 2.0514L13.3862 0.624303C13.7362 0.267528 13.9982 0.445917 13.9982 0.891376V4.90459C14.0017 4.96402 13.9928 5.02353 13.972 5.07919C13.9513 5.13485 13.9192 5.1854 13.8779 5.2275C13.8366 5.2696 13.7871 5.30229 13.7325 5.32341C13.6778 5.34454 13.6195 5.35362 13.5612 5.35005L9.62417 5.35107Z" fill="#0EC604"/>
</svg>



    </span>
)

export default UpdateBtn