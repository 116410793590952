import React from 'react';

const Checkbox = ({ label, name, id = name, checked, onChange, className }) => {
	return (
			<div className={`d-flex align-items-center ${className}`}>
				<label className="checkbox-switch mb-0">
					<input
							type="checkbox"
							className="hidden opacity-0 h-0 w-0"
							name={name}
							id={id}
							checked={checked}
							onChange={onChange}
					/>
					<span className="slider round"></span>
				</label>

				<label htmlFor={id} className="pl-12px my-0 d-flex cursor-pointer disable-user-select">
					{label}
				</label>
			</div>
	);
};

export default Checkbox;