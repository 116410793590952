import React from 'react'

const Response = (props) => {
	// console.log(props)
	return (
		<>
			<div className={`custom-modal-wraper ${props.styles.wraper}`}>
				<div className={`custom-modal-header ${props.styles.header}`}>
					<div className="py-32px text-center">
						<div className="p-12px">
							<i className={`svg-i ${!props.data.statusCode ? 'error-message' : 'success-messages'} mx-auto`}></i>
						</div>
					</div>
				</div>
				<div className={`custom-modal-content ${props.styles.content}`}>
					<div className="pb-48px">
						<div className="font-size-16 font-base text-blue-gray text-center">
							{props.data.statusMessage ? props.data.statusMessage : 
								(props.data.statusCode ? props.langs.SuccessMessage : props.langs.ErrorMessage)
							}
						</div>
						<div className=""></div>
					</div>
				</div>
			</div>
			<div className={`custom-modal-actions ${props.styles.actions}`}>
				<div className="pb-28px mt-0 d-flex justify-content-center">
					<button type="button" className="font-size-14 font-base-caps px-64px py-16px btn bg-middle-gray mx-8px text-blue-gray" 
						onClick={(e) => props.callback(e, 'destroy')}>
						{props.langs.Close}
					</button>
				</div>
			</div>
		</>
	)
}

export default Response
