import React, {useContext} from 'react'
import { HistoryContext } from 'contexts/history';

const NewProduct = (props) => {
    const { push, params } = useContext(HistoryContext)

    const success = (e, pcat) => {
        e.preventDefault() || e.stopPropagation();
        if (!params.PCat || parseInt(params.PCat) !== pcat) {
            push(`/${props.getLang}/pr-form`, { PCat: pcat }, true)
        }
        props.callback(null, 'preDestroy')
        props.callback(null, 'destroy')
    }

    return (
       <div className="custom-modal-wrapper">
           <div className={`position-relative d-flex flex-column align-items-center justify-content-center px-md-40px py-40px`}>
               <button className="btn p-32px  font-size-14 font-bold-caps text-dark d-flex align-items-center justify-content-center text-decoration-none add-btns-custom w-100 mb-16px border-radius-md"
                   onClick={(e) => success(e, 0)}>
                   {props.langs.Sell}
               </button>
               <button className="btn p-32px  font-size-14 font-bold-caps text-dark d-flex align-items-center justify-content-center text-decoration-none add-btns-custom w-100 mb-16px border-radius-md"
                   onClick={(e) => success(e, 2)}>
                   {props.langs.Rent}
               </button>
               <button className="btn p-32px  font-size-14 font-bold-caps text-dark d-flex align-items-center justify-content-center text-decoration-none add-btns-custom w-100 border-radius-md"
                   onClick={(e) => success(e, 1)}>
                   {props.langs.Services}
               </button>
           </div>
       </div>
    )
}

export default NewProduct