import React from 'react'
import Select from 'react-select';
import {Field, ErrorMessage} from 'formik';
import { SummaryText, SummaryBox, ProductInfo } from '../components';

const Component = (props) => {
    return (
        <div className={props.currentStep === 1 ? 'd-none' : ''}>
            <ProductInfo {...props} />
            <div className="mt-16px mt-md-36px">
                <div className="row m-0 d-flex product-details-card border-top-0 pb-30px justify-content-between">
                    <div className="col-12 col-md-6 p-0 pr-md-24px">
                        <div className="form-group p-0 m-0">
                            <label htmlFor="Amount" className="font-medium font-size-12 font-weight-normal text-text_label">
                                {props.langs.NM_installment_amount} <span className="required text-blue">*</span>
                            </label>
                            <Field type="number" name="Amount" className="position-relative border-radius-10 border-solid-1 border-gray bg-white px-12px py-auto w-100 outline-none" disabled/>
                            <ErrorMessage name="Amount" component="label" className="error"/>
                        </div>
                        <div className="form-group p-0 m-0">
                            <label htmlFor="FirstPaymentAmount" className="font-medium font-size-12 font-weight-normal text-text_label">
                                {props.langs.NM_LoanFirstAmount} <span className="required text-blue">*</span>
                            </label>
                            <Field type="number" name="FirstPaymentAmount" placeholder="0" className="position-relative border-radius-10 border-solid-1 border-gray bg-white px-12px py-auto w-100 outline-none"/>
                            <ErrorMessage name="FirstPaymentAmount" component="label" className="error"/>
                        </div>
                        <div className="form-group payment-period p-0 m-0">
                            <label htmlFor="Period" className="font-medium font-size-12 font-weight-normal text-text_label">
                                {props.langs.PaymentPeriod} <span className="required text-blue">*</span>
                            </label>
                            <Select
                                name="Period"
                                className="border-radius-10 border-solid-1 border-gray bg-white py-auto w-100 cursor-pointer"
                                classNamePrefix="sg-selectbox"
                                placeholder={props.langs.NM_LoanPeriodPlaceholder}
                                noOptionsMessage={() => props.langs.NM_NothingFound}
                                options={props.periodList}
                                isSearchable={false}
                                onChange={(value) => props.setFieldValue("Period", value.value)}
                                value={{value: props.values.Period, label: `${props.values.Period} ${props.langs.Month}`}}
                            />
                            <ErrorMessage name="Period" component="label" className="error"/>
                        </div>
                    </div>
                    <SummaryBox {...props} />
                </div>
            </div>
            <SummaryText {...props} />
        </div>
    )
}

export default Component