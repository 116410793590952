import React from 'react'
const Tradein = (className = '') => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center tradein-icon ${className}`}>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="6" fill="#F8F9FD"/>
<path d="M16.0007 9.33334C12.3207 9.33334 9.33398 12.32 9.33398 16C9.33398 19.68 12.3207 22.6667 16.0007 22.6667C19.6807 22.6667 22.6673 19.68 22.6673 16C22.6673 12.32 19.6807 9.33334 16.0007 9.33334ZM16.0007 21.3333C13.0607 21.3333 10.6673 18.94 10.6673 16C10.6673 13.06 13.0607 10.6667 16.0007 10.6667C18.9407 10.6667 21.334 13.06 21.334 16C21.334 18.94 18.9407 21.3333 16.0007 21.3333ZM18.7807 17.84L18.0473 17.1067C18.5207 16.22 18.4007 15.1 17.654 14.3533C17.194 13.8933 16.6007 13.6667 16.0007 13.6667C15.9807 13.6667 15.9607 13.6733 15.9407 13.6733L16.6673 14.4L15.9607 15.1067L14.074 13.22L15.9607 11.3333L16.6673 12.04L16.0273 12.68C16.874 12.6867 17.714 13 18.3607 13.64C19.494 14.78 19.634 16.5467 18.7807 17.84ZM17.9273 18.78L16.0407 20.6667L15.334 19.96L15.9673 19.3267C15.1273 19.32 14.2873 18.9933 13.6473 18.3533C12.5073 17.2133 12.3673 15.4533 13.2207 14.16L13.954 14.8933C13.4807 15.78 13.6007 16.9 14.3473 17.6467C14.814 18.1133 15.434 18.34 16.054 18.32L15.334 17.6L16.0407 16.8933L17.9273 18.78Z" fill="#8996AE"/>
</svg>


    </span>
)

export default Tradein