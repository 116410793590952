import React, {useEffect} from "react";
const Sixth = (props) => {
  const {langs, result, cancel, success} = props;
  useEffect(() => {
    setTimeout(() => {
      if (result.statusCode) {
        success();
      }
      cancel()
    }, 5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="pt-24px pb-32px">
      {result.statusCode ?
        <div>
          <i className="svg-i return-success w-60px h-60px mx-auto"></i>
          <div className="font-size-18 text text-nLight-dark text-center mt-24px">
            {langs.NM_ConfirmReturnAccepted}
          </div>
        </div>
      : 
        <div>
          <i className="svg-i error-message w-60px h-60px mx-auto"></i>
          <div className="font-size-18 text text-nLight-dark text-center mt-24px">
            {langs.ErrorMessage}
          </div>
        </div>
      }
    </div>
  )
}
export default Sixth