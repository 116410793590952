import React, { useEffect, useState, useRef } from 'react';
// import { RatingStars } from 'components';
import ProductSlider from './ProductSlider';
import { Parser } from 'components';
const BoxView = (props) => {
  const [titleHeight, setTitleHeight] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current !== null) {
      setTitleHeight(ref.current.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);


  return (
    <div className={`bg-white border-radius card-hover  h-354px px-6px px-md-12px pt-8px pt-md-12px pb-16px position-relative`}>
      {props.data.search &&
        <div className="vip-box d-flex align-items-center position-absolute top-114px top-md-22px left-12px left-md-18px">
          {props.data.product.discount > 0 &&
            <div
              className="percent bg-dark-orange text-white mr-4px box-item text-white arctic-headline font-size-12">
              {`-${props.data.product.discount}%`}
            </div>
          }
          {props.prom && <div className={props.prom.className + ' hide-on-mobile'}>{props.prom.text}</div>}
        </div>
      }

      <div className={'hide-on-desktop position-absolute left-0 d-flex flex-row left-11px top-12px z-index-11'} style={{gap: props.prom ? '4px' : '0'}}>
      <div className={'vip-box d-flex align-items-center'}>
        {props.prom && <div className={props.prom.className}>{props.prom.text}</div>}
      </div>
		{ (props.langs.CondTypes[props.data.product.cond_type_id] && props.appData.Cats.List[props.data.product.catId]?.HasCondType)  &&
        <div className={`conditions-badge h-22px d-flex align-items-center font-size-10 font-medium px-8px border-radius-md z-index-9
         ${(props.data.product.cond_type_id && parseInt(props.data.product.cond_type_id) === 1) ? 'bg-dark-green text-white' : 'bg-blue text-white'}`}>
          				{props.langs.CondTypes[props.data.product.cond_type_id]}
          </div>
      }
      </div>

          {/* {console.log(props.appData)} */}

      <div className="card-img-box d-flex justify-content-center position-relative ">
          { (props.langs.CondTypes[props.data.product.cond_type_id] && props.appData.Cats.List[props.data.product.catId]?.HasCondType)  &&
              <div className={`conditions-badge h-22px d-flex align-items-center font-size-10 font-medium px-8px border-radius-md z-index-9 hide-on-mobile position-absolute top-10px right-4px
         ${(props.data.product.cond_type_id && parseInt(props.data.product.cond_type_id) === 1) ? 'bg-dark-green text-white' : 'bg-blue text-white'}`}>
                  {props.langs.CondTypes[props.data.product.cond_type_id]}
              </div>
          }
        {(props.data.product.quantity > 0 && props.data.product.shop_type_id === 1) &&
          <div className={`cashback-badges position-absolute left-8px bottom-8px d-flex position-absolute z-index-9 ${(props.data.product.isEmoney || props.data.product.isMyCoins) ? 'f-flex flex-column flex-sm-row align-items-start' : ''}`}>
            {props.data.product.isVisaPay &&
              <div className=" d-flex bg-white text-black mr-4px  box-item arctic-headline font-size-10 px-8px">
                <i className="svg-i svg-i-md visa-i w-26px h-8px mr-4px" />
                <div className="text-black font-size-10">{props.data.product.isVisaPay.PERCENT}%</div>
              </div>
            }
            {(props.isSafari && props.data.product.isApplePay) &&
              <div className="">
                <i className="svg-i applePayCard-i w-100px h-22px"></i>
              </div>
            }
            {props.data.product.isEmoney &&
              <div className=" d-flex bg-white text-black mr-4px  box-item arctic-headline font-size-10 px-8px">
                <i className="svg-i svg-i-md badge-emoney w-40px  mr-4px" />
                <div className="text-black font-size-10 font-bold">{props.data.product.isEmoney.PERCENT}%</div>
              </div>
            }
            {props.data.product.isMyCoins &&
              <div className="mt-4px mt-sm-0">
                <div className="d-flex bg-white text-black mr-4px  box-item arctic-headline font-size-10 px-8px">
                  <i className="svg-i svg-i-md Crypto  w-12px h-12px mr-4px" />
                  <div className="text-black font-size-10 font-bold">{props.langs.NM_Cashback}</div>
                </div>
              </div>
            }
          </div>
        }
        {props.data.product.isInstallment &&
          <div className="cashback-badges position-absolute left-8px bottom-8px d-flex position-absolute z-index-9">
            <div className=" d-flex bg-blue-tbc text-black mr-4px  box-item arctic-headline font-size-10 px-8px">
                <i className="svg-i w-12px h-12px tbc-button-icon-i  mr-6px" />
                <div className="text-white font-size-10 font-base d-flex align-items-center">
                <span className="d-flex font-size-10 font-bold">
                  -{props.data.product.isInstallment.MAX_AMOUNT}
                </span>
                <span className="d-flex mr-2px">{props.currency()}</span>
                  {props.langs.NM_WhenInstallment}
                </div>
              </div>
          </div>
        }
        <ProductSlider {...{
          product: props.data.product,
          noSwiper: props.data.noSwiper,
          activeStyle: props.activeStyle,
          favorites: props.favorites,
          isSearch: props.isSearch
        }} />
      </div>
      <div className="card-infos mt-12px">
        {(props.data.product.shop) ?
          <div className="font-size-10 text-blue-gray d-flex justify-content-between align-items-center mb-8px">
            <div className="d-flex justify-content-start align-items-center ">
              <i className={`svg-i svg-i-md  mr-6px shop-icon-card`}></i>
              <span className="clamp _1 font-medium">
                {props.data.product.shop }
              </span>
            </div>
            {(props.data.product.shop && props.data.product.shop_type_id === 1 && parseInt(props.data.product.rates_count) > 0) &&
              <div className="rating-card-box">
                <div className="d-flex justify-content-center">
                  <div className="d-flex align-items-center d-flex justify-content-center text-nLight-dark font-size-12 font-base">
                    {parseFloat(props.data?.product?.rating).toFixed(1)} 
                    <i className="svg-i rate-active ml-4px"></i>
                  </div>
                  {/* <span className="font-size-12 text-blue_2 d-flex justify-content-center">
                    ({props.data.product.rates_count})
                  </span> */}
                </div>
              </div>
            }
          </div>
          :
            <div
                className="font-base font-size-12 text-blue-gray d-flex justify-content-between align-items-center mb-8px w-100 ">
              <div className='d-flex justify-content-center '>
                <i className={`svg-i svg-i-md  mr-6px user-icon-card `}></i>
                <span className='clamp _1'>

                {props.langs.PhysicalPerson}
                </span>
              </div>
              {props.data?.product?.rating > 0  && (
                <div
                    className="d-flex align-items-center d-flex justify-content-center text-nLight-dark font-size-12 font-base">
                  {parseFloat(props.data?.product?.rating).toFixed(1)}
                  <i className="svg-i rate-active ml-4px"></i>
                </div>
              )
              }
            </div>

        }

        <div className={`h-18px`}>
          {(parseInt(props.data.product.available_to_shipping) === 1 || props.data.product.shop_type_id === 1) &&
              <div className="d-flex align-items-center ">
              <i className="svg-i w-18px h-18px card-online-i mr-4px"></i>
              <span className="font-size-11 font-size-md-12 font-base text-dark-green">{props.langs.NM_courierService}</span>
            </div>
          }
        </div>
        <h4 ref={ref} className={`product-title mb-0  text-black font-base font-size-14 line-height-12 mt-2px clamp ${props.data.product.title.length <= 27 ? '_1' : '_2'}`}>
          {props.data.product.title}
        </h4>
        {/* {console.log(props.data.product.cond_type_id)} */}
        {(props.data.product.title.length <= 27 && titleHeight < 48) &&
          <div className={`h-22px font-size-12 line-height-12 font-base text-blue-gray clamp _1 `}>
            <Parser html={props.data.product.desc.replace(/(<([^>]+)>)/gi, "")} />
          </div>
        }

        <div className="card-footer border-top border-2px border-rgba-blue-gray-15 pt-7px mt-7px d-flex flex-column flex-md-row justify-content-between align-items-center px-0">
          <div className="card-price-box mb-12px mb-md-0">
            {!parseInt(props.data.product.discount) ?
              <span className="card-text price d-flex align-items-center justify-content-center justify-content-md-start  line-height-3">
                <span className="mr-3px d-flex">
                  {parseFloat(props.data.product.price) > 0 ?
                    <span
                      className="text-black font-bold font-size-14 line-height-6">
                      {props.data.product.price}
                    </span>
                    :
                    <span className="text-blue font-base font-size-12 line-height-6">
                      {props.langs.PriceWithAgreement}
                    </span>
                  }
                </span>
                <span className="text-black font-medium  font-size-14 ">
                  {(props.data.product.price) > 0 ? props.currency() : ''}
                </span>
              </span>
              : (props.data.product.discount > 0 && props.data.product.old_price > 0) ?
                <div className="d-flex align-items-center">
                  <span
                    className="card-text price text-black text-black font-size-14 d-flex align-items-center ">
                    <span className="mr-3px font-medium">
                      {parseFloat(props.data.product.price) > 0 ?
                        props.data.product.price
                        :
                        props.langs.PriceWithAgreement}
                    </span>
                    {(props.data.product.price) > 0 && props.currency()}
                  </span>
                  <div className="ml-4px d-flex align-items-center">
                    <span
                      className="card-text card-old position-relative price text-black opacity-25 font-size-12 d-flex align-items-center">
                      <span className="mr-3px font-medium">
                        {props.data.product.old_price}
                      </span>
                      {/* {props.currency()} */}
                    </span>
                  </div>
                </div>
                : <></>
            }
            <div className="h-20px h-md-auto">
              {(props.data.product.sell_online === 1 && props.data.product.price >= 100) &&
                <div className="text-blue-gray font-size-12 font-medium line-height-6">
                  {props.langs.NM_InstallmentPerMonth}
                  <span className="text-blue ml-6px font-bold">
                    {props.data.product.installment_price}{props.currency()}
                  </span>
                  <span className={'font-bold'}>
                  -{props.langs.From}
                  </span>
                </div>
              }
            </div>
          </div>
          <div className="">
            {props.data.favorites &&
              <div className="d-flex justify-content-center fav_add">
                {
                  (props.data.product.shop && props.data.product.shop.type_id === 1) &&
                  <button type="button"
                    className={`btn toggle-cart  bg-rgba-blue-gray-15 border-radius-xs font-base p-8px my-auto ${props.checkCartPr ? 'active' : ''} `}
                    onClick={props.toggleCartPr}>
                    <i className="svg-i svg-i-sm cart-i"></i>
                  </button>
                }
                <button type="button"
                  className={`btn toggle-fav bg-rgba-blue-gray-15 border-radius-xs font-base p-8px ml-4px ${props.checkFavPr ? 'active' : ''} `}
                  onClick={props.toggleFavPr}
                  title={props.checkFavPr ? props.langs.RemoveFromFav : props.langs.AddToFav}
                >
                  <i className="svg-i svg-i-md favorites-white-i"></i>

                </button>


              </div>
            }

            {props.data.search &&
              <div className="d-flex justify-content-center fav_add">
                {
                  (props.data.product.quantity > 0 && props.data.product.shop_type_id === 1) &&
                  <button type="button"
                    className={`btn toggle-cart bg-rgba-blue-gray-15 border-radius-xs  p-8px my-auto ${props.checkCartPr ? 'active' : ''} `}
                    onClick={props.toggleCartPr}>
                    <i className="svg-i svg-i-20 cart-i"></i>
                  </button>
                }
                <button type="button"
                  className={`btn toggle-fav bg-rgba-blue-gray-15 border-radius-xs  p-8px ml-4px ${props.checkFavPr ? 'active' : ''} `}
                  onClick={(e) => {
                    props.toggleFavPr(e);
                  }}
                  title={props.checkFavPr ? props.langs.RemoveFromFav : props.langs.AddToFav}
                >
                  <i className="svg-i svg-i-20 favorites-i"></i>
                </button>

              </div>
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default BoxView;