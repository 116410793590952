import React, {useState, useEffect, useContext} from 'react';
import {Helmet} from "react-helmet";

import { HistoryContext } from 'contexts/history';

const HeadMeta = (props) => {
	const { getLang, langs, FixedLists } = props;
	const { history, getPagename }                  = useContext(HistoryContext)
	const metaConfig                                = FixedLists.getHeadMeta(langs)
	const mainTitle = langs.NM_MetaTitle ?? 'მეორადი და ახალი ნივთების ყიდვა-გაყიდვის პლატფორმა Mymarket';
	const getDefaults = () => {
		return {
			title: mainTitle,
			description: langs.MetaDescr,
			keywords: langs.MetaKeywords,
		}
	}
	
	const [page, setPage]                        = useState(null)
	const [meta, setMeta]                        = useState(getDefaults())

	const processPage = () => {
		let newPage = getPagename()
		if (page !== newPage) {
			setPage(newPage)
		}
	}

	useEffect(() => {
		if (page) {
			let newConfig = metaConfig[page]
			if (newConfig) {
				let newMeta = {
					title: `${mainTitle}${newConfig.title ? ` - ${newConfig.title}` : ''}`,
					description: newConfig.description ? newConfig.description : langs.MetaDescr,
					keywords: newConfig.keywords ? newConfig.keywords : langs.MetaKeywords,
				}
				setMeta(newMeta)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page])

	useEffect(() => {
		processPage()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location])

	return (
		<Helmet htmlAttributes={{lang: getLang}}>
			<title>{meta.title}</title>
			<meta name="description" lang={getLang} content={meta.description} />
			<meta name="keywords" lang={getLang} content={meta.keywords} />
		</Helmet>
	)
}

export default HeadMeta;