import React from 'react'
import { EmptyStates } from 'components'

const FinancesEmpty = (props) => {

    const Icon = () => {
        return (
            <div className=""></div>
        )
    }

    const Title = () => {
        return (
            <div className="mb-16px font-size-16 font-bold-caps text-dark">
                {props.title}
            </div>
        )
    }
    const Description = () => {
        return (
            <div className="max-width-356px text-center font-size-14 font-base text-blue-gray">
                 {props.description}
            </div>
        )
    }
    return <EmptyStates {...{Icon, Title, Description,  classIcon:"svg-i empty-finances mb-24px" , classContainer:'mt-8px', classInner: "border-radius-md bg-white pt-100px pb-200px "}} />
}

export default FinancesEmpty