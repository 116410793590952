import { useCardIcon, useEventListener, useMediaQuery } from 'libs';
import moment from 'moment/moment';
import React, { useState, useEffect, useContext, useCallback } from 'react';

import { HistoryContext } from 'contexts/history';
import { AppDataContext } from 'contexts/appData';
import { UserContext } from 'contexts/user';
import { ModalsContext } from 'contexts/modals';

import { Loader, Checkbox } from 'components';
import * as PMT from 'components/common/payment';
import PaymentItem from 'components/payment/PaymentItem';
import PaymentCardItem from 'components/payment/PaymentCardItem';
import TnetPlatforms from './components/TnetPlatforms';


const Payment = (props) => {
  // console.log(props)
  const { data, callback } = props;
  // data.methods []
  // data.price
  // data.addressID
  // data.express
  // data.procedures

  // For every Modal to pass the procedures list:
  // procedures: [{method: 2, procedure: "inTesting"}],

  const { getPathname } = useContext(HistoryContext);
  const { api, langs, getLang, currency, CONFIG } = useContext(AppDataContext);
  const { accountDetails, user } = useContext(UserContext);
  const { callResponse } = useContext(ModalsContext);
  const isMobile = useMediaQuery('(max-width: 768px)')

  const [availableMethods, setAvailableMethods] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(false);
  const [balance, setBalance] = useState(null);

  const [inProgress, setInProgress] = useState(false);

  // cards
  const [saveCard, setSaveCard] = useState(true);
  const [cards, setCards] = useState([]);
  const [cardSelection, setCardSelection] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [iframe, setIframe] = useState(null);
  const { getCardIcon } = useCardIcon();
  //

  const isPromotion = data.promotion && data.promotion.data.id;
  const isTradeIn = data.tradein ? data.tradein : false;
  const UserAddressID = data.addressID ? data.addressID : 258;
  const Express = data.express ? data.express : 0;
  const Procedures = data.procedures ? data.procedures : false;

  const [paymentForm, setPaymentForm] = useState({});
  // const [termsAgreement, setTermsAgreement]       = useState(false);

  const isNewCard = (!selectedCard && saveCard && selectedMethod.id === 2);

  const processAmount = () => {
    let totalAmount = data.price ? data.price : 0;
    if (totalAmount === 0 && data.promotion) {
      if (data.promotion.quantity && data.promotion.data) {
        totalAmount = data.promotion.quantity * data.promotion.price * data.promotion.products.length;
      }
    } else if (data.promPackage && data.promPackage.prom_package_id) {
      totalAmount = data.promPackage.price;
    }
    return parseFloat(totalAmount).toFixed(2);
  };
  const totalPrice = processAmount();

  const buyPromotion = async (callback = false) => {
    if (inProgress) {
      return false;
    }
    let prom = data.promotion;
    let apiData = null;
    let set = {
      PrIDs: prom.products,
      pcount: prom.products.length,
      PayWithPromPackage: selectedMethod.id === 8 ? 1 : 0,
      Quantity: prom.quantity,
    };

    if(selectedCard && selectedMethod.id === 2) {
      set['UserCardID'] = selectedCard.uuid;
      set['PayWith'] = 'charge';
    }

    // console.log(prom)
    if ([CONFIG.PROM_ORDERS.SUPER_VIP, CONFIG.PROM_ORDERS.VIP_PLUS, CONFIG.PROM_ORDERS.VIP].includes(prom.data.id)) {
      set['OrderValue'] = prom.data.id;
      apiData = await api.addPromVip({ ...selectedMethod.request, ...set });
    } else if ([CONFIG.PROM_ORDERS.COLOR].includes(prom.data.id)) {
      apiData = await api.addPromColor({ ...selectedMethod.request, ...set });
    } else if ([CONFIG.PROM_ORDERS.PAYD_RENEW, CONFIG.PROM_ORDERS.AUTO_RENEW].includes(prom.data.id)) {
      if (prom.data.id === CONFIG.PROM_ORDERS.AUTO_RENEW) {
        set['Hour'] = prom.hour;
        set['UpdateTypeID'] = 1;
      } else {
        set['UpdateTypeID'] = 0;
      }
      apiData = await api.addPromAutoUpdate({ ...selectedMethod.request, ...set });
    }

    setInProgress(false);
    if (apiData !== null && apiData.statusCode) {
      if (!callback) {
        success();
        return true;
      } else {
        return apiData.data;
      }
    } else {
      callResponse({ statusCode: false, statusMessage: apiData.statusMessage });
      // close();
      return false;
    }
  };

  const buyTradeIn = async (callback = false) => {
    let set = { ...selectedMethod.request, ...isTradeIn };

    if(selectedCard && selectedMethod.id === 2) {
      set['UserCardID'] = selectedCard.uuid;
      set['PayWith'] = 'charge';
    }

    //set.PaymentMethod = 'emoneybalance'
    //delete  set.PaymentType
    //delete  set.PayWithCard
    const apiData = await api.tradeInCreateOrder(set);
    if (apiData.statusCode) {
      if (!callback) {
        success();
        return true;
      } else {
        return apiData.data;
      }
    } else {
      callResponse({ statusCode: false, statusMessage: apiData.statusMessage });
      return false;
    }
  };

  const generatePaymentOrder = async () => {
    const apiData = await api.payment(generateConfigurationSet());
    if (apiData.statusCode) {
      // console.log(apiData)
      proceedToPayment(apiData.data);
    } else {
      callResponse({ statusCode: false, statusMessage: false });
    }
  };

  const proceedToPayment = (data) => {
    // console.log(data)
    if(!data) return false;

    if (data.PaymentURL) {
      setPaymentForm({ PaymentURL: data.PaymentURL, TransID: data.TransID });
      setTimeout(() => {
        document.getElementById('TransferToPayment').submit();
      }, 100);
      // callResponse({statusCode: true, statusMessage: data.PaymentURL})
    } else if (data.Code) {
      callResponse({ statusCode: true, statusMessage: data.Code });
      // if we dont have PaymentURL and method is card;
    } else if(selectedMethod.id === 2) {
      success();
    } else {
      return false;
    }
  };

  const generatePointsPaymentOrder = async () => {
    const apiData = await api.payment(generateConfigurationSet());
    if (apiData.statusCode) {
      processPointsPayment(apiData.data);
    } else {
      callResponse({ statusCode: false, statusMessage: false });
    }
  };

  const processPointsPayment = (data) => {
    if (data.PaymentURL) {
      window.location.href = data.PaymentURL;
    } else if (data.Code) {
      callResponse({ statusCode: true, statusMessage: data.Code });
    } else {
      return false;
    }
  };

  const generateConfigurationSet = () => {
    let result = {
      ...selectedMethod.request,
      ...{
        UserAddressID: UserAddressID,
        express: Express,
        Amount: totalPrice,
      },
    };

    if(selectedCard && selectedMethod.id === 2) {
      result['UserCardID'] = selectedCard.uuid;
      result['PayWith'] = 'charge';
    }

    if (data.promPackage) {
      result['PaymentType'] = 'prom_package_order';
      result['PackageID'] = data.promPackage.prom_package_id;
    }

    return result;
  };

  const ProceedToPayment = async (e) => {
    e.preventDefault() || e.stopPropagation();
    if (!selectedMethod) {
      return false;
    }
    setInProgress(true);
    switch (selectedMethod.id) {
      case 1:
        // if (selectedMethod.checkBalance) {
        if (isPromotion) {
          buyPromotion();
        } else if (isTradeIn) {
          buyTradeIn();
        } else {
          success();
        }
        // } else {
        // callResponse({ statusCode: false, statusMessage: langs.InsufficientAmount })
        // }
        break;
      case 2:

        if(!selectedCard && saveCard) {

          const res = await api.addUserCard({ ReturnURL: CONFIG.HOST.appUrl + getLang + '/process-card' }).finally(() => setInProgress(false));
          const data = res.data;
          if (data) {
            setIframe(data.PaymentURL + `?trans_id=${data.PaymentData.trans_id}`);
          }
          break;
        }

        if (isPromotion) {
          let data = await buyPromotion(!selectedCard);
          proceedToPayment(data);
        } else if (isTradeIn) {
          let data = await buyTradeIn(!selectedCard);
          proceedToPayment(data);
        } else {
          generatePaymentOrder();
        }
        break;
      case 3:
        if (isPromotion) {
          let data = await buyPromotion(true);
          processPointsPayment(data);
        } else if (isTradeIn) {
          let data = await buyTradeIn(true);
          processPointsPayment(data);
        } else {
          generatePointsPaymentOrder();
        }
        break;
      case 8:
        if (selectedMethod.checkPromotions && isPromotion) {
          buyPromotion();
        } else if (data.page && data.page === 'addProduct') {
          success({ PayWithPromPackage: 1 });
        } else {
          callResponse({ statusCode: false, statusMessage: langs.InsufficientAmount });
        }
        break;
      case 12:
        if (isPromotion) {
          let data = await buyPromotion(true);
          proceedToPayment(data);
        } else if (isTradeIn) {
          let data = await buyTradeIn(true);
          proceedToPayment(data);
        } else {
          generatePaymentOrder();
        }
        break;

      case 11:
        if (isPromotion) {
          let data = await buyPromotion(true);
          proceedToPayment(data);
        } else if (isTradeIn) {
          let data = await buyTradeIn(true);
          proceedToPayment(data);
        } else {
          generatePaymentOrder();
        }
        break;

      default:
        break;
    }
  };

  const fetchData = async () => {
    let [accoutData, cardsData] = await Promise.all([accountDetails(), api.getUserCards()]);

    if (accoutData) {
      let methodsConfig = PMT.getMethodsConfig({
        langs,
        CONFIG,
        getPathname,
        methods: data.methods,
        procedures: Procedures,
        isTradeIn,
      });
      setAvailableMethods(
        PMT.procedures({
          amount: totalPrice,
          methods: methodsConfig,
          userData: { ...user, ...accoutData },
        }),
      );

      setBalance(accoutData.AmountData.Amount);
    } else {
      callResponse({ statusCode: false, statusMessage: false });
    }

    const cards = cardsData?.data?.UserCards ?? [];

    setCards(cards);
    setSelectedCard(cards.find(card => card.favorite === '1') || cards[0]);

  };

  const close = (e) => {
    callback(e, 'destroy');
  };

  const success = (returnData = null) => {
    callback(returnData, 'success');
    close();
  };

  const handleMessage = useCallback((e) => {
    try {
      if (e.data?.event === 'card') {
        api.getUserCards().then(res => {
          const cards = res?.data?.UserCards ?? [];
          const cardWithMaxId = cards?.reduce((maxItem, currentItem) => {
            return (parseInt(currentItem.id) > parseInt(maxItem.id)) ? currentItem : maxItem;
          }, cards[0]);

          setCards(cards);
          setSelectedCard(cardWithMaxId ?? cards[0]);
          setIframe(null);
        })

        // 2, 3 - success statuses
        if(parseInt(e.data?.payload.StatusID) !== 2 && parseInt(e.data?.payload.StatusID) !== 3) {
          callResponse({ statusCode: false });
        }
      }
    } catch {
      //
    }
  }, []);

  useEffect(() => {
    processAmount();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEventListener('message', handleMessage);


  if(iframe) {
    return (
      <iframe title="addCard" className="mt-40px w-100 border-0 h-600px" src={iframe} />
    )
  }


  if (cardSelection) {
    return (
      <div className="custom-modal-wrapper">
        <div className="p-8px">
          <span
            className="svg-i back-arrow cursor-pointer"
            onClick={() => {
              const card = cards.find((card) => card.favorite === '1') || cards[0];
              setSelectedCard(cards.find((card) => card.favorite === '1') || cards[0]);
              setCardSelection(false);
              const isExpired = moment(new Date()).isSameOrAfter(moment(card?.expire_date ?? ''));
              if (isExpired) {
                setSelectedMethod(false);
              }
            }}
          />
        </div>

        <div className="pay-content pt-30px">
          <div className="position-relative">
            <form id="TransferToPayment" method="post" action={paymentForm.PaymentURL}>
              <input type="hidden" name="trans_id" readOnly value={paymentForm.TransID ?? 0} />
            </form>
            <div className="popup-content px-md-40px content-scroll">
              <h1 className="font-bold-caps font-size-24 text-dark-text text-center mb-8px">{langs.NM_PayWithCard}</h1>
              <p className="font-base font-size-14 text-blue-gray text-center">{langs.NM_ChooseOrAddCard}</p>
              <div className="pay-check mt-32px mb-40px">
                <div className="font-size-14 font-medium mb-12px text-dark">{langs.NM_MyCards}</div>
                {cards.map((card, i) => {
                  const isSelected = card.uuid === selectedCard?.uuid;
                  const Icon = getCardIcon(card.card_mask);
                  const isExpired = moment(new Date()).isSameOrAfter(moment(card.expire_date));
                  return (
                    <div className={`mb-8px ${isExpired ? 'pointer-events-none' : 'cursor-pointer'}`} key={i}>
                      <div
                        className={`d-flex justify-content-between align-items-center custom-control custom-radio border-radius-md py-24px m-auto ${
                          isSelected ? 'active' : ''
                        } ${isExpired ? 'expired bg-pink-error' : ''}`}
                        onClick={(e) => {
                          return setSelectedCard(card);
                        }}
                      >
                        <label className={`custom-control-label ${isSelected ? 'active' : ''}`}>
                          <Icon className="mr-14px" />
                          <div className={`font-medium font-size-14 text-text-black ${isExpired ? 'opacity-40' : ''}`}>
                            {isMobile ? card.card_mask.slice(-8) : card.card_mask}
                          </div>
                        </label>
                        {isExpired && (
                          <div className="bg-red-80 font-medium font-size-12 text-white py-4px px-8px ml-16px rounded mr-24px">{langs.NM_CardExpired}</div>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="font-size-14 font-medium mb-12px mt-32px text-dark">{langs.NM_AddNewCard}</div>

                <div className="mb-8px cursor-pointer">
                  <div
                    className={`d-flex justify-content-between align-items-center custom-control custom-radio border-radius-md py-24px m-auto ${
                      !selectedCard ? 'active' : ''
                    }`}
                    onClick={() => setSelectedCard(null)}
                  >
                    <div className={`d-flex align-items-center font-medium font-size-12 font-size-md-14 text-text-black`}>
                      <i className="svg-i svg-i-md add-statement-black-i mr-16px flex-shrink"></i>
                      {langs.NM_PayWithOtherCard}
                    </div>

                    <Checkbox
                      name="SaveCard"
                      className="mr-24px font-size-10 font-size-md-12"
                      checked={saveCard}
                      onChange={(e) => setSaveCard(e.target.checked)}
                      label={langs.NM_SaveCard}
                    />
                  </div>
                </div>
                {!selectedCard && saveCard && <TnetPlatforms />}
              </div>
            </div>

            <div className="popup-footer d-flex justify-content-between px-64px  pt-24px">
              {!isNewCard ? (
                <div className="text-left mb-16px my-md-auto   d-flex align-items-center d-md-block ">
                  <h2 className="font-bold-caps font-size-18 text-dark-text mr-8px m-md-0">
                    {totalPrice} {currency()}
                  </h2>
                  <p className="font-base font-size-12 text-blue-gray m-0">{langs.PriceToPay}</p>
                </div>
              ) : (
                <div></div>
              )}
              {inProgress ? (
                <Loader />
              ) : (
                <button
                  disabled={!selectedMethod}
                  className={`btn bg-gradient font-bold-caps font-size-13 text-white ${!selectedMethod ? 'disabled' : ''}`}
                  onClick={(e) => ProceedToPayment(e)}
                >
                  {isNewCard ? langs.NM_AddCard : langs.Pay}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
      <div className="custom-modal-wrapper">
        <div className="pay-content pt-40px">
          <div className="position-relative">
            <form id="TransferToPayment" method="post" action={paymentForm.PaymentURL}>
              <input type="hidden" name="trans_id" readOnly value={paymentForm.TransID ?? 0}/>
            </form>
            <div className="popup-content px-md-40px content-scroll">
              <h1 className="font-bold-caps font-size-24 text-dark-text text-center mb-8px">{langs.PayAmount}</h1>
              <p className="font-base font-size-14 text-blue-gray text-center">{langs.ChoosePaymentMethod}</p>
              <div className="pay-check mt-32px mb-40px">
                {availableMethods !== null && !inProgress ? (
                    availableMethods.map((method, i) => {
                      const isSelected = selectedMethod.id === method.id;
                      const isCard = method.id === 2;
                      const isExpired = moment(new Date()).isSameOrAfter(moment(selectedCard?.expire_date ?? ''));

                      if(isCard) {
                        return (
                          <PaymentCardItem
                            key={method.id}
                            card={selectedCard}
                            method={{title: method.style.title, icon: method.style.icon }}
                            isSelected={isSelected}
                            isExpired={isExpired}
                            onChange={!isExpired ? () => setSelectedMethod(method) : undefined}
                            saveCard={saveCard}
                            onSaveCard={(value) => setSaveCard(value)}
                            onCardChange={() => {
                              setCardSelection(true);
                              setSelectedMethod(method);
                              if(isExpired) setSelectedCard(false);
                            }}
                          />
                        );
                      }

                      return (
                        <PaymentItem
                          key={method.id}
                          method={{title: method.style.title, icon: method.style.icon, checkBalance: method.checkBalance }}
                          isSelected={isSelected}
                          isBalance={method.id === 1}
                          balance={balance}
                          onChange={() => setSelectedMethod(method)}
                      />)
                    })
                ) : (
                  <div className="h-300px"><Loader/></div>
                )}

                {isNewCard && <TnetPlatforms />}
                {/* {data.promPackage &&
								<>
									<div className="business-info business-info-payment  px-12px py-8px d-inline-flex position-relative w-100 mt-4px">
										<div className="d-inline-flex align-items-center justify-content-center w-100" >
											<i className="svg-i blue-tbc-i mr-4px"></i>
											<span className="font-medium font-size-10 font-size-md-12 text-blue">{langs.NM_BusinessClubShortDescr}</span>
										</div>
									</div>
								 </>
						 } */}

              </div>

              {/* {data.promPackage &&
						<>
								<div className="form-group my-24px">
									<div className="custom-control custom-checkbox custom-checkbox-2 custom-control-inline pl-25px">
										<input type="checkbox" id="termsAgree" name="termsAgree" className={`custom-control-input ${termsAgreement ? 'active' : ''}`} value="1"
											checked={termsAgreement}
											onChange={(e) => setTermsAgreement(!termsAgreement)} />
										<label className="custom-control-label text-dark font-base font-size-12 font-size-md-14 active" htmlFor="termsAgree">
											{langs.Agree}
											<span className="cursor-pointer text-blue pl-5px"
												onClick={callInfoUsageAgreement}>
												{langs.NM_InfoUsageAgreement}
											</span>
										</label>
									</div>
								</div>
							</>
						} */}
            </div>

            <div className="popup-footer d-flex justify-content-between px-64px  pt-24px">
              {!isNewCard ? (
                <div className="text-left mb-16px my-md-auto   d-flex align-items-center d-md-block ">
                <h2 className="font-bold-caps font-size-18 text-dark-text mr-8px m-md-0">
                  {totalPrice} {currency()}
                </h2>
                <p className="font-base font-size-12 text-blue-gray m-0">{langs.PriceToPay}</p>
              </div>
              ) : (
                <div></div>
              )}
              {inProgress ? (
                  <Loader/>
              ) : (
                  <button
                      disabled={!selectedMethod}
                      className={`btn bg-gradient font-bold-caps font-size-13 text-white ${!selectedMethod ? 'disabled' : ''}`}
                      onClick={(e) => ProceedToPayment(e)}
                  >
                    {isNewCard ? langs.NM_AddCard : langs.Pay}
                  </button>
              )}
            </div>
          </div>
        </div>
    </div>
  );
};

export default Payment;
