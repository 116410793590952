import React from 'react'
import { DateMoment } from 'components';
const TransactionDetails = (props) => {
  return (
    <div className="custom-modal-wraper">
      <div className="pt-20px pl-md-20px">
        <h1 className="font-bold-caps font-size-16 text-dark-text text-left">
          {props.langs.NM_transaction}
        </h1>
      </div>
      <div className="pb-16px px-md-20px">
        <div className="py-32px px-12px px-md-24px text-center bg-rgba-middle-gray-50 py-32px border-radius-md d-flex flex-column align-items-center justify-content-center mt-28px">
          <h2 className="font-bold-caps font-size-18 font-size-md-24 text-dark-text text-left d-flex align-items-center">
            {props.data.transaction.amount} {props.currency()}
          </h2>
          <div className="font-size-14 text-dark-text mt-8px">{props.data.transaction.title}</div>
        </div>
        <div className="mt-48px">
          <div className="d-flex pb-14px mb-14px border-bottom border-rgba-middle-gray-50 ">
            <div className="mr-sm-48px mr-5px w-sm-30 w-50 font-size-14 font-base text-blue-gray d-flex justify-content-start"> {props.langs.Site}   </div>
            <div className="w-50  font-size-14 font-medium text-dark font-size-14 font-medium text-dark d-flex justify-content-start"> {props.data.transaction.site_name}</div>
          </div>
          <div className="d-flex pb-14px mb-14px border-bottom border-rgba-middle-gray-50  ">
            <div className="mr-sm-48px mr-5px w-sm-30 w-50 font-size-14 font-base text-blue-gray d-flex justify-content-start"> {props.langs.TransactionType} </div>
            <div className="w-50  font-size-14 font-medium text-dark  d-flex justify-content-start"> {props.data.transaction.title}</div>
          </div>
          <div className="d-flex pb-14px mb-14px border-bottom border-rgba-middle-gray-50 ">
            <div className="mr-sm-48px mr-5px w-sm-30 w-50 font-size-14 font-base text-blue-gray d-flex justify-content-start">{props.langs.Operator}</div>
            <div>{props.data.transaction.operator}</div>
          </div>
          <div className="d-flex pb-14px mb-14px border-bottom border-rgba-middle-gray-50 ">
            <div className="mr-sm-48px mr-5px w-sm-30 w-50 font-size-14 font-base text-blue-gray d-flex justify-content-start"> {props.langs.PrID}   </div>
            <div className="w-50  font-size-14 font-medium text-dark  d-flex justify-content-start">{props.data.transaction.product_id}</div>
          </div>
          <div className="d-flex pb-14px mb-14px border-bottom border-rgba-middle-gray-50 ">
            <div className="mr-sm-48px mr-5px w-sm-30 w-50 font-size-14 font-base text-blue-gray d-flex justify-content-start">{props.langs.AmountBefore}</div>
            <div className="w-50  font-size-14 font-medium text-dark  d-flex justify-content-start">{props.data.transaction.money_before}</div>
          </div>
          <div className="d-flex pb-14px mb-14px border-bottom border-rgba-middle-gray-50 ">
            <div className="mr-sm-48px mr-5px w-sm-30 w-50 font-size-14 font-base text-blue-gray d-flex justify-content-start"> {props.langs.Amount} </div>
            <div className="w-50  font-size-14 font-medium text-dark  d-flex justify-content-start">{props.data.transaction.amount}</div>
          </div>
          <div className="d-flex pb-14px mb-14px border-bottom border-rgba-middle-gray-50 ">
            <div className="mr-sm-48px mr-5px w-sm-30 w-50 font-size-14 font-base text-blue-gray d-flex justify-content-start">{props.langs.Quantity} </div>
            <div className="w-50  font-size-14 font-medium text-dark  d-flex justify-content-start">{props.data.transaction.quantity}</div>
          </div>
          <div className="d-flex pb-14px mb-14px border-bottom border-rgba-middle-gray-50 ">
            <div className="mr-sm-48px mr-5px w-sm-30 w-50 font-size-14 font-base text-blue-gray d-flex justify-content-start">{props.langs.AmountAfter}</div>
            <div className="w-50  font-size-14 font-medium text-dark  d-flex justify-content-start">{props.data.transaction.money_after}</div>
          </div>
          <div className="d-flex pb-14px mb-14px  ">
            <div className="mr-sm-48px mr-5px w-sm-30 w-50 font-size-14 font-base text-blue-gray d-flex justify-content-start"> {props.langs.Date}</div>
            <div className="w-50  font-size-14 font-medium text-dark  d-flex justify-content-start">
              <DateMoment {...{ date: props.data.transaction.insert_date, format: "DD/MM/yyyy" }} />
              <div className="ml-12px">
                <DateMoment {...{ date: props.data.transaction.insert_date, format: "HH:mm" }} />
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="pb-24px mt-0 d-flex justify-content-center">
        <button type="button" className="font-size-14 font-base-caps px-64px py-16px btn bg-middle-gray  mx-8px text-blue-gray"
          onClick={e => props.callback(null, 'destroy')}>
          {props.langs.Close}
        </button>
      </div>
    </div>
  )
}

export default TransactionDetails