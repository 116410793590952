import React, { useState, useEffect } from 'react'

import { Formik, Field, Form, ErrorMessage } from 'formik';

import * as Yup from 'yup';
import { API } from 'services';

import { Functions } from 'libs';
const TimerCounter = (tprops) => {
	const [timer, setTimer] = useState(tprops.codeSendTimer);

	useEffect(() => {
		let mounted = true
		if (timer > 0) {
			setTimeout(() => {
				if (mounted) {
					setTimer(timer - 1)
				}
			}, 1000)
		} else {
			tprops.setCodeSendTimer(0)
		}
		return () => {
			mounted = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timer])

	return (
		<div key="countDownTimer">{`00:${timer < 10 ? `0${timer}` : timer}`}</div>
	)
}
const PhoneConfirmation = (props) => {
	const { langs, data, callback } = props;
	const api = API();
	const [codeSendTimer, setCodeSendTimer] = useState(false);

	const close = () => {
		callback(null, 'destroy');
	}

	const [formData, setFormData] = useState({
		Phone: '',
		SmsCode: '',
	});

	const getValidationSchema = () => {
		let validationSchema = {
			Phone: Yup.string().required(`${langs.WriteYourPhone}`)
				.matches(/^[0-9]+$/, `${langs.IncorrectFormat}`)
				.test('len', `${langs.IncorrectFormatOfPhoneNumber}`, o => o && o.length === 9),
			SmsCode: Yup.string().required(langs.SetValidSMSCode),
		}
		return Yup.object().shape(validationSchema)
	}

	const config = {
		initialValues: formData,
		validationSchema: getValidationSchema(),
		enableReinitialize: true
	}

	const sendVerificationSMS = async (e, values) => {
		e && (e.preventDefault() || e.stopPropagation());
		if (!codeSendTimer && data.productID) {
			const apiData = await api.sendProductActivationCode(data.productID, { Phone: values.Phone })
			// console.log(apiData)
			if (apiData.statusCode) {
				setCodeSendTimer(60)
			}
			setFormData(values)
		}
	}

	const handleSubmit = async (values) => {
		setFormData(values)
		if (values.SmsCode && data.productID) {
			let response = await api.activateProduct(data.productID, {
				Phone: values.Phone,
				SmsCode: values.SmsCode,
			})
			if (response.statusCode) {
				callback(null, 'success');
				callback(null, 'destroy');
			} else {

			}
		}
	}

	const handleChange = (e, setFieldValue) => {
		let value = Functions.checkStringLength(Functions.checkNumeric(e.target.value), 6)
		if (value.length <= 6) {
			setFieldValue("SmsCode", value)
		}
	}

	return (
		<div className="custom-modal-wrapper">
			<div className="d-flex justify-content-center px-20px py-30px">
				<span className="font-size-21">{langs.ActivatePr}</span>
			</div>
			<div className="d-flex justify-content-center px-5px">
				<span className="font-size-12">ID{data.productID}</span>
			</div>
			<Formik {...config} onSubmit={handleSubmit} >
				{({ values, setFieldValue }) => (
					<Form>
						<div className=" border-0  border-none d-flex flex-column justify-content-start bg-white px-sm-32px  px-md-100px pb-34px px-20px">
							<div className="form-group">
								<label className="my-12px text-text_label font-size-12 d-block" htmlFor="Phone">
									{langs.ContactFormPhone} <b className="text-blue">*</b>
								</label>
								<div className="d-flex position-relative">
									<div className="form-control d-flex align-items-center justify-content-center w-30 border-right-radius-none text-text_label">
										+995
									</div>
									<Field type="string" maxLength="9" name="Phone" className="form-control radius-10 border-left-radius-none" />
									{(values.Phone.length === 9 && values.Phone.match(/^[0-9]+$/)) &&
										<div className="flexible-btn d-flex align-items-center code-btn">
											{codeSendTimer > 0 &&
												<div className="flexible-timer mr-8px font-size-10 texct-dark">
													<TimerCounter key="countdownTimer" {...{ codeSendTimer, setCodeSendTimer }} />
												</div>
											}
											<div className={`btn font-weight-normal bg-gradient p-10px font-size-10  font-bold text-white ${codeSendTimer ? 'disabled' : ''}`}
												onClick={(e) => sendVerificationSMS(e, values)}>
												{langs.SendCode}
											</div>
										</div>
									}
								</div>
								<ErrorMessage name="Phone" component="label" className="error pl-25px" />
							</div>
							{codeSendTimer !== false &&
								<div className="form-group">
									<label className="my-12px text-text_label font-size-12 d-block" htmlFor="SmsCode">
										{langs.WriteOneTimeCode} <b className="text-blue">*</b>
									</label>
									<div className="position-relative">
										<Field type="text" name="SmsCode" className="form-control radius-10"  
											onChange={(e) => handleChange(e, setFieldValue)}/>
									</div>
									<ErrorMessage name="SmsCode" component="label" className="error pl-25px" />
								</div>
							}
						</div>
						<div className="popup-footer px-40px pt-20px mt-0 d-flex justify-content-between">
							<button className="btn bg-transparent font-base font-size-12 font-weight-normal text-blue-gray px-0 mb-16px mb-md-0" 
								onClick={close}>
								{langs.Close}
							</button>
							<button type="submit" className={`btn font-weight-normal bg-gradient font-size-13 text-white`} >
								{langs.Activate}
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	)
}


export default PhoneConfirmation;
