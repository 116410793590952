import React from 'react'
import { Parser } from 'components'
import pickUpModal from 'resources/img/pickUpModal.svg'
import tnetSites from 'resources/img/icons/tnet-all-sites.svg'
import tnetLogo from 'resources/img/icons/tnet-logo.svg'

const Confirmation = (props) => {
	const data = props.data ? props.data : {}

	const success = (e) => {
		props.callback(props.data, 'success')
		props.callback(e, 'destroy')
	}

	const fail = (e) => {
		props.callback.fail ?? props.callback(e, 'fail')
		props.callback(e, 'destroy')
	}

	if (data?.custom?.text.includes('%&%')) {
		data.custom.text = data.custom.text.replace('%&%', `<img src="${pickUpModal}"/>`);
	}

	return (
		<>
			<div className={`custom-modal-wraper ${props.styles.wraper ? props.styles.wraper : ''}`}>
				<div className={`custom-modal-header ${props.styles.header ? props.styles.header : ''}`}>
					<div className="py-32px text-center">
						{!data.customText &&
							<div className="py-32px text-center">
								<Parser html={data.title} />
							</div>
						}
					</div>
				</div>
				{data.custom &&
					<div className={`custom-modal-content px-34px my-0 ${props.styles.content ? props.styles.content : ''}`}>
						{data.custom.Logo &&
							<img src={tnetSites} className='m-auto d-flex pb-23px w-100' alt="" />
						}
						<div className="d-flex flex-column align-items-center mb-16px">
							{data.custom.svg &&
								<div className="icon mb-40px">
									<i className={data.custom.svg}></i>
								</div>
							}
							<h3 className="font-size-18 font-bold-caps text-center text-dark mb-8px">
								<Parser html={data.custom.title} />
							</h3>
							<div className={`font-size-14 font-base text-left text-center ${props.styles.text ? props.styles.text : ''}`}>
								<Parser html={data.custom.text} />
							</div>
						</div>
					</div>
				}
			</div>
			<div className={`custom-modal-actions ${props.styles.actions} ${data.custom?.Logo ? 'px-34px' :  ''}` }>
				<div className={`pb-28px mt-0 d-flex justify-content-center d-flex  ${data.custom?.Logo ? 'flex-column' :  ''}`}>
					<button type="button"
						className={`${props.styles.btnClassName ?
							props.styles.btnClassName : 'bg-middle-gray'} font-size-14 px-36px py-16px btn mx-8px  ${data.custom?.Logo ? 'bg-error-red text-white mb-16px border-radius-lg' :  ""}` }
						onClick={success}>
						{data.custom?.Yes ? data.custom.Yes : props.langs.Yes}
					</button>
					<button type="button" className={`font-size-14 px-36px py-16px btn bg-middle-gray mx-8px  ${data.custom?.Logo ? 'border-radius-lg' :  ""}`}
						onClick={fail}>
						{data.custom?.No ? data.custom.No : props.langs.No}
					</button>
				</div>
				{
					data.custom?.Logo &&
					<div className='d-flex justify-content-center text-gray-2 align-items-center font-size-12'>{props.langs.APP_powered_by}
						<img src={tnetLogo} className='pl-6px pr-3px' alt="" />
						<span className='text-blue font-bold-caps'>{props.langs.NM_tnet}</span>
					</div>
				}
			</div>
		</>
	)
}

export default Confirmation