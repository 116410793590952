import {Functions} from 'libs';

const brandPropMap = [
	{json: 'cat_id', js: 'CatID', dataType: 'int'},
	{json: 'brand_id', js: 'BrandID', dataType: 'int'},
	{json: 'title', js: 'Title', dataType: 'string'},
];

export class Brands {
	CatID;
	BrandID;
	Title;

	constructor(data) {
        if (!data) {
          return;
        }

		Functions.transformToObject(data, this, brandPropMap);
	}
}
