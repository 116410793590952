import React from 'react'
const View = (className = '') => (
  <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center  ${className}`}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#A4AEC1" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.8004 6.20509L7.37499 10.7931C7.24222 10.9307 7.06795 11 6.89367 11C6.71939 11 6.54511 10.9307 6.41234 10.7931L4.19971 8.49908C3.93343 8.22314 3.93343 7.77699 4.19971 7.50104C4.46586 7.22497 4.89607 7.22497 5.16235 7.50104L6.89367 9.29602L10.8377 5.20705C11.1039 4.93098 11.5341 4.93098 11.8004 5.20705C12.0665 5.48299 12.0665 5.92902 11.8004 6.20509Z" fill="white" stroke="white" strokeWidth="0.4" />
    </svg>

  </span>
)

export default View