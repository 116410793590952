import { Location } from '../models';

export class LocationsParser {
    data;
    convertedData;
    mainLocs;
    LocsHierarchy;

    constructor(data) {
        this.data = data;
        return this;
    }

  convert() {
    this.convertedData = {}

    const Locs = this.data;
    const lList = [];
    const lHierarchy = [];
    const mLocs = [];
    const selectList = [];

    for (const i in Locs) {
        if (!Locs[i]['t']) {
            continue;
        }
        let lTitle = Locs[i]['t'];
        let lPLoc = Locs[i]['p'];
        let cParentLoc = lPLoc;
        let cPLocTitleTree = lTitle;
        while (cParentLoc !== 0) {
            if (!Locs[cParentLoc]) {
                break;
            }
            cPLocTitleTree = Locs[cParentLoc]['t'] + ' / ' + cPLocTitleTree;
            cParentLoc = parseInt(Locs[cParentLoc]['p']);
        }
        let cLocData = {'locID': i, 't': lTitle, 'p': lPLoc, 'tTree': cPLocTitleTree};

        lList[i] = new Location(cLocData);
        if (lPLoc === 0) {
            mLocs.push(lList[i]);
            selectList.push({value: parseInt(i), label: cPLocTitleTree})
        }
        if (lHierarchy[lPLoc] == null) {
            lHierarchy[lPLoc] = [];
        }
        lHierarchy[lPLoc].push(i);
    }
    this.convertedData = {
      List : lList, 
      Hierarchy : lHierarchy, 
      Main: mLocs, 
      SelectList: selectList
    };
    return this;
  }

  convertItem(rawItem) {
    return false;
  }

  getRaw() {
    return this.data || null;
  }

  get() {
    return this.convertedData || null;
  }

  getHierarchy() {
    return this.LocsHierarchy || null;
  }

  getMainLocations() {
    return this.mainLocs || null;
  }
}
