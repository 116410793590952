import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import "swiper/css/pagination";
import 'swiper/scss';

export default React.forwardRef((props, ref) => {
	const [swiper, updateSwiper] = useState(null);
	
	useEffect(() => {
		if (swiper !== null) {
			ref.current = swiper
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [swiper])

	return (
		<Swiper {...props.swiperProps}
			onSwiper={updateSwiper}
			modules={[Pagination]}
		>
			{
				props.customSwiper ?	
					props.children 
				: 
				React.Children.map(props.children, (child) => {
					return <SwiperSlide>{child}</SwiperSlide>
				})
			}
		</Swiper>
	)
});