import React, {useState, useEffect} from 'react'
import { Field, ErrorMessage } from 'formik';
const RadioField = (props) => {
	// console.log(props)
	// styles {parentClassname, optionsListClassname, optionClassname, fieldClassname, labelClassname, errorClassname}
	// parentLabel {name, className, title}
	// fieldName (string)
	// optionsList [{value, title}]
	// showError (bool)
	// onChange (function)
	const [activeValue, setActiveValue] = useState(props.activeValue)
	const onChange = (e) => {
		if (!props.condition) {
			setActiveValue(e.target.value)
		}
		props.onChange(e)
	}
	
	useEffect(() => {
		setActiveValue(props.activeValue)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.activeValue])

	return(
		<div className={props.styles?.parentClassname ?? ''}>
			{props.parentLabel &&
				<label htmlFor={props.fieldName} className={props.parentLabel.className ?? ''}>
					{props.parentLabel.title}
				</label>
			}
			<div className={props.styles?.optionsListClassname ?? ''}>
				{props.optionsList?.length > 0 && 
					props.optionsList.map((option, index) => (
						<div className={props.styles?.optionClassname ?? ''} key={index}>
							<Field 
								name={props.fieldName} 
								type="radio" 
								id={`radio-${props.fieldName}-${index}`} 
								value={option.value} 
								className={`${props.styles?.fieldClassname ?? ''} ${String(activeValue) === option.value ? 'active' : ''}`} 
								onChange={onChange}
								disabled={props.disabled}
							/>
							<label className={props.styles?.labelClassname ?? ''} htmlFor={`radio-${props.fieldName}-${index}`}>
								{props.online &&
									<div className="d-flex align-items-center w-100">
												<i className={props.styles?.iconClassname}></i>
												<div className="w-100">
														<div className="w-100 d-flex justify-content-between align-items-center  mb-4px">
															<div className="font-size-14 font-medium text-dark ">{option.onlinetitle}</div>
															{props.recomended &&
																<div className="font-size-12 font-medium text-danger">recomended</div>}
														</div>
														<div  className="font-size-12 font-base text-dark text-left">{option.onlinesubtitle}</div>
												</div>
									</div>
								}
								{option.title}
							</label>
							{props.children && props.children}
						</div>
					))
				}
			</div>
			{props.showError &&
				<ErrorMessage name={props.fieldName} component="label" className={props.styles?.errorClassname ?? 'error text-danger'} />
			}
		</div>
	)
}
export default RadioField