import React, { useState, useEffect, createContext } from 'react';

import moment from 'moment';
import queryString from 'query-string';
import { Trackers } from 'libs';

export const BannersContext = createContext();

export const BannersProvider = (props) => {
	const { api, CONFIG } = props;

	const [bannersData, setBannersData]             = useState(null)
	const [adBlock, setAdBlock]                     = useState(null)
	const [connectHash, setConnectHash]             = useState(null)
	
	const [resolution, setResolution]               = useState([
		window.innerWidth, 
		window.innerHeight
	]);
	const [process, setProcess]                     = useState({
		CountList: []
	})

	const getBanner = (position = false) => {
		if (!isBanner(position)) {
			return false;
		}
		let banner = bannersData[position].banners[0]
		// console.log(banner.banner_place_name)
		if (parseInt(banner.type) === CONFIG.BANNER_TYPES.SLIDER) {
			return bannersData[position].banners
		}
		countBannerViews(banner.banner_id)
		return banner
	}
	
	const fetchData = async () => {
		const apiData = await api.getBanners();
		if (apiData && apiData.Banners) {
			setBannersData(apiData.Banners);
			const dependances = Trackers.processAdoDependances()
			setAdBlock(dependances ? false : true)
			// initializeStatic()
		} else {
			setBannersData(false)
			setAdBlock(true)
		}
	}

	const getConnectHash = async () => {
		let hash = await Trackers.connectGetHash();
		setConnectHash(hash ? hash : false)
	}

	const isBanner = (position) => {
		if (!position || !bannersData || !bannersData[position] || !bannersData[position].banners[0]) {
			return false;
		}
		return true;
	}

	const updateResolution = () => {
		setResolution([window.innerWidth, window.innerHeight]);
	}

	const prepareCountData = (data) => {
		return queryString.stringify({
			SiteID: CONFIG.COMMON.SITE_ID,
			IDs: Array.isArray(data) ? data.join(',') : data,
			ScreenWidth: resolution[0],
			ScreenHeight: resolution[1],
			Do: 'Views'
		})
	}

	const countBannerView = async (bannerID) => {
		await api.countBannerViews(prepareCountData(bannerID));
	}

	const countBannerViews = (bannerID) => {
		if (!adBlock) {
			let newList = {...process}
			if (newList.CountList.indexOf(bannerID) === -1) {
				newList.CountList.push(bannerID)
				setProcess(newList)
			}
		}
	}


	//@deprecated
	const unsetOldStorage = () => {
		// api.storage.remove('AdoHeader')
		// api.storage.remove('AdoFooter')
	}

	const bannerTimeout = (name, props = false) => {
		// let data = api.storage.getJson('Banners') ?? {};
		// data[name] = {date: new Date()};
		// if (props) data[name]['props'] = props;
		// api.storage.set('Banners', JSON.stringify(data))


		// set date in timestamp milliseconds.
		api.storage.set(name, JSON.stringify(moment(new Date()).valueOf()));

	}

	const checkTimeout = (name, timeOut = 15) => {
		// let data = api.storage.getJson('Banners') ?? false;
		// if (!data || !data[name] || !data[name].date) return true;
		// let mins = moment(new Date()).diff(new Date(data[name].date), 'minutes');
		// return mins > timeOut;

		const bannerValue = api.storage.getJson(name);
		if(!bannerValue) return true;
		const timeDiff = moment(new Date()).diff(bannerValue, 'minutes');
		return timeDiff > timeOut;
	}

	useEffect(() => {
		if (bannersData === null) fetchData()
		if (connectHash === null) getConnectHash()
		// unsetOldStorage()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		window.addEventListener('resize', updateResolution);
		updateResolution();
		return () => window.removeEventListener('resize', updateResolution);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (process.CountList.length > 0) {
			const timer = setTimeout( async () => {
				await api.countBannerViews(prepareCountData(process.CountList));
				setProcess({CountList: []})
			}, 2000);
			return () => clearTimeout(timer);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [process.CountList])
	
	return (
		<BannersContext.Provider value={{ 
			bannersData,
			getBanner,
			isBanner,
			resolution,
			countBannerView,
			countBannerViews,
			adBlock,
			connectHash,
			bannerTimeout,
			checkTimeout
		}}>
			{props.children}
		</BannersContext.Provider>
	);
};
export default BannersProvider;