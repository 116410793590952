import React, {useState, useEffect, useRef } from 'react'

const PasswordCheck = (props) => {
	const [password, setPassword] = useState('')
	const passwordInput = useRef(null);

	useEffect(() => {
		passwordInput.current.focus();
	}, [password]);

	const success = (e) => {
		props.callback({password, values: props.data.returnValues}, 'success');
		props.callback(e, 'destroy')
	}

	const handleChange = (e) => setPassword(e.target.value);

	return (
		<>
			<div className={`custom-modal-wraper ${props.styles.wraper}`}>
				<div className={`custom-modal-header ${props.styles.header}`}>
					<div className="py-32px text-center">
						<h1>{props.langs.NM_ConfirmChanges}</h1>
					</div>
				</div>
				<div className={`custom-modal-content ${props.styles.content}`}>
					<div className="pb-48px">
						<div id="" className="font-size-16 font-base text-blue-gray mt-24px">
							<input key="password" type="password" className="form-control w-100 w-md-50 mx-auto" placeholder={props.langs.WritePassword} ref={passwordInput}  value={password} onChange={handleChange} />
						</div>
					</div>
				</div>
			</div>
			<div className={`custom-modal-actions ${props.styles.actions}`}>
				<div className="pb-28px mt-0 d-flex justify-content-center">
					<button disabled={!password} className={`btn bg-gradient font-bold-caps font-size-13 text-white ${!password ? 'disabled' : ''}`} 
						onClick={success}>
						{props.langs.Accept}
					</button>
				</div>
			</div>
		</>
	)
}

export default PasswordCheck