export class DateAndTime {
    data;

    constructor() {
        this.data = {
            ka: {
                monthsShort : 'იან_თებ_მარ_აპრ_მაი_ივნ_ივლ_აგვ_სექ_ოქტ_ნოე_დეკ'.split('_'),
                weekdaysShort : 'კვი_ორშ_სამ_ოთხ_ხუთ_პარ_შაბ'.split('_'),
            },
            ru: {
                monthsShort : 'янв_февр_мар_апр_мая_июня_июля_авг_сент_окт_нояб_дек'.split('_'),
                weekdaysShort : 'вс_пн_вт_ср_чт_пт_сб'.split('_'),
            },
            en: {
                monthsShort : 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
                weekdaysShort : 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
            }
        }
    }

    convert() {}

    get() {
        return this.data;
    }
}
