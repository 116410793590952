import {Functions} from 'libs';
import CONFIG from 'core/config';


const attrPropMap = [
    {json: 'id', js: 'attrID', dataType: 'int'},
    {json: 't', js: 'attrTitle', dataType: 'string'},
    {json: 'v', js: 'attrViewType', dataType: 'int'},
    {json: 'd', js: 'attrDataType', dataType: 'int'},
    {json: 'pid', js: 'pAttrID', dataType: 'int'},
    {json: 'dt', js: 'dimTitle', dataType: 'string'},
    {json: 'did', js: 'attrDim'},
    {json: 's', js: 'subAttrs'},
    {json: 'r', js: 'rAttrs'},
    {json: 'ranges', js: 'rangeAttrs'}
];

export class Attribute {
    attrID               = 0;
    pAttrID              = 0;
    attrTypeID           = 0;
    attrViewType         = 0;
    attrDataType         = 0;
    attrDataTypeText     = '';
    attrTitle            = '';
    attrDim              = null;
    dimTitle             = '';
    isYesNo              = false;
    attrValueChecked     = false;
    attrValueLabel;
    subAttrs             = [];
    attrValues           = [];
    rAttrs               = [];
    radioVal            = -1;
    textValue;
    selectValue;
    selectID;
    hidden              = false;
    invalid             = false;
    focused             = false;
    currentlyOpened     = false;
    placeHolder;
    valueFlexed         = false;
    rangeAttrs          = [];
    children;

    /* FOR FORM BINDINGS */
    isChecked           = false;

    constructor(d) {
        if (!d) {
            return false;
        }
        Functions.transformToObject(d, this, attrPropMap);
        if (this.attrViewType === CONFIG.VIEW_TYPES.CHECKBOX_TYPE_ID) {
            this.isYesNo = true;
        }

        switch (this.attrDataType) {
            case CONFIG.DATA_TYPES.INTEGER :
                this.attrDataTypeText = 'int';
                break;
            case CONFIG.DATA_TYPES.DOUBLE :
                this.attrDataTypeText = 'double';
                break;
            case CONFIG.DATA_TYPES.STRING :
                this.attrDataTypeText = 'alpha';
                break;
            default:
                break;
        }

        if (this.children && this.children.length) {
            for (let i in this.children) {
                this.children[i]['attrValID'] = parseInt(i, 10);
                this.children[i]['parentAttrID'] = this.attrID;
            }
        }
    }
}
