import React from 'react'

const Component = (props) => {
    return (
        <>
            <div className="d-flex justify-content-center px-20px py-30px">
                <span className="font-size-21">{props.title}</span>
            </div>
            {props.productID && 
                <div className="d-flex justify-content-center px-5px">
                    <span className="font-size-12">ID{props.productID}</span>
                </div>
            }
        </>
    )
    
}

export default Component