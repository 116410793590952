import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Cookies, TnetPlatforms } from '../components';
import Select from 'react-select';
const Footer = (lib) => {
	const [nav, setNav] = useState(false)
	const [help, setHelp] = useState(false)
	const [cat, setCat] = useState(false)

	const showNav = () => {
		setNav(!nav)
	}
	const showHelp = () => {
		setHelp(!help)
	}
	const showCat = () => {
		setCat(!cat)
	}
	let config = lib.resolution[0] <= lib.CONFIG.TOSPLIT.RESPONSIVE_RESOLUTION ? {
		className: "border-bottom",
		firstChild: "border-top"
	}
		: {
			classNameDesk: "border-bottom",
		}

	const currentYear = new Date().getFullYear();

	const langsOption = (props) => {
		return (
			<div className={`langs-select-option ${props.data.NoOption ? 'bg-transparent mt-10px ' : ''}`}>
				<a href={props.data.Link} className={`text-decoration-none text-dark font-size-12 d-flex align-items-center justify-content-start py-8px pl-24px `} >
					<div className={`w-24px h-24px flex-shrink rounded-circle border mr-14px  ${props.data.NoOption ? 'd-none ' : ''} ${lib.getLang === props.data.Name ? ' border-primary border-8px' : 'border-blue-gray '}`}></div>
					<div className="font-size-14 font-base text-dark">
						{props.data.text}
					</div>
				</a>
			</div>
		)
	}
	// {lib.staticLinks.Language.map((item, i) => (
	// 	<a key={i} href={item.Link} className={`text-decoration-none text-dark font-size-12 d-flex align-items-center justify-content-start py-10px  `} >
	// 		<div className="w-24px h-24px rounded-circle border mr-14px"></div>
	// 		<div className="div">
	// 			{item.text}
	// 		</div>
	// 	</a>
	// ))}

	return (
		<footer className="bg-white">
			<div className="scroll-top d-flex align-items-center justify-content-center bg-market-color cursor-pointer p-15px mb-32px mb-md-40px" onClick={lib.scrollToTop}>
				<i className="svg-i scroll-top-i"></i>
			</div>
			<div className={`pb-40px  ${config.classNameDesk}`}>
				<div className="container ">
					<div className="d-flex align-items-start  align-items-md-center  flex-column flex-md-row">
						<div className="mb-16px mb-md-0 mr-md-36px">
							<div className="text-dark font-size-12 d-none d-md-block d-lg-block d-xl-block text-truncate"></div>
							<div className="d-flex">
								<div >
									<a href="https://play.google.com/store/apps/details?id=ge.my.mymarket&utm_camapaign=marketing&utm_source=facebook&utm_term=main+video" target="_blanck">
										<i className={`svg-i  google-play-i`}></i>
									</a>
								</div>
								<div className="ml-14px">
									<a href="https://apps.apple.com/us/app/mymarket-ge/id1560783192?utm_camapaign=marketing&utm_source=facebook&utm_term=main+video" target="_blanck">
										<i className={`svg-i  app-store-i`}></i>
									</a>
								</div>

							</div>
						</div>
						<div className="font-size-12 font-base opacity-60 col-md-3 p-0">
							{lib.langs.NM_downloadApp}
						</div>
					</div>
					<div className="d-flex d-md-none mt-28px">
						{lib.staticLinks.Social.map((item, i) => (
							<a key={i} href={item.Link} rel="noopener noreferrer" target="_blank" className={`text-decoration-none text-dark font-size-12 d-flex align-items-center justify-content-center w-40px h-40px  bg-middle-gray mr-8px rounded-circle ${item.Class}`} >
								<i className={`svg-i ${item.Icon}`}></i>
							</a>
						))}
					</div>
				</div>
			</div>

			<div className="container footer-container list-1 my-56px">
				<div className="row">
					<div className={`col-12 col-md-3  py-4px py-md-0  ${config.firstChild} ${config.className}`}>
						<h6 className={`text-dark font-size-12 m-0  py-16px py-md-16px cursor-pointer d-flex align-items-center justify-content-between`} onClick={showNav}>
							{lib.langs.Navigation}
							<i className={`svg-i arrow-down-black-i w-8px h-6px d-flex d-md-none ${nav ? 'up' : ''}`}></i>
						</h6>
						<ul className="list-unstyled">
							{lib.staticLinks.Menu.map((item, i) => (
								<li key={i} className={`${(lib.resolution[0] <= lib.CONFIG.TOSPLIT.RESPONSIVE_RESOLUTION && !nav) ? 'd-none' : ''}`}>
									{item.External ?
										<a href={`${item.Link}`} rel="noopener noreferrer" target="_blank" className="text-decoration-none text-dark font-size-12 d-flex py-8px">
											{item.Title}
										</a>
										:
										<Link to={`/${lib.getLang}/${item.Link}`} className="text-decoration-none text-dark font-size-12 d-flex py-8px">
											{item.Title}
										</Link>
									}
								</li>
							))}
						</ul>
					</div>
					<div className={`col-12 col-md-3 py-4px py-md-0  ${config.className}`}>
						<h6 className={`text-dark font-size-12 m-0  py-16px py-md-16px cursor-pointer d-flex align-items-center justify-content-between`} onClick={showHelp}>
							{lib.langs.Help}
							<i className={`svg-i arrow-down-black-i w-8px h-6px d-flex d-md-none ${help ? 'up' : ''}`}></i>
						</h6>
						{lib.staticLinks.Help.map((item, i) => (
							<li key={i} className={`${(lib.resolution[0] <= lib.CONFIG.TOSPLIT.RESPONSIVE_RESOLUTION && !help) ? 'd-none' : ''}`}>
								<a href={`${item.Email ? item.Email : item.Link}`} rel="noopener noreferrer" target="_blank" className="text-decoration-none text-dark font-size-12 d-flex py-8px">
									{item.Title}
								</a>

							</li>
						))}
						<div className="d-none d-md-flex mt-18px">
							{lib.staticLinks.Social.map((item, i) => (
								<a key={i} href={item.Link} rel="noopener noreferrer" target="_blank" className={`text-decoration-none text-dark font-size-12 d-flex align-items-center justify-content-center w-40px h-40px bg-middle-gray mr-8px rounded-circle d-flex py-8px`} >
									<i className={`svg-i ${item.Icon}`}></i>
								</a>
							))}
						</div>
					</div>
					<div className={`col-12 col-md-6  footer-cats py-4px py-md-0  ${config.className}`}>
						<div className="">
							<h6 className={`text-dark font-size-12 m-0  py-16px py-md-16px cursor-pointer d-flex align-items-center justify-content-between`} onClick={showCat}>
								{lib.langs.Categories}
								<i className={`svg-i arrow-down-black-i w-8px h-6px d-flex d-md-none ${cat ? 'up' : ''}`}></i>
							</h6>
							<div className="row">

								{lib.categoryList !== null &&
									lib.categoryList.map((item, i) => (
										<div className={`col-md-4 ${(lib.resolution[0] <= lib.CONFIG.TOSPLIT.RESPONSIVE_RESOLUTION && !cat) ? 'd-none' : ''}`} key={i} >
											<ul className="list-unstyled">
												{item.map((cat, ii) => (
													<li key={ii}>
														<a key={i} href={`/${lib.getLang}/search${lib.prefix(cat.CatID)}?CatID=${cat.CatID}`}  target="_blank" rel="noreferrer" className="text-decoration-none text-dark font-size-12 d-flex py-8px">
														{cat.Title}
														</a>
													</li>
												))}
											</ul>
										</div>
									)
									)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr className="footer-hr my-20px" />
			<div className="container py-8px">

				<div className="d-flex flex-column flex-md-row rules-link ">
					<div className="d-flex flex-column flex-md-row align-items-md-center">

						{lib.staticLinks.Rules.map((item, i) => (
							<li key={i} className="mr-24px">
								{item.External ?
									<a href={`${item.Link}`} rel="noopener noreferrer" target="_blank" className="text-decoration-underline text-dark font-size-12">
										{item.Title}
									</a>
									:
									<Link to={`/${lib.getLang}/${item.Link}`} className="text-decoration-underline text-dark font-size-12">
										{item.Title}
									</Link>
								}
							</li>
						))}
					</div>
					<div className="d-flex w-100 align-items-center justify-content-between mt-16px mt-md-0 ml-16px ml-md-0">
						<Select
							className="sg-selectbox-container w-160px langs-select"
							classNamePrefix="sg-selectbox"
							value={lib.staticLinks.Language}
							components={{ Option: langsOption }}
							options={lib.staticLinks.Language}
							// menuIsOpen={true}
							isSearchable={false}
							getOptionLabel={e => (
								lib.staticLinks.Language.map((item, i) => (
									<div key={i}>

										{lib.getLang === item.Name &&
											<div className="d-flex align-items-center ">
												<i className="svg-i langs-i w-24px h-24px mr-8px"></i>
												{item.text}
											</div>
										}
									</div>
								))
							)}
						/>
						<div className="mr-16px">
							<div id="top-ge-counter-container" data-site-id="11654"></div>
						</div>
					</div>

				</div>
			</div>
			<TnetPlatforms />
			<div className="container my-32px ">
				<div className="d-flex  flex-column flex-md-row align-items-center justify-content-md-between  text-dark font-size-12">
					<div className="text-center justify-content-center">
						<span>{lib.langs.NM_reCAPTCHA}</span>
						<a href="https://policies.google.com/privacy" rel="noreferrer" className="px-5px text-dark text-decoration-underline">
							{lib.langs.NM_Privacy_Policy_eng}
						</a>
						<span>{lib.langs.NM_and_eng}</span>
						<a href="https://policies.google.com/terms" rel="noreferrer" className="px-5px text-dark text-decoration-underline">
							{lib.langs.NM_Terms_of_Service}
						</a>
						{lib.langs.NM_apply}
					</div>
					<div className="d-flex align-items-center font-size-12 mt-24px mt-md-0">&copy; {currentYear} {lib.langs.AllRightsReserved}</div>
				</div>
			</div>



			{/* {(lib.resolution[0] <= lib.CONFIG.TOSPLIT.PRODUCT_BANNERS.RESPONSIVE_RESOLUTION && lib.catfish) &&
				<div className="cat-fish-box position-fixed d-flex justify-content-end bg-gray">
					<div onClick={lib.closeCatfish} className="close-catfish d-flex align-items-center justify-content-center">
						<i className="svg-i svg-i-sm  close-catfish-i"></i>
					</div>
					<Banner {...{ position: "NM_Catfish", parentClassName: "w-100" }} />
				</div>
			} */}
			{/* {(resolution[0] <= CONFIG.TOSPLIT.PRODUCT_BANNERS.RESPONSIVE_RESOLUTION && getPathname() !== `/${lib.getLang}/app` && showAppModal) &&
				<AppModal {...{ lib.langs, close: closeAppModal }} />
			} */}
			{/* {specialPopup &&
				<SpecialPopup {...{ lib.langs, close: closeSpecialPopup }} />
			} */}
			{lib.cookieAgreement &&
				<Cookies {...{ langs: lib.langs, processCookieAgreement: lib.processCookieAgreement }} />
			}
		</footer>
	)
}

export default Footer
