import React, {useEffect, useState, useContext} from 'react';
import {Link} from 'react-router-dom';

import {AppDataContext} from 'contexts/appData';

const BreadCrumbs = (props) => {
	const {appData, getLang, langs, prefix} = useContext(AppDataContext);

	const [breadCrumbs, setBreadCrumbs] = useState([]);

	const defaultList = [
		{link: `/${getLang}`, title: langs.home, reload: true}
	]

	useEffect(() => {
		if (props.list) {
			setBreadCrumbs(defaultList.concat(props.list));
		} else {
			let result = [];
			const processList = (CatID) => {
				// console.log("BreadCrumps Generate")
				// console.log(appData.Cats.List[CatID])
				if (!appData.Cats.List[CatID]) {
					return false
				}

				result.push({
					link: `/${getLang}/search${prefix(CatID)}?CatID=${CatID}`,
					title: appData.Cats.List[CatID].Title
				})

				if (appData.Cats.List[CatID].ParentId !== 0) {
					processList(appData.Cats.List[CatID].ParentId);
					return false;
				}

				setBreadCrumbs(defaultList.concat(result.reverse()));
			}

			if (props.CatID) {
				processList(props.CatID)
			} else {
				setBreadCrumbs(defaultList)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.CatID, props.list])

	return (
		props.newVersion === 1 ?
			<div className={`${props.className ? props.className : 'mt-0'}  d-block`}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb p-0 m-0 bg-transparent flex-nowrap text-nowrap d-flex">
						{breadCrumbs && breadCrumbs.length > 0 && breadCrumbs.map((item, i) => (
							<li className="breadcrumb-item d-block text-truncate fade-out-hover " key={i}>
								<Link className={`font-size-12 font-medium ${i + 1 === breadCrumbs.length ? 'text-dark' : 'text-blue-gray'}`}
									  to={item.link} reloadDocument={item.reload}>{item.title}</Link>
							</li>)
						)}
					</ol>
				</nav>
			</div>
			:
			<div className={`${props.className ? props.className : 'mt-0'}  d-block`}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb p-0 m-0 bg-transparent flex-nowrap text-nowrap d-flex mb-4px">
						{breadCrumbs && breadCrumbs.length > 0 && breadCrumbs.map((item, i) => (
							<li className="breadcrumb-item d-block text-truncate" key={i}>
								<Link className="text-blue-gray font-size-12 font-medium"
									  to={item.link} reloadDocument={item.reload}>{item.title}</Link>
							</li>)
						)}
					</ol>
				</nav>
			</div>

	)
}

export default BreadCrumbs