import React from 'react';

import { FavoritesTotal } from 'components';
import { Link } from 'react-router-dom';

const Favorites = (props) => {
  return (
    <li className="nav-item icon d-flex position-relative mr-8px ">
      <Link to={`/${props.getLang}/favorites`} className="favorites-a">
        <i className="svg-i svg-i-sm  favorites-i w-24px h-24px"></i>
        <span
          className={`rounded-circle badge badge-pill font-base d-none bg-badge-orange text-white position-absolute badge-counter ${
            FavoritesTotal() > 0 ? 'd-flex' : 'd-none'
          }`}
        >
          <FavoritesTotal />
        </span>
      </Link>
    </li>
  );
};

export default Favorites;
