import React from 'react'
const AutoUpdateIcon = props => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${props.className ? props.className : ''}`}>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.37712 13.7709C9.81412 13.7709 9.98912 14.0329 9.63912 14.3829L8.32712 15.6949C9.01272 16.397 9.88207 16.8919 10.8357 17.1231C11.7894 17.3543 12.7889 17.3125 13.7199 17.0023C14.6509 16.6921 15.4758 16.1262 16.1003 15.3693C16.7247 14.6123 17.1236 13.6949 17.2511 12.7219C17.295 12.5076 17.4169 12.3171 17.5931 12.1875C17.7693 12.0578 17.9874 11.998 18.2051 12.0198C18.4228 12.0416 18.6247 12.1433 18.7717 12.3053C18.9188 12.4673 19.0005 12.6781 19.0011 12.8969V13.0719C18.748 14.7411 17.9001 16.2626 16.6138 17.356C15.3274 18.4494 13.6893 19.041 12.0011 19.0219C10.1436 19.0087 8.36076 18.2894 7.01412 17.0099L5.61412 18.4099C5.26412 18.7599 5.00212 18.5849 5.00212 18.1479V14.2079C4.99862 14.1496 5.00753 14.0913 5.02825 14.0366C5.04898 13.982 5.08105 13.9325 5.12235 13.8912C5.16364 13.8499 5.21323 13.8178 5.26784 13.7971C5.32244 13.7763 5.38082 13.7674 5.43912 13.7709H9.37712ZM14.6261 10.2709C14.1891 10.2709 14.0141 10.0089 14.3641 9.65893L15.6761 8.34693C14.9904 7.64482 14.1209 7.14991 13.1671 6.91881C12.2132 6.6877 11.2136 6.72975 10.2826 7.04013C9.35154 7.35052 8.52666 7.9167 7.90232 8.67392C7.27797 9.43113 6.87937 10.3488 6.75212 11.3219C6.71836 11.5455 6.59996 11.7475 6.42141 11.8862C6.24286 12.0248 6.01783 12.0896 5.79289 12.067C5.56795 12.0443 5.36031 11.9361 5.21293 11.7647C5.06555 11.5933 4.98972 11.3717 5.00112 11.1459V10.9719C5.25425 9.30276 6.1021 7.78131 7.38845 6.68791C8.6748 5.59451 10.313 5.00285 12.0011 5.02193C13.8586 5.03518 15.6415 5.75449 16.9881 7.03393L18.3881 5.63393C18.7381 5.28393 19.0001 5.45893 19.0001 5.89593V9.83293C19.0036 9.89123 18.9947 9.94961 18.974 10.0042C18.9533 10.0588 18.9212 10.1084 18.8799 10.1497C18.8386 10.191 18.789 10.2231 18.7344 10.2438C18.6798 10.2645 18.6214 10.2734 18.5631 10.2699L14.6261 10.2709Z" fill="#6F7383" />
        </svg>


    </span>
)

export default AutoUpdateIcon