import React, { useState, useEffect } from 'react'
const ButtonField = (props) => {
  const {
    styles,
    active,
    condition,
    onClick,
    title,
    subtitle,
    recomended,
    recomendedtext
  } = props;
  const [loActive, setLoActive] = useState(active)
  const onLoClick = () => {
    let stateS = !loActive;
    if (!condition) {
      setLoActive(stateS)
    }
    onClick(stateS ? 1 : 0);
  }

  useEffect(() => {
    setLoActive(active)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return (
    <button type='button' className={`${styles?.parentClassname ?? '' } ${loActive ? 'active' : ''}`} 
      onClick={onLoClick}
    >
      <i className={styles?.iconClassname}></i>
      <div className="w-100">
        <div className="w-100 d-flex justify-content-between align-items-center  mb-4px">
          <div className="font-size-14 font-medium text-dark text-left">
            {title}
          </div>
          {recomended &&
            <div className="font-size-12 font-medium text-light-red">{recomendedtext}</div>}
        </div>
        <div className="font-size-12 font-base text-dark text-left">
          {subtitle}
        </div>
      </div>
    </button>
  )
}
export default ButtonField