import React, { useContext } from "react";
import { Cookie } from "../../../services";
import { AppDataContext } from "../../../contexts/appData";


export const NewVersionBar = () => {
	const {langs} = useContext(AppDataContext)

	const oneMonthInSeconds = 30 * 24 * 60 * 60;
	const expires = new Date(Date.now() + oneMonthInSeconds * 1000);

	const handleSwitchVersion = () => {
		const cookie = Cookie()
		cookie._set('app_market_version', 'v1', {expires})
		window.location.reload();
	}


	return <div className="w-100 hover-bg-gray cursor-pointer px-3 align-items-center"
							style={{background: '#EEE', paddingTop: '12px', paddingBottom: '12px'}} onClick={handleSwitchVersion}>
		<div className="container p-0 d-flex align-items-center">
			<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
				<path
						d="M16.6663 9.16665H6.52467L11.183 4.50831L9.99967 3.33331L3.33301 9.99998L9.99967 16.6666L11.1747 15.4916L6.52467 10.8333H16.6663V9.16665Z"
						fill="black"/>
			</svg>

			<div className="ml-1 font-medium font-size-12">{langs.NM_SeeNewMarketVersion}</div>
		</div>
	</div>
}