import {useState, useEffect} from 'react';
import { API } from 'services';
import { CONFIG } from 'core';
const SlideDown = ({ children, ...props }) => {
  const {
    name,
    title,
    containerClassName = 'px-20px',
    titleClassName = '',
    contentClassName = '',
  } = props;
  const api = API();
  const [isOpen, setIsOpen] = useState(null);
  const setStorage = (filters) => {
    api.storage.set(CONFIG.CACHE.TAGS.FILTERS, JSON.stringify(filters));
  }
  const toggleFilter = () => {
    const filters = api.storage.getJson(CONFIG.CACHE.TAGS.FILTERS);
    filters[name] = !isOpen;
    setStorage(filters)
    setIsOpen(!isOpen);
  }
  useEffect(() => {
    if (!api.storage.exists(CONFIG.CACHE.TAGS.FILTERS)) {
      setStorage({});
    }
    const filters = api.storage.getJson(CONFIG.CACHE.TAGS.FILTERS);
    if (filters[name] === undefined) {
      filters[name] = false;
      setStorage(filters);
    }
    setIsOpen(filters[name]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, api.storage]);

  return (
    <div className={`pt-16px pb-8px ${containerClassName}`}>
      <div className={`d-flex justify-content-between align-items-center cursor-pointer font-size-14 font-base text-dark ${titleClassName}`}
        onClick={toggleFilter}
      >
        {title}
        <i className={`svg-i svg-i-lg dropdown-arrow-down ${!isOpen ? 'up' : ''}`}></i>
      </div>
      <div className={`pt-12px ${isOpen ? 'd-flex flex-wrap' : 'd-none'} ${contentClassName}`}>
        {children}
      </div>
    </div>
  );
}

export default SlideDown;