import React from 'react'
import { Modal, Parser } from 'components'

const Component = (props) => {
    const close = () => {
        props.close();
    }

    const success = () => {
        props.success();
    }

    const Content = () => {
        return (
            <div className="custom-modal-wrapper content-scroll">
                <div className="modal-content-box mb-24px px-md-64px">
                    <h3 className="blocked-reason-text font-size-14 font-size-md-16 font-bold-caps text-dark text-left text-market-color mb-24px pt-32px pt-md-80px">
                        {props.title ? props.title : props.langs.NM_InfoUsageAgreement}
                    </h3>
                    <div className="font-size-14 text-dark font-base scrolled-modal">
                        <Parser html={props.text ? props.text : props.langs.NM_InfoUsageAgreementText} />
                    </div>
                </div>
            </div>
        )
    }



    const Actions = () => {
        return (
            <div className="d-flex justify-content-center mt-5px mb-48px">
                <button type="button" className="font-size-14 font-bold-caps px-48px px-lg-100-px py-16px btn bg-gradient text-white d-block mx-auto "
                    onClick={e => success()}>
                    {props.button ? props.button : props.langs.NM_ReadAgree}
                </button>
            </div>
        )
    }

    return <Modal {...{ Content, Actions, close, classInner: "fixed-mobile agreement-modal" }} />
}

export default Component