import React, { useEffect } from 'react';
import WarningAmber from "./icons/WarningAmber";
import CloseBlack from "./icons/CloseBlack";

const icons = {
	warning: <WarningAmber />
}

const Toast = ({ type = 'warning', message = '', onClose }) => {

	useEffect(() => {
		setTimeout(() => {
			onClose?.();
		}, 4000)
	}, []);

	return (
			<div className="d-flex align-items-center p-12px mt-2 max-width-300px shadow-toast bg-primary border-radius-12 overflow-hidden">
				{icons[type]}
				<div className="text-dark font-size-12 ml-8px">{message}</div>
				<CloseBlack className="ml-20px cursor-pointer" onClick={() => onClose?.()}/>
			</div>
	);
};

export default Toast;