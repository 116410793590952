import { getCookie } from 'react-use-cookie';


export const getGtagClientIdAndSessionId = async () => {
  const clientIdPromise = new Promise((resolve, reject) => {
    if(!getCookie('_ga')) {
      return resolve(null);
    }

    try {
      window.gtag('get', 'G-7DGG6YVBRZ', 'client_id', (clientId) => {
        resolve(clientId)
      })
    }catch (ex) {
      reject(null)
    }
  });

  const sessionIdPromise = new Promise((resolve, reject) => {
    if(!getCookie('_ga')) {
      return resolve(null)
    }

    try {
      window.gtag('get', 'G-7DGG6YVBRZ', 'session_id', (sessionId) => {
        resolve(sessionId)
      })
    }catch (ex) {
      reject(null)
    }
  })


  const clientId = await clientIdPromise
  const sessionId = await sessionIdPromise

  return {
    clientId: clientId ? clientId : null,
    sessionId: sessionId ? sessionId : null
  }
}


export const getClientId = () => {
  return new Promise((resolve, reject) => {
    if (!getCookie('_ga')) return resolve(null);

    window.gtag('get', 'G-7DGG6YVBRZ', 'client_id', clientId => {
      if (clientId) {
        resolve(clientId);
      } else {
        reject('Failed to get Client ID');
      }
    });
  });
};

export const getSessionId = () => {
  return new Promise((resolve, reject) => {
    if (!getCookie('_ga')) return resolve(null);

    window.gtag('get', 'G-7DGG6YVBRZ', 'session_id', sessionId => {
      if (sessionId) {
        resolve(sessionId);
      } else {
        reject('Failed to get Session ID');
      }
    });
  });
};



export const conditionTypes = {
  0: 'used',
  1: 'new',
  2: 'like_new',
  4: 'for_parts',
  5: 'outlet',
};

export const promotionTypes = {
  20: 'SVIP',
  15: 'VIP+',
  10: 'VIP',
};

export const getProductSellType = (product) => {
  if (product.shop && product.shop_type_id) {
    return product.shop_type_id === 1 ? 'b2c_online' : 'b2c_offline';
  }

  if (product.shop) {
    return product.shop.type_id === 1 ? 'b2c_online' : 'b2c_offline';
  }

  return product.sell_online === 1 ? 'c2c_online' : 'c2c_offline';
};

export const makeProductItem = (product) => {
  const itemVariant = conditionTypes[product?.cond_type_id];
  const productSellType = getProductSellType(product);
  const [item_category, item_category2, item_category3, item_category4, item_category5] = product.category_tree
    ? product.category_tree
    : [];

  return {
    item_id: product.product_id,
    item_name: product?.lang_data?.title ?? '',
    affiliation: product.shop && product.shop.title ? product.shop.title : 'no-affiliation',
    discount: Number(product.discount),
    item_brand: product.brand_title ? product.brand_title : 'no-brand',
    item_category: item_category ? item_category?.title : 'no-category',
    item_category2: item_category2 ? item_category2?.title : 'no-category',
    item_category3: item_category3 ? item_category3?.title : 'no-category',
    item_category4: item_category4 ? item_category4?.title : 'no-category',
    item_category5: item_category5 ? item_category5?.title : 'no-category',
    item_variant: itemVariant ? itemVariant : 'no-variant',

    location_id: product?.location ?? '',
    price: Number(product?.price),

    promotion_name: promotionTypes[product?.vip] ?? '',
    promotion_color: Boolean(product?.color),
    currency: 'GEL',

    product_sell_type: productSellType,
    seller_user_id: String(product?.user_id ?? ''),
    shop_id: product?.shop?.description?.id_code ?? '',
  };
};


