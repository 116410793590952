import React from 'react';
import { Slider, SliderNav } from 'components';
import { getSwiperConfig } from 'core';

const HomeSlider = React.forwardRef((props, ref) => {

    const getUrl = (ban) => {
        return `${props.CONFIG.TOSPLIT.MY_ADS_BANNERS_URL}?SiteID=${props.CONFIG.COMMON.SITE_ID}&Do=NewClick&ID=${ban.banner_id}&RedirectUrl=${encodeURIComponent(ban.url)}`
    }

    const getImg = (ban) => {
        let img = ban.banner_contents.lg
        if (props.resolution[0] <= props.CONFIG.TOSPLIT.PRODUCT_BANNERS.RESPONSIVE_RESOLUTION && ban.banner_contents.sm) {
            img = ban.banner_contents.sm
        } else if (props.resolution[0] <= props.CONFIG.TOSPLIT.PRODUCT_BANNERS.RESPONSIVE_RESOLUTION && ban.banner_contents.md) {
            img = ban.banner_contents.md
        }
        return img
    }
    return (
        <div className="position-relative h-100">
            <Slider {...getSwiperConfig('homeSlider', {banner: props.banner, countBannerView: props.countBannerView, ref})} ref={ref} >
                {props.banner.map((ban, i) => (
                    <div className={props.parentClassName} key={i}>
                        <a href={getUrl(ban)} rel="noopener noreferrer" target="_blank">
                            <img className="z-index-9 w-100" src={getImg(ban)} alt=""/>
                        </a>
                    </div>
                ))}
            </Slider>
            <SliderNav ref={ref} {...{
                loop: true,
                leftClassName: "btn btn-no-outline main-slider-btn p-0", 
                rightClassName: "btn btn-no-outline right-btn main-slider-btn p-0"
            }} />
        </div>
    )   
})

export default HomeSlider