import React from 'react';
import Select from 'react-select';

const SelectSingleField = (props) => {
  return (
		<div className={props.styles?.parentClassname ?? ''}>
			<Select {...{
				...props.selectProps,
			}} />
		</div>
  )
}

export default SelectSingleField