import React from 'react'
const ListView = (className = '') => (
<span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center  ${className}`}>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd"
        d="M3.5 7.88462V3.5H7.8843V7.88462H3.5ZM2 3C2 2.44772 2.44772 2 3 2H8.3843C8.93658 2 9.3843 2.44772 9.3843 3V8.38462C9.3843 8.9369 8.93658 9.38462 8.3843 9.38462H3C2.44772 9.38462 2 8.9369 2 8.38462V3ZM12.1172 7.88462V3.5H16.5015V7.88462H12.1172ZM10.6172 3C10.6172 2.44772 11.0649 2 11.6172 2H17.0015C17.5538 2 18.0015 2.44772 18.0015 3V8.38462C18.0015 8.9369 17.5538 9.38462 17.0015 9.38462H11.6172C11.0649 9.38462 10.6172 8.9369 10.6172 8.38462V3ZM3.5 12.1154V16.5H7.8843V12.1154H3.5ZM3 10.6154C2.44772 10.6154 2 11.0631 2 11.6154V17C2 17.5523 2.44772 18 3 18H8.3843C8.93658 18 9.3843 17.5523 9.3843 17V11.6154C9.3843 11.0631 8.93658 10.6154 8.3843 10.6154H3ZM12.1172 16.5V12.1154H16.5015V16.5H12.1172ZM10.6172 11.6154C10.6172 11.0631 11.0649 10.6154 11.6172 10.6154H17.0015C17.5538 10.6154 18.0015 11.0631 18.0015 11.6154V17C18.0015 17.5523 17.5538 18 17.0015 18H11.6172C11.0649 18 10.6172 17.5523 10.6172 17V11.6154Z"
        fill="#222222" />
</svg>
 </span>
)

export default ListView
