import React, {useContext} from 'react'
import { AppDataContext } from 'contexts/appData';
const BlockedReason = (props) => {
	const { appData } = useContext(AppDataContext);
	return (
    <>
      <div className="mb-24px custom-modal-wrapper">
				<h3 className="blocked-reason-text font-size-16 font-bold-caps text-dark text-center mb-24px pt-32px pt-md-16px">
					{`${props.langs.BlockReason}:`}
				</h3>
				{props.data.reason?.map((reason, i) => (
					<div className="blocked-reason d-block w-100 mt-16px py-12px  border-radius-lg d-inline-block " key={i}>
						<div className="d-flex align-items-center px-12px">
							<i className="svg-i blocked-i flex-shrink mr-8px"></i>
							<div className="blocked-reason-text font-size-12 font-bold text-dark text-center py-8px mb" >
								{appData.BlockReasons[reason]}
							</div>
						</div>
					</div>
				))}
			</div>
      <div className=" mt-0">
				<button type="button" className="font-size-14 font-base-caps px-64px py-16px btn bg-middle-gray   text-blue-gray d-block mx-auto " onClick={e => props.callback(e, 'destroy')}>
					{props.langs.Close}
				</button>
			</div>
    </>
  )
}

export default BlockedReason;