import React from 'react';
import WarrningImg from '../../../resources/img/icons/warrningsvg.svg'
import { Link } from 'react-router-dom'
import { Parser } from 'components';
const SecurityFishing = (props) => {
    const destroy = (e) => {
        props.callback(e, 'destroy');
        props.callback(e, 'afterDestroy');
    }
    return (
        <div className="custom-modal-wrapper content-scroll bg-white">
            <Link to={`/${props.getLang}/safetylanding`} target="_blank"
                className="d-flex align-items-center rounded-0 text-decoration-none text-black w-100"
                onClick={destroy}>
                <div className='p-20px pb-30px'>
                    <div className='text-center'>
                        <img src={WarrningImg} alt="" />
                        <h1 className='pb-24px pt-10px font-size-32'>{props.langs.NM_WarrningTitle}</h1>
                    </div>
                    <div className='text-editor-styles'>
                        <Parser html={props.langs.NM_WarrningListFull} />
                    </div>
                </div>
            </Link>
        </div>

    )
}

export default SecurityFishing