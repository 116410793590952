import React from 'react'
import AccountIcon from '../../../../src/resources/img/icons/account-icon.svg'
import { Link } from 'react-router-dom'

const Empty = (props) => {
	const {
		langs,
		getLang,
	} = props;
	return (
		<>
			<div className='row'>
				<div className="border-radius-md w-100 h-md-500px h-auto mt-md-32px mt-24px py-40px bg-white mb-24px mb-m-48px d-flex align-items-center justify-content-center">
					<div className='d-block text-center max-width-350px'>
						<img src={AccountIcon} alt="AccountIcon" className='mb-16px' />
						<p className='font-size-16 font-weight-bold mb-16px'>
							{langs.NM_AccountManegment}
						</p>
						<p className='font-size-14 text-greymiddle mb-32px'>
							{langs.NM_AccountText}
						</p>
						<Link className=""
							to={`/${getLang}/account/new`}>
							<button
								className={`btn bg-gradient text-black font-bold-caps px-32px py-18px w-auto w-md-100`}>
								{langs.NM_AddAccount}
							</button>
						</Link>

					</div>
				</div>
			</div>
		</>
	)
}
export default Empty