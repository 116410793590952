import React, {useState, useEffect, useContext} from 'react';
import { HistoryContext } from 'contexts/history';
import { BannersContext } from 'contexts/banners';
import { Banner } from 'components';

const HeaderScrollBanner = (props) => {
  const { getLang, storage, CONFIG } = props;
  const { adBlock, resolution, bannerTimeout, checkTimeout, bannersData, getBanner } = useContext(BannersContext);
  const { history } = useContext(HistoryContext);
  const [adoHeader, setAdoHeader] = useState(null)
  const [callCounter, setCallCounter] = useState(false);
  const [counter, setCounter] = useState(0);

  const bannerClose = () => {
    bannerTimeout('AdoHeader');
    setAdoHeader(false);
    window.removeEventListener('scroll', scrollHandler);
    storage.dispatch()
  }

  const processCount = () => {
    setCounter(counter + 1);
  }
  
  const scrollHandler = () => {
    if (window.pageYOffset < 200) {
      setAdoHeader(true)
      setCallCounter(true);
    } else {
      setAdoHeader(false)
    }
 	}

  const refreshBanner = () => {
    let bannerData = getBanner('AdoHeader')
    let element = document.querySelector('[data-position="AdoHeader"]')
    if (bannerData && element) {
      element.id = bannerData.banner_content
      window.ado.refresh(bannerData.banner_content)
    }
  }

  useEffect(() => {
    if (callCounter) {
      processCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callCounter]);

  useEffect(() => {
    if (adoHeader && bannersData && counter > 0 && [`/${getLang}`, `/${getLang}/`].includes(history.pathname) && checkTimeout('AdoHeader')) {
      refreshBanner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adoHeader, bannersData])

  useEffect(() => {
    if ([`/${getLang}`, `/${getLang}/`].includes(history.pathname) && checkTimeout('AdoHeader')) {
      window.addEventListener('scroll', scrollHandler);
    }
   	return () => window.removeEventListener('scroll', scrollHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.pathname]);
  
  return (
  <>
  {(checkTimeout('AdoHeader') && !adBlock && [`/${getLang}`, `/${getLang}/`].includes(history.pathname)) &&
  <div className={`nm-ado-header ${resolution[0] <= CONFIG.TOSPLIT.PRODUCT_BANNERS.RESPONSIVE_RESOLUTION ? 'nm-ado-mobile' : ''}`}>
    <div className="close-video p-4px m-0 rounded-circle bg-white position-absolute top-8px right-8px z-index-1000 cursor-pointer"
      onClick={bannerClose}>
      <i className="svg-i close-black-i w-14px h-14px"></i>
    </div>
    <Banner {...{
      isOn: true,
      name: 'AdoHeader',
      mobileName: 'AdoHeader',
      groupName: false,
      parentClassName: "nm-ado-header-inner position-relative",
      childClassName: ""
    }}/>
  </div>
  }
  </>
  )
}

export default HeaderScrollBanner;