import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HistoryContext } from 'contexts/history';
import * as Yup from 'yup';
import { API } from 'services';
import { UserContext } from 'contexts/user';
import { Loader } from 'components'
import { ModalsContext } from 'contexts/modals';

const MessageToUser = (props) => {
    const api = API();
    const { user } = useContext(UserContext);
    const [messageState, setMessageState] = useState(false)
    const [submitState, setSubmitState] = useState(false)
    const { stringify } = useContext(HistoryContext);
    const { callResponse } = useContext(ModalsContext);


    const [setActiveUser] = useState(false);
    const [activeUserID, setActiveUserID] = useState(false);
    const [activeUserGender, setActiveUserGender] = useState(null);

    const config = {
        initialValues: { Message: '' },
        validationSchema: Yup.object().shape({
            Message: Yup.string().required(props.langs.WriteMessageText),
        }),
        enableReinitialize: false,
        validateOnBlur: false,
        validateOnChange: false,
    }
    const getUserMessages = async (e, UserID, GenderID) => {
        e && (e.preventDefault() || e.stopPropagation());
        let query = {
            MyGenderID: user.GenderID,
            DestGenderID: GenderID,
            DestUserID: UserID,

        }
        const apiData = await api.getUserMessages(stringify(query, true));
        if (apiData.statusCode) {
            getActiveUser(false, UserID, GenderID)
        }
    }
    const handleSubmit = async (values) => {
        setSubmitState(true)
        const apiData = !props.data.PrID ?
            await api.sendUserMessageChat({
                ReceiverUserID: props.data.UserID,
                Message: values.Message,
                MyGenderID: props.data.MyGenderID === null ? 0 : props.data.MyGenderID,
                DestGenderID: props.data.DestGenderID === null ? 0 : props.data.DestGenderID,
            })
            :
            await api.sendMessageToUser({
                PrID: props.data.PrID,
                Message: values.Message
            });

        if (apiData.statusCode) {
            getUserMessages(false, activeUserID, activeUserGender)
            setMessageState(true)
            if (apiData.data.block_reason_id === 1) {
               
                if (apiData.data.block_reason_id === 1)
                    callResponse({
                        statusCode: false,
                        statusMessage: props.langs.BlockReasonCard,
                    })
                
                    
            }
        } else {
            setSubmitState(false)
        }

    }
    const getActiveUser = (sendData, userID, genderID) => {
        if (sendData) {
            setActiveUser(sendData[0]?.user)
        } 
        if (userID !== undefined && parseInt(userID) >= 0) {
            setActiveUserID(parseInt(userID))
            setActiveUserGender(genderID ? parseInt(genderID) : 1)
        } else {
            if (sendData.length > 0) {
                let activeUser = sendData[0]
                setActiveUserID(parseInt(activeUser.dest_user_id))
                setActiveUserGender(activeUser.user?.gender_id ? parseInt(activeUser.user.gender_id) : 1)
            }
        }
    }

    const SuccessComponent = () => (
        <div className="position-relative pt-24px text-center">
            <div className="popup-icon d-flex align-items-center justify-content-center">
                <i className="svg-i send-offer-i"></i>
            </div>
            <h4 className="font-bold-caps font-size-21px text-dark mt-16px">
                {props.langs.MessageSuccessfullySent}
            </h4>
            <span className="text-blue-gray font-base font-size-16 d-inline-block mt-8px">
                {props.langs.NM_MessageToUser[0]}
                <span className="text-blue mx-8px">{`ID${props.data.UserID}`}</span>
                {props.langs.NM_MessageToUser[1]}
            </span>
            <Link to={`/${props.getLang}/chat`} onClick={(e) => props.callback(e, 'destroy')} className="btn bg-middle-gray text-blue-gray mt-48px font-size-14 font-medium-caps font-weight-normal mb-64px py-20px px-140px">
                {props.langs.NM_ViewChatUser}
            </Link>
        </div>
    )

    return (
        <div className="custom-modal-wrapper">
            {messageState ?
                <SuccessComponent />
                :
                <Formik {...config} onSubmit={handleSubmit}>
                    <Form autoComplete="off">
                        <div className="position-relative pt-24px">
                            <div className="popuo-content  px-md-40px  content-scroll">
                                <h3 className="font-bold-caps font-size-18 pb-32px text-center">
                                    {props.langs.SendContactInfo}
                                </h3>
                                <div className="form-group col p-0">
                                    <Field as="textarea" name="Message" className="form-control pb-sm-200px text-dark font-size-14 font-base" placeholder={props.langs.NM_WriteToSeller} />
                                    <ErrorMessage name="Message" component="label" className="error text-left" />
                                </div>
                            </div>
                            {submitState && <Loader />}
                            <div className="popup-footer d-flex justify-content-between mt-40px px-40px  pt-24px">
                                <button type="button" className="btn bg-transparent font-base font-size-13 text-blue-gray px-0"
                                    onClick={(e) => props.callback(e, 'destroy')}>
                                    {props.langs.Cancel}
                                </button>
                                <button type="submit" className="btn bg-gradient font-bold-caps font-size-13 text-white  px-40px py-16px" disabled={submitState}>
                                    {props.langs.Send}
                                </button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            }
        </div>
    )
}

export default MessageToUser