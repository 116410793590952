import React, { useContext } from 'react';
import { SyncContext } from 'contexts/sync';
import { AppDataContext } from 'contexts/appData';

import ListView from './components/ListView';
import BoxView from './components/BoxView';
import * as gtagAnalytics from '../../../libs/gtagAnalytics';
import {Link} from "react-router-dom";
import {HistoryContext} from "../../../contexts/history";
import { makeProductItem } from '../../../libs/helpers';

const ProductCard = (props) => {
  const { langs } = props;
  const { productPrefixUrl, currency, isSafari, appData, api } = useContext(AppDataContext);
  const { toggleItem, checkItem } = useContext(SyncContext);
  const {params} = useContext(HistoryContext)

  const checkFavPr = checkItem(false, props.product.product_id);
  const checkCartPr = checkItem(true, props.product.product_id);

  const toggleFavPr = (e) => {
    e && (e.preventDefault() || e.stopPropagation());
    toggleItem(false, props.product.product_id, props.product);
    if (props.onProductChange) props.onProductChange(props.product.product_id);

    const gtagData = {
      currency: 'GEL',
      value: Number(props.product.price),
      items: [{
        ...makeProductItem(props.product),
        quantity: 1,
      }],
    };

    if (checkFavPr) {
      gtagAnalytics.removeFromWishlistEvent({
        ...makeProductItem(props.product),
        quantity: 1,
      });
    } else {
      gtagAnalytics.addToWishlistEvent(gtagData);
    }
  };

  const toggleCartPr = (e) => {
    e && (e.preventDefault() || e.stopPropagation());
    toggleItem(true, props.product.product_id, props.product);

    const gtagData = {
      currency: 'GEL',
      value: Number(props.product.price),
      items: [{
          ...makeProductItem(props.product),
          quantity: 1,
        }],
    };

    if (checkCartPr) {
      gtagAnalytics.removeFromCartEvent(gtagData);
    } else {
      gtagAnalytics.addToCartEvent(gtagData);
    }
  };

  const getVip = () => {
    let promotions = {
      20: {
        text: 'S-VIP',
        className: 'vip-super-plus box-item  bg-dark-green arctic-headline font-size-12 text-white px-8px',
      },
      15: {
        text: 'VIP +',
        className: 'vip-plus box-item bg-dark-orange arctic-headline font-size-12 text-white px-8px',
      },
      10: {
        text: 'VIP',
        className: 'vip box-item bg-blue arctic-headline font-size-10 text-white px-8px',
      },
    };
    return promotions[props.product.vip] ?? false;
  };

  const clickProduct = (product, event) => {
    event.preventDefault()
    const productUrl = productPrefixUrl(product);

    const productsPerPage = 28
    const currentPage = params.Page ? Number(params.Page) : 1;
    const productIndex = (props.productIndex + 1) + (productsPerPage * (currentPage - 1))

    gtagAnalytics.viewItemEvent({
      currency: 'GEL',
      value: Number(product.price),
      items: [{
        ...makeProductItem(product),
        index: productIndex,
        quantity: 1,
      }]
    });

    window.location.href = productUrl;
  };

  const prom = getVip();
  return (
    <Link to={productPrefixUrl(props.product)} className="h-100 d-block text-decoration-none cursor-pointer"
          onClick={(event) => clickProduct(props.product, event)}
    >
      {props.activeStyle === true || props.activeStyle === undefined ? (
        <BoxView
          {...{
            langs,
            currency,
            checkFavPr,
            checkCartPr,
            toggleFavPr,
            setShowFeedback: props.setShowFeedback,
            showFeedback: props.showFeedback,
            toggleCartPr,
            data: props,
            prom,
            appData,
            isSafari,
            activeStyle: props.activeStyle,
            favorites: props.favorites,
            isSearch: props.search,
          }}
        />
      ) : (
        <ListView
          {...{
            langs,
            currency,
            checkFavPr,
            checkCartPr,
            toggleFavPr,
            toggleCartPr,
            data: props,
            prom,
            appData,
            isSafari,
            activeStyle: props.activeStyle,
            isSearch: props.search,
          }}
        />
      )}
    </Link>
  );
};

export default ProductCard;
