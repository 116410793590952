import { Checkbox } from 'components/index';
import TnetPlatforms from 'components/modals/components/payment/components/TnetPlatforms';
import PaymentCardItem from 'components/payment/PaymentCardItem';
import PaymentItem from 'components/payment/PaymentItem';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Loader from 'components/Loader';
import { AppDataContext } from 'contexts/appData';
import { HistoryContext } from 'contexts/history';
import { ModalsContext } from 'contexts/modals';
import { UserContext } from 'contexts/user';
import { Constants, useCardIcon, useEventListener, useMediaQuery } from 'libs';

const FormParams = {
  orderId: 'OrderID',
  transId: 'trans_id',
};

const PaymentOptions = ({ data = {}, callback }) => {
  const { paymentTypeId, price, params = {}, processPayment } = data;
  const totalAmount = parseFloat(price).toFixed(2);

  const { langs, api, currency, CONFIG: config, isSafari, getLang } = useContext(AppDataContext);
  const { callResponse } = useContext(ModalsContext);
  const { getPathname } = useContext(HistoryContext);
  const { userBalance, accountDetails } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const formRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  // cards
  const [saveCard, setSaveCard] = useState(true);
  const [cards, setCards] = useState([]);
  const [cardSelection, setCardSelection] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const isNewCard = !selectedCard && saveCard && selectedPaymentMethod?.id === 2;
  const [iframe, setIframe] = useState(null);
  const { getCardIcon } = useCardIcon();
  //

  const insufficientBalanceAmount = parseFloat(totalAmount) > parseFloat(userBalance);

  const onSuccess = () => {
    callback(null, 'success');
    callback(null, 'destroy');
  };

  const handlePayment = (paymentMethod) => {
    setPaymentLoading(true);

    if (processPayment) return processPayment(paymentMethod);

    const query = {
      PaymentMethodID: paymentMethod.id,
      PaymentTypeID: paymentTypeId,
      Amount: totalAmount,
      ReturnURL: config.HOST.appUrl + getPathname().substring(1),
      ...params,
    };

    if (paymentMethod.id === 2) {
      if (selectedCard) {
        query['UserCardID'] = selectedCard.uuid;
        query['PayWith'] = 'charge';
        query['PaymentMethodID'] = Constants.PaymentMethodsEnum.saved_card;
      } else if (!selectedCard && saveCard) {
        return api
          .addUserCard({ ReturnURL: config.HOST.appUrl + getLang + '/process-card' })
          .then((res) => {
            const data = res.data;
            if (data) {
              setIframe(data.PaymentURL + `?trans_id=${data.PaymentData.trans_id}`);
            }
          })
          .finally(() => setPaymentLoading(false));
      }
    }

    api
      .processPay(query)
      .then((res) => {
        if (res.statusCode === 200) {
          const order = res.data?.Order;

          if (!order?.PaymentURL) return onSuccess();

          formRef.current.method = order.PaymentMethod || 'GET';
          formRef.current.action = order.PaymentURL;

          const formData = new FormData(formRef.current);
          formData.set(FormParams.transId, order.PaymentData?.trans_id ?? '');
          if (config.HOST.branch === 'TEST') {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = FormParams.orderId;
            input.readOnly = true;
            formRef.current.appendChild(input);
            formData.set(FormParams.orderId, order.OrderID ?? '');
          }
          for (const [name, value] of formData.entries()) {
            formRef.current.elements[name].value = value;
          }

          formRef.current.submit();
        } else {
          callResponse({ statusCode: false, statusMessage: res.statusMessage });
        }
      })
      .finally(() => setPaymentLoading(false));
  };

  const handleMessage = useCallback((e) => {
    try {
      if (e.data?.event === 'card') {
        api.getUserCards().then((res) => {
          const cards = res?.data?.UserCards ?? [];
          const cardWithMaxId = cards?.reduce((maxItem, currentItem) => {
            return parseInt(currentItem.id) > parseInt(maxItem.id) ? currentItem : maxItem;
          }, cards[0]);

          setCards(cards);
          setSelectedCard(cardWithMaxId ?? cards[0]);
          setIframe(null);
        });

        // 2, 3 - success statuses
        if (parseInt(e.data?.payload.StatusID) !== 2 && parseInt(e.data?.payload.StatusID) !== 3) {
          callResponse({ statusCode: false });
        }
      }
    } catch {
      //
    }
  }, []);

  useEventListener('message', handleMessage);

  useEffect(() => {
    Promise.all([api.getPaymentMethods({ paymentTypeId: paymentTypeId, amount: totalAmount }), api.getUserCards(), accountDetails()])
      .then(([paymentMethodsRes, cardsRes]) => {
        const paymentMethods = isSafari ? paymentMethodsRes.data : paymentMethodsRes.data.filter((el) => el.id !== Constants.PaymentMethodsEnum.apple);
        setPaymentMethods(paymentMethods);

        const cards = cardsRes?.data?.UserCards ?? [];
        setCards(cards);
        setSelectedCard(cards.find((card) => card.favorite === '1') || cards[0]);
      })
      .finally(() => setLoading(false));
  }, []);

  if (iframe) {
    return <iframe title="addCard" className="mt-40px w-100 border-0 h-600px" src={iframe} />;
  }

  if (cardSelection) {
    return (
      <div className="custom-modal-wrapper">
        <div className="p-8px">
          <span
            className="svg-i back-arrow cursor-pointer"
            onClick={() => {
              const card = cards.find((card) => card.favorite === '1') || cards[0];
              setSelectedCard(cards.find((card) => card.favorite === '1') || cards[0]);
              setCardSelection(false);
              const isExpired = moment(new Date()).isSameOrAfter(moment(card?.expire_date ?? ''));
              if (isExpired) {
                setSelectedPaymentMethod(false);
              }
            }}
          />
        </div>

        <div className="pay-content pt-30px">
          <div className="position-relative">
            <form ref={formRef} method="GET" action="">
              <input type="hidden" name={FormParams.transId} readOnly />
            </form>
            <div className="popup-content px-md-40px payment-options-saved-cards-modal">
              <h1 className="font-bold-caps font-size-24 text-dark-text text-center mb-8px">{langs.NM_PayWithCard}</h1>
              <p className="font-base font-size-14 text-blue-gray text-center">{langs.NM_ChooseOrAddCard}</p>
              <div className={`pay-check mt-32px ${!selectedCard && saveCard ? '' : 'mb-40px'}`}>
                <div className="font-size-14 font-medium mb-12px text-dark">{langs.NM_MyCards}</div>
                {cards.map((card, i) => {
                  const isSelected = card.uuid === selectedCard?.uuid;
                  const Icon = getCardIcon(card.card_mask);
                  const isExpired = moment(new Date()).isSameOrAfter(moment(card.expire_date));
                  return (
                    <div className={`mb-8px ${isExpired ? 'pointer-events-none' : 'cursor-pointer'}`} key={i}>
                      <div
                        className={`d-flex justify-content-between align-items-center custom-control custom-radio border-radius-md py-24px m-auto ${
                          isSelected ? 'active' : ''
                        } ${isExpired ? 'expired bg-pink-error' : ''}`}
                        onClick={(e) => {
                          return setSelectedCard(card);
                        }}
                      >
                        <label className={`custom-control-label ${isSelected ? 'active' : ''}`}>
                          <Icon className="mr-14px" />
                          <div className={`font-medium font-size-14 text-text-black ${isExpired ? 'opacity-40' : ''}`}>
                            {isMobile ? card.card_mask.slice(-8) : card.card_mask}
                          </div>
                        </label>
                        {isExpired && (
                          <div className="bg-red-80 font-medium font-size-12 text-white py-4px px-8px ml-16px rounded mr-24px">{langs.NM_CardExpired}</div>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="font-size-14 font-medium mb-12px mt-32px text-dark">{langs.NM_AddNewCard}</div>

                <div className="mb-8px cursor-pointer">
                  <div
                    className={`d-flex justify-content-between align-items-center custom-control custom-radio border-radius-md py-24px m-auto ${
                      !selectedCard ? 'active' : ''
                    }`}
                    onClick={() => setSelectedCard(null)}
                  >
                    <div className={`d-flex align-items-center font-medium font-size-12 font-size-md-14 text-text-black`}>
                      <i className="svg-i svg-i-md add-statement-black-i mr-16px flex-shrink"></i>
                      {langs.NM_PayWithOtherCard}
                    </div>

                    <Checkbox
                      name="SaveCard"
                      className="mr-24px font-size-10 font-size-md-12"
                      checked={saveCard}
                      onChange={(e) => setSaveCard(e.target.checked)}
                      label={langs.NM_SaveCard}
                    />
                  </div>
                </div>
              </div>
            </div>

            {!selectedCard && saveCard && (
              <div className="px-md-40px mb-40px">
                <TnetPlatforms />
              </div>
            )}

            <div className="popup-footer d-flex justify-content-between px-64px pt-24px">
              {!isNewCard ? (
                <div className="text-left mb-16px my-md-auto d-flex align-items-center d-md-block">
                  <h2 className="font-bold-caps font-size-18 text-dark-text mr-8px m-md-0">
                    {totalAmount} {currency()}
                  </h2>
                  <p className="font-base font-size-12 text-blue-gray m-0">{langs.PriceToPay}</p>
                </div>
              ) : (
                <div></div>
              )}

              {!paymentLoading && (
                <button
                  disabled={!selectedPaymentMethod}
                  className={`btn bg-gradient font-bold-caps font-size-13 text-white ${!selectedPaymentMethod ? 'disabled' : ''}`}
                  onClick={(e) => {
                    handlePayment(selectedPaymentMethod);
                  }}
                >
                  {isNewCard ? langs.NM_AddCard : langs.Pay}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="custom-modal-wrapper">
      <div className="position-relative pay-content">
        <form ref={formRef} method="GET" action="">
          <input type="hidden" name={FormParams.transId} readOnly />
        </form>
        <div className="pt-40px px-md-40px popup-content payment-options-cards-modal">
          <div className="font-bold-caps font-size-24 text-dark-text text-center mb-8px">{langs.PayAmount}</div>
          <div className="font-base font-size-14 text-blue-gray text-center">{langs.ChoosePaymentMethod}</div>
          <div className={`d-flex flex-column pay-check mt-32px ${!isNewCard ? 'mb-40px' : ''}`}>
            {loading && (
              <div className="h-240px">
                <Loader />
              </div>
            )}
            {paymentLoading && <Loader />}

            {paymentMethods?.map((paymentMethod) => {
              const isSelected = selectedPaymentMethod?.id === paymentMethod.id;
              const isCard = paymentMethod.id === 2;
              const isExpired = moment(new Date()).isSameOrAfter(moment(selectedCard?.expire_date ?? ''));

              if (isCard) {
                return (
                  <PaymentCardItem
                    key={paymentMethod.id}
                    card={selectedCard}
                    method={{ title: paymentMethod.name, icon: Constants.PaymentMethodsIconsEnum[paymentMethod.id] }}
                    isSelected={isSelected}
                    isExpired={isExpired}
                    onChange={!isExpired ? () => setSelectedPaymentMethod(paymentMethod) : undefined}
                    saveCard={saveCard}
                    onSaveCard={(value) => setSaveCard(value)}
                    onCardChange={() => {
                      setCardSelection(true);
                      setSelectedPaymentMethod(paymentMethod);
                      if (isExpired) setSelectedCard(false);
                    }}
                  />
                );
              }

              return (
                <PaymentItem
                  key={paymentMethod.id}
                  method={{ title: paymentMethod.name, icon: Constants.PaymentMethodsIconsEnum[paymentMethod.id], checkBalance: !insufficientBalanceAmount }}
                  isSelected={isSelected}
                  isBalance={paymentMethod.id === Constants.PaymentMethodsEnum.balance}
                  balance={userBalance}
                  onChange={() => setSelectedPaymentMethod(paymentMethod)}
                />
              );
            })}
          </div>
        </div>

        {isNewCard && (
          <div className="px-md-40px mb-40px">
            <TnetPlatforms />
          </div>
        )}

        <div className="popup-footer d-flex justify-content-between px-64px  pt-24px">
          {!isNewCard ? (
            <div className="text-left mb-16px my-md-auto d-flex align-items-center d-md-block">
              <h2 className="font-bold-caps font-size-18 text-dark-text mr-8px m-md-0">
                {totalAmount} {currency()}
              </h2>
              <p className="font-base font-size-12 text-blue-gray m-0">{langs.PriceToPay}</p>
            </div>
          ) : (
            <div></div>
          )}

          {!paymentLoading && (
            <button
              disabled={!selectedPaymentMethod}
              className={`btn bg-gradient font-bold-caps font-size-13 text-white ${!selectedPaymentMethod ? 'disabled' : ''}`}
              onClick={(e) => {
                handlePayment(selectedPaymentMethod);
              }}
            >
              {isNewCard ? langs.NM_AddCard : langs.Pay}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentOptions;
