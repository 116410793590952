import React, { useState, useEffect, useContext } from 'react';
import { BannersContext } from 'contexts/banners';

const Card = (props) => {
	const { resolution } 	= useContext(BannersContext);
	const [quantity, setQuantity] = useState(0);
	
	let defaultValues = {
		quantity: {
			xl: 6,
			l: 4,
			m: 4,
			s: 2
		}
	}

	// console.log(quantity)
	useEffect(() => {
		if (resolution && resolution[0] > 0 && props.quantity) {
			if (resolution[0] > 1200) {
				setQuantity(props.quantity ? props.quantity.xl : defaultValues.quantity.xl)
			}
			 else if (resolution[0] > 1023 && resolution[0] < 1200) {
				setQuantity(props.quantity ? props.quantity.l : defaultValues.quantity.l)
			} else if (resolution[0] > 767 && resolution[0] < 1023) {
				setQuantity(props.quantity ? props.quantity.m : defaultValues.quantity.m)
			}
			else if (resolution[0] < 767) {
				setQuantity(props.quantity ? props.quantity.s : defaultValues.quantity.s)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resolution])

	return (
		<div className={`${props.containerClass} row  placeholder-container `}>
			{[...Array(quantity)].map((item, i) => (
				<div className={`${props.cardClass}  placeholder-card`} key={i}>
					<div className="w-100 placeholder-inner">
						<div className="card-placeholder d-inline-flex flex-column">
							<div className="card-placeholder-image animate my-15px my-sm-25px my-md-40px mx-15px mx-sm-25px mx-md-40px"></div>
							<div className="card-placeholder-body px-40px py-10px">
								<div className="line border-radius-md animate m-0 mt-md-15px mx-5px"></div>
								<div className="line border-radius-md animate mt-5px mt-md-15px"></div>
								<div className="line border-radius-md animate mt-10px mb-15px mt-md-20px mb-md-20px mx-10px mx-sm-20px mx-md-50px"></div>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	)
};

export default Card