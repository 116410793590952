import React, { useState } from 'react';
import InputMask from "react-input-mask";
import { Request } from "services";
import { Divider } from 'components';

const PhotoMobile = ({ langs, callback, Functions, data, ...props }) => {
	const request = Request();

	const [phoneNumber, setPhoneNumber] = useState('');
	const token = data.imagesToken;


	const onSend = () => {
		if(phoneNumber) {
			// replace all non numeric
			data.api.sendImageUploadLink(phoneNumber, token)
					.then(() => callback(null, 'success'))
					.catch(() => callback(null, 'fail'));
			callback(null, 'destroy');
		}
	}

	return (
			<div className="custom-modal-wrapper">
				<div className="position-relative">
					<div className="popup-content content-scroll">
						<div className="mt-3 font-bold-caps font-size-18 mb-4px text-center">
							{langs.PhoneUpload_ScanQrCode}
						</div>

						<div className="font-size-14 text-left mb-5 text-center">
							{langs.PhoneUpload_ScanQrCodeDetails}
						</div>


						<div className="d-flex justify-content-center mb-5">
							<div className="h-150px w-150px">
								<img className="w-100 h-100 object-fit-cover" src={request._build(`upload/qr-code?token=${token}`)} alt=""/>
							</div>
						</div>

						{/*<Divider className="my-4">{langs.Or}</Divider>*/}


						{/*<div className="font-bold-caps font-size-18 mb-4px text-center">*/}
						{/*	{langs.PhoneUpload_FillNumber}*/}
						{/*</div>*/}

						{/*<div className="font-size-14 text-center mb-5">*/}
						{/*		{langs.PhoneUpload_FillNumberDetails}*/}
						{/*</div>*/}

						{/*<div className="position-relative	mb-3">*/}

						{/*	<InputMask*/}
						{/*			mask="999 99-99-99"*/}
						{/*			type="text"*/}
						{/*			placeholder={`${langs.PhoneNumber}`}*/}
						{/*			value={phoneNumber ?? ''}*/}
						{/*			onChange={e => {*/}
						{/*				setPhoneNumber(e.target.value.replace(/\D/g, ''))*/}
						{/*			}}*/}
						{/*	>*/}
						{/*		{(inputProps) => (*/}
						{/*				<input*/}
						{/*						{...inputProps}*/}
						{/*						className="form-control px-12px"*/}
						{/*				/>*/}
						{/*		)}*/}
						{/*	</InputMask>*/}
						{/*	<button*/}
						{/*			type="button"*/}
						{/*			onClick={onSend}*/}
						{/*			disabled={phoneNumber.length < 9}*/}
						{/*			className="btn ml-auto h-38px	px-20px d-flex align-items-center position-absolute right-5px top-50 bg-primary transform-y-center font-size-12 text-primary-text-color hover-bg-primary-80"*/}
						{/*	>*/}
						{/*		{langs.Send}*/}
						{/*	</button>*/}
						{/*</div>*/}

					</div>
				</div>
			</div>
	);
};

export default PhotoMobile;