const getHeadMeta = (langs) => {
  return {
    'myshop': {
      title: langs.OnlineShops
    },
    'pr-form': {
      title: ''
    },
    'address': {
      title: langs.Addresses
    },
    'account': {
      title: langs.MyAccountes
    },
    'chat': {
      title: langs.messages
    },
    'favorites': {
      title: langs.MyFavorites
    },
    'finances': {
      title: langs.MyFinaces
    },
    'mycart': {
      title: langs.MyCart
    },
    'mygroups': {
      title: langs.MyGroups
    },
    'myproducts': {
      title: langs.MyPrs
    },
    'notifications': {
      title: langs.NewsHistory
    },
    'orders': {
      title: langs.MyOrders
    },
    'profile': {
      title: langs.EditAccount
    },
    'shop': {
      title: ''
    }
  }
}
const searchSortList = (langs) => {
  return [
    { value: 1, label: langs.NM_DateDesc},
    { value: 5, label: langs.NM_DateAsc},
    { value: 3, label: langs.PriceAsc},
    { value: 4, label: langs.PriceDesc},
    { value: 6, label: langs.ViewsASC},
    { value: 2, label: langs.ViewsDESC},
    { value: 7, label: langs.NM_SortByDiscountDESC},
  ]
}

// deprecated*
const searchSetTypes = () => {
  return [ 
    { id:-1, title: 'All' }, 
    { id: 1, title: 'User' }, 
    { id: 2, title: 'Shop' },
    { id: 3, title: 'Buy' },  
    // { id: 4, title: 'OnlineShopping' },
    { id: 5, title: 'Discount' }, 
    // { id: 6, title: 'Installment' }
  ];
}

const searchSellerTypes = () => {
  return [
    { id:-1, title: 'All' },
    { id: 1, title: 'User' },
    { id: 2, title: 'Shop' },
  ];
}


const searchAdTypes = () => {
  return [
    { id: -1, title: 'All' },
    { id: 0, title: 'AdType_Filter_Buy' },
    { id: 1, title: 'AdType_Filter_Sell' },
    // { id: 2, title: 'Rent' },
    // { id: 3, title: 'Services' },
  ];
}

const searchPromotionTypes = (langs) => { 
  return [
    { id: 20, title: 'SUPER VIP' }, 
    { id: 15, title: 'VIP +' }, 
    { id: 10, title: 'VIP' },
    { id: 1,  title: langs.Color },
  ];
}

const reviewsSort = (langs) => {
  return [
		{ value: 1, label: langs.TheLatest},
		{ value: 2, label: langs.NM_RatingDesc},
		{ value: 3, label: langs.NM_RatingAsc}
	]
}

const promsList = (langs) => { 
  return [
  {
    id: 20,
    title: "SUPER VIP",
    name: 'SUPER_VIP',
    period: langs.Day,
    mainClassName: "bg-dark-market-color",
    iconClassName: "vip-super-i",
    fullIcon: 'super-vip-icon-i'
  },
  {
    id: 15,
    title: "VIP PLUS",
    name: 'VIP_PLUS',
    period: langs.Day,
    mainClassName: "bg-dark-orange",
    iconClassName: "vip-i",
    fullIcon: 'vip-plus-icon-i'
  },
  {
    id: 10,
    title: "VIP",
    name: 'VIP',
    period: langs.Day,
    mainClassName: "bg-blue",
    iconClassName: "vip-i",
    fullIcon: 'vip-icon-i'
  },
  {
    id: 1,
    title: langs.Color,
    name: 'COLOR',
    period: langs.Day,
    mainClassName: "bg-blue",
    iconClassName: "color-i",
    fullIcon: 'color-icon-i',
  },
  {
    id: 3,
    title: langs.OneTimeUpdate,
    name: 'UPDATE',
    period: langs.Day,
    mainClassName: "bg-blue",
    iconClassName: "update-i",
    fullIcon: 'update-icon-i',
    custom: true
  },
  {
    id: 2,
    title: langs.AutoUpdate,
    name: 'AUTO_UPDATE',
    period: langs.Day,
    mainClassName: "bg-dark-green",
    iconClassName: "update-i",
    fullIcon: 'update-icon-i',
  }
  ]
}

const seoOfferType = (vip) => {
  const list = [
    { vip: 20, seo: 'SuperVip'},
    { vip: 15, seo: 'VipPlus'},
    { vip: 10, seo: 'VipUsual'},
    { vip: 0, seo: 'Basic'},
  ];
  let result = list.find(o => o.vip === parseInt(vip))
  return `OfferType=${result ? result.seo : 'Basic'}`;
}

const condOptions = (langs, shop) => {
  let condTypeOptions = [];
  for (let i = 0; i < 5; i++) {
    if ([0,2,3].includes(i)) {
      condTypeOptions.push({ value: `${i}`, title: langs.CondTypes[i] })
    } else if (i === 4 && (shop && shop.TypeID === 1)) {
      condTypeOptions.push({ value: '4', title: langs.CondTypes[4] })
    } else if (i === 1) {
      condTypeOptions.push({ value: '1', title: langs.CondTypes[1] })
    }
  }
  return condTypeOptions;
}

const currencyOptions = (langs) => {
  return [
    { value: 3, label: langs.Gel },
		{ value: 1, label: langs.Usd }
  ]
}

const tHistorySites = (langs) => {
  return [
    { value: 0, label:  langs.All },
    { value: 2, label: 'MYMARKET' },
    { value: 1, label: 'MYAUTO' },
    { value: 4, label: 'MYHOME' },
    { value: 3, label: 'MYPARTS' },
    { value: 5, label: 'MYJOBS' },
    { value: 8, label: 'MYADS' },
  ]
}

const checkOutTabs = (langs) => {
  return [
		{ id: 0, title: langs.PhysicalPerson },
		{ id: 1, title: langs.LegalPerson }
	]
}

const fixedLists = {
  getHeadMeta,
  searchSortList,
  searchSetTypes,
  searchPromotionTypes,
  reviewsSort,
  seoOfferType,
  promsList,
  condOptions,
  currencyOptions,
  tHistorySites,
  checkOutTabs,
  searchSellerTypes,
  searchAdTypes,
};

export default fixedLists;