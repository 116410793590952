import Functions from "libs/functions";
import { useEffect, useMemo, useRef } from "react";

export const useDebouncedCallback = (callback , waitMs = 300) => {
	const ref = useRef(null);

	useEffect(() => {
		ref.current = callback;
	}, [callback]);

	const debouncedCallback = useMemo(() => {
		const func = (...args) => {
			ref.current?.(...args);
		};

		return Functions.debounce(func, waitMs);
	}, []);

	return debouncedCallback;
};