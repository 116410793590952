import React from 'react'

const Component = (props) => {
    const defaultSrc = (e) => {
        e.onerror = null;
        e.target.src = 'https://www.mymarket.ge/beta/templates/assets/img/no-image.png'
    }
    return (
        <div className="product-details-card d-flex justify-content-between mt-24px px-0 px-md-20px py-13px">
            <div className="item-img flex-shrink p-0">
                <img onError={defaultSrc} className="w-56px h-56px object-fit-cover border-radius-10" alt={props.product.title}
                    src={props.product.images.length > 0 ? props.product.images[0].thumbs : 'https://www.mymarket.ge/beta/templates/assets/img/no-image.png'}/>
            </div>
            <div className="px-16px my-auto">
                    <span
                        className="d-flex justify-content-start font-medium font-size-14 font-weight-normal text-dark">
                        {props.product.title}
                    </span>
                <span
                    className="d-flex justify-content-start font-base font-size-12 font-weight-normal text-blue-gray">
                        {`ID${props.product.product_id}`}
                    </span>
            </div>
            <div className="ml-auto my-auto">
                    <span
                        className="d-flex justify-content-end font-base font-size-12 font-weight-normal text-blue-gray">
                        {props.product.Cost}
                    </span>
                <span
                    className="d-flex justify-content-end font-bold font-size-16 font-weight-bold text-dark">
                        {props.product.price}
                    <span className="ml-8px"> {props.currency()} </span>
                    </span>
            </div>
        </div>
    )
}
export default Component
                                