import React, {useEffect, useState} from 'react';

const SliderNav = React.forwardRef((props, ref) => {
    const [start, setStart]             = useState(false);
    const [end, setEnd]                 = useState(false);
    const loop                          = props.loop ? true : false
    
    const goNext = (e) => {
        e.preventDefault() || e.stopPropagation();
        if (ref.current !== null && !ref.current.destroyed) { 
            ref.current.slideNext()
            updateLimits()
        }
    };

    const goPrev = (e) => {
        e.preventDefault() || e.stopPropagation();
        if (ref.current !== null && !ref.current.destroyed) { 
            ref.current.slidePrev() 
            updateLimits()
        }
    };

    const updateLimits = () => {
        if (ref.current !== null ) {
            setStart(ref.current.isBeginning);
            setEnd(ref.current.isEnd);
        }
    }

    useEffect(() => {
        if (ref.current !== null && !ref.current.destroyed) {
            ref.current.on('slideChange', () => {
                updateLimits()
            });
            if (!loop) {
                updateLimits()
            } else {
                setStart(true)
                setEnd(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current, ref.current?.slides])

    let settings = {
        mainClassName: "control-btn align-items-center ml-auto d-none d-md-flex",
        leftClassName: "btn seacrh-slider-btn btn-no-outline main-slider-btn p-0 mr-6px",
        leftIconClassName: "svg-i slider-left-i",
        rightClassName: "btn seacrh-slider-btn btn-no-outline right-btn main-slider-btn p-0",
        rightIconClassName: "svg-i slider-right-i"
    }

    return (
        <div className={props.mainClassName ? props.mainClassName : settings.mainClassName}>
            <button className={props.leftClassName ? props.leftClassName : settings.leftClassName} disabled={loop ? false : start} onClick={goPrev}>
                <i className={props.leftIconClassName ? props.leftIconClassName : settings.leftIconClassName}></i>
            </button>
            <button className={props.rightClassName ? props.rightClassName : settings.rightClassName} disabled={loop ? false : end} onClick={goNext}>
                <i className={props.rightIconClassName ? props.rightIconClassName : settings.rightIconClassName}></i>
            </button>
        </div>
    )
});

export default SliderNav;