import React from "react";
import Select from 'react-select';
import { useDropzone } from 'react-dropzone';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Parser } from 'components';
const FifthForm = (props) => {
	const { langs, cancel, config, handleSubmit, files, setFiles, deleteFile, uploadImagesToStatic, quantity } = props;

	const Photos = (values) => {
		const maxPhotos = 4

		const { getRootProps, getInputProps } = useDropzone({
			maxFiles: maxPhotos,
			accept: {
				'image/*': ['.jpeg', '.png', '.jpg', '.heic', '.jpg', '.heif', '.webp']
			},
			initialFiles: '',
			onDropAccepted: async acceptedFiles => {
				if (acceptedFiles.length > maxPhotos || files.length >= maxPhotos || (acceptedFiles.length + files.length) > maxPhotos) {
					return false;
				}
				let action = await uploadImagesToStatic(acceptedFiles, values.values)
				if (action) {
					let newlyUploaded = acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
					setFiles(files.concat(newlyUploaded))
				} else {
					return false;
				}
			}
		});
		return (
			<div className=" d-flex align-items-start justify-content-center  flex-md-row-reverse row  flex-column-reverse">
				<div className="col-md-6">
				{files.map((file, i) => (
						<div className='files-preview-thumbs d-inline-block mx-2px border  border-radius-10 w-56px h-56px mx-2px ' key={i}>
							<div className="uploaded-img w-100 h-100">
								<img src={file.preview} alt={file.name} className="w-100 h-100 object-fit-cover" />
								<button className="btn w-16px h-16px m-0 d-flex-align-items-center justify-content-center position-absolute p-0 top-4px right-4px z-index-1000"
									onClick={e => deleteFile(e, i, values.values)} title={langs.DeletePhoto}>
									<i className="svg-i svg-i return-delete"></i>
								</button>
							</div>
						</div>
				))}
				</div>
					<div className="col-md-6 mb-12px mb-md-"> 
						<div className={` ${files.length < maxPhotos  ? 'd-flex justify-content-center  cursor-pointer   outline-none  d-flex   border-radius-lg w-100' : ' pointer-events-none opacity-50'}  `}
							{...getRootProps()} >
							<div className="w-100 d-flex align-items-center justify-content-center ">
								<input {...getInputProps()} name="Photos" id="Photos" />
								<div className={`w-100 d-flex justify-content-center align-items-center px-12px py-16px border-radius-md rgba-bg-rgba-blue-10`}>
									<span className={` d-flex justify-content-center align-items-center`}>
										<i className={`svg-i svg-i-lg mr-16px  return-icon `}></i>
									</span>
									<span className={`font-weight-bold font-size-14 text-dark d-flex justify-content-center`}>
										{langs.UploadPhoto}
									</span>
								</div>
							</div>
						</div>
					</div>
			</div>
		)
	}

	return (
		<div className="px-md-24px">
			<h2 className="font-size-18 font-bold text-center pt-28px pb-36px text-nLight-dark">
			<Parser html={langs.NM_ConfirmReturnFormTitle}/>
			</h2>
			<Formik {...config} onSubmit={handleSubmit} >
				{({ values, setFieldValue }) => (
					<Form>
						<div className="row">
							<div className="form-group col-12 col-md-6">
								<label className="my-12px text-text_label font-size-12 d-block" htmlFor="FirstName">
									{langs.ContactUser} <b className="text-blue">*</b>
								</label>
								<Field name="FirstName" className="form-control radius-10" />
								<ErrorMessage name="FirstName" component="label" className="error pl-25px" />
							</div>
							<div className="form-group col-12 col-md-6">
								<label className="my-12px text-text_label font-size-12 d-block" htmlFor="LastName">
									{langs.SurName} <b className="text-blue">*</b>
								</label>
								<Field type="text" name="LastName" className="form-control radius-10" />
								<ErrorMessage name="LastName" component="label" className="error pl-25px" />
							</div>
							<div className="form-group col-12 col-md-6">
								<label className="my-12px text-text_label font-size-12 d-block" htmlFor="Address">
									{langs.Address} <b className="text-blue">*</b>
								</label>
								<Field type="text" name="Address" className="form-control radius-10" />
								<ErrorMessage name="Address" component="label" className="error pl-25px" />
							</div>
							<div className="form-group col-12 col-md-6">
								<label className="my-12px text-text_label font-size-12 d-block" htmlFor="Email">
									{langs.Email} <b className="text-blue">*</b>
								</label>
								<Field type="text" name="Email" className="form-control radius-10" />
								<ErrorMessage name="Email" component="label" className="error pl-25px" />
							</div>
							<div className="form-group col-12 col-md-6">
								<label className="my-12px text-text_label font-size-12 d-block" htmlFor="Phone">
									{langs.ContactFormPhone} <b className="text-blue">*</b>
								</label>
								<Field type="text" name="Phone" className="form-control radius-10" />
								<ErrorMessage name="Phone" component="label" className="error pl-25px" />
							</div>
							<div className="form-group col-12 col-md-6">
								<label className="my-12px text-text_label font-size-12 d-block" htmlFor="ReturnReason">
									{langs.NM_ReturnReason} <b className="text-blue">*</b>
								</label>
								<Select
									name="ReturnReason"
									className="sg-selectbox-container text-nowrap w-100"
									classNamePrefix="sg-selectbox"
									isSearchable={false}
									noOptionsMessage={() => langs.NM_NothingFound}
									placeholder=""
									options={langs.NM_ConfirmReturnReasons?.map((v, i) => ({ value: v, label: v }))}
								// value={props.StatusList.find(o => o.value === props.StatusID)}
									onChange={(value) => {
								  	setFieldValue("ReturnReason", value.value)
									}}
								/>
							</div>
							<div className="form-group col-12 col-md-6">
								<label className="my-12px text-text_label font-size-12 d-block" htmlFor="Quantity">
									{langs.Quantity} <b className="text-blue">*</b>
								</label>
								<Field type="number" name="Quantity" className="form-control radius-10" disabled={quantity === 1} />
								<ErrorMessage name="Quantity" component="label" className="error pl-25px" />
							</div>
							<div className="form-group col-12 ">
								<Photos {...{ values }} />
							</div>

						</div>
						<div className="mb-16px px-20px px-md-100px d-flex justify-content-center">
							<button type="submit" className="return-item-btn-actions font-size-16 w-100 font-medium font-weight-normal py-16px btn bg-middle-gray text-dark d-block mr-12px">
								{langs.NM_finish}
							</button>
							<button className="return-item-btn-actions font-size-16 w-100 font-medium font-weight-normal py-16px btn bg-middle-gray text-dark d-block mr-12px"
								type="button" onClick={cancel}>
								{langs.NM_Quit}
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	)
}
export default FifthForm