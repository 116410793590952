import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from 'contexts/user';
import { HistoryContext } from 'contexts/history';
import { AppDataContext } from 'contexts/appData';
import Logout from 'components/global/Logout';

// import openShop from '../../src/resources/img/open-shop.svg';
// import isShop from '../../src/resources/img/rocket.png';

import {
	EditAccount,
	// AddStatement,
	LogOutIcon,
	MyAdress,
	// MyCarts,
	// MyFaforites,
	// Mymessage,
	MyOrders,
	// NotificationHistory,
	Statements,
	ShopsOrder,
	MyFinnaces,
	MyShop,
	MyGroups,
	Avatar,
	// Tradein,
	InnerInstallment,
	// FillBalance,
	BidsAndOffers,
	// Tooltip
} from 'components'

const UserDropdownMenu = (props) => {
	const { langs, getLang } = useContext(AppDataContext)
	const { getPathname } = useContext(HistoryContext)
	const { user, shop } = useContext(UserContext);
	const url = getPathname()

	// const [balance, setBalance] = useState(userBalance);
	const mobileSidebar = props.mobileSidebar ? props.mobileSidebar : null
	const toggleMenu = (e, action = null) => {
		if (mobileSidebar !== null) {
			props.toggleMenu(e, action)
		}
	}


	// const openAddDialog = (e) => {
	// 	e.preventDefault() || e.stopPropagation();
	// 	if (!user) {
	// 		Functions.redirectWindow(true, getLang, true, `${CONFIG.HOST.appUrl}${getPathname().substring(1)}`);
	// 		return;
	// 	}
	// 	callModal({
	// 		component: 'NewProduct',
	// 		styles: { inner: "new-product" },
	// 		callback: {
	// 			preDestroy: () => toggleMenu(e, false)
	// 		}
	// 	})
	// }

	const list = [
		
		{
			title: `${langs.MyPrs}`,
			params: {
				to: `/${getLang}/myproducts`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: Statements(),
			type: null
		},
		{
			title: `${langs.NM_ReceivedOffers}`,
			params: {
				to: `/${getLang}/offer-price/seller`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100 seller  '
			},
			component: BidsAndOffers(),
			type: null,
			tooltip: true
		},
		{
			title: `${langs.ShopOrders}`,
			params: {
				to: `/${getLang}/orders/shop`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: ShopsOrder(),
			type: (shop !== null && shop.TypeID === 1)
		},
		{
			title: `${langs.SoldOrders}`,
			params: {
				to: `/${getLang}/orders/c2c`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: ShopsOrder(),
			type: shop === null
		},

		{
			title: `${langs.NM_MyCards}`,
			params: {
				to: `/${getLang}/mycards`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: null,
			type: null,
		},

		{
			title: `${langs.MyFinaces}`,
			params: {
				to: `/${getLang}/finances`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  w-100   ',

			},
			component: MyFinnaces(),
			type: null
		},
		
		
		{
			title: `${langs.MyGroups}`,
			params: {
				to: `/${getLang}/mygroups`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: MyGroups(),
			type: (shop !== null && shop.TypeID === 1)
		},
	
		{
			title: `${langs.MyShop}`,
			params: {
				to: `/${getLang}/shop`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  w-100   '
			},
			component: MyShop(),
			type: shop !== null
		},
		
		{
			title: `${langs.NM_InnerInstallment}`,
			params: {
				to: `/${getLang}/installment`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  w-100   '
			},
			component: InnerInstallment(),
			type: shop !== null && (shop.ID === 10949 || shop.ID === 6688)
		},
		
		{
			title: `${langs.MyOrders}`,
			params: {
				to: `/${getLang}/orders/user`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: MyOrders(),
			type: null
		},
		{
			title: `${langs.NM_senTOffers}`,
			params: {
				to: `/${getLang}/offer-price/user`,
				route: '',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   '
			},
			component: BidsAndOffers(),
			type: null
		},
		{
			title: `${langs.Addresses}`,
			params: {
				to: `/${getLang}/address`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  w-100  '
			},
			component: MyAdress(),
			type: null
		},
		{
			title: `${langs.NM_MyAccounts}`,
			params: {
				to: `/${getLang}/account/list`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none  w-100  '
			},
			component: MyAdress(),
			type: null
		},
		
		{
			title: `${langs.EditAccount}`,
			params: {
				to: `/${getLang}/profile`,
				route: '#',
				className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100 '
			},
			component: EditAccount(),
			type: null
		}

		
	]

	return (
		<div className={`mr-16px mr-lg-20px ${mobileSidebar ? 'user_profile sidebar-scroll bg-white' : 'user_menu mb-48px mb-lg-100px'}`}>
			{mobileSidebar &&
				<div className="btn m-sidebar-close bg-white rounded-circle top-50px right-40px  p-8px" onClick={toggleMenu}>
					<i className="svg-i  close-gray-i"></i>
				</div>
			}
			<div>
				{user &&
					<div className={`sidebar-container px-16px  bg-white mb-0 ${mobileSidebar ? 'border-radius-0  border-0 pt-20px' : 'border-radius-md '}`}>
						<div className={`sidebar-head pt-20px border-radius-md ${mobileSidebar ? 'bg-white border-0' : ''}`}>
							<div className="d-flex align-items-center">
								<div className={`avatar mr-8px ${mobileSidebar ? 'bg-market-color' : ''} `}>
									<Avatar {...{
										logo: false,
										link: '',
										photo: user.Photo ? user.Photo : false,
										gender: user.GenderID ? user.GenderID : 1
									}}
										isHeaderDropdown={true}
									isLoggedIn={!!user} />
								</div>
								<div className="overflow-hidden max-width-180px">
									<div className="font-bold font-size-16 text-truncate user-name">
										{(!user.Name || user.Name === null || user.Name === 'undefined') ? user.Username : `${user.Name} ${user.Lastname}`}
									</div>
									<div className={`font-size-12 font-medium user-id    ${mobileSidebar ? 'text-left' : ''} `}>
										{`ID ${user.ID}`}
									</div>
								</div>
							</div>
							
						</div>
						<hr className='hr d-none' />
						<ul>
						{list.map((item, i, array) => (
									(item.type || item.type === null) &&
									<li className={`list-group-item d-flex position-relative align-items-center bg-transparent border-0 py-0  px-0 my-16px ${array.length - 1 === i ? 'mt-3' : ''} ${(item.params.route && url.indexOf(item.params.to) !== -1) || (item.params.route === "" && url === item.params.to) ? 'active' : ''}`}
										key={i} onClick={toggleMenu}>
										<Link {...item.params}>
											
											{item.title}
											{/* <span>{item.balance}</span> */}
										</Link>
									</li>
								))}
							<hr className='hr d-none' />
							<li className="list-group-item d-flex position-relative align-items-center bg-transparent border-0 py-0  px-0 my-6px">
									<Logout {...{
										element: 'div',
										className: 'd-flex align-items-center font-size-14 font-base text-greymiddle text-decoration-none w-100   cursor-pointer',
										onClick: (e) => toggleMenu(e, false)
									}} >
										<span className="mr-24px leave">
											<LogOutIcon />
										</span>
										{langs.LogOut}
									</Logout>
								</li>
						</ul>
					</div>
				}
			</div>
		</div>
	)
}

export default UserDropdownMenu
