import React from 'react'
const ProductDateInfo = (props) => {
  return (
    <div className="custom-modal-wrapper content-scroll">
      <div className="modal-content-box mb-24px px-md-64px">
        <h3 className="font-size-14 font-size-md-16 font-bold-caps text-dark text-left mb-24px pt-12px">
          {props.langs.NM_Periods}
        </h3>
        <div className="font-size- 14 text-dark font-base scrolled-modal">
          <div className="d-flex justify-content-between font-size-14 text-dark mb-12px">
            <span>{props.langs.NM_PublishDate}:</span>
            <span>  {props.data.item.create_date}</span>
          </div>
          <div className="d-flex justify-content-between font-size-14 text-dark mb-12px">
            <span>{props.langs.NM_RefreshDate}:</span>
            <span>{props.data.item.date}</span>
          </div>
          <div className="d-flex justify-content-between font-size-14 text-dark mb-12px">
            <span>{props.langs.NM_ExpirationDate}:</span>
            <span> {props.data.item.end_date}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDateInfo
