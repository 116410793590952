import React from 'react';
import { CartTotal } from 'components';
import {Link} from "react-router-dom";

const Cart = (props) => {

  return (
    <li className={`nav-item icon cursor-pointer d-flex position-relative dropdown`}>
      <Link to={`/${props.getLang}/mycart`}>
        <i className="svg-i svg-i-sm cart-i w-24px h-24px"></i>
        <span
          className={`rounded-circle badge badge-pill font-base bg-badge-orange text-white position-absolute badge-counter ${
            CartTotal() > 0 ? 'd-flex' : 'd-none'
          }`}
        >
          <CartTotal />
        </span>
        <div className="svg_arrow">{/* bg-icon-css */}</div>
      </Link>
    </li>
  );
};

export default Cart;
