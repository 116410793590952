import React, { lazy, useState, useEffect, useContext, Suspense } from 'react'
import Select from 'react-select';
import moment from 'moment';
import { HistoryContext }   from 'contexts/history';
import { API } from 'services';
import { Loader } from 'components';
const ProductStats = (props) => {
	const ApexCharts = lazy(() => import('components/common/suspense/ApexCharts'));
	const { langs, callback } = props;
	const api = API();
	const {stringify} = useContext(HistoryContext);

	const [period, setPeriod]   			= useState(4);
	const [totalViews, setTotalViews] = useState(0);
	const [data, setData] = useState([]);
	const [options, setOptions] = useState(false);

	

	useEffect(() => {
		const getStats = async () => {
			let productID = props.data.PrID;
			if (!productID) { return callback(null, 'fail'); }
			
			const apiData = await api.getProductsStatistics(productID, stringify({ PeriodID: period }, true));
			if (apiData.statusCode) {
				let totalViews = 0;
				let dataSeries = [{
					name: langs.Views,
					data: []
				}];
				let defaultOptions = {
					// colors: ['#8996ae','#fec900','#0ec604','#ff641f','#4a6cfa'],
					colors: [({ value, seriesIndex, dataPointIndex }) => {
						let currentDataFragment = apiData.data[dataPointIndex]
						if (currentDataFragment.prom_status_id > 0) {
							if (currentDataFragment.prom_status_id === 10) {
								return '#0ec604'
							} else if (currentDataFragment.prom_status_id === 15) {
								return '#ff641f'
							} else if (currentDataFragment.prom_status_id === 20) {
								return '#fec900'
							} else {
								return '#4a6cfa'
							}
						} else {
							return '#8996ae'
						}
					}],
					chart: {
						type: 'bar',
						height: 350,
						// width: '740',
						stacked: true,
						selection: {
							enabled: false,
						},
						toolbar: {
							show: false,
							autoSelected: 'zoom'
						},
						zoom: {
							enabled: false
						}
					},
					// fill: {
					//     type: "gradient",
					//     gradient: {
					//         gradientToColors: ["#F55555", "#6078ea", "#6094ea"]
					//     },
					//     opacity: 1
					// },
					grid: {
						yaxis: {
							shpw: false,
							lines: {
								show: false
							}
						}
					},
					legend: {
						show: false
					},
					xaxis: {
						axisBorder: {
							show: false
						},
						type: 'category',
						categories: [],
					},
					responsive: [{
						breakpoint: 1000,
						options: {
							plotOptions: {
								bar: {
									columnWidth: '70%',
									horizontal: false
								}
							},
							legend: {
								position: "bottom"
							}
						}
					}],
					plotOptions: {
						bar: {
							horizontal: false,
							columnWidth: '15%',
							endingShape: 'rounded'
						},
					},
					dataLabels: {
						enabled: false
					},
				}
				apiData.data.map((item, i) => {
					let date = moment(new Date(item.insert_date)).format('DD MMM').toString()
					defaultOptions.xaxis.categories.push(date);
					dataSeries[0].data.push(item.views);
					totalViews += parseInt(item.views)
					return item;
				})
				setTotalViews(totalViews)
				setData(dataSeries)
				setOptions(defaultOptions)
			} else {
				return callback(null, 'fail');
			}
		}
		getStats()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [period])

	let status = true;
	return (
    <>
      <div className="custom-modal-wrapper">
				<div className="d-flex align-items-center mb-24px pt-24px pt-md-12px px-12px">
					<div className="font-size-16 font-bold">პერიოდი:</div>
					<div className="stats-month cursor-pointer">
						<div className="">
							<div className="w-100">
								<Select
									name="Period"
									className="sg-selectbox-container"
									classNamePrefix="sg-selectbox"
									placeholder=""
									isSearchable={false}
									noOptionsMessage={() => "არ მოიძებნა"}
									value={{ value: period, label: `${langs.ChartPeriods[(period - 1)]}` }}
									options={
										[...Array(5)].map((item, i) => (
											item = { value: i + 1, label: `${langs.ChartPeriods[i]}` }
										))
									}
									onChange={(value) => { setPeriod(value.value) }}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="content-scroll">
					<div className="d-flex justify-content-around row">
						<div className="col-md-3 mb-12px mb-md-0">
							<button className="btn stats-btn position-relative d-flex flex-column align-items-start ">
								<div className="text-dark font-size-12 font-base mb-5px">ნახვები</div>
								<div className="text-dark font-size-24 font-bold">{totalViews}</div>
								<div className="stats-arrow">
									<i className={`svg-i  ${!status ? 'stats-top ' : 'stats-bottom' || 'stats-dissabled'}`}></i>
								</div>
							</button>
						</div>
						<div className="col-md-3 mb-12px mb-md-0">
							<button className="btn stats-btn position-relative d-flex_ flex-column align-items-start d-none ">
								<div className="text-dark font-size-12 font-base mb-5px">ფავორიტებში</div>
								<div className="text-dark font-size-24 font-bold">0</div>
								<div className="stats-arrow">
									<i className={`svg-i  ${!status ? 'stats-top ' : 'stats-bottom' || 'stats-dissabled'}`}></i>
								</div>
							</button>
						</div>
						<div className="col-md-3 mb-12px mb-md-0">
							<button className="btn stats-btn position-relative d-flex_ flex-column align-items-start d-none">
								<div className="text-dark font-size-12 font-base mb-5px">კალათაში</div>
								<div className="text-dark font-size-24 font-bold">0</div>
								<div className="stats-arrow">
									<i className={`svg-i  ${!status ? 'stats-top ' : 'stats-bottom' || 'stats-dissabled'}`}></i>
								</div>
							</button>
						</div>
						<div className="col-md-3 mb-12px mb-md-0">

							<button className="btn stats-btn position-relative d-flex_ flex-column align-items-start d-none">
								<div className="text-dark font-size-12 font-base mb-5px">შენაძენი</div>
								<div className="text-dark font-size-24 font-bold">0</div>
								<div className="stats-arrow">
									<i className={`svg-i  ${!status ? 'stats-top ' : 'stats-bottom' || 'stats-dissabled'}`}></i>
								</div>
							</button>
						</div>



					</div>
					<div  >
						{(data.length > 0 && options) &&
							<Suspense fallback={<Loader/>}>
								<div id="chart" className="scrolled-modal stats-scroll">
									<ApexCharts options={options} series={data}/>
								</div>
							</Suspense>
						}
					</div>
					<div className="d-flex justify-content-center mt-36px">
						<button className="btn vip-btn">
							<div className="color-vip s-vip"></div>
							<div className="font-size-11 font-bold-caps font-weight-bold">s-vip</div>
						</button>
						<button className="btn vip-btn ">
							<div className="color-vip vip-plus"></div>
							<div className="font-size-11 font-bold-caps font-weight-bold">vip+</div>
						</button>
						<button className="btn vip-btn ">
							<div className="color-vip vip"></div>
							<div className="font-size-11 font-bold-caps font-weight-bold">vip</div>
						</button>
						<button className="btn vip-btn ">
							<div className="color-vip clr"></div>
							<div className="font-size-11 font-bold-caps font-weight-bold">ფერი</div>
						</button>
					</div>
				</div>
			</div>
      <div className="d-flex justify-content-center mt-16px">
				<button type="button" className="btn btn-light mx-5px" 
					onClick={(e) => callback(e, 'destroy')}>
						{langs.Close}
				</button>
			</div>
    </>
  );
}

export default ProductStats;