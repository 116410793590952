import React from 'react'
const MyAdress = (className = '') => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="6" fill="#F8F9FD" />
            <path d="M16.0007 9.33333C13.4207 9.33333 11.334 11.42 11.334 14C11.334 17.5 16.0007 22.6667 16.0007 22.6667C16.0007 22.6667 20.6673 17.5 20.6673 14C20.6673 11.42 18.5807 9.33333 16.0007 9.33333ZM12.6673 14C12.6673 12.16 14.1607 10.6667 16.0007 10.6667C17.8407 10.6667 19.334 12.16 19.334 14C19.334 15.92 17.414 18.7933 16.0007 20.5867C14.614 18.8067 12.6673 15.9 12.6673 14Z" fill="#8996AE" />
            <path d="M16.0007 15.6667C16.9211 15.6667 17.6673 14.9205 17.6673 14C17.6673 13.0795 16.9211 12.3333 16.0007 12.3333C15.0802 12.3333 14.334 13.0795 14.334 14C14.334 14.9205 15.0802 15.6667 16.0007 15.6667Z" fill="#8996AE" />
        </svg>
    </span>
)

export default MyAdress




