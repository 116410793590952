import React, { useState, useEffect } from 'react'

const Timer = (props) => {
    const [timer, setTimer]             = useState(props.codeSendTimer);

    useEffect(() => {
        let mounted = true
        if (timer > 0) {
            setTimeout(() => {
                if (mounted) {
                    setTimer(timer - 1)
                }
            }, 1000)
        } else {
            props.setCodeSendTimer(0)
        }
        return () => {
            mounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timer])

    return (
        <div key="countDownTimer">
            {`00:${timer < 10 ? `0${timer}` : timer}`}
        </div>
    )
}

export default Timer