import React, {useEffect} from 'react'
import { Success, Custom } from './components'

const SnackBar = (props) => {
    const { langs }                            =props;

    const close = () => {
        props.close()
    }

    useEffect(() => {
        setTimeout(() => close(), 5000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="snack-bar-index bg-dark text-white  font-size-12 font-base border-radius-md position-fixed bottom-10px right-10px ">
            <button className="btn p-8px close-popup position-absolute top-8px right-8px" onClick={props.close}>
                <i className="svg-i close-gray-i"></i>
            </button>
            <div className="p-24px">
                {props.type === 'success' && 
                    <Success {...{ langs, close }}/>
                }
                {props.type === 'custom' && 
                    <Custom {...{ text: props.text, close }}/>
                }
            </div>
        </div>
    )
}

export default SnackBar