import React from 'react'
import { Link } from 'react-router-dom'

const Component = (props) => {
    return (
        <div className="adress-box-cont  col-12 col-sm-6 col-md-6 col-lg-4 px-12px">
			<Link className="adress-adding-box text-decoration-none d-flex flex-column align-items-center justify-content-center bg-white mb-12px"
				to={`/${props.getLang}/address/new?Type=${props.params.Type}`}>
				<div className="adress-pin">
					<i className="svg-i svg-i-lg  adress-pin"></i>
				</div>
				<div className="adress-adding-box-hint">
					<span className="text-market-color  font-bold font-size-14">{props.langs.AddAddress}</span>
				</div>
			</Link>
		</div>
    )
}

export default Component