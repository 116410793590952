import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';

import { AppDataContext } from 'contexts/appData';
import { HistoryContext } from 'contexts/history';

import img404 from '../resources/img/404.svg';

import { ProductCard } from 'components/cards/products';

const PageNotFound = (props) => {
    const {api, getLang, langs, appData} = props

    const { prefix } = useContext(AppDataContext);
    const { push, cache, setCache, params } = useContext(HistoryContext)

    const [data, setData] = useState(null)

    const fetchData = async () => {
        const apiData = await api.getProductInfo(params.Product);
        if (!apiData.statusCode && apiData.data.CatID) {
            setData({ Products: apiData.SimilarProducts, CatID: apiData.data.CatID })
        } else {
            setData(false)
        }
    }

    useEffect(() => {
        if (cache) {
            setData(cache)
            setCache(null)
        } else if (params.Product) {
            fetchData()
        } else {
            setData(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.Product])

    const proceedToResult = () => {
        push(`/${getLang}/`)
    }

    return (
        <div className="wrapper">
            <div className="container">
                <div className="row">
                    <div className="img-404 d-flex flex-1 justify-content-center align-items-center mt-84px">
                        <img src={img404} alt="" />
                    </div>
                </div>
            </div>
            <h1 className="font-size-24 font-bold-caps text-dark mt-40px mb-20px text-center">
                {langs.NM_PageNotFoundTitle}
            </h1>
            <span className="d-block mx-auto font-size-16 font-base text-blue-gray line-height-18 text-center w-100 w-md-520px px-20px px-md-0">
                {!data ? langs.NM_PageNotFoundTextGeneral : langs.NM_PageNotFoundTextProduct}
                {(data && data.CatID) &&
                    <Link className="font-medium font-size-16 text-blue ml-3px " to={`/${getLang}/search${prefix(data.CatID)}?CatID=${data.CatID}`}>
                        {appData.Cats.List[data.CatID].Title}
                    </Link>
                }
            </span>

            {(data && data.Products.length > 0) &&
                <div className="container mt-48px mt-md-64px mt-lg-120px">
                    <div className="row row-cols-1  row-cols-sm-2 row-cols-md-3 row-cols-lg-5 ">
                        {data.Products.map((product, i) => (
                            <div key={i} className="card_404 px-15px  px-md-8px  mb-8px mb-md-20px">
                                <ProductCard {...{
                                    product,
                                    noSwiper: true,
                                    search: true,
                                    langs
                                }} />
                            </div>
                        ))
                        }
                    </div>
                </div>
            }
            <div className="d-flex justify-content-center px-20px px-md-0 mb-64px">
                <button className="btn font-size-13  font-bold-caps text-white bg-gradient d-block  border-radius-sm my-24px my-md-40px px-48px px-md-80px py-20px h-56px "
                    onClick={proceedToResult}>
                    {langs.GoToMainPage}
                </button>
            </div>
        </div>
    )
}

export default PageNotFound;