import React, {useEffect } from 'react';
import Icons from 'components/icons/Icons';
const ConfirmDialog = (props) => {
  const { onClose, langs } = props;
  useEffect(() => {
    document.body.classList.add('overflow-hidden','position-relative');
    return () => {
      document.body.classList.remove('overflow-hidden','position-relative');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<>
    {/* confirm start */}
    <div className="modal-backdrop opacity-20" onClick={() => onClose(false)} />
    <div className="w-300px flex-shwink close_live_caller rounded shadow mx-auto position-fixed absolute--center-position z-index-max bg-white overflow-hidden z-index-1000">
      <div onClick={() => onClose(false)} className="cursor-pointer position-absolute d-flex align-items-center justify-content-center right-10px top-10px w-24px h-24px rounded-circle bg-bg">
        <Icons icon='close' width='8px' height='8px' className=' d-flex' />
      </div>
      <div className="p-25px d-flex flex-column align-items-center">
        <div className="my-20px ">
          <Icons icon='liveCaller_chats' className='d-flex' />
        </div>
        <h4 className="text-center font-size-16 mb-8px font-TBCX-bold">{langs.NM_TempClose}</h4>
        <p className="text-center font-TBCX-reg font-size-12 mb-12px text-gray-600">{langs.NM_LiveCallerTempCloseDescr}</p>
        <button onClick={() => onClose(true)} className="w-100 h-40px text-white bg-gray-650 font-size-12 font-TBCX-bold rounded-pill border-solid-0" >{langs.Accept}</button>
      </div>
    </div>
    {/* confirm end */}
  </>);
}

export default ConfirmDialog;