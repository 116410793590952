import React from 'react';

const ShopsCard = () => {
  return (
  	<div className="col mb-20px">
	    <div className="card-placeholder startup-card-placeholder d-inline-flex flex-column">
	      <div className="card-placeholder-image animate m-20px"></div>
	      	<div className="card-placeholder-body px-40px py-10px">
	          <div className="line border-radius-md animate mt-30px mx-5px"></div>
	          <div className="line border-radius-md animate mt-25px mb-30px mx-50px"></div>
	        </div>
	    </div>
    </div>
  )
}

export default ShopsCard;