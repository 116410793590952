import {Functions} from 'libs';

const locationPropMap = [
    {json: 't', js: 'Title', dataType: 'string'},
    {json: 'p', js: 'ParentID', dataType: 'int'},
    {json: 'locID', js: 'Id', dataType: 'int'},
    {json: 'tTree', js: 'TitleTree', dataType: 'string'},
];

export class Location {

    Title;
    Id = 0;
    ParentID = 0;
    TitleTree;
    isSelected = false;

    constructor(data) {
        if (!data) {
            return false;
        }
        Functions.transformToObject(data, this, locationPropMap);
    }
}
