import React, { useState, useEffect, useContext, useRef } from 'react';
import { API } from 'services';
import { Functions } from 'libs'
import { CONFIG } from 'core'

import { HistoryContext } from 'contexts/history';
import { BannersContext } from 'contexts/banners';

const AdoBanner = (props) => {
	const api = API()
	const { params, history }             	= useContext(HistoryContext)
	const { connectHash }                   = useContext(BannersContext)
	const [refreshCount, setRefreshCount]   = useState(0)
	const [closeButton, setCloseButton]     = useState(false)
	const [routeParams, setRouteParams]    	= useState(null)
	
	const adoCurrentBanner                  = useRef(null);

	const checkRefNull = () => {
		return adoCurrentBanner.current !== null
	}

	const adoDestroy = () => {
		api.storage.set("VideoPreroll", JSON.stringify({ date: new Date(), status: 0 }))
		adoCurrentBanner.current.remove()
	}

	const bindCloseButtonClick = () => {
		setCloseButton(true)
	}

	let count = 0
	const checkVideo = () => {
		let video = (adoCurrentBanner && adoCurrentBanner.current !== null) ? 
			adoCurrentBanner.current.getElementsByTagName("video") 
		: []
		if (video.length > 0) {
			let adoVideo = video[0]
			bindCloseButtonClick()
			adoVideo.onplaying = () => {}
			adoVideo.onended = () => {
				adoDestroy()
			}
			adoVideo.onpause = () => {}
		} else if (count < 3) {
			count++
			setTimeout(() => {
				checkVideo()
			}, 1000)
		} else if(count >= 3 && props.name === 'NM_Video' && adoCurrentBanner.current !== null) {
			adoCurrentBanner.current.remove()
		}
	}

	// const setAwaitPlacement = () => {
	//     setTimeout(() => {
	//         awaitAdoPlacement()
	//     }, 500)
	// }

	const placeAdoBanner = (vars) => {
		window.ado.placement({
			id: props.banner_content, 
			vars: vars,
			server: 'port80ge.adocean.pl' 
		});
		if (props.name === 'NM_Video') {
			checkVideo()
		}
		return true;
	}

	const awaitAdoPlacement = () => {
		let vars = { url: encodeURIComponent(window.location.href) }
		if (checkRefNull()) {
			if (connectHash && props.type === CONFIG.BANNER_TYPES.CONNECT && CONFIG.HOST.branch === 'PRODUCTION') {
				vars.tbc = connectHash;
			}
			placeAdoBanner(vars)
		}
	}

	const processRefresh = () => {
		if(!adoCurrentBanner.current) return;

		let child = adoCurrentBanner.current.children[0]
		if (child.id !== props.banner_content && refreshCount > 0 ) {
			child.id = props.banner_content
			window.ado.refresh(props.banner_content);
			// console.log(`refresh ${props.name}`, refreshCount)
		}
		setRefreshCount(refreshCount + 1)
	}

	useEffect(() => {
		awaitAdoPlacement()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectHash])

	useEffect(() => {
		if (checkRefNull() && (Functions.checkObjEmpty(params) || routeParams !== history.pathname )) {
			const timer = setTimeout(() => {
				processRefresh()
			}, 500);
			setRouteParams(history.pathname)
			return () => clearTimeout(timer);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params, history.pathname])

	return (
		<div className={props.parentClassName} ref={adoCurrentBanner}>
			{(closeButton || props.name === 'NM_Video') && 
				<div className="close-video p-8px rounded-circle bg-rgba-dark-gray-75 position-absolute top-20px right-20px z-index-1000 cursor-pointer"
					onClick={adoDestroy}>
					<i className="svg-i close-white-i"></i>
				</div>
			}
			{props.children}
			<div id={props.banner_content} className={props.childClassName} data-position={props.name}></div>
		</div>
	)

}

export default AdoBanner;
