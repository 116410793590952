import React, { useEffect } from "react";

const useOutsideClickHandler = (ref, toggleButton, disabled, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (!toggleButton || (toggleButton && toggleButton.current !== event.target && toggleButton.current !== event.target.parentNode)) {
          callback();
        }
      }
    }
    if (disabled) {
      document.addEventListener("mousedown", handleClickOutside);
    } 
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, disabled]);
}

const OutsideClickHandler = React.forwardRef((props, ref) => {
  const { className, style, onOutsideClick, toggleButton, disabled, children } = props;
  useOutsideClickHandler(ref, toggleButton, disabled, onOutsideClick);

  return (
    <div className={className ?? ''} style={style ?? {}} ref={ref}>
      {children}
    </div>
  );
})

export default OutsideClickHandler;
