import React, {
	Suspense
} from 'react';
import { 
	Routes,
	BrowserRouter as Router,
	Route,
	Outlet 
} from 'react-router-dom';
import {
	Loader,
	ScrollToTop,
	PageNotFound,
	AuthRequired,
	HeadMeta,
	HeaderScrollBanner,
	FooterFixedBanner,
	Modals, 
	RSnackBar,
	LiveCaller,
	// CaptchaBadge,
	// Feedback,
	MainLayout,
	// FeedbackHome
} from 'components';
import {
	UserProvider,
	AppDataProvider,
	HistoryProvider,
	SyncProvider,
	BannersProvider,
	ModalsProvider,
	ToastsProvider
	// CaptchaProvider
} from 'contexts';
import {
	RouteList 
} from 'modules';

import { constructor } from 'services';
import {OffersPolicyModal} from "./modules/offersPolicyModal/OffersPolicyModal";
import {DataSharingConsentModal} from './modules/dataSharingConsentModal/DataSharingConsentModal';

const App = () => {
	const build = constructor();

	const PrivateRoute = () => (
		build.processUser() ? <Outlet /> : <AuthRequired {...build.data} />
	)

	return !build.data ? <Loader /> : 
		<Router>
			<AppDataProvider {...build.data}>
				<HistoryProvider {...{RouteList}}>
					<HeadMeta {...build.data}/>
					<UserProvider {...build.data}>
						<SyncProvider {...build.data}> 
							<BannersProvider {...build.data}>
								<ModalsProvider>
									<ToastsProvider>
										{/* <CaptchaBadge /> */}
										<ScrollToTop />
										<HeaderScrollBanner {...build.data}/>
										{/*<Header {...build.data} />*/}

											<Routes>
												{RouteList.map((config, i) => {
													const Layout = config.Layout ? config.Layout : MainLayout
													config.path = build.processPath(config.path);
													return config.private ?
														<Route element={<PrivateRoute/>} key={i}>
															<Route {...config} element={
																<Layout {...build.data}>
																	<Suspense fallback={<Loader />}>
																		<config.Component {...build.data}/>
													 				</Suspense>
																</Layout>
															}/>
														</Route>
													:
														<Route {...config} key={i} element={
															<Layout {...build.data}>
																<Suspense fallback={<Loader />}>
																	<config.Component {...build.data}/>
																</Suspense>
															</Layout>
														} />
												})}
												<Route path="*" element={<MainLayout {...build.data}><PageNotFound {...build.data}/></MainLayout>} />
											</Routes>

										{/*<Footer {...build.data} />*/}
										<Modals />
										<RSnackBar />
										<LiveCaller {...build.data} />
										<FooterFixedBanner/>
										 {/* <Feedback/> */}
										<OffersPolicyModal {...build.data} />
										<DataSharingConsentModal {...build.data} />
									</ToastsProvider>
								</ModalsProvider>
							</BannersProvider>
						</SyncProvider>
					</UserProvider>
				</HistoryProvider>
			</AppDataProvider>
		</Router>
};

export default App

