import React from 'react'
const BuyBtn = props => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${props.className ? props.className : ''}`}>
 <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9868 6.12065C10.968 6.04952 10.9298 5.9836 10.8755 5.92873C10.8212 5.87386 10.7526 5.83173 10.6756 5.80607L7.07369 4.60544L7.99031 0.531597C8.01164 0.436776 7.9976 0.338358 7.95031 0.251272C7.90303 0.164185 7.82508 0.0931821 7.7283 0.0490316C7.63151 0.00488117 7.52117 -0.0100076 7.41401 0.00662348C7.30684 0.0232546 7.2087 0.0704983 7.13448 0.141189L0.134475 6.80786C0.0783854 6.86127 0.0379778 6.92616 0.0168296 6.99677C-0.00431854 7.06738 -0.00555493 7.14154 0.01323 7.21267C0.0320149 7.28381 0.0702418 7.34973 0.124526 7.4046C0.17881 7.45947 0.247477 7.5016 0.324447 7.52726L3.92635 8.72789L3.00972 12.8017C2.98838 12.8966 3.00241 12.995 3.04969 13.0821C3.09697 13.1692 3.17492 13.2402 3.27171 13.2843C3.3685 13.3285 3.47885 13.3434 3.58602 13.3267C3.69319 13.3101 3.79133 13.2628 3.86556 13.1921L10.8656 6.52547C10.9216 6.47205 10.962 6.40716 10.9832 6.33655C11.0043 6.26594 11.0056 6.19179 10.9868 6.12065V6.12065Z" fill="#FEC900"/>
</svg>


    </span>
)

export default BuyBtn