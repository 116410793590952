import React from 'react'

import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import { Timer } from './';
import { Functions } from 'libs';

const Component = (props) => {
    // console.log(props)
    const getInitialValues = () => ({
        Phone: props.data.finalData ? props.data.finalData.Phone : '',
        SmsCode: '',
    })

    const getValidationSchema = () => {
        return Yup.object().shape({
            Phone: Yup.string().required(`${props.langs.WriteYourPhone}`)
                .matches(/^[0-9]+$/, `${props.langs.IncorrectFormat}`)
                .test('len', `${props.langs.IncorrectFormatOfPhoneNumber}`, o => o && o.length === 9),
            SmsCode: Yup.string().required(props.langs.SetValidSMSCode),
        })
    }

    const config = {
        initialValues: getInitialValues(),
        validationSchema: getValidationSchema(),
        enableReinitialize: true
    }

    const handleChange = (e, setFieldValue) => {
        let value = Functions.checkStringLength(Functions.checkNumeric(e.target.value), 6)
        // console.log(value)
        if (value.length <= 6) {
            setFieldValue("SmsCode", value)
            
        }
    }

    return (
        <Formik {...config} onSubmit={props.handlePhoneSubmit}>
            {({ values, setFieldValue }) => (
                <Form autoComplete="off">
                    <div className="flex-column border-0  border-none d-flex justify-content-start bg-white px-sm-32px  px-md-100px pb-34px px-20px">
                        <div className="form-group">
                            <label className="my-12px text-text_label font-size-12 d-block" htmlFor="Phone">
                                {props.langs.ContactFormPhone} <b className="text-blue">*</b>
                            </label>
                            <div className="d-flex position-relative">
                                <div className="form-control d-flex align-items-center justify-content-center w-30 border-right-radius-none text-text_label">
                                    +995
                                </div>
                                <Field type="text" maxLength="9" name="Phone" className="form-control radius-10 border-left-radius-none"
                                    disabled={props.codeSendTimer > 0} 
                                />
                                <div className="flexible-btn d-flex align-items-center code-btn">
                                    {props.codeSendTimer > 0 &&
                                        <div className="flexible-timer mr-8px font-size-10 texct-dark">
                                            <Timer {...{ codeSendTimer: props.codeSendTimer, setCodeSendTimer: props.setCodeSendTimer }} />
                                        </div>
                                    }
                                    <button type="button" className={`btn font-weight-normal bg-gradient p-10px font-size-10  font-bold text-white ${props.codeSendTimer ? 'disabled' : ''}`}
                                        disabled={values.Phone.toString().length !== 9 || !values.Phone.toString().match(/^[0-9]+$/)}
                                        onClick={(e) => props.sendVerificationSMS(e, values)}>
                                        {props.langs.SendCode}
                                    </button>
                                </div>
                            </div>
                            <ErrorMessage name="Phone" component="label" className="error pl-25px" />
                        </div>
                        {props.codeSendTimer !== false &&
                            <div className="form-group">
                                <label className="my-12px text-text_label font-size-12 d-block" htmlFor="SmsCode">
                                    {props.langs.WriteOneTimeCode} <b className="text-blue">*</b>
                                </label>
                                <div className="position-relative">
                                    <Field type="text" name="SmsCode" className="form-control radius-10" 
                                        onChange={(e) => handleChange(e, setFieldValue)}/>
                                </div>
                                <ErrorMessage name="SmsCode" component="label" className="error pl-25px" />
                            </div>
                        }
                    </div>
                    <div className="popup-footer px-40px pt-20px mt-0 d-flex justify-content-between">
                        <button className="btn bg-transparent font-base font-size-12 font-weight-normal text-blue-gray px-0 mb-16px mb-md-0" 
                            onClick={props.close}>{props.langs.Close}
                        </button>
                        <button type="submit" className={`btn font-weight-normal bg-gradient font-size-13 text-white`} disabled={!values.Phone.toString().length || !values.SmsCode.length} >
                            {props.langs.Activate}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default Component