import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select';
import { FixedLists } from 'core';
import { FillBalance, VipsIcon, AddColorIcon, AutoUpdateIcon } from 'components';
import { UserContext } from 'contexts/user';
import { Link } from 'react-router-dom';

const BuyPromotion = (props) => {
	const {
		data,
		callback,
		langs,
		getLang,
		CONFIG,
		currency,
	} = props;
	const promList = FixedLists.promsList(langs)
	const { accountDetails, userBalance } = useContext(UserContext)
	const [quantity, setQuantity] = useState(1)
	const [autoHour, setAutoHour] = useState({ value: 0, label: `00:00` })
	const [activeList, setActiveList] = useState([])
	const [activePromotion, setActivePromotion] = useState({})
	const [priceToPay, setPriceToPay] = useState(0)

	const [activeIndex, setActiveIndex] = useState(null);
	const [activePromo, setActivePromo] = useState(0);

	const getInitialPrice = (promName) => {
		return (data?.promPrices[promName]?.List[0]?.options[0]?.price || 0)
	}
	const defValues = {
		20: { value: 1, label: `1 ${langs.Day}`, price: getInitialPrice('SUPER_VIP') },
		15: { value: 1, label: `1 ${langs.Day}`, price: getInitialPrice('VIP_PLUS') },
		10: { value: 1, label: `1 ${langs.Day}`, price: getInitialPrice('VIP') },
		1: { value: 1, label: `1 ${langs.Day}`, price: getInitialPrice('COLOR') },
		2: { value: 1, label: `1 ${langs.Day}`, price: getInitialPrice('AUTO_UPDATE') },
		3: { value: 1, label: `1 ${langs.Day}`, price: getInitialPrice('UPDATE') },
	};
	const [promHandlers, setPromHandlers] = useState(defValues)

	const success = () => {
		callback({
			products: data.pollAction.products,
			data: activePromotion,
			quantity: quantity,
			hour: autoHour.value,
			price: parseFloat(promHandlers[activePromotion.id]?.price)
		}, 'success')
		callback(null, 'destroy')
	}

	const processList = (ids, vip = false) => {
		let newList = [...promList]
		newList = newList.filter(o => ids.includes(String(o.id)))
		if (vip) {
			newList = newList.filter(o => o.id >= vip)
		}
		setActiveList(newList)
	}

	const handleSelects = (promID, obj = false) => {
		// console.log(promID, obj)
		let newData = { ...defValues}
		let total = 0;
		Object.entries(newData).map(([key, value]) => {
			if (parseInt(key) === promID) {
				newData[key] = obj
				let prs = data.pollAction.products;
				total = parseInt(newData[key].value) * parseFloat(newData[key].price) * ((prs && prs.length) ? prs.length : 1)
			} else {
				newData[key] = defValues[key]
			}
			return key;
		})

		setPriceToPay(total)
		setPromHandlers(newData)
		setActivePromotion(promList.find(o => o.id === promID))
		setQuantity(obj.value)
	}

	const getCurrentPrice = (promID) => {
		let currentPrice = parseFloat(promHandlers[promID]?.price)
		return currentPrice > 0 ? currentPrice : 0
	}

	const setDefaults = () => {
		setQuantity(1);
		setAutoHour({ value: 0, label: `00:00` });
		setActivePromotion({});
		setPriceToPay(0);
		setActiveIndex(null);
	}

	const openVips = () => {
		processList(CONFIG.PROM_VIPS_LIST, data.pollAction.activeVip);
		setActivePromo(1);
		setDefaults();
	}

	const openColor = () => {
		processList(CONFIG.PROM_COLORS_LIST);
		setActivePromo(2);
		setDefaults();
	}

	const openUpdates = () => {
		processList(CONFIG.PROM_UPDATES_LIST);
		setActivePromo(3);
		setDefaults();
	}

	useEffect(() => {
		accountDetails();
		let type = data.pollAction.type;
		let activeVip;
		if (data.pollAction.productsList.length > 0) {
			let filterVip = data.pollAction.productsList.filter(o => o.vip > 0)
			activeVip = Math.max.apply(Math, filterVip.map(o => o.vip))
			// console.log(highest)
			// let newList = promList.map(o => {
			// 	if (o.id < highest && o.id > 5) {
			// 		o.disabled = true
			// 	}
			// 	return o
			// })
			// setActiveList(newList)
		} else {
			activeVip = data.pollAction.activeVip
		}
		if (type === 1) {
			processList(CONFIG.PROM_VIPS_LIST, activeVip)
			setActivePromo(1)
		} else if (type === 2) {
			processList(CONFIG.PROM_COLORS_LIST)
			setActivePromo(2)

		} else if (type === 3) {
			processList(CONFIG.PROM_UPDATES_LIST)
			setActivePromo(3)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className='promos-modal-container w-100 h-100 min-height-552px d-flex flex-column flex-md-row promotions-scroll'>
			<div className='acceleration-promotions bg-light-gray p-24px'>
				<div className='pb-24px text-dark font-bold'>{langs.NM_PersonalBlocks[8]}</div>
				<div className='promo-balance bg-white'>
					<div className='d-flex justify-content-between align-items-center'>
						<div className="d-block">
							<div className="font-base font-size-12 text-greymiddle mb-3px">{langs.BalanceAmount}</div>
							<div>{userBalance}</div>
						</div>
						<div>
							<div className='font-size-12 text-greymiddle mb-4px'>{langs.Fill}</div>
							<Link to={`/${getLang}/finances`} onClick={(e) => callback(e, 'destroy')} >
								<div className='justify-content-end d-flex'>
									<FillBalance />
								</div>
							</Link>
						</div>
					</div>
				</div>

				<div className={`pt-md-72px pt-32px`} >
					<div className={`promo cursor-pointer font-size-14 pb-26px d-flex  ${activePromo === 1 ? 'active' : ''
					}`}
							 onClick={openVips}>
						<VipsIcon className="mr-16px" />
						{langs.NM_vip_buy}
					</div>
					<div className={`promo cursor-pointer font-size-14 my-13px d-flex  ${activePromo === 2 ? 'active' : ''
					}`}
							 onClick={openColor}>
						<AddColorIcon className="mr-16px" />
						{langs.AddColor}
					</div>
					<div className={`promo cursor-pointer font-size-14 d-flex  ${activePromo === 3 ? 'active' : ''
					}`}
							 onClick={openUpdates}>
						<AutoUpdateIcon className="mr-16px" />
						{langs.APP_update}
					</div>
				</div>
			</div>
			<div className=" flex-1">
				<div className="position-relative h-100">
					<div className="pt-md-56px pt-0 h-100">
						<div className="scrolled-promotion w-100 py-md-12px pb-10px pt-16px d-flex flex-column justify-content-between h-100">
							<div className='font-size-12 font-bold px-24px '>{langs.IsSelectedCount} {props.data?.pollAction?.products?.length} {langs.APP_state}</div>
							<div>
								<div className='px-24px border-bottom'>
									<div className="font-size-13 pb-12px pt-16px">
										{langs.ChooseAdType}
									</div>
								</div>
								{activeList.length > 0 &&
									activeList.map((prom, i) => {
										let price = getCurrentPrice(prom.id);
										let list = data.promPrices[prom.name]?.List ?? [];
										return (
											<div className={`d-flex flex-column flex-sm-row align-items-center border-bottom border-gray w-100 py-md-16px py-16px px-md-32px px-20px ${prom.disabled ? 'opacity-50 pointer-events-none' : ''}`} data-prom-id={prom.id} key={i}>
												<div className={`d-flex align-items-center w-100 width-sm-initial flex-grow-1 cursor-pointer  ${prom.id === 3 ? 'flex-shrink ' : ''}`}
														 onClick={() => {
															 setActiveIndex(i);
															 handleSelects(prom.id, defValues[prom.id]);
														 }}>
													<div className="pr-20px cursor-pointer">
														<div className="w-basis-16px h-16px border-solid-1 border-bluepart-100 rounded-circle">
															<div className={`w-basis-10px h-10px bg-bluepart-100 rounded-circle  mt-2px ml-2px ${activeIndex === i ? 'bg-blue' : ''} `}>
															</div>
														</div>
													</div>
													<span className={`${prom.mainClassName} d-flex align-items-center justify-content-center w-basis-40px h-40px border-radius-sm`}>
														<i className={`svg-i svg-i-big ${prom.iconClassName}`} />
													</span>
													<span className="ml-12px font-size-14 text-dark w-basis-90px ">
														{prom.title}
													</span>
													<div className="d-flex flex-column justify-content-center align-items-center ml-auto">
														<span className="font-size-13 text-nowrap text-gray-light">
															{prom.period}
														</span>
														<span className="font-medium font-size-14 text-nowrap">
															{price} {currency()}
														</span>
													</div>

												</div>
												<div className="d-flex align-items-center w-100 width-sm-initial pt-20px pt-sm-0 ml-sm-auto">
													<div className="ml-auto flex-grow-1 flex-sm-grow-0 d-flex">
														{prom.id === 3 ?
															<></>
															:
															<Select
																name="Days"
																className="sg-selectbox-container sg-menu-right sg-wide-menu group-options-menu w-100 ml-md-28px"
																classNamePrefix="sg-selectbox"
																placeholder=""
																isSearchable={false}
																options={list}
																onChange={(value) => {
																	setActiveIndex(i);
																	handleSelects(prom.id, value)
																}}
																value={promHandlers[prom.id]}
																isDisabled={prom.disabled}
															/>
														}
													</div>
													{prom.id === 2 &&
														<div className={`ml-8px w-basis-sm-80px flex-grow-1 flex-sm-grow-0 proms-select`}>
															<Select
																name="PromBlockAutoUpdateHour"
																className="sg-selectbox-container group-options-menu w-100"
																classNamePrefix="sg-selectbox"
																placeholder=""
																isSearchable={false}
																noOptionsMessage={() => langs.NM_NothingFound}
																options={
																	[...Array(24)].map((item, i) => (
																		item = { value: i, label: `${i < 10 ? `0${i}` : i}:00` }
																	))
																}
																onChange={(value) => setAutoHour(value)}
																value={autoHour}
															/>
														</div>
													}
												</div>
											</div>
										)
									})
								}
								<div className="mt-16px mb-15px py-12px rounded font-size-12 bg-light-gray px-24px mx-24px">
									{langs.NM_Promotions}
								</div>
							</div>


							<div className="d-flex align-items-center justify-content-between px-24px mt-auto">
								<div className="d-flex flex-column justify-content-center ">
									<span className="font-size-14 text-gray-light cursor-pointer" onClick={(e) => callback(e, 'destroy')}>
										{langs.NM_Close}
									</span>
								</div>
								<button className="btn  d-inline-flex align-items-center h-48px border-radius font-size-14  py-15px bg-blue font-weight-normal text-white"
												onClick={success} disabled={!priceToPay || priceToPay === 0}>
									{langs.Pay}
									<span className=" text-nowrap pl-8px">
										{priceToPay.toFixed(2)} {currency()}
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div></div>
		</div>
	)
}

export default BuyPromotion