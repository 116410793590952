import styles from './ReviewModal.module.scss';
import RateStarIcon from '../../../../components/icons/RateStarIcon';
import React from 'react';

const starGroups = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }];

const RatingGroup = ({ rating, onRate, itemClassname, width = 36, height = 36 }) => {
  return (
    <div className={styles.rateStarsWrapper}>
      {starGroups.map((starItem) => (
        <div key={starItem.value} className={`${styles.starWrapper} ${itemClassname}`}>
          <RateStarIcon fill={rating >= starItem.value ? '#FEC900' : '#CAD7E5'} width={width} height={width} onClick={() => onRate(starItem.value)} />
        </div>
      ))}
    </div>
  );
};

export default RatingGroup;
