import React from 'react'

import {Parser} from 'components'
const KnowMore = (props) => {
  return (
    <>
      <div className="custom-modal-wrapper content-scroll text-editor-styles">
        {/* <iframe id="aboutOnlineVideo" width="100%" height="694" allow="autoplay;"
          title="KnowMore" frameborder="0" allowfullscreen=""
          data-src="https://www.youtube.com/embed/KgAOW_rp70M?enablejsapi=1&amp;autoplay=1"  
          src="https://www.youtube.com/embed/KgAOW_rp70M?enablejsapi=1&amp;autoplay=1" >
        </iframe> */}
         <Parser html={props.data.customMessage ? props.data.customMessage : props.langs.NM_OnlineKnowMoreText.replace(/<(?!p|br).*?>/g, "")}/>
      </div>
      <div className={`custom-modal-actions`}>
        <div className="pb-28px mt-0 d-flex justify-content-center mt-8px">
          <button type="button" className="font-size-14 font-base-caps px-64px py-16px btn bg-middle-gray mx-8px text-blue-gray" 
            onClick={(e) => props.callback(e, 'destroy')}>
            {props.langs.Close}
          </button>
        </div>
      </div>
    </>
  )
}

export default KnowMore