import React from 'react'
import parse from 'html-react-parser';

const Parser = (props) => {
    return (
        <>
            {(props.html && (typeof props.html === 'string' || props.html instanceof String)) &&
                parse(props.html)
            }
        </>
    )
}

export default Parser