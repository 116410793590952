import React from 'react'
import { Parser } from 'components'

const TBCConnect = (props) => {
    const success = (e) => {
        props.callback(e, 'success')
        props.callback(null, 'destroy')
    }
    return (
        <div className="custom-modal-wrapper d-flex flex-column flex-md-row">
            <div className="modal-content-box py-24px px-16px px-md-36px px-lg-56px flex-1">
                <div className="font-size-14 text-dark font-base scrolled-modal">
                    <Parser html={props.data.text} />
                </div>
            </div>
            <div className="custom-modal-actions d-flex align-items-center justify-content-center">
                <div className="d-flex justify-content-center flex-column ">
                    <button type="button" className="w-170px font-size-13 font-bold-caps d-flex align-items-center justify-content-center py-16px btn bg-gradient text-white d-block mx-auto  "
                        onClick={e => success(1)}>
                        {props.langs.Agree}
                    </button>
                    {props.data.button &&
                        <button type="button" className="w-170px font-size-13 font-bold-caps d-flex align-items-center justify-content-center py-16px btn bg-middle-gray text-blue-gray d-block mx-auto mt-8px"
                            onClick={e => props.callback(null, 'destroy')}>
                            {props.langs.NM_SkipStep}
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default TBCConnect