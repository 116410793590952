
import React from 'react'
import PropTypes from 'prop-types'

Minus.propTypes = {
    className: PropTypes.string
}

export default function Minus({ className = '' } = '') {
    return (
        <span className={`svg-icon svg-md-icon ${className}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="2" viewBox="0 0 8 2">
                <g id="minus-circle" transform="translate(-6.417 -9.15)">
                    <line id="Line_188" dataname="Line 188" x2="6" transform="translate(7.417 10.15)" fill="none" stroke="#737e94" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                </g>
            </svg>





        </span>
    )
}