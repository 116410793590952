import React from 'react'
const View = (className = '') => (
  <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center  ${className}`}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.3454 1.9187C11.0254 1.5987 10.3854 1.33203 9.93203 1.33203H6.06536C5.61203 1.33203 4.97203 1.5987 4.65203 1.9187L1.9187 4.65203C1.5987 4.97203 1.33203 5.61203 1.33203 6.06536V9.93203C1.33203 10.3854 1.5987 11.0254 1.9187 11.3454L4.65203 14.0787C4.97203 14.3987 5.61203 14.6654 6.06536 14.6654H9.93203C10.3854 14.6654 11.0254 14.3987 11.3454 14.0787L14.0787 11.3454C14.3987 11.0254 14.6654 10.3854 14.6654 9.93203V6.06536C14.6654 5.61203 14.3987 4.97203 14.0787 4.65203L11.3454 1.9187ZM10.6854 10.6854C10.5854 10.7854 10.4587 10.832 10.332 10.832C10.2054 10.832 10.0787 10.7854 9.9787 10.6854L7.9987 8.70536L6.0187 10.6854C5.9187 10.7854 5.79203 10.832 5.66536 10.832C5.5387 10.832 5.41203 10.7854 5.31203 10.6854C5.1187 10.492 5.1187 10.172 5.31203 9.9787L7.29203 7.9987L5.31203 6.0187C5.1187 5.82536 5.1187 5.50536 5.31203 5.31203C5.50536 5.1187 5.82536 5.1187 6.0187 5.31203L7.9987 7.29203L9.9787 5.31203C10.172 5.1187 10.492 5.1187 10.6854 5.31203C10.8787 5.50536 10.8787 5.82536 10.6854 6.0187L8.70536 7.9987L10.6854 9.9787C10.8787 10.172 10.8787 10.492 10.6854 10.6854Z" fill="#A4AEC1" />
    </svg>



  </span>
)

export default View