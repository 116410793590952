import React from 'react'
const MyFinnaces = (className = '') => (
        <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="6" fill="#F8F9FD" />
                        <path d="M21.666 12.8533V11.3333C21.666 10.6 21.066 10 20.3327 10H10.9993C10.2593 10 9.66602 10.6 9.66602 11.3333V20.6667C9.66602 21.4 10.2593 22 10.9993 22H20.3327C21.066 22 21.666 21.4 21.666 20.6667V19.1467C22.0593 18.9133 22.3327 18.4933 22.3327 18V14C22.3327 13.5067 22.0593 13.0867 21.666 12.8533ZM20.9993 14V18H16.3327V14H20.9993ZM10.9993 20.6667V11.3333H20.3327V12.6667H16.3327C15.5993 12.6667 14.9993 13.2667 14.9993 14V18C14.9993 18.7333 15.5993 19.3333 16.3327 19.3333H20.3327V20.6667H10.9993Z" fill="#8996AE" />
                        <path d="M18.3327 17C18.885 17 19.3327 16.5523 19.3327 16C19.3327 15.4477 18.885 15 18.3327 15C17.7804 15 17.3327 15.4477 17.3327 16C17.3327 16.5523 17.7804 17 18.3327 17Z" fill="#8996AE" />
                </svg>

        </span>
)

export default MyFinnaces