import React, {useState} from 'react'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import rebank from '../../../src/resources/img/rebank.svg';

import {Formik, Field, Form, ErrorMessage} from 'formik';

import { Functions } from 'libs'
import * as Yup from 'yup';
import {API} from 'services';
import {Modal, Response, InfoUsageAgreement, Parser} from 'components';

const LoanRebank = (props) => {
    const api = API();
    const [responseModal, setResponseModal] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [agreementModal, setAgreementModal] = useState(false);

    const close = () => {
        props.close();
    }

    const success = () => {
        props.success();
        close()
    }

    const onReadAgree = (e) => {
        setAgreementModal(false)
        e.values[e.name] = 1
        setData(e.values)
    }

    const periodList = [
        {value: 2, label: `2 ${props.langs.Month}`},
        {value: 3, label: `3 ${props.langs.Month}`},
        {value: 4, label: `4 ${props.langs.Month}`},
        {value: 5, label: `5 ${props.langs.Month}`},
        {value: 6, label: `6 ${props.langs.Month}`},
    ]

    const locale = {
        localize: {
            month: n => props.appData.DateAndTime[api.lang].monthsShort[n],
            day: n => props.appData.DateAndTime[api.lang].weekdaysShort[n]
        },
        formatLong: {
			date: () => 'dd/MM/yyyy'
		}
    }

    const defaultSrc = (e) => {
        e.onerror = null;
        e.target.src = 'https://www.mymarket.ge/beta/templates/assets/img/no-image.png'
    }

    const getInitialValues = () => ({
        Name: '',
        Amount: props.product.price,
        Period: 2,
        PersonalNumber: '',
        BirthDate: '',
        Phone: '',
        HasSalary: 0,
        AgreeTerms: 0,
        AgreeConfidentiality: 0,
        GroupID: 1
    })

    const [data, setData] = useState(getInitialValues());

    const getValidationSchema = () => {
        let validationSchema = {
            Name: Yup.string().required(props.langs.WriteFullName),
            Amount: Yup.string().required(props.langs.NM_LoanWriteAmount)
                .test('amount', `${props.langs.AmountPlaceHolder.replace("%s", 100).replace("%s", 10000)}`, val => val && parseFloat(val) >= 100),
            Period: Yup.string().required(props.langs.SelectPeriod),
            PersonalNumber: Yup.string()
                .matches(/^[0-9]+$/, `${props.langs.IncorrectFormat}`)
                .test('len', `${props.langs.IncorrectFormatOfPersonaCode}`, val => val && val.length === 11)
                .required(`${props.langs.WritePersonalNumber}`),
            BirthDate: Yup.string().required(props.langs.WriteBirthDate),
            Phone: Yup.string().required(`${props.langs.WriteYourPhone}`)
                .matches(/^[0-9]+$/, `${props.langs.IncorrectFormat}`)
                .test('len', `${props.langs.IncorrectFormatOfPhoneNumber}`, o => o && o.length === 9),
            AgreeTerms: Yup.string().required(props.langs.NM_AgreementWithTerms),
            AgreeConfidentiality: Yup.string().required(props.langs.NM_AgreementWithTerms),
        }
        return Yup.object().shape(validationSchema)
    }

    const config = {
        initialValues: data,
        validationSchema: getValidationSchema(),
        enableReinitialize: true
    }

    const handleSubmit = async (values) => {
        setData(values)
        let response = await api.addLeadBank(props.product.product_id, {
            Name: values.Name,
            Amount: values.Amount,
            Period: values.Period,
            PersonalNumber: values.PersonalNumber,
            BirthDate: moment(new Date(values.BirthDate)).format('YYYY-MM-DD').toString(),
            Phone: values.Phone,
            HasSalary: values.HasSalary,
            GroupID: values.GroupID
        })
        let modalParams = {
            statusCode: response.statusCode,
            statusMessage: response.statusMessage,
            close: () => setResponseModal(false)
        }
        if (response.statusCode) {
            modalParams['close'] = () => {
                setResponseModal(false);
                success()
            }
        }
        setResponseModal(modalParams);
    }

    const changeFieldValue = (name, value, values) => {
        let newData = {...values}
        newData[name] = value
        setData(newData)
    }

    const changeStep = (e, step, values) => {
        e && (e.preventDefault() || e.stopPropagation());
        if (currentStep === 0 && step === 0) {
            close()
            return;
        }
        setData(values)
        setCurrentStep(step)
    }

    const summarizeInfo = (values) => {
        let result = props.langs.NM_LeadBankSum
        result = result.replace("s%", values.Amount ? parseFloat(values.Amount).toFixed(2) : 0)
        result = result.replace("s%", 0)
        result = result.replace("s%", values.Period)
        result = result.replace("s%", values.Amount ? (parseFloat(values.Amount) / values.Period).toFixed(2) : 0)
        return result
    }

    const Component = () => {
        return (
            <div className="custom-modal-wrapper content-scroll">
            <div className="m-0 px-8px py-8px">
                {responseModal &&
                <Response {...{
                    langs: props.langs,
                    statusMessage: responseModal.statusMessage,
                    statusCode: responseModal.statusCode,
                    close: responseModal.close
                }} />
                }
                {agreementModal &&
                <InfoUsageAgreement {...{
                    langs: props.langs,
                    text: agreementModal.text,
                    title: "",
                    success: () => onReadAgree(agreementModal),
                    close: () => setAgreementModal(false)
                }} />
                }
                <div className="">
                    <h5 className="m-0 font-bold-caps font-size-21 font-weight-bold text-dark">
                        {props.langs.RequestLoan}
                    </h5>
                    <span className="font-base font-size-12 font-weight-normal text-blue-gray">
                        {props.langs.RequestLoanDescription}
                    </span>
                </div>
                <Formik {...config} onSubmit={handleSubmit}>
                    {({values, setFieldValue}) => (
                        <Form autoComplete="off">
                            {currentStep === 0 &&
                            <div className="">
                                <div
                                    className="product-details-card d-flex justify-content-between mt-24px px-0 px-md-20px py-13px">
                                    <div className="item-img flex-shrink p-0">
                                        <img onError={defaultSrc}
                                             className="w-56px h-56px object-fit-cover border-radius-10"
                                             alt={props.product.title}
                                             src={props.product.images.length > 0 ? props.product.images[0].thumbs : 'https://www.mymarket.ge/beta/templates/assets/img/no-image.png'}/>
                                    </div>
                                    <div className="px-16px my-auto">
                                            <span
                                                className="d-flex justify-content-start font-medium font-size-14 font-weight-normal text-dark">
                                                {props.product.title}
                                            </span>
                                        <span
                                            className="d-flex justify-content-start font-base font-size-12 font-weight-normal text-blue-gray">
                                                {`ID${props.product.product_id}`}
                                            </span>
                                    </div>
                                    <div className="ml-auto my-auto">
                                            <span
                                                className="d-flex justify-content-end font-base font-size-12 font-weight-normal text-blue-gray">
                                                {props.product.Cost}
                                            </span>
                                        <span
                                            className="d-flex justify-content-end font-bold font-size-16 font-weight-bold text-dark">
                                                {props.product.price}
                                            <span className="ml-8px"> {props.currency()} </span>
                                            </span>
                                    </div>
                                </div>
                                <div className="mt-16px mt-md-36px">
                                    <div
                                        className="row m-0 d-flex product-details-card border-top-0 pb-30px justify-content-between">
                                        <div className="col-12 col-md-6 p-0 pr-md-24px">
                                            <div className="form-group p-0 m-0">
                                                <label htmlFor="Amount"
                                                       className="font-medium font-size-12 font-weight-normal text-text_label">
                                                    {props.langs.NM_LoanAmount} <span
                                                    className="required text-blue">*</span>
                                                </label>
                                                <Field type="number" name="Amount"
                                                       className="position-relative border-radius-10 border-solid-1 border-gray bg-white px-12px py-auto w-100 outline-none"/>
                                                <ErrorMessage name="Amount" component="label"
                                                              className="error position-absolute top-80px left-0"/>
                                            </div>
                                            <div className="form-group payment-period p-0 m-0 mt-24px">
                                                <label htmlFor="Period"
                                                       className="font-medium font-size-12 font-weight-normal text-text_label">
                                                    {props.langs.PaymentPeriod} <span
                                                    className="required text-blue">*</span>
                                                </label>
                                                <Select
                                                    name="Period"
                                                    className="border-radius-10 border-solid-1 border-gray bg-white py-auto w-100 cursor-pointer"
                                                    classNamePrefix="sg-selectbox"
                                                    placeholder={props.langs.NM_LoanPeriodPlaceholder}
                                                    noOptionsMessage={() => props.langs.NM_NothingFound}
                                                    options={periodList}
                                                    isSearchable={false}
                                                    onChange={(value) => {
                                                        changeFieldValue("Period", value.value, values)
                                                    }}
                                                    value={periodList.find(o => o.value === data.Period)}
                                                />
                                                <ErrorMessage name="Period" component="label" className="error"/>
                                            </div>
                                            <div className="form-group p-0 m-0 mt-24px">
                                                <div
                                                    className="custom-control custom-checkbox custom-checkbox-2 d-inline-flex">
                                                    <Field type="checkbox" id="HasSalary" name="HasSalary" value="1"
                                                           className={`custom-control-input ${data.HasSalary === 1 ? 'active' : ''}`}
                                                           checked={data.HasSalary === 1}
                                                           onChange={() => changeFieldValue("HasSalary", data.HasSalary === 1 ? 0 : 1, values)}/>
                                                    <label
                                                        className="custom-control-label font-base font-size-13 font-weight-normal text-dark pt-4px"
                                                        htmlFor="HasSalary">
                                                        {props.langs.HaveConfirmedIncome}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 px-0 mt-12px mt-md-0">
                                            <div className="border-radius-lg bg-rgba-middle-gray-50 px-24px py-24px mt-6px">
                                                <h5 className="font-bold-caps font-size-16 font-weight-bold text-dark mb-12px">
                                                    {props.langs.Summarize}
                                                </h5>
                                                <div className="d-flex justify-content-between py-12px">
                                                        <span className="font-medium font-size-14 font-weight-normal text-blue-gray d-table">
                                                            {props.langs.NM_LoanMonthly}
                                                        </span>
                                                        <span className="d-flex justify-content-between font-medium font-size-14 font-weight-normal text-dark ml-auto">
                                                            {values.Amount ? (parseFloat(values.Amount) / values.Period).toFixed(2) : 0}
                                                        <span className="ml-8px"> {props.currency()} </span>
                                                        </span>
                                                </div>
                                                <div className="d-flex justify-content-between py-12px">
                                                        <span className="font-medium font-size-14 font-weight-normal text-blue-gray">
                                                            {props.langs.NM_LoanYearly}
                                                        </span>
                                                        <span className="font-medium font-size-14 font-weight-normal text-dark ml-auto">
                                                            0%
                                                        </span>
                                                </div>
                                                <div className="d-flex justify-content-between py-12px">
                                                        <span className="font-medium font-size-14 font-weight-normal text-blue-gray">
                                                            {props.langs.Period}
                                                        </span>
                                                        <span className="font-medium font-size-14 font-weight-normal text-dark ml-auto">
                                                            {data.Period} {props.langs.Month}
                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className={`${currentStep === 1 ? 'bg-rgba-middle-gray-50 border-radius-18 mt-24px px-20px' : '' } d-flex justify-content-center border-bottom-0 px-0 px-md-20px py-20px`}>
                                <div className="font-base font-size-14 line-height-23 font-weight-normal text-dark">
                                    <Parser html={summarizeInfo(values)} />
                                </div>
                            </div>
                            {currentStep === 1 &&
                            <div className="py-20px">
                                <div className="row d-flex justify-content-between ">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group p-0 m-0">
                                            <div className=" d-flex justify-content-start mb-8px">
                                                <label htmlFor="Name"
                                                       className=" font-size-12 font-weight-normal text-text_label my-auto">
                                                    {props.langs.YourFirstAndLastName}<span
                                                    className="required text-blue"> *</span>
                                                </label>
                                            </div>
                                            <Field type="string" name="Name"
                                                   className="outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px"/>
                                            <ErrorMessage name="Name" component="label"
                                                          className="error mb-0 mt-4px"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mt-16px mt-md-0">
                                        <div className="form-group p-0 m-0">
                                            <div className=" d-flex justify-content-start mb-8px">
                                                <label htmlFor="PersonalNumber"
                                                       className=" font-size-12 font-weight-normal text-text_label my-auto">
                                                    {props.langs.PersonalNumber}<span
                                                    className="required text-blue"> *</span>
                                                </label>
                                            </div>
                                            <Field type="number" name="PersonalNumber" className="outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px" onChange={(e) => {
                                                setFieldValue("PersonalNumber", Functions.checkStringLength(Functions.checkNumeric(e.target.value), 11))
                                            }} />
                                            <ErrorMessage name="PersonalNumber" component="label" className="error mb-0 mt-4px"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between mt-24px">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group p-0 m-0 w-100">
                                            <div className=" d-flex justify-content-start mb-8px">
                                                <label htmlFor="BirthDate"
                                                       className=" font-size-12 font-weight-normal text-text_label my-auto">
                                                    {props.langs.BirthDate}
                                                    <span className="required text-blue"> *</span>
                                                </label>
                                                <span className="my-auto tooltip-top" data-toggle="tooltip"  title="სესხით სარგებლობისთვის თქვენ უნდა იყოთ 18-დან 60 წლამდე ასაკის">
                                                    <i className="svg-i i-icon-i my-auto ml-4px"></i>
                                                </span>
                                            </div>
                                            <div
                                                className="position-relative border-radius-10 border-solid-1 border-gray">
                                                <DatePicker
                                                    selected={values.BirthDate ? values.BirthDate : null}
                                                    onChange={date => {
                                                        changeFieldValue("BirthDate", date, values)
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    locale={locale}
                                                    isClearable
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    placeholderText={props.langs.NM_LoanDateFormat}
                                                    className="position-relative outline-none border-radius-10 border-0  bg-white w-100 pl-12px font-size-12 pr-64px"
                                                    calendarClassName=""
                                                />
                                                <ErrorMessage name="BirthDate" component="label"
                                                              className="error position-absolute top-53px left-0"/>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mt-16px mt-md-0">
                                        <div className="form-group p-0 m-0">
                                            <div className=" d-flex justify-content-start mb-8px">
                                                <label htmlFor="Phone"
                                                       className=" font-size-12 font-weight-normal text-text_label my-auto">
                                                    {props.langs.PhoneNumber}<span className="required text-blue"> *</span>
                                                </label>
                                            </div>
                                            <div
                                                className="outline-none input-group border-radius-10 border-solid-1 border-gray bg-white p-0">
                                                <div className="input-group-prepend z-index-9">
                                                    <span
                                                        className="input-group-text font-medium font-size-12 text-dark border-left-0 border-bottom-0 border-top-0">+995</span>
                                                </div>
                                                <Field type="number" name="Phone"
                                                       className="form-control border-0  position-relative"/>
                                            </div>
                                            <ErrorMessage name="Phone" component="label"
                                                          className="error mb-0 mt-4px"/>
                                        </div>
                                    </div>
                                </div>
                                <hr className="mb-20px mt-28px mx-n20px text-gray-transparent opacity-1"/>
                                <img src={rebank} className='rebank' alt="" />
                                <div className="form-group pb-3px my-0 p-0 mt-20px">
                                    <div className="custom-control custom-checkbox custom-checkbox-2 d-inline-flex">
                                        <Field type="checkbox" id="AgreeTerms" name="AgreeTerms" value="1"
                                               className={`custom-control-input ${data.AgreeTerms === 1 ? 'active' : ''}`}
                                               checked={data.AgreeTerms === 1}
                                               onChange={() => changeFieldValue("AgreeTerms", data.AgreeTerms === 1 ? 0 : 1, values)}/>
                                        <label className="custom-control-label  text-dark font-base font-size-14"
                                               htmlFor="AgreeTerms">
                                            {`${props.langs.Agree} `}
                                            <span className="cursor-pointer text-blue"
                                                  onClick={() => setAgreementModal({values, text: props.langs.PrivacyPolicyText, name:'AgreeTerms'})}>
                                                    {props.langs.SiteRulesAndTerms}
                                                </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group p-0 m-0 ">
                                    <div className="custom-control custom-checkbox custom-checkbox-2 d-inline-flex">
                                        <Field type="checkbox" id="AgreeConfidentiality" name="AgreeConfidentiality"
                                               value="1"
                                               className={`custom-control-input ${data.AgreeConfidentiality === 1 ? 'active' : ''}`}
                                               checked={data.AgreeConfidentiality === 1}
                                               onChange={() => changeFieldValue("AgreeConfidentiality", data.AgreeConfidentiality === 1 ? 0 : 1, values)}/>
                                        <label className="custom-control-label  text-dark font-base font-size-14"
                                               htmlFor="AgreeConfidentiality">
                                            {`${props.langs.Agree} `}
                                            <span className="cursor-pointer text-blue"
                                                  onClick={() => setAgreementModal({
                                                      values,
                                                      text: props.langs.PrivacyPolicyText,
                                                      name: 'AgreeConfidentiality'
                                                  })}>
                                                    {props.langs.PrivacyPolicy}
                                                </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            }
                            <hr className="m-0 text-gray-transparent"/>
                            <div className="d-flex justify-content-between  pt-24px">
                                <button type="button"
                                        className="btn bg-transparent font-base font-size-12 line-height-20 font-weight-normal text-blue-gray px-0"
                                        onClick={(e) => changeStep(e, 0, values)}>
                                    {currentStep === 1 ? props.langs.ReturnBack : props.langs.Close}
                                </button>
                                {currentStep === 1 ?
                                    <button type={currentStep === 1 ? "submit" : "button"}
                                            className={`btn bg-gradient font-bold-caps font-size-13 font-weight-bold text-white px-40px py-16px ${(values.AgreeConfidentiality !== 1 || values.AgreeTerms !== 1) ? 'disabled' : ''}`}
                                            disabled={values.AgreeConfidentiality !== 1 || values.AgreeTerms !== 1}
                                    >
                                        {props.langs.Send}
                                    </button>
                                    :
                                    <button type="button"
                                            className="btn bg-gradient font-bold-caps font-size-13 font-weight-bold text-white px-40px py-16px"
                                            disabled={values.Amount < 100 || data.Period < 2 || data.Period > 6}
                                            onClick={(e) => changeStep(e, 1, values)}>
                                        {props.langs.Continue}
                                    </button>
                                }
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            </div>
        )
    }

    return <Modal {...{customInner: true, Component, close, classInner: "fixed-mobile"}} />
}

export default LoanRebank