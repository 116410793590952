import React from 'react'

const EmptyState = (props) => {

    let prefix = props.prefix ? props.prefix : "empty-state"

    let className = {
        container: props.classContainer ? props.classContainer : '',
        inner: props.classInner ? props.classInner : '',
        icon: props.classIcon ? props.classIcon : '',
        title: props.classTitle ? props.classTitle : '',
        description: props.classDescription ? props.Description : '',
        actions: props.classActions ? props.classActions : '',
    }

    return (
        <div className={`${prefix}-container ${className.container}`}>
            <div className={`${prefix}-inner ${className.inner}`}>
                {props.customInner ? <props.Component /> :
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className={`${prefix}-icon ${className.icon}`}> </div>
                        <div className={`${prefix}-title ${className.title}`}>
                            {props.Title && <props.Title />}
                        </div>
                        <div className={`${prefix}-description ${className.description}`}>
                            {props.Description && <props.Description />}
                        </div>
                        <div className={`${prefix}-actions ${className.actions}`}>
                            {props.Actions && <props.Actions />}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default EmptyState