import React from 'react';

export default function ShopProductCard({colspan}) {
	return (
		<div className="d-flex align-items-center w-100 my-16px py-12px"> 
            <div>
                <div className="animate d-flex item-img mr-12px"></div>
            </div>
            <div colSpan={colspan ? colspan - 2 : ''}  className="p-0 d-inline-block mr-12px w-100">
            	<div className="d-flex w-100">
	            	<div className="d-flex flex-1 flex-column">
						<div className="animate d-flex border-radius-sm mt-5px" style={{width: '100%', height: '10px'}}></div>
						<div className="animate d-flex border-radius-sm mt-10px" style={{width: '40%', height: '10px'}}></div>
					</div>
					<div className="d-flex ml-15px">
						<div className="animate d-flex border-radius-sm mt-5px" style={{width: '80px', height: '10px'}}></div>
					</div>
				</div>
            </div>
        </div>
	)
};