import { Map } from 'services';
import { CONFIG } from 'core';
import { Cookie, Storage } from 'services';

const Response = (res, mode) => {
  let cookie = Cookie()
  let storage = Storage();
  let map = Map();
  let resData = res.data;
  let response = {
    data: (resData.data || resData.data === 0) ? resData.data : {},
    statusCode: parseInt(resData.statusCode) === 200 ? true : false,
    statusMessage: resData.statusMessage ? resData.statusMessage : ''
  }
  
  if (parseInt(resData.statusCode) === 401) {
    storage.remove(CONFIG.CACHE.TAGS.AUTH);
    storage.remove(CONFIG.CACHE.TAGS.USER);
		storage.remove(CONFIG.CACHE.TAGS.SYNC);
    cookie._remove(CONFIG.CACHE.TAGS.COOKIEAUTH, {
			path: "/", 
			domain: CONFIG.HOST.branch === 'LOCAL' ? 'localhost' : '.mymarket.ge',
			days: 0
		});
    sessionStorage.clear();
		cookie._clear();
    if (window.location.pathname && !window.location.pathname.includes('/pr/') && !window.location.pathname.includes('/product/')) {
      window.location.href = CONFIG.HOST.appUrl + CONFIG.LANGS.DEFAULT_LANG;
    }
    return;
  }
  
  if (resData.data) {
    if (resData.data.Cats) {
      response["Cats"] = map.searchCats(resData.data.Cats)
    }
    if (resData.data.Prs) {
      response["Products"] = map.products(resData.data.Prs)
    }
    if (resData.data.totalCount) {
      response["TotalCount"] = resData.data.totalCount
    }
    if (resData.data.pagination || resData.data.Pagination || (resData.data.order_products && resData.data.order_products.pagination)) {
      if (resData.data.pagination) {response["Pagination"] = resData.data.pagination}
        else if (resData.data.Pagination) {response["Pagination"] = resData.data.Pagination}
          else if (resData.data.order.order_products && resData.data.order.order_products.pagination) { response["Pagination"] = resData.data.order.order_products.pagination}
    }
    if (resData.data.statusCounts) {
      response["StatusCounts"] = resData.data.statusCounts
    }
    if (resData.data.notifications) {
      response["Notifications"] = map.notifications(resData.data.notifications)
    }
    if (resData.data.orders) {
      response["Orders"] = map.orders(resData.data.orders)
    }
    if (resData.data.order) {
      response["Order"] = map.order(resData.data.order)
    }
    if (resData.data.order_products) {
      response["OrderProducts"] = map.products(resData.data.order.order_products, true)
    }
    if (resData.data.Product) {
      response["Product"] 			= map.product(resData.data.Product)
      response["Attrs"] 				= map.attrs(resData.data.Product.attributes)
      // response["AvailableToReview"] 	= resData.data.AvailableToReview
      response["ShippingDates"] 		= resData.data.ShippingDates
      response["ShippingPrices"] 		= resData.data.ShippingPrices
      
    }
    if (resData.data.SimilarProducts) {
      response["SimilarProducts"] = map.products(resData.data.SimilarProducts.Prs)
    }
    // if (resData.data.DiscountProducts) {
    // 	response["DiscountProducts"] = map.products(resData.data.DiscountProducts)
    // }
    if (resData.data.shops) {
      response["Shops"] = map.shops(resData.data.shops)
    }
    // UserData
    if (resData.data.user && !resData.data.product_id) {
      response["data"] = map.userData(resData.data)
    }
    if (resData.data.PromPackageServices) {
      response.data["PromPackageServices"] = map.promPackageServices(resData.data.PromPackageServices)
    }
    if (resData.data.Order) {
      response["data"] = map.paymentOrder(resData.data.Order)
    }
  }
  if (mode === "address") {
    response.data = map.address(response.data);
  }
  if (mode === "captcha") {
    response.data = map.captcha(response.data)
  }
  if (resData.data.bankAccount) {
    response["BankAccount"] = response.data.bankAccount;
  }
  return response;
}

export default Response;