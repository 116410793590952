import { useRef } from "react";
import { API } from "services";

export const useEventSubscription = (channel) => {

	const api = API();
	const lastUpdates = useRef(-1);
	const callbacks = useRef([]);

	const interval = useRef(null);
	const stop = useRef(false);

	const triageEvent = (data) => {
		for (let callback of callbacks.current) {
			try {
				callback(data);
			} catch (e) {}
		}
	}

	const emit = (files, action = 'changes') => {
		api.emitEvent(channel, { action: action, files: files }).catch(() => {});
	}

	const subscribeToEvents = (callback) => {
		callbacks.current = [...callbacks.current, callback];

		stop.current = false;
		if (lastUpdates.current === -1) {
			lastUpdates.current = 0;
			// const interval = setTimeout(() => {
			//
			// }, (CONFIG.COMMON.SYNC_INTERVAL * 1000) || 3000);
			fetchEvents();
			return () => {
				try {
					interval.current && clearTimeout(interval.current);
					stop.current = true;
				} catch (ex) {}
			};
		}
	}

	const fetchEvents = () => {
		api.getEvent(channel, lastUpdates.current).then(response => {
			if (response?.data?.version) {
				lastUpdates.current = response.data.version;
			}
			if (response?.data?.current) {
				triageEvent(response.data);
			}
			next();
		}).catch(() => next());
	}

	const next = () => {
		interval.current && clearTimeout(interval.current);
		if (!stop.current) {
			interval.current = setTimeout(() => {
				fetchEvents();
			}, 2000);
		}
	}

	const clearSubscription = () => {
		interval.current && clearTimeout(interval.current);
		stop.current = true;
	}

	return {
		subscribeToEvents,
		emit,
		clearSubscription,
	};
}