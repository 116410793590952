import React, { useContext } from 'react'

import { AppDataContext } from 'contexts/appData';
import { ModalsContext } from 'contexts/modals'

import Component from './Component'

const Modals = (props) => {
	const { currency, langs, getLang, CONFIG, Functions } = useContext(AppDataContext);
  const { modal, modalActive, callLoading, destroy } = useContext(ModalsContext);
	// console.log(modal)
	const callback = async (e, type = null, loading = false) => {
		if (modal.callback && modal.callback[type]) {
			modal.callback[type](e);
			if (loading) {
				callLoading()
			}
		} else {
			destroy()
		}
	}

	const processDestroy = (e) => {
		if (modal.callback && modal.callback['preDestroy']) {
			callback(e, 'preDestroy')
		}
		callback(e, 'destroy')
		if (modal.callback && modal.callback['afterDestroy']) {
			callback(e, 'afterDestroy')
		}
	}

	const staticProps = {
		component: modal.component,
		data: modal.data,
		styles: modal.styles,
		callback,
		langs, getLang, currency, CONFIG, Functions
	}
	return (
		<>
			{ modalActive &&
				<div className={`custom-modal-container ${modal?.styles?.container}`}>
					<div className={`custom-modal-dialog ${modal?.styles?.dialog}`}>
						<div className={`custom-modal-inner ${modal?.styles?.inner}`}>
							<Component {...staticProps} />
							<button
									className={`btn position-absolute top-0 right-0 ${modal?.styles?.close}`}
									onClick={processDestroy}
									style={{ padding: 'inherit' }}
							>
								<div className="p-8px bg-secondary-gray-10 rounded-circle">
									<i className="svg-i close-black-i"></i>
								</div>
							</button>
						</div>
						{!modal.backdrop &&
							<div className={`modal-backdrop ${modal?.styles?.backdrop}`} onClick={processDestroy}></div>
						}
					</div>
				</div>
			}
		</>
		
	)
}

export default Modals