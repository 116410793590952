import React, { useState, useEffect, createContext, useCallback } from 'react';

export const UserContext = createContext();

const ConsentSteps = {
  DefaultStep: 0,
  PlatformsStep: 1,
  PartnersStep: 2,
};

export const UserProvider = (props) => {
  const { langs, api, CONFIG } = props;

  const [loading, setLoading] = useState(true);
  const [consentModalData, setConsentModalData] = useState();
  const [consentModalStep, setConsentModalStep] = useState(ConsentSteps.DefaultStep);
  const [isOffersPolicyModalOpen, setIsOffersPolicyModalOpen] = useState(false);
  const [isDataSharingConsentModalOpen, setIsDataSharingConsentModalOpen] = useState(false);



  const getStorageUser = () => {
    return JSON.parse(api.storage.get(CONFIG.CACHE.TAGS.USER));
  };

  // const getStorageAuth = () => {
  // 	return api.storage.get(CONFIG.CACHE.TAGS.AUTH)
  // }

  const getCookieAuth = () => {
    return api.cookie._get(CONFIG.CACHE.TAGS.COOKIEAUTH);
  };

  const setStorageData = (data) => {
    if (!data) {
      return false;
    }
    return api.storage.set(CONFIG.CACHE.TAGS.USER, JSON.stringify(data));
  };

  const setAccessToken = (AccessToken) => {
    if (!AccessToken) {
      return false;
    }
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 14);
    return api.cookie._set(CONFIG.CACHE.TAGS.COOKIEAUTH, AccessToken, {
      path: '/',
      domain: CONFIG.HOST.branch === 'LOCAL' ? 'localhost' : '.mymarket.ge',
      expires: expirationDate,
    });
    // return api.storage.set(CONFIG.CACHE.TAGS.AUTH, AccessToken)
  };

  const checkIfUserIsAuthorized = () => {
    // let storage = getStorageAuth()
    return getCookieAuth() ? true : false;
  };

  const setDefaultValues = (type) => {
    let data = getStorageUser();
    if (!checkIfUserIsAuthorized() || !data) {
      return false;
    }
    if (type === 0) {
      return data.User;
    } else if (type === 1) {
      return data.Shop;
    } else if (type === 2) {
      return data.ActivityState;
    } else if (type === 3) {
      return data.TradeInOrders;
    }
    return false;
  };

  const [user, setUser] = useState(setDefaultValues(0));
  const [userBalance, setUserBalance] = useState(0);
  const [finances, setFinances] = useState(null);
  const [shop, setShop] = useState(setDefaultValues(1));
  const [activity, setActivity] = useState(setDefaultValues(2));
  const [tradeInOrders, setTradeInOrders] = useState(setDefaultValues(3));
  const [notifications, setNotifications] = useState(null);
  const [promPrices, setPromPrices] = useState(null);

  const login = async (AccessToken) => {
    setAccessToken(AccessToken);
    if (getCookieAuth()) {
      return await getUserData(true);
    }
    return false;
  };

  const getUserData = async (returnData = false) => {
    let apiData = await api.getUserInfo();
    if (apiData && apiData.statusCode) {
      const data = apiData.data;
      setStorageData(data);

      setUser(data ? data.User : false);
      setShop(data ? data.Shop : false);
      setUserBalance(prev => data ? data.User?.Money : prev)
      setActivity(data ? data.ActivityState : false);
      setTradeInOrders(data ? data.TradeInOrders : false);

      setLoading(false);
      // setUserData()
      return returnData ? apiData.data : true;
    } else {
      return false;
    }
  };

  // const setUserData = () => {
  // 	let data = getStorageUser()
  // 	if(data === null) {
  // 		getUserData()
  // 	} else {
  // 		setUser(data ? data.User : false)
  // 		setShop(data ? data.Shop : false)
  // 		setActivity(data ? data.ActivityState : false)
  // 		setTradeInOrders(data ? data.TradeInOrders : false)
  // 	}
  // }

  const accountDetails = async () => {
    let apiData = await api.getUserDetails();
    if (apiData) {
      setUserBalance(apiData.data?.AmountData?.Amount ?? 0);
      setFinances(apiData.data ?? null);
      return apiData.data;
    } else {
      return false;
    }
  };

  // const updateLastSync = (newDate) => {
  // 	let data = getStorageUser()
  // 	data.User.LastSync = newDate
  // 	setUser(data.User)
  // 	setStorageData(data)
  // }

  const getNotifications = async () => {
    // const apiData = await api.getNotifications("limit=4");
    const apiData = await api.getNotificationsCount();
    if (apiData.statusCode) {
      setNotifications(apiData.data);
    }
  };

  const processPromPrices = async (langs) => {
    const apiData = await api.getPromPrices();
    if (apiData.statusCode) {
      let data = { ...apiData.data };
      let result = {};
      Object.entries(data).map(([k, v], i) => {
        if (!v) return false;
        result[k] = {
          Name: k,
          DayPrice: v[0] ? v[0].price : 0,
          List: [],
          // {
          // 	label: langs.ChooseDays,
          // 	options: [{
          // 		label: langs.ChooseDays,
          // 		value: 0
          // 	}]
          // }
        };
        if (v.length > 1) {
          v.map((e, l) => {
            let from = v[l + 1] ? parseInt(v[l + 1].from_quantity) : 30;
            let range = from - parseInt(e.from_quantity);
            let count = parseInt(e.from_quantity) + 1;
            result[k].List.push({
              label: `${count} - ${from} ${langs.Day}   ${langs.Day}: ${e.price} ₾`,
              options: [...Array(range)].map((item, ii) => ({
                label: `${ii + count} ${langs.Day}`,
                value: `${ii + count}`,
                price: e.price,
              })),
            });
            return e;
          });
        } else {
          if (k === 'UPDATE') {
            result[k].List.push({
              label: `1 ${langs.Day}   ${langs.Day}: ${v[0].price} ₾`,
              options: [{ label: `1 ${langs.Day}`, value: `1`, price: v[0].price }],
            });
          } else {
            result[k].List.push({
              label: `${parseInt(v[0].from_quantity + 1)} - 30 ${langs.Day}   ${langs.Day}: ${v[0].price} ₾`,
              options: [...Array(30)].map((item, ii) => ({ label: `${ii + 1} ${langs.Day}`, value: `${ii + 1}`, price: v[0].price })),
            });
          }
        }
        return k;
      });
      setPromPrices(result);
    }
  };

  const storageEventHandler = useCallback((evt) => {
    if (evt.key === CONFIG.CACHE.TAGS.USER && evt.newValue !== null) {
      const value = JSON.parse(evt.newValue)[CONFIG.CACHE.TAGS.USER];
      setUser(value);
      setUserBalance(value.Money);
      setFinances(prev => ({...prev, Amount: value.Money}));
      api.request._setHeaders(api.cookie._get(CONFIG.CACHE.TAGS.COOKIEAUTH));
    }
  }, [CONFIG.CACHE.TAGS.USER]);

  useEffect(() => {
    let isAuth = checkIfUserIsAuthorized();
    if (isAuth) {
      let authCookie = api.cookie._get(CONFIG.CACHE.TAGS.COOKIEAUTH);
      api.request._setHeaders(authCookie);
      // setUserData()
      getUserData();
    } else {
      setLoading(false);
    }
    // console.log(user)
    // console.log(shop)
    // console.log(activity)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      if (notifications === null) {
        setNotifications(false);
        getNotifications();
      }
      if (promPrices === null) {
        setPromPrices(false);
        processPromPrices(langs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  useEffect(() => {
    window.addEventListener('storage', storageEventHandler, false);

		return () => {
			window.removeEventListener('storage', storageEventHandler, false);
		}
  }, []);

  useEffect(() => {
    if (user) {
      api
        .getConnectModal()
        .then((res) => {
          const shouldShowModal = res.statusCode && res.data.show_modal;
          if (shouldShowModal) {
            setIsOffersPolicyModalOpen(shouldShowModal);
            setConsentModalData(res.data);

            const step = res.data.modals.expanded ? consentModalStep : ConsentSteps.PlatformsStep;
            setConsentModalStep(step);
          }


          if (res.statusCode && !res.data.show_modal) {
            api
              .getDataSharingConsentModal()
              .then((res) => {
                const shouldShowModal = res.statusCode && res.data.show_modal;
                if (shouldShowModal) {
                  setIsDataSharingConsentModalOpen(shouldShowModal);
                  setConsentModalData(res.data);

                  const step = res.data.modals.expanded ? consentModalStep : ConsentSteps.PlatformsStep;
                  setConsentModalStep(step);
                }
              })
              .catch(() => {
                setIsDataSharingConsentModalOpen(false);
              });
          }
        })
        .catch(() => {
          setIsOffersPolicyModalOpen(false);
        });
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        shop,
        activity,
        tradeInOrders,
        accountDetails,
        getUserData,
        notifications,
        getNotifications,
        // updateLastSync,
        promPrices,
        login,
        userBalance,
        finances,
        loading,
        consentModalData,
        consentModalStep,
        setConsentModalStep,
        setConsentModalData,
        isOffersPolicyModalOpen,
        setIsOffersPolicyModalOpen,
        isDataSharingConsentModalOpen,
        setIsDataSharingConsentModalOpen
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
export default UserProvider;
