import React, {useEffect, useState} from 'react'
import * as Yup from 'yup';
import { API } from 'services';
import {First, Second, Third, Fourth, FifthForm, Sixth} from './components'
const ReturnItem = (props) => {
	const api = API();
	const {langs, callback, data} = props
	const [step, setStep] = useState(1)
	const [result, setResult] = useState(null)
	const [files, setFiles]	= useState([]);

	const cancel = () => {
		callback(null, 'destroy');
	}

	const success = () => {
		callback(null, 'success');
	}

	const nextStep = () => {
		setStep(step + 1);
	}

	const deleteFile = (e, index, values) => {
		e.preventDefault() || e.stopPropagation();
		let reorder = [...files]
		let newPhotoValues = values.Photos.split(',')
		reorder.splice(index, 1)
		newPhotoValues.splice(index, 1)
		values.Photos = newPhotoValues.join(',')
		setFiles(reorder)
	}

	const getInitialValues = () => ({
		FirstName: '',
		LastName: '',
		Address: '',
		Email: '',
		Phone: '',
		ReturnReason: '',
		Photos: '',
		Quantity: 1
	})

	const getValidationSchema = () => {
		let validationSchema = {
			FirstName: Yup.string().required(`${langs.WriteYourName}`),
			LastName: Yup.string().required(`${langs.WriteSurName}`),
			Phone: Yup.string().required(`${langs.WriteYourPhone}`)
				.matches(/^[0-9]+$/, `${langs.IncorrectFormat}`)
				.test('len', `${langs.IncorrectFormatOfPhoneNumber}`, o => o && o.length === 9),
			Address: Yup.string().required(langs.APP_enter_address),
			Email: Yup.string().required(langs.WriteYourEmail),
			Quantity: Yup.number().min(1, langs.WriteQuantity).max(data.quantity, `${langs.MaxQuantity} ${data.quantity}`).required(langs.WriteQuantity),
		}
		return Yup.object().shape(validationSchema)
	}

	const config = {
		initialValues: getInitialValues(),
		validationSchema: getValidationSchema(),
		enableReinitialize: true
	}

	const handleSubmit = async (values) => {
		let finalData = { ...values }
		finalData.PrID = data.productID
		let response = await api.returnProduct(data.orderID, finalData);
		setResult(response)
		setStep(step + 1);
	}
	
	const uploadImagesToStatic = async (files, values) => {
		let newList = values.Photos.indexOf(",") > -1 ? values.Photos.split(',') : values.Photos.length > 0 ? [values.Photos] : [];
		const uploadData = await api.uploadFiles(files, data.userID, newList.length)
		if (uploadData) {
			values.Photos = newList.concat(uploadData.FilesList).join(',')
			return uploadData
		}
		return false
	}

	const filesProcess = () => {
		if (files.length > 0) {
			files.forEach(file => {
				if (file.existing) {
					Object.assign(file, { preview: file.existing })
				} else {
					Object.assign(file, { preview: URL.createObjectURL(file) })
				}
			});
		}
	}

	useEffect(filesProcess, [files]);

	return (
		<div className="custom-modal-wrapper">
			{step === 1 && <First {...{ langs, cancel, nextStep }}/>}
			{step === 2 && <Second {...{ langs, cancel, nextStep }}/>}
			{step === 3 && <Third {...{ langs, cancel, nextStep }}/>}
			{step === 4 && <Fourth {...{ langs, cancel, nextStep }}/>}
			{step === 5 && <FifthForm {...{ langs, cancel, config, handleSubmit, files, setFiles, deleteFile, uploadImagesToStatic, quantity: data.quantity }}/>}
			{step === 6 && <Sixth {...{ langs, result, cancel, success }}/>}
		</div>
	)
}

export default ReturnItem
