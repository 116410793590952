import React from 'react';

const CustomAccountOption = (props) => {
	const { innerProps, label, data } = props;
	return (
		<div {...innerProps} className="custom-select-option">
			<div className="font-size-14 font-bold mb-12px">
				{data.title}
			</div>
			<div className="font-size-14 font-base">
				{label}
			</div>
		</div>
	)
}

export default CustomAccountOption