import React, { useState, useEffect } from 'react'

import { Formik, Field, Form, ErrorMessage } from 'formik';

import * as Yup from 'yup';
import { API } from 'services';
import { InfoUsageAgreement } from 'components';

const FlexiblePackage = (props) => {
	const api = API();
	const [agreementModal, setAgreementModal] = useState(false);
	const [codeSendTimer, setCodeSendTimer] = useState(false);
	const [phoneChecked, setPhoneChecked] = useState(false);
	const [codeError, setCodeError] = useState(false);

	const success = (e) => {
		props.callback(e, 'success');
		props.callback(e, 'destroy');
	}

	const onReadAgree = (e) => {
		setAgreementModal(false)
		e.values["TermsAgreement"] = 1
		setData(e.values)
	}

	const getInitialValues = () => ({
		FirstName: '',
		LastName: '',
		Phone: '',
		Code: '',
		UserID: props.data.userID,
		Token: '',
		TermsAgreement: 0
	})

	const [data, setData] = useState(getInitialValues());

	const getValidationSchema = () => {
		let validationSchema = {
			FirstName: Yup.string().min(2).max(50).required(`${props.langs.WriteYourName}`),
			LastName: Yup.string().min(2).max(50).required(`${props.langs.WriteSurName}`),
			Phone: Yup.string().required(`${props.langs.WriteYourPhone}`)
				.matches(/^[0-9]+$/, `${props.langs.IncorrectFormat}`)
				.test('len', `${props.langs.IncorrectFormatOfPhoneNumber}`, o => o && o.length === 9),
			Code: Yup.string().required(props.langs.SetValidSMSCode),
			Token: Yup.string().required(),
			TermsAgreement: Yup.number().required().test('len', `${props.langs.AgreeWithSiteRules}`, o => o && parseInt(o) === 1),
		}
		return Yup.object().shape(validationSchema)
	}

	const config = {
		initialValues: data,
		validationSchema: getValidationSchema(),
		enableReinitialize: true
	}

	const TimerCounter = (tprops) => {
		const [timer, setTimer] = useState(tprops.codeSendTimer);

		useEffect(() => {
			let mounted = true
			if (timer > 0) {
				setTimeout(() => {
					if (mounted) {
						setTimer(timer - 1)
					}
				}, 1000)
			} else {
				tprops.setCodeSendTimer(0)
			}
			return () => {
				mounted = false
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [timer])

		return (
			<div key="countDownTimer">{`00:${timer < 10 ? `0${timer}` : timer}`}</div>
		)
	}


	const sendVerificationSMS = async (e, values) => {
		e && (e.preventDefault() || e.stopPropagation());
		if (!codeSendTimer) {
			const apiData = await api.sendUserSMS({ Phone: values.Phone })
			if (apiData.statusCode) {
				setCodeSendTimer(60)
				values.Token = apiData.data.Token
			}
			setData(values)
		}
	}

	const verifyCode = async (e, values) => {
		e && (e.preventDefault() || e.stopPropagation());
		if (values.Code && values.Token) {
			setData(values)
			let apiData = await api.checkUserSMSCode({
				UserID: values.UserID,
				Phone: values.Phone,
				Code: values.Code,
				Token: values.Token
			})
			if (apiData.statusCode) {
				setPhoneChecked(true)
				setCodeError(false)
			} else {
				setCodeError(props.langs.NM_IncorrectSMSCode)
			}
		}
	}

	const handleSubmit = async (values) => {
		setData(values)
		if (phoneChecked) {
			let finalData = { ...values }
			delete finalData.TermsAgreement
			let response = await api.sendCustomPackageRequest(finalData);
			success(response)
		}
	}

	return (
		<div className="custom-modal-wrapper">
			{agreementModal &&
				<InfoUsageAgreement {...{ langs: props.langs, text: "", title: "", success: () => onReadAgree(agreementModal), close: () => setAgreementModal(false) }} />
			}
			<div className="d-flex justify-content-center px-20px py-30px">
				<span className="font-size-21">ფასდაკლების შექმნა</span>
			</div>
			<Formik {...config} onSubmit={handleSubmit} >
				{({ values, setFieldValue }) => (
					<Form>
						<div className="border-0  border-none d-flex flex-column justify-content-start bg-white px-0 px-sm-32px  px-md-100px pb-34px px-20px content-scroll">
							<div className="form-group">
								<label className="my-12px text-text_label font-size-12 d-block" htmlFor="FirstName">
									{props.langs.ContactUser} <b className="text-blue">*</b>
								</label>
								<Field name="FirstName" className="form-control radius-10" />
								<ErrorMessage name="FirstName" component="label" className="error pl-25px" />
							</div>
							<div className="form-group">
								<label className="my-12px text-text_label font-size-12 d-block" htmlFor="LastName">
									{props.langs.SurName} <b className="text-blue">*</b>
								</label>
								<Field type="text" name="LastName" className="form-control radius-10" />
								<ErrorMessage name="LastName" component="label" className="error pl-25px" />
							</div>
							<div className="form-group">
								<label className="my-12px text-text_label font-size-12 d-block" htmlFor="Phone">
									{props.langs.ContactFormPhone} <b className="text-blue">*</b>
								</label>
								<div className="d-flex position-relative">
									<div className="form-control d-flex align-items-center justify-content-center w-30 border-right-radius-none text-text_label">
										+995
									</div>
									<Field type="string" maxLength="9" name="Phone" disabled={phoneChecked} className="form-control radius-10 border-left-radius-none" />

									{(values.Phone.length === 9 && values.Phone.match(/^[0-9]+$/) && !phoneChecked) &&
										<div className="flexible-btn d-flex align-items-center code-btn">
											{(codeSendTimer > 0 && !phoneChecked) &&
												<div className="flexible-timer mr-8px font-size-10 texct-dark">
													<TimerCounter key="countdownTimer" {...{ codeSendTimer, setCodeSendTimer }} />
												</div>

											}
											<div className={`btn font-weight-normal bg-gradient p-10px font-size-10  font-bold text-white ${codeSendTimer ? 'disabled' : ''}`}
												onClick={(e) => sendVerificationSMS(e, values)}>
												{props.langs.SendCode}
											</div>
										</div>
									}
								</div>

								<ErrorMessage name="Phone" component="label" className="error pl-25px" />
							</div>
							{codeSendTimer !== false &&
								<div className="form-group">
									<label className="my-12px text-text_label font-size-12 d-block" htmlFor="Code">
										{props.langs.WriteOneTimeCode} <b className="text-blue">*</b>
									</label>
									<div className="position-relative">
										<Field type="text" name="Code" disabled={phoneChecked} className="form-control radius-10" />
										{!phoneChecked &&
											<div className="flexible-btn ">
												<div className={`btn font-weight-normal bg-gradient px-15px py-10px font-size-10  font-bold text-white ${values.Code.length > 0 ? '' : 'disabled'}`}
													onClick={(e) => verifyCode(e, values)}>
													{props.langs.Accept}
												</div>
											</div>
										}
									</div>

									<ErrorMessage name="Code" component="label" className="error pl-25px" />
									{codeError && <label class="error pl-25px">{codeError}</label>}
								</div>
							}
							<div className="form-group ">
								<div className="custom-control custom-checkbox custom-checkbox-2 custom-control-inline pl-25px">
									<Field type="checkbox" id="TermsAgreement" name="TermsAgreement" className={`custom-control-input ${parseInt(values.TermsAgreement) === 1 ? 'active' : ''}`}
										value="1" checked={parseInt(values.TermsAgreement) === 1}
										onChange={(e) => setFieldValue(e.target.name, parseInt(values.TermsAgreement) === 1 ? 0 : 1)} />
									<label className="custom-control-label  text-dark font-base font-size-14" htmlFor="TermsAgreement">
										{props.langs.Agree}
										<span className="cursor-pointer pl-5px cursor-pointer text-blue" onClick={() => setAgreementModal({ values })}>
											{props.langs.NM_InfoUsageAgreement}
										</span>
									</label>
								</div>
								<ErrorMessage name="TermsAgreement" component="label" className="error pl-25px" />
							</div>
						</div>
						<div className="popup-footer px-40px pt-20px mt-0 d-flex justify-content-between">
							<button className="btn bg-transparent font-base font-size-12 font-weight-normal text-blue-gray px-0 mb-16px mb-md-0" >უკან დაბრუნება</button>
							<button type="submit" className={`btn font-weight-normal bg-gradient font-size-13 text-white ${!phoneChecked ? 'disabled' : ''}`} >
								{props.langs.Send}
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	)

}

export default FlexiblePackage

