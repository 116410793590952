import { Modal } from '../../../../components';
import React from 'react';

const ReviewPhotosModal = (props) => {
  const { reviewAllImages, openDetailsModal } = props;

  return (

    <Modal
      classInner={` fixed-mobile show-rate-modal-inner-with-images `}
      customInner={true}
      Component={() => (
        <div className=''>
          <span className="font-size-16 line-height-24 font-weight-bold">{props.langs.NM_Review_text}</span>
          <div className="mt-16px">
            <div className='d-flex flex-wrap calc-margin'>
              {reviewAllImages?.map(item => (
                <img
                  onClick={() => {
                    openDetailsModal(item?.data?.order_product_id)
                    props.onClose();
                  }}
                  src={item.image.thumbs}
                  alt="current-image"
                  className="border-radius-8 w-100 h-100 max-width-114px max-height-114px w-md-114px h-md-114px cursor-pointer mr-16px mb-16px"
                />
              ))}
            </div>
          </div>
        </div>
      )}
      close={props.onClose}
    />
  );
};

export default ReviewPhotosModal;
