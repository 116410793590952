import {Functions} from 'libs';

const categoryPropMap = [
    {json: 'id', js: 'CatID', dataType: 'int'},
    {json: 'cn', js: 'ProductsCount', dataType: 'int'},
    {json: 'scn', js: 'ShopProductsCount', dataType: 'int'},
    {json: 'hc', js: 'HasCondType', dataType: 'bool'},
    {json: 'dt', js: 'HasDeliveryType', dataType: 'bool'},
    {json: 'i',  js: 'Icon', dataType: 'string'},
    {json: 'pc', js: 'ParentId', dataType: 'int'},
    {json: 'sc', js: 'SubCatCount', dataType: 'int'},
    {json: 't',  js: 'Title', dataType: 'string'},
    {json: 'im', js: 'Image', dataType: 'string'},
    {json: 'ty', js: 'TypeID', dataType: 'int'},
    {json: 'shc', js: 'IsShopCat', dataType: 'int'},
    {json: 'kw', js: 'Keywords', dataType: 'string'},
];

export class Category {
    Image;
    CatID;
    ProductsCount;
    HasCondType;
    HasDeliveryType;
    Icon;
    ParentId;
    SubCatCount;
    Title;
    IsShopCat;
    SeoLink;
    Keywords;

    constructor(data) {
        if (!data) {
          return;
        }
        
        Functions.transformToObject(data, this, categoryPropMap);
        this.Image = `https://static.my.ge/mymarket/images/cats/${this.CatID}.svg`;
    }
}
