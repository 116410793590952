import noImage from '../resources/svgs/no-image.svg';
import { Functions } from 'libs';
import { FixedLists } from 'core';

const Map = () => {
	let lang = window.location.pathname.split('/')[1]
	let currentpath = window.location.pathname
	let productPath = `/${lang}/pr/`;

	const processPrice = (price) => {
		let dotPlace = String(price).indexOf(".")
		if (!dotPlace || dotPlace < 0){
			return parseFloat(price).toFixed(2)
		}
		return parseFloat(String(price).substring(0, dotPlace + 3)).toFixed(2)
	}
	const products = (data, order = false) => {
		if (!data) {return false}
		let result = {};
		result = data.filter((e, i) => {
			return (order && e.product === null) ? false : true;
		}).map((e, i) => {
			let n = order ? e.product : e
			let title = (order && n.lang_data && n.lang_data.title) ? n.lang_data.title : n.title ? n.title : ''
			return {
				product_id: parseInt(n.product_id),
				catId: n.cat_id,
				status_id: n.status_id ? parseInt(n.status_id) : false,
				price: processPrice(order ? e.price : n.price),
				discount: n.discount ? parseInt(n.discount) : 0,
				old_price: processPrice(n.old_price),
				product_code: (n.details && n.details.product_code) ? n.details.product_code : n.product_code !== null ? n.product_code : '',
				views: n.views !== null ? n.views : 0,
				quantity: order ? parseInt(e.quantity) : n.quantity ? parseInt(n.quantity) : 0,
				title: title,
				seoTitle: title ? Functions.seoUrlEncode(Functions.convertUrlComponent(title)) : '',
				desc: n.descr ? n.descr : '',
				shop: n.shop ? n.shop : '',
				cond_type_id: n.cond_type_id ,
				shop_id: n.shop_id ? n.shop_id : 0,
				shop_type_id: n.shop_type_id ? parseInt(n.shop_type_id) : 0,
				weight: e.weight ? parseFloat(e.weight) : 0,
				url: productPath + n.product_id,
				images: (n.photos && n.photos[0]) ? n.photos[0].thumbs : '',
				photos: (n.photos && n.photos.length > 0) ? n.photos : [],
				vip: parseInt(n.vip),
				vip_to: n.vip_to,
				vipSeo: FixedLists.seoOfferType(n.vip),
				sell_online: n.sell_online ? parseInt(n.sell_online) : 0,
				color: n.color,
				color_to: n.color_to,
				auto_update_to: n.auto_update_to,
				available_review: n.available_product_review,
				block_reason_ids: n.block_reason_ids,
				block_date: n.block_date,
				rating: n.rating ? n.rating : 0,
				rates_count: n.rates_count ? n.rates_count : 0,
				installment_price: n.installment_price,
				date: n.order_date ? n.order_date : '',
				create_date: n.create_date ? n.create_date : '',
				end_date: n.end_date ? n.end_date : '',
				isApplePay: n.discount_offers?.find(o => o.PAYMENT_METHOD === 11) ? true : false,
				isVisaPay: n.discount_offers?.find(o => o.PAYMENT_METHOD === 2) ?? false,
				isEmoney: n.discount_offers?.find(o => o.PAYMENT_METHOD === 5) ?? false,
				isMyCoins: n.discount_offers?.find(o => o.PAYMENT_METHOD === 9) ?? false,
				isInstallment: n.discount_offers?.find(o => o.PAYMENT_METHOD === 7) ?? false,
				available_to_return: e.available_to_return,
				available_to_return_reason: e.available_to_return_reason,
				model_title: n.model_title,
				available_to_pick_up: n.available_to_pick_up,
				available_to_shipping: n.available_to_shipping,
				brand_title: n.brand_title,
				review: n.review ? n.review : null,
				orderProductId: e.id ? e.id : null,
				can_review: e.product?.can_review,
				category_tree: n.category_tree,
				user_id: n.user_id,
				lang_data: n.lang_data
			}
		}, this);

		return result
	}
	const product = (data) => {
		return {
			product_id: parseInt(data.product_id),
			old_price: processPrice(data.old_price),
			discount: data.discount ? parseInt(data.discount) : 0,
			price: processPrice(data.price),
			loc_id: data.loc_id,
			brand_id: data.brand_id ? data.brand_id : null,
			model_id: data.model_id ? data.model_id : null,
			model_title: data.model_title,
			brand_title: data.brand_title,
			address: data.address,
			rates_count: data.rates_count ? data.rates_count : 0,
			rating: data.rating ? data.rating : 0,
			cond_type_id: data.cond_type_id,
			title: data.title ? data.title : '',
			seoTitle: data.title ? Functions.seoUrlEncode(Functions.convertUrlComponent(data.title)) : '',
			desc: data.descr ? data.descr : '',
			shop: data.shop ? data.shop : null,
			user: data.user ? data.user : {},
			userID: data.user_id ? data.user_id : '',
			username: data.username ? data.username : '',
			phone: data.phone ? data.phone : '',
			views: data.views ? data.views : 0,
			offer_price: data.offer_price === 1 ? true : false,
			date: data.order_date ? data.order_date : '',
			create_date: data.create_date ? data.create_date : '',
			end_date: data.end_date ? data.end_date : '',
			catId: data.cat_id,
			url: productPath + data.product_id,
			vip: parseInt(data.vip),
			vipSeo: FixedLists.seoOfferType(data.vip),
			images: data.photos.length > 0 ? data.photos : [],
			quantity: data.quantity ? parseInt(data.quantity) : 0,
			product_code: data.product_code ? data.product_code : '',
			weight: data.weight ? parseFloat(data.weight) : 0,
			sell_online: data.sell_online ? parseInt(data.sell_online) : 0,
			products_count: data.user_products_count ? data.user_products_count : 0,
			self_delivery_price: data.self_delivery_price ? data.self_delivery_price : '',
			self_delivery_period: data.self_delivery_period ? data.self_delivery_period : '',
			video: data.video_url ? data.video_url : false,
			installment_price: data.installment_price,
			isApplePay: data.discount_offers?.find(o => o.PAYMENT_METHOD === 11) ? true : false,
			isVisaPay: data.discount_offers?.find(o => o.PAYMENT_METHOD === 2) ?? false,
			isEmoney: data.discount_offers?.find(o => o.PAYMENT_METHOD === 5) ?? false,
			isMyCoins: data.discount_offers?.find(o => o.PAYMENT_METHOD === 9) ?? false,
			isInstallment: data.discount_offers?.find(o => o.PAYMENT_METHOD === 7) ?? false,
			available_to_pick_up: data.available_to_pick_up,
			available_to_shipping: data.available_to_shipping,
			available_to_return: data.available_to_return,
			not_available_to_return_reason: data.not_available_to_return_reason,
			service_centers: data.service_centers,
			warranty_period_id: data.warranty_period_id,
			seller_contact: data.seller_contact,
		}
	}
	const shops = (data) => {
		// let urlA = currentpath.split('/').filter(String);
		// if( urlA.length > 2 ){ urlA.pop() }
		// currentpath = '/' + urlA.join('/') + '/';

		let result = data.map((data, idx) => {
			return {
				id: data.shop_id,
				title: data.title ? data.title : '',
				description: data.description ? data.description : '',
				org_title: data.description ? data.description.org_title : '',
				logo: data.logo ? data.logo : noImage,
				products_count: data.products_count,
				url: `/${lang}/search?ShopID=${ data.shop_id}`,
			}
		}, this);
		return result ? result : {}
	}
	const shop = (data) => {
		let urlA = currentpath.split('/').filter(String);
		if( urlA.length > 2 ){ urlA.pop() }
		currentpath = '/' + urlA.join('/') + '/';

		return {
			id: data.shop_id,
			title: data.title,
			descr: data.lang_data ? {
				shortDescr: data.lang_data.short_descr ? data.lang_data.short_descr : '',
				fullDescr: data.lang_data.descr ? data.lang_data.descr : '',
				history: data.lang_data.history ? data.lang_data.history : '',
				mission: data.lang_data.mission ? data.lang_data.mission : '',
				plans: data.lang_data.bussiness_plans ? data.lang_data.bussiness_plans : '',
				finances: data.lang_data.finances ? data.lang_data.finances : '',
			} : {},
			required_investment: data.required_investment != null ? data.required_investment : null,
			logo: data.logo,
			cover: data.cover ? data.cover : '',
			url: currentpath + data.shop_id + '/',
		}
	}
	const attrs = (data) => {
		let result = {}
		result = data.map((attr, idx) => {
			return {
				id: attr.AttrID,
				value: attr.AttrValue,
				type: attr.ViewType,
				title: attr.AttrTitle,
				valueId: attr.AttrValID,
				valueTitle: attr.AttrValTitle,
				valueTitles: attr.ValTitles,
				decValue: attr.DecValue,
				dimTitle: attr.DimTitle
			}
		});
		return result
	}
	const cats = (data) => {
		let result = {}
		result = data.map((data, idx) => {
			return {
				id: data.cat_id,
				title: data.lang_data.title,
				image: 'https://static.my.ge/mymarket/images/cats/' + data.cat_id + '.png', // API დან უნდა წამოვიდეს
			}
		});
		return result
	}
	const searchCats = (data) => {
		let result = {}
		result = data.map((data, idx) => {
			return {
				id: data.key,
				count: data.doc_count,
			}
		});
		return result
	}
	const breadcrumbs = (data) => {
		let result = {}
		result = data.map((data, idx) => {
			return {
				id: data.cat_id,
				title: data.lang_data.title,
			}
		});
		return result
	}
	const captcha = (data) => {
		if (!data) {return false}
		return {
			image: data.Captcha.Image ? data.Captcha.Image : '',
			token: data.CaptchaToken ? data.CaptchaToken : '',
		}
	}
	const notifications = (data) => {
		if (!data) {return false}
		let result = {}
		result = data.map((data, idx) => {
			return {
				id: data.notification_id,
				description: data.lang_data.descr.substr(),
				status_id: data.status_id,
				seen: data.seen,
				is_read: data.seen ? {
					seen: data.lang_data.notification_id ? data.lang_data.notification_id : ''
				  }: {},
				notification_date: data.insert_date,
				type_id: data.type_id,
				user_type:data.user_type,
				action_button_descr:data.lang_data.action_button_descr,
				action_button_link:data.action_button_link,
			}
		});
		return result
	}
	const profile = (data) => {
		return {
			name:       data && data.user_name ? data.user_name : '',
			lastname:   data && data.user_surname ? data.user_surname : '',
			year:       data && data.birth_year ? data.birth_year : '',
			gender_id:  data && data.gender_id ? parseInt(data.gender_id) : '',
			photo:      data && data.photo     ? data.photo     : noImage,
			email:      data && data.username ? data.username   : ''
		}
	}
	const address = (data) => {
		if (!data || data.length === 0) {return false}
		let m = (i) => {
			return i ? {
				id: i.id,
				isDefault: i.is_default,
				city: i.loc_id,
				address: i.address ? i.address : '',
				personalNumber: i.personal_number ? i.personal_number : '',
				fullname: i.fullname ? i.fullname : '',
				phone: i.phone ? i.phone : '',
				secondPhone: i.second_phone ? i.second_phone : '',
				status: i.status_id,
				type: i.type_id
			} : {}
		}
		return data.length > 0 ? data.map((data, idx) => {
			return m(data)
		}, this) : m(data)
	}
	const orders = (d) => {
		return d.length > 0 ? d.map((e, i) => {
			return {
				address: address(e.address),
				order_id: e.order_id,
				order_code: e.order_code ? e.order_code : '',
				insert_date: e.insert_date ? e.insert_date : '',
				order_products: e.order_products ? products(e.order_products, true) : {},
				available_review: e.available_seller_review,
				shop: e.shop ? e.shop : {},
				reviews: e.reviews ? e.reviews : {},
				total_amount: e.total_amount ? parseFloat(e.total_amount).toFixed(2) : 0,
				site_commission: e.site_commission ? parseFloat(d.site_commission).toFixed(2) : 0,
				status_id: e.status_id ? e.status_id : 0,
				pick_up_code: e.pick_up_code ? e.pick_up_code : null,
				shipping_type: e.shipping_type ? e.shipping_type : 0,
				courier_review: e.courier_review,
				can_request_review: e.can_request_review,

			}
		}, this) : []
	}
	const order = (d) => {
		return !d ? false :
		{
			order_id: d.order_id,
			order_code: d.order_code ? d.order_code : '',
			insert_date: d.insert_date ? d.insert_date : '',
			address: d.address ? address(d.address) : {},
			order_products: d.order_products ? products(d.order_products, true) : {},
			amount: d.amount ? parseFloat(d.amount).toFixed(2) : 0,
			shipping_price: d.shipping_price ? parseFloat(d.shipping_price).toFixed(2) : 0,
			total_amount: d.total_amount ? parseFloat(d.total_amount).toFixed(2) : 0,
			site_commission: d.site_commission ? parseFloat(d.site_commission).toFixed(2) : 0,
			order_status_history: d.order_status_history ? d.order_status_history : [],
			status_id: d.status_id,
			pick_up_code: d.pick_up_code ? d.pick_up_code : null,
			shipping_type: d.shipping_type ? d.shipping_type : 0,
			cart_type: d.cart_type,
			bank_account: d.bank_account ? d.bank_account.iban : '',
			delivery_date: d.delivery_date,
		}
	}
	const sectionsQueryPrepare = (config) => {
		let Positions = []
		let PositionParams = []

		Object.entries(config).map(([k, v], i) => {
			Positions.push(k)
			v.item_ids && PositionParams.push(`PositionParams[${k}][item_ids]=${v.item_ids}`)
			v.tab_id && PositionParams.push(`PositionParams[${k}][tab_id]=${v.tab_id}`)
			v.all_tabs && PositionParams.push(`PositionParams[${k}][all_tabs]=${v.all_tabs}`)
			v.limit && PositionParams.push(`PositionParams[${k}][limit]=${v.limit}`)
			return 0;
		})
		let query = `Positions=${Positions.join('.')}&${PositionParams.join('&')}&Page=1`
		return query;
	}
	const availableReviews = (d) => {
		if (!d) {return false}
		let reviews = []
		Object.entries(d).map(([k, v], i) => {
			let type        = v.seller.type_id
			let products    = []

			v.products.map((e, l) => {
				return products.push({
					product_id: e.product_id,
					title: e.title,
					photo: e.photos[0] ? e.photos[0].thumbs : noImage,
					complete: 0
				})
			})

			return reviews.push({
				orderID: k,
				products: products,
				sellerType: v.seller.type_id,
				sellerID: type === 2 ? v.seller.data.shop_id : type === 3 ? v.seller.data.user_id : 0,
				sellerName: type === 2 ? v.seller.data.title : type === 3 ? `${v.seller.data.user_name} ${v.seller.data.user_surname}` : '',
				complete: 0
			})
		})
		return reviews
	}
	const promPackageServices = (d) => {
		return d.length > 0 ? d.map((e, i) => {
			return e ? {
				prom_package_id: e.prom_package_id,
				prom_service_id: e.service.prom_service_id,
				amount_left: e.amount_left,
				end_date: e.end_date,
				total_amount: e.init_amount,
				title: e.service.lang_data.title,
				description: e.service.lang_data.description
			} : e
		}, this) : []
	}
	const userData = (d) => {
		return {
			User: d.user ? {
				BirthYear: d.user.birth_year,
				GenderID: d.user.gender_id ? parseInt(d.user.gender_id) : 1,
				HasPhoto: d.user.has_photo,
				Phone: d.user.phone,
				Photo: d.user.photo,
				ID: d.user.user_id,
				Money: d.user.user_money,
				Name: d.user.user_name,
				Lastname: d.user.user_surname,
				Email: d.user.username,
				Terms: d.user.agree_tbc_terms,
				LastSync: d.lastSyncDate ? d.lastSyncDate : Math.floor(new Date().getTime() / 1000),
			} : null,
			Shop: d.shop ? {
				HasCover: d.shop.has_cover,
				HasLogo: d.shop.has_logo,
				Logo: d.shop.logo,
				ID: d.shop.shop_id,
				StatusID: d.shop.status_id,
				Title: d.shop.title,
				TypeID: d.shop.type_id,
				ProcessOrders: d.shop.process_order_permission
			} : null,
			ActivityState: d.userActivityState ? d.userActivityState : null,
			Reviews: d.hasAvailableReviews ? true : false,
			TradeInOrders: d.hasTradeInOrders ? true : false,
		}
	}
	const paymentOrder = (d) => {
		return !d ? false :
		{
			Operator: d.Operator,
			OrderID: d.OrderID,
			PaymentData: d.PaymentData,
			TransID: (d.PaymentData && d.PaymentData.trans_id) ? d.PaymentData.trans_id : '',
			PaymentMethod: d.PaymentMethod,
			PaymentURL: d.PaymentURL,
			StatusID: d.StatusID,
			Code: d.Code,
		}
	}

	return {
		processPrice,
		products,
		product,
		shops,
		shop,
		attrs,
		cats,
		searchCats,
		breadcrumbs,
		captcha,
		notifications,
		profile,
		address,
		orders,
		order,
		sectionsQueryPrepare,
		availableReviews,
		promPackageServices,
		userData,
		paymentOrder
	}
}
export default Map
