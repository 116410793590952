import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { API } from 'services';
import { Functions } from 'libs'
import { Loader } from 'components'
import { ImageHandler } from 'components';
import { Parser } from 'components';
const OfferPrice = (props) => {
	const api = API();
	const [messageState, setMessageState] = useState(false);
	const [submitState, setSubmitState] = useState(false)

	const getInitialValues = () => ({
		Price: '',
		Message: ''
	})

	const [data, setData] = useState(getInitialValues());

	const getValidationSchema = () => {
		let validationSchema = {
			Price: Yup.string().required(props.langs.WritePrice),
			// Message: Yup.string().required(props.langs.WriteMessageText),
		}
		return Yup.object().shape(validationSchema)
	}

	const config = {
		initialValues: data,
		validationSchema: getValidationSchema(),
		enableReinitialize: true
	}

	const handleSubmit = async (values) => {
		setSubmitState(true)
		setData(values)
		let sendData;
		if (props.data.OfferID) {
			sendData = await api.updateOfferStatus({
				Price: values.Price,
				offerPriceText: values.Message,
				StatusID:2,
				OfferID: props.data.OfferID
			});
		} else {
			sendData = await api.sendOfferPrice({
				PrID: props.data.PrID,
				Price: values.Price,
				Message: values.Message
			});
		}
	if (sendData.statusCode) {
		setMessageState(true)
		setSubmitState(false)
	} else {
		props.callback(sendData, 'success')
		setSubmitState(false)
	}
}

const handleChange = (e, setFieldValue) => {
	let value = Functions.checkStringLength(Functions.checkNumeric(e.target.value), 6)
	if (value.length >= 0 && value.length <= 6) {
		setFieldValue("Price", value)
	}
	return e.target.focus()
}

const SuccessComponent = () => (
	<div className="position-relative d-flex flex-column justify-content-center align-items-center pt-40px">
		<div className="w-100 d-flex align-items-center justify-content-start mb-24px">
			<i className="svg-i sent-offer-i w-24px h-24px"></i>
			<h4 className="text-dark text-center font-size-20 text-dark my-0 ml-12px">
				{props.langs.NM_OfferPriceSend}
			</h4>
		</div>

		<span className="text-dark font-base font-size-16 font-base d-inline-block ">
			{props.langs.NM_MessageToUser[0]}
			<span className="text-blue">{` ID${props.data.userID} `}</span>
			{props.langs.NM_MessageToUser[1]}
		</span>
		<div className="offered-product py-24px px-12px bg-gray-transparent border-radius mt-24px d-flex align-items-center w-100">
			<ImageHandler {...{
				src: props.data.Img.length > 0 ? props.data.Img[0].thumbs : false,
				className: "w-68px h-68px object-fit-cover mr-12px border-radius"
			}} />
			<div className="font-size-16 text-dark">
				{props.data.Title}
			</div>
		</div>
		<div className="font-size-14 text-blue-gray mt-24px">
			{props.langs.NM_OfferPriceNotifyText}
		</div>
		<Link to={`/${props.getLang}/chat`} className="btn bg-blue text-white mt-24px font-size-16 font-medium font-weight-normal d-flex align-items-center justify-content-center h-48px w-100" 
			onClick={(e) => props.callback(e, 'destroy')}>
			{props.langs.NM_ViewChatUser}
		</Link>
	</div>
)
return (
	<div className="custom-modal-wrapper">
		{messageState ?
			<SuccessComponent />
			:
			<Formik {...config} onSubmit={handleSubmit} >
				{({ setFieldValue }) => (
					<Form autoComplete="off">
						<div className="position-relative d-flex flex-column justify-content-center align-items-center pt-40px">
							<div className="popuo-content  w-100">
								<h3 className="font-bold text-center font-size-24 pb-8px">
									{props.langs.OfferYourPrice}
								</h3>
								{props.data.Price ?
									<h3 className="font-bold text-center font-size-14">
									{props.langs.NM_OfferPriceOriginalPrice} {props.data.Price} {props.currency()}
									</h3>
								: null}
								<div className="form-group col p-0 my-24px position-relative">
									<Field type="text" name="Price" className="form-control  m-0 text-left text-blue-gray font-size-18 font-medium"
										placeholder={`${props.langs.ShortForExample} ${props.data.Price ? parseFloat(props.data.Price - 1).toFixed(2) : 1} `}
										onChange={(e) => handleChange(e, setFieldValue)} />
									<ErrorMessage name="Price" component="label" className="error" />
									<div className="position-absolute top-15px right-16px text-blue-gray">
										{props.currency()}
									</div>
								</div>
								<div className="form-group col p-0 m-0">
									<Field as="textarea" name="Message" className="offer-textarea form-control text-dark font-size-14 font-base" placeholder={props.langs.NM_WriteToSeller} />
									<ErrorMessage name="Message" component="label" className="error" />
								</div>
							</div>
							{submitState && <Loader />}
							<button type="submit" className="btn mt-24px d-flex align-items-center justify-content-center bg-blue w-100 font-bold-caps font-size-13 text-white  px-40px h-48px" 
								disabled={submitState}>
								{props.langs.Send}
							</button>
							<div className="link-dark font-size-14 text-dark text-decoration-underline  mt-16px"><Parser html={props.langs.NM_OfferPriceFAQ} /></div>
						</div>
					</Form>
				)}
			</Formik>
		}
	</div>
)
}

export default OfferPrice