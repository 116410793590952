import React from 'react'
import LawNameIcon from '../../../resources/img/icons/law-name.svg'
import IndicativeIcon from '../../../resources/img/icons/indicative.svg'
import LocationIcon from '../../../resources/img/icons/address-location.svg'
import PhoneIcon from '../../../resources/img/icons/phone.svg'
import WarrantyIcon from '../../../resources/img/icons/warranty.svg'
import ServiceCenterIcon from '../../../resources/img/icons/service-center.svg'
import { Parser } from 'components';
const ConcurentionAgancy = (props) => {
  const { 
    langs, 
    data,
  } = props;
  return (
    <div className="custom-modal-wrapper ">
      <div className="position-relative ">
        {data.type ?
          <div className="font-size-14 text-black-70 ">
            <div className="shop-modal-content mx-sm-18px">
              <div className='font-size-18 font-bold text-black'>
                {langs.Seller_information}
              </div>
              <div className='pt-36px'>
                <div className='d-flex align-items-center pb-12px'>
                  <img className='mr-8px' src={LawNameIcon} alt="" />
                  <span>
                    {`${langs.Legal_name} : `}
                    <b className='text-black'>
                      {data.Shop?.org_title}
                    </b>
                  </span>
                </div>
                <div className='d-flex align-items-center pb-12px'>
                  <img className='mr-8px' src={IndicativeIcon} alt="" />
                  <span>
                    {`${langs.identification} : `}
                    <b className='text-black'>
                      {data.Shop?.id_code}
                    </b>
                  </span>
                </div>
                <div className='d-flex align-items-center pb-12px'>
                  <img className='mr-8px' src={LocationIcon} alt="" />
                  <span>
                    {`${langs.Address} : `}
                    <b className='text-black'>
                      {data.Shop?.address}
                    </b>
                  </span>
                </div>
                <div className='d-flex align-items-center pb-12px'>
                  <img className='mr-8px' src={PhoneIcon} alt="" />
                  <span>
                    {`${langs.ContactFormPhone} : `}
                    <b className='text-black'>
                      {data.Shop?.phone}
                    </b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        :
          <div>
            <div className='font-size-18 font-bold text-black mx-sm-18px pb-36px'>
              {langs.Warranty_conditions}
            </div>
            <div className='d-flex align-items-center pb-16px mb-16px mx-sm-18px border-bottom border-rgba-gray-11'>
              <img className='mr-8px' src={WarrantyIcon} alt="" />
              <span>
                {`${langs.Warranty} : `} 
                <b className='text-black'>
                  {data.WarrantyPeriod}
                </b>
              </span>
            </div>
            {data.Services.map((service, index) => (
              <div key={index} className="font-size-14 text-black-70">
                <div className="mx-sm-18px">
                  <div className='border-bottom border-rgba-gray-11 mb-16px'>
                    <div className='d-flex align-items-center pb-12px'>
                      <img className='mr-8px' src={ServiceCenterIcon} alt="" />
                      <span>
                        {`${langs.NM_Service_center} : `} 
                        <b className='text-black'>
                          {service.lang_data.title}
                        </b>
                      </span>
                    </div>
                    <div className='d-flex align-items-center pb-12px'>
                      <img className='mr-8px' src={LocationIcon} alt="" />
                      <span>
                        {`${langs.Address} : `} 
                        <b className='text-black'>
                          {service.lang_data.address}
                        </b>
                      </span>
                    </div>
                    <div className='d-flex align-items-center pb-16px'>
                      <img className='mr-8px' src={PhoneIcon} alt="" />
                      <span>
                        {`${langs.ContactFormPhone} : `}
                        <b className='text-black'>
                          {service.phone}
                        </b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className='mx-sm-18px text-editor-styles'>
             <Parser html={data.Descr} />
            </div>
          </div>
        }
      </div>
    </div>
  )
}
export default ConcurentionAgancy