
import React from 'react'
import PropTypes from 'prop-types'

Plus.propTypes = {
    className: PropTypes.string
}

export default function Plus({ className = '' } = '') {
    return (
        <span className={`svg-icon svg-md-icon ${className}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
        <g id="plus-circle" transform="translate(-6 -5.668)">
            <line id="Line_189" dataname="Line 189" y2="6" transform="translate(10 6.668)" fill="none" stroke="#737e94" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <line id="Line_190" dataname="Line 190" x2="6" transform="translate(7 9.668)" fill="none" stroke="#737e94" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </g>
        </svg>




        </span>
    )
}