import React from 'react'
const MyFaforites = (className = '') => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="6" fill="#F8F9FD" />
            <path d="M21.1062 10.7781C19.3462 9.57814 17.1728 10.1381 15.9995 11.5115C14.8262 10.1381 12.6528 9.57148 10.8928 10.7781C9.95949 11.4181 9.37282 12.4981 9.33282 13.6381C9.23949 16.2248 11.5328 18.2981 15.0328 21.4781L15.0995 21.5381C15.6062 21.9981 16.3862 21.9981 16.8928 21.5315L16.9662 21.4648C20.4662 18.2915 22.7528 16.2181 22.6662 13.6315C22.6262 12.4981 22.0395 11.4181 21.1062 10.7781ZM16.0662 20.4848L15.9995 20.5515L15.9328 20.4848C12.7595 17.6115 10.6662 15.7115 10.6662 13.7848C10.6662 12.4515 11.6662 11.4515 12.9995 11.4515C14.0262 11.4515 15.0262 12.1115 15.3795 13.0248H16.6262C16.9728 12.1115 17.9728 11.4515 18.9995 11.4515C20.3328 11.4515 21.3328 12.4515 21.3328 13.7848C21.3328 15.7115 19.2395 17.6115 16.0662 20.4848Z" fill="#8996AE" />
        </svg>
    </span>
)

export default MyFaforites



