import React, {useState} from 'react'

import moment from 'moment'

import CONFIG from 'core/config'
import { Functions } from 'libs'
import {Formik} from 'formik';

import * as Yup from 'yup';
import {API} from 'services';
import {Modal, Response, InfoUsageAgreement, Verification} from 'components';
import { Header, LeadForm } from './components';

const InstallmentForm = (props) => {
    const api                                                   = API();

    const [currentStep, setCurrentStep]                         = useState(0);

    const [responseModal, setResponseModal]                     = useState(false);
    const [agreementModal, setAgreementModal]                   = useState(false);
    const [verificationModal, setVerificationModal]             = useState(false);

    const close = () => {
        props.close();
    }

    const success = () => {
        props.success();
        close()
    }

    const onReadAgree = (e) => {
        setAgreementModal(false)
        e.values[e.name] = 1
        setData(e.values)
    }

    const periodList = new Array(CONFIG.TOSPLIT.INSTALLMENT.MONTHS_COUNT).fill().map((_, i) => ({value: i+3, label: `${i+3} ${props.langs.Month}`}))

    const locale = {
        localize: {
            month: n => props.appData.DateAndTime[api.lang].monthsShort[n],
            day: n => props.appData.DateAndTime[api.lang].weekdaysShort[n]
        },
        formatLong: {
			date: () => 'dd/MM/yyyy'
		}
    }

    const getLoanRate = (month) => {
        let current = CONFIG.TOSPLIT.INSTALLMENT.RATES.filter(o => month >= o.MIN && month <= o.MAX)
        return current[0] ? current[0].RATE : CONFIG.TOSPLIT.INSTALLMENT.RATES[0].RATE
    }

    const getCurrentAmount = (price, month) => {
        return (parseFloat(price) + (getLoanRate(month ? month : CONFIG.TOSPLIT.INSTALLMENT.MIN_MONTH) * parseFloat(price) / 100)).toFixed(2)
    }

    const getInitialValues = () => ({
        GroupID: 2,
        Name: '',
        Amount: getCurrentAmount(props.product.price),
        FirstPaymentAmount: '',
        Period: CONFIG.TOSPLIT.INSTALLMENT.MIN_MONTH,
        PersonalNumber: '',
        OrgAddress: '',
        Address: '',
        BirthDate: '',
        OrgTitle: '',
        Phone: '',
        Salary: '',
        SalaryType: 0,
        AgreeTerms: 0,
    })

    const [data, setData] = useState(getInitialValues());

    const getValidationSchema = () => {
        let validationSchema = {
            Name: Yup.string().required(props.langs.WriteFullName),
            Amount: Yup.string().required(props.langs.NM_LoanWriteAmount),
            FirstPaymentAmount: Yup.string().matches(/^[0-9]+$/, `${props.langs.IncorrectFormat}`),
            Period: Yup.string().required(props.langs.SelectPeriod),
            PersonalNumber: Yup.string()
                .matches(/^[0-9]+$/, `${props.langs.IncorrectFormat}`)
                .test('len', `${props.langs.IncorrectFormatOfPersonaCode}`, val => val && val.length === 11)
                .required(`${props.langs.WritePersonalNumber}`),
            BirthDate: Yup.string().required(props.langs.WriteBirthDate),
            Phone: Yup.string().required(`${props.langs.WriteYourPhone}`)
                .matches(/^[0-9]+$/, `${props.langs.IncorrectFormat}`)
                .test('len', `${props.langs.IncorrectFormatOfPhoneNumber}`, o => o && o.length === 9),
            Salary: Yup.string().required(props.langs.NM_WriteMonthlyIncome),
            OrgTitle: Yup.string().required(props.langs.NM_WriteEmployedOrganization),
            OrgAddress: Yup.string().required(props.langs.NM_WriteLegalAddress),
            Address: Yup.string().required(props.langs.NM_WriteCurrentAddress),
            AgreeTerms: Yup.string().required(props.langs.NM_AgreementWithTerms),
        }
        return Yup.object().shape(validationSchema)
    }

    const config = {
        initialValues: data,
        validationSchema: getValidationSchema(),
        enableReinitialize: true
    }

    const handleSubmit = (values) => {
        setData(values)
        let finalData = {...values}
        finalData.BirthDate = moment(new Date(values.BirthDate)).format('YYYY-MM-DD').toString()
        if (!finalData.FirstPaymentAmount) {
            finalData.FirstPaymentAmount = 0
        }
        delete finalData.AgreeTerms
        setVerificationModal({
            title: props.langs.NM_ConfirmPhoneNumber,
            component: 'Phone',
            action: 'sendUserSMS', 
            finalData
        })
    }

    const onPhoneConfirm = async (e) => {
        let finalData = {...e.finalData}
        finalData.Code = e.SmsCode
        finalData.Token = e.smsData ? e.smsData.Token : ''
        let response = await api.addLeadBank(props.product.product_id, finalData)
        let modalParams = {
            statusCode: response.statusCode,
            statusMessage: response.statusMessage,
            close: () => setResponseModal(false)
        }
        if (response.statusCode) {
            modalParams['close'] = () => {
                setResponseModal(false);
                success()
            }
        }
        setResponseModal(modalParams);
    }

    const changeStep = (e, step, values) => {
        e && (e.preventDefault() || e.stopPropagation());
        if (currentStep === 0 && step === 0) {
            close()
            return;
        }
        setData(values)
        setCurrentStep(step)
    }


    const FormComponent = ({values, setFieldValue}) => {

        if (parseFloat(values.FirstPaymentAmount) > 0 && parseFloat(values.FirstPaymentAmount) < props.product.price) {
            values.Amount = getCurrentAmount((props.product.price - values.FirstPaymentAmount) ,values.Period)
        } else {
            values.Amount = getCurrentAmount(props.product.price, values.Period)
            if ((parseFloat(values.FirstPaymentAmount) < 0 && values.FirstPaymentAmount) || (parseFloat(values.FirstPaymentAmount) > props.product.price)) {
                values.FirstPaymentAmount = 0
            }
        }

        return (
            <LeadForm {...{
                langs: props.langs,
                currency: props.currency,
                locale,
                currentStep,
                values,
                periodList,
                product: props.product,
                setFieldValue,
                getLoanRate,
                setAgreementModal,
                changeStep,
                moment,
                Functions
            }} />
        )
    }
    

    const Component = () => {
        return (
            <div className="custom-modal-wrapper">
                <div className="loan_form m-0 px-8px py-8px">
                    {responseModal &&
                        <Response {...{
                            langs: props.langs,
                            statusMessage: responseModal.statusMessage,
                            statusCode: responseModal.statusCode,
                            close: responseModal.close
                        }} />
                    }
                    {agreementModal &&
                        <InfoUsageAgreement {...{
                            langs: props.langs,
                            text: agreementModal.text,
                            title: "",
                            success: () => onReadAgree(agreementModal),
                            close: () => setAgreementModal(false)
                        }} />
                    }
                    {verificationModal &&
                        <Verification {...{ 
                            data: verificationModal, 
                            success: onPhoneConfirm, 
                            close: () => {
                                setVerificationModal(false); 
                                setTimeout(() => {
                                    document.body.classList.add('modal-active')
                                }, 200)
                            } 
                        }} />
                    }
                    <Header {...{langs: props.langs, currentStep}} />
                    <Formik {...config} onSubmit={handleSubmit} component={FormComponent} />
                </div>
            </div>
        )
    }

    return <Modal {...{customInner: true, Component, close, classInner: "fixed-mobile"}} />
}
export default InstallmentForm
