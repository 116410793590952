import React from 'react';
import {Parser} from 'components';

const Suggestions = (props) => {
	const onMouseDown = (item) => {
		props.setNewKeyword(item);
		props.setSearchBySuggestion(true);
	}
	return (
		<div className="suggestions z-index-999">
			<ul className="list-unstyled font-size-14">
				{props.suggestions.map((item, i) => (
					<li key={i} className={parseInt(props.hoveredSugID) === i ? 'grey' : ''} 
						onMouseDown={(e) => onMouseDown(item)} 
						onMouseEnter={(e) => props.setHoveredSugID(i)}
					>
						<Parser html={item.replace(props.keyword.active, "<b>"+props.keyword.active+"</b>")} />
					</li>
				))}
			</ul>
		</div>
	)
}

export default Suggestions;