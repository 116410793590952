import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { AppDataContext } from 'contexts/appData';
import LazyImage from 'components/common/suspense/LazyImage'
const HomeCards = (props) => {
	const { langs, appData, currency, productPrefixUrl } = useContext(AppDataContext);
	let product = props.product ? props.product : {}
	let img = product.photos && product.photos[0] && product.photos[0].thumbs ? product.photos[0].thumbs : ''

	return (
		<div className={`slider-card  position-relative cursor-pointer h-100 ${props.ShopOffers ? 'shop-card' : ''}  ${props.SalesCard ? 'd-flex' : ''}`}>
			<Link to={productPrefixUrl(product)} className="d-inherit text-decoration-none" reloadDocument>
				<div className={` d-flex align-items-center justify-content-center overflow-hidden  ${props.SalesCard ? 'sale-card-img ' : 'sl-card-img flex-shrink-0 bg-gray'} ${props.ShopOffers ? 'shop-card-img' : ''} `}>
					<LazyImage {...{
            src: img,
            alt: '',
            className: 'w-100 h-100 object-fit-cover',
          }} />
				</div>

				<div className={`card-info ${props.SalesCard ? 'sale-hover-info' : 'mt-8px'}`}>
					{(props.CategoryName && appData.Cats.List[product.cat_id]) &&
						<div className="cat-name font-size-12 font-base-caps mb-8px  text-dark opacity-50 text-nowrap">
							{appData.Cats.List[product.cat_id].Title}
						</div>
					}
					{(props.Offerslider && parseInt(product.discount) > 0) &&
						<div className={`d-flex align-items-center percent-box `}>
							<div className="percent offers-sale arctic-headline bg-dark-green text-white font-size-12 mr-8px">
								-{parseInt(product.discount)}%
							</div>
						</div>
					}
					<div className={`sl-card-name font-size-14 font-medium text-dark ${props.CategoryName ? 'mb-20px' : 'mb-4px mb-lg-10px '} ${props.SalesCard ? 'd-none' : ''}  ${props.ShopOffers ? 'font-size-24 clamp ' : 'clamp _1 '} ${props.Offerslider ? 'clamp _3' : 'clamp _1 '} `}>
						{product.title}
					</div>
					<div className={`sl-card-price  font-bold  line-height-11 ${props.CategoryName ? 'mb-6px ' : ''}  ${props.SalesCard ? 'text-dark-green font-size-16' : 'font-size-12 text-blue-gray'}  ${props.ShopOffers ? 'font-size-28 text-dark shop-slide-price' : ''}`}> 
						{parseFloat(product.price) > 0 ? parseFloat(product.price).toFixed(2) :  langs.PriceWithAgreement}
						{(product.price) > 0 ? <span className="ml-4px">{currency()}</span> : ''}
					</div>
					{(props.SalesCard && parseInt(product.discount) > 0) && 
						<div className={`sl-card-price sl-card-price-old font-size-12 font-bold text-white line-height-11 mt-4px`}>
							{`${parseFloat(product.old_price).toFixed(2)}`} {currency()}
						</div>
					}
					{(props.Offerslider && parseInt(product.discount) > 0) &&
						<div className={`sl-card-price sl-card-price-old font-size-12 font-bold  line-height-11  ${props.ShopOffers ? 'font-size-18 text-blue-gray mt-12px' : 'text-white mt-4px '}`}>
							{parseFloat(product.old_price) > 0 ? product.old_price :  langs.PriceWithAgreement}
							{(product.old_price) > 0 ? <span className="ml-4px">{currency()}</span> : ''}
						</div>
					}
				</div>
			</Link>
		</div>
	)
}

export default HomeCards
