import React, { useState, useEffect, useContext, useRef } from 'react'

import ReactPlayer from 'react-player'
import { SwiperSlide } from 'swiper/react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { getSwiperConfig } from 'core';
import { AppDataContext } from 'contexts/appData';
import { Slider, ImageHandler } from 'components';
import ProductInfo from './ProductInfo';

const GalleryOpener = (props) => {
	const { product } = props;
	const { langs } = useContext(AppDataContext);
	const gallerySwiper = useRef(null);
	const thumbnailSwiper = useRef(null);
	const [activeThumb, setActiveThumb] = useState(props.activeThumb);
	const [lastTap, setLastTap] = useState(null);
	// const [tab, setTab] = useState(
	// 	((props.images && !props.images[props.activeThumb]) || !props.images) && props.video ? false : true
	// );
	const [activeImage, setActiveImage] = useState(null);
	const goNext = () => {
		if (gallerySwiper && gallerySwiper.current !== null) {
			let nextIndex = gallerySwiper.current.activeIndex + 1;
			let index = nextIndex > (props.totalImages - 1) ? 0 : nextIndex;
			slideTo(index);
		}
	};
	const goPrev = () => {
		if (gallerySwiper && gallerySwiper.current !== null) {
			let prevIndex = gallerySwiper.current.activeIndex - 1;
			let index = prevIndex < 0 ? props.totalImages - 1 : prevIndex;
			slideTo(index)
		}
	};
	const slideTo = (index) => {
		gallerySwiper.current.slideTo(index);
		thumbnailSwiper.current.slideTo(index);
		thumbnailSwiper.current.update();
		gallerySwiper.current.update();
		setActiveThumb(index);
	};

	const handleImageZoom = (e, image) => {
		if (e.type === 'touchend') {
      const now = Date.now();
      if (lastTap && (now - lastTap) < 300) {
        setActiveImage(image);
        setLastTap(true);
      } else {
        setLastTap(now);
      }
    }
	}

	const handleImageZoomClose = (e) => {
		e.preventDefault() || e.stopPropagation();
		setActiveImage(null);
	}

	const processVideoLink = (large = false) => {
		let url = props.video.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/|\/shorts\/)/);
		if (url[2] !== undefined) {
			let videoID = url[2].split(/[^0-9a-z_-]/i);
			return `https://img.youtube.com/vi/${videoID[0]}/${large ? 'maxres' : ''}default.jpg`
		}
		return false;
	}

	const processThumbsClick = (image) => {
		if (activeImage !== null) {
			setActiveImage(image);
		}
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			if (thumbnailSwiper.current !== null && !thumbnailSwiper.current.destroyed) {
				thumbnailSwiper.current.on('click', (e, event) => {
					let index = [...event.target.parentNode.parentNode.children].indexOf(event.target.parentNode);
					slideTo(index);
					if (activeImage !== null) {
						setActiveImage();
					}
				});
			}
		}, 100)
		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [thumbnailSwiper.current])

	useEffect(() => {
		const timer = setTimeout(() => {
			if (gallerySwiper.current !== null && !gallerySwiper.current.destroyed) {
				gallerySwiper.current.on('slideChange', () => {
					slideTo(gallerySwiper.current.realIndex);
				});
			}
		}, 100)
		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gallerySwiper.current])


	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.keyCode === 37 || e.key === "ArrowLeft") {
				goPrev();
			} else if (e.keyCode === 39 || e.key === "ArrowRight") {
				goNext();
			} else if (e.keyCode === 27 || e.key === "Escape") {
				props.setOpenGallery(null);
			}
		}
		const timer = setTimeout(() => {
			window.addEventListener('keydown', e => handleKeyDown(e));
		}, 100)
		return () => {
			clearTimeout(timer);
			// window.removeEventListener("event", handleKeyDown);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="position-fixed w-100 h-100 top-0 left-0 z-index-999 disable-user-select m-0 d-flex justify-content-center align-items-center">
			<div className="rgba-bg-backdrop position-fixed absolute--center-position w-100 h-100"
				onClick={() => props.setOpenGallery(null)} />
			{((props.images && props.images.length > 0) || props.video) &&
				<div
					className="gallery-outer-box d-flex flex-column justify-content-start product-slider-container px-m-15px w-100 w-m-85 m-0  h-100  position-relative gallery-border no-border  bg-white">
					<div className="SRLControls SRLControls-tabs d-flex justify-content-berween tabs pt-m-32px pb-m-16px ">
						<div className="tabs d-flex " >
							<div className="btn d-flex d-m-none align-items-center border-radius border border-black p-10px font-size-14 font-bold bg-white"
								onClick={() => props.setOpenGallery(null)}>
								<i className='svg-i back-arrow mr-12px'></i>
								{langs.Back}
							</div>
							{/* {(props.images && props.images.length > 0) &&
								<div className={`gallery-tab font-size-14 font-medium mr-24px d-none d-m-flex ${tab ? 'active' : ''}`}
									onClick={() => setTab(true)}>
									{langs.NM_Photos}
								</div>
							} */}
							{/* {props.video &&
								<div className={`gallery-tab font-size-14 font-medium d-none d-m-flex ${!tab ? 'active' : ''}`}
									onClick={() => setTab(false)}>
									{langs.NM_Videos}
								</div>
							} */}
						</div>
						<button onClick={() => props.setOpenGallery(null)} title="Close"
							className="SRLCloseButton z-index-2 border-0 bg-transparent d-none d-m-flex " >
							<div className="svg-i close-black-i svg-i-lg"></div>
						</button>
					</div>
					{activeImage &&
						<div className='w-100 h-90 d-flex justify-content-center position-fixed z-index-1000 zoom-container'>
							<button className={`btn position-fixed  d-flex d-m-none top-20px right-10px z-index-1000 border-radius font-size-14 font-bold `}
								onClick={handleImageZoomClose}>
								<div className="svg-i close-black-i svg-i-lg"></div>
							</button>
							<TransformWrapper>
								<TransformComponent>
									<img src={activeImage} alt="" />
								</TransformComponent>
							</TransformWrapper>
						</div>
					}
					<div className={`img-content  h-100 flex-column flex-m-row  justify-content- pr-xl-72px d-flex d-m-flex`}>
						<div className="d-flex media-content  vh-80 mr-8px mr-lg-24px align-items-center px-0 position-relative w-100  col-m-9">
							{/* {props.totalImages > 1 &&
							<>
								<button title="Previous"
									className="SRLPrevButton border-0 transform-y-center top-50 z-index-2 position-absolute left-0 d-none d-m-flex"
									onClick={() => goPrev()} />
								<button title="Next"
									className="SRLNextButton border-0 transform-y-center top-50 z-index-2 position-absolute right-0 d-none d-m-flex"
									onClick={() => goNext()} />
							</>
						} */}
							<Slider {...getSwiperConfig('galleryLightBox', { activeThumb })}
								customSwiper={true} ref={gallerySwiper}>
								{props.images.map((image, i) => {
									return (
										<SwiperSlide key={i}
											className={`swiper-slide large align-items-center justify-content-center w-100 ${activeImage ? 'd-none' : 'd-flex'}`}>
											<img src={image.large ? image.large : image} alt="" className="w-100 h-95 object-fit-contain position-absolute"
												onTouchEnd={(e) => handleImageZoom(e, image.large ? image.large : image)} 
											/>
										</SwiperSlide>
									)
								})}
								{props.video &&
									<SwiperSlide
										className={`swiper-slide swiper-slide-video large align-items-center justify-content-center w-100 ${activeImage ? 'd-none' : 'd-flex'}`}>
										<ReactPlayer width="100%" height="100%" url={props.video} />
									</SwiperSlide>
								}
							</Slider>

						</div>
						{((props.images || props.video)   && (props.images.length > 0 || props.video.length > 0)) &&
							<div className={`product-details-thumbs product-details-thumbs-inner px-24px px-m-0   mb-m-0 position-relative  justify-cntent-end mt-12px  max-width-m-370px d-flex`}>
								<div className="product-details-out-container  w-100 justify-content-center">
									{product &&
										<ProductInfo {...{
											product,
											langs,
										}} />
									}
									<Slider {...getSwiperConfig('galleryLightBoxThumbnail', { activeThumb })}
										customSwiper={true} ref={thumbnailSwiper}>
										{props.images.map((image, i) => (
											<SwiperSlide key={i}
												className={`thumb-el max-width-80px h-60px  ${activeThumb === i ? 'custom-active' : ''}`}>
												<img src={image.thumbs ? image.thumbs : image} alt="" 
													onClick={() => processThumbsClick(image.large ? image.large : image)} />
											</SwiperSlide>
										))}
										{props.video &&
											<SwiperSlide
												className={`thumb-el max-width-80px h-60px  ${activeThumb === props.images.length ? 'custom-active' : ''}`}>
												<ImageHandler {...{
													src: processVideoLink(),
													alt: '',
													className: '',
													onClick: () => processThumbsClick(processVideoLink(true))
												}} />
												<div className="position-absolute top-30 left-30">
													<i className="svg-i youtube-play" id="youtube-play"></i>
												</div>
											</SwiperSlide>
										}
									</Slider>
								</div>
							</div>
						}
					</div>
					{/* <div className={`video-content media-content   flex-column flex-m-row justify-content-center justify-content-m-between  pr-xl-72px ${!tab ? ' d-none d-m-flex' : 'd-none'}`}> */}
						{/* <div className=" d-flex media-content  mr-8px mr-lg-24px align-items-center px-0 position-relative w-100 w-100 h-100 col-m-9">
							{props.video &&
								<div
									className="swiper-slide h-100 d-flex align-items-center  px-30px">
									<ReactPlayer width="100%" height="100%" url={props.video} />
								</div>
							}
						</div> */}
						{/* {((props.images && props.images.length >= 1) || props.video) &&
							<div className="product-details-thumbs product-details-thumbs-inner mt-32px position-relative d-flex justify-cntent-end w-100  max-width-m-370px">

								<div className="product-details-out-container  w-100 justify-content-center">
									<div className="d-flex font-size-14 font-medium text-dark p-12px bg-middle-gray  border-radius mb-12px">
										მსგავსი ვიდეოები ამ პროდუქტზე
									</div>

					   
								</div>
							</div>
						} */}
						{/* <div className="product-details-thumbs product-details-thumbs-inner mt-32px position-relative d-flex justify-cntent-end w-100  max-width-m-370px">
							<div className="product-details-out-container  w-100 justify-content-center">
							{product &&
								<ProductInfo {...{
									product,
									langs,
								}} />
							}
							</div>
						</div> */}
					{/* </div> */}
				</div>
			}
		</div>
	)
}

export default GalleryOpener