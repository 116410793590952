import React from 'react'
const MyCarts = (className = '') => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${className}`}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="6" fill="#F8F9FD" />
            <path d="M19.0301 16.6667C19.5301 16.6667 19.9701 16.3933 20.1967 15.98L22.5834 11.6533C22.8301 11.2133 22.5101 10.6667 22.0034 10.6667H12.1367L11.5101 9.33334H9.33008V10.6667H10.6634L13.0634 15.7267L12.1634 17.3533C11.6767 18.2467 12.3167 19.3333 13.3301 19.3333H21.3301V18H13.3301L14.0634 16.6667H19.0301ZM12.7701 12H20.8701L19.0301 15.3333H14.3501L12.7701 12ZM13.3301 20C12.5967 20 12.0034 20.6 12.0034 21.3333C12.0034 22.0667 12.5967 22.6667 13.3301 22.6667C14.0634 22.6667 14.6634 22.0667 14.6634 21.3333C14.6634 20.6 14.0634 20 13.3301 20ZM19.9967 20C19.2634 20 18.6701 20.6 18.6701 21.3333C18.6701 22.0667 19.2634 22.6667 19.9967 22.6667C20.7301 22.6667 21.3301 22.0667 21.3301 21.3333C21.3301 20.6 20.7301 20 19.9967 20Z" fill="#8996AE" />
        </svg>
    </span>
)

export default MyCarts
