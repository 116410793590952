import React, { useEffect } from 'react'
import CloseModal from 'components/icons/CloseModal'
const Modal = (props) => {

  let prefix = props.prefix ? props.prefix : "custom-modal"

  const showClose = props.showClose ?? true

  let className = {
    container: props.classContainer ? props.classContainer : '',
    inner: props.classInner ? props.classInner : '',
    wraper: props.classWraper ? props.classWraper : '',
    header: props.classHeader ? props.classHeader : '',
    content: props.classContent ? props.classContent : '',
    actions: props.classActions ? props.classActions : '',
  }

  const addModalState = () => {
    document.body.classList.add('modal-active')
  }

  const removeModalState = () => {
    document.body.classList.remove('modal-active')
  }

  const close = (e) => {
    if(props.close) {
      props.close(e);
    }
  }

  useEffect(() => {
    addModalState()
    return () => {
      removeModalState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`${prefix}-container ${className.container}`}>
      <div className="custom-modal-dialog">
        <div className={`${prefix}-inner ${className.inner}`}>
          {props.customInner ? (
            <props.Component />
          ) : (
            <div className={`${prefix}-wraper ${className.wraper}`}>
              <div className={`${prefix}-header ${className.header}`}>
                {props.Header && <props.Header />}
              </div>
              <div className={`${prefix}-content ${className.content}`}>
                {props.Content && <props.Content />}
              </div>
            </div>
          )}

          {showClose && (
            <button
              className="btn p-8px close-popup"
              onClick={(e) => close(e)}
            >
              <CloseModal />
            </button>
          )}

          {props.Actions && (
            <div className={`${prefix}-actions ${className.actions}`}>
              <props.Actions />
            </div>
          )}
        </div>
        <div className="modal-backdrop" onClick={(e) => close(e)}></div>
      </div>
    </div>
  );
}

export default Modal;