import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, ErrorMessage } from 'formik';

const Component = (props) => {
    return (
        <div className={`py-20px ${props.currentStep === 0 ? 'd-none' : ''}`}>
            <div className="row d-flex justify-content-between ">
                <div className="col-12 col-md-6">
                    <div className="form-group p-0 m-0">
                        <div className=" d-flex justify-content-start mb-8px">
                            <label htmlFor="Name" className=" font-size-12 font-weight-normal text-text_label my-auto">
                                {props.langs.YourFirstAndLastName}<span className="required text-blue"> *</span>
                            </label>
                        </div>
                        <Field type="string" name="Name" className="outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px" />
                        <ErrorMessage name="Name" component="label" className="error mb-0 mt-4px" />
                    </div>
                </div>
                <div className="col-12 col-md-6 mt-16px mt-md-0">
                    <div className="form-group p-0 m-0">
                        <div className=" d-flex justify-content-start mb-8px">
                            <label htmlFor="PersonalNumber" className=" font-size-12 font-weight-normal text-text_label my-auto">
                                {props.langs.PersonalNumber}<span className="required text-blue"> *</span>
                            </label>
                        </div>
                        <Field type="text" name="PersonalNumber" className="outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px" onChange={(e) => {
                            props.setFieldValue("PersonalNumber", props.Functions.checkStringLength(props.Functions.checkNumeric(e.target.value), 11))
                        }} />
                        <ErrorMessage name="PersonalNumber" component="label" className="error mb-0 mt-4px" />
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-between mt-24px">
                <div className="col-12 col-md-6">
                    <div className="form-group p-0 m-0 w-100">
                        <div className=" d-flex justify-content-start mb-8px">
                            <label htmlFor="BirthDate" className=" font-size-12 font-weight-normal text-text_label my-auto">
                                {props.langs.BirthDate}
                                <span className="required text-blue"> *</span>
                            </label>
                            <span className="my-auto tooltip-top" data-toggle="tooltip" title="სესხით სარგებლობისთვის თქვენ უნდა იყოთ 18-დან 60 წლამდე ასაკის">
                                <i className="svg-i i-icon-i my-auto ml-4px"></i>
                            </span>
                        </div>
                        <div className="position-relative border-radius-10 border-solid-1 border-gray">
                            <DatePicker
                                selected={props.values.BirthDate ? props.values.BirthDate : null}
                                onChange={date => {
                                    props.setFieldValue("BirthDate", date)
                                }}
                                onChangeRaw={(e) => e.preventDefault()}
                                dateFormat="dd/MM/yyyy"
                                minDate={props.moment().subtract(65, "years")._d}
                                maxDate={props.moment().subtract(18, "years")._d}
                                locale={props.locale}
                                isClearable
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText={props.langs.NM_LoanDateFormat}
                                className="position-relative outline-none border-radius-10 border-0  bg-white w-100 pl-12px font-size-12 pr-64px"
                                calendarClassName=""
                            />
                            <ErrorMessage name="BirthDate" component="label" className="error position-absolute top-53px left-0" />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mt-16px mt-md-0">
                    <div className="form-group p-0 m-0">
                        <div className=" d-flex justify-content-start mb-8px">
                            <label htmlFor="Phone" className=" font-size-12 font-weight-normal text-text_label my-auto">
                                {props.langs.PhoneNumber}<span className="required text-blue"> *</span>
                            </label>
                        </div>
                        <div className="outline-none input-group border-radius-10 border-solid-1 border-gray bg-white p-0">
                            <div className="input-group-prepend z-index-9">
                                <span className="input-group-text font-medium font-size-12 text-dark border-left-0 border-bottom-0 border-top-0">
                                    +995
                                </span>
                            </div>
                            <Field type="text" name="Phone" className="form-control border-0  position-relative" onChange={(e) => {
                                props.setFieldValue("Phone", props.Functions.checkStringLength(props.Functions.checkNumeric(e.target.value), 9))
                            }} />
                        </div>
                        <ErrorMessage name="Phone" component="label" className="error mb-0 mt-4px" />
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-between mt-24px">
                <div className="col-12 col-md-6 mt-16px mt-md-0">
                    <div className="form-group p-0 m-0">
                        <div className=" d-flex justify-content-start mb-8px">
                            <label htmlFor="Address" className=" font-size-12 font-weight-normal text-text_label my-auto">
                                {props.langs.NM_CurrentAddress}<span className="required text-blue"> *</span>
                            </label>
                        </div>
                        <Field type="text" name="Address" className="outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px" />
                        <ErrorMessage name="Address" component="label" className="error mb-0 mt-4px" />
                    </div>
                </div>
                <div className="col-12 col-md-6 mt-16px mt-md-0">
                    <div className="form-group p-0 m-0">
                        <div className=" d-flex justify-content-start mb-8px">
                            <label htmlFor="OrgAddress" className=" font-size-12 font-weight-normal text-text_label my-auto">
                                {props.langs.NM_LegalAddress}<span className="required text-blue"> *</span>
                            </label>
                        </div>
                        <Field type="text" name="OrgAddress" className="outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px" />
                        <ErrorMessage name="OrgAddress" component="label" className="error mb-0 mt-4px" />
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-between mt-24px">
                <div className="col-12 col-md-12 mt-16px mt-md-0">
                    <div className="form-group p-0 m-0">
                        <div className=" d-flex justify-content-start mb-8px">
                            <label htmlFor="OrgTitle" className=" font-size-12 font-weight-normal text-text_label my-auto">
                                {props.langs.NM_EmployedOrganization}<span className="required text-blue"> *</span>
                            </label>
                        </div>
                        <Field type="text" name="OrgTitle" className="outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px" />
                        <ErrorMessage name="OrgTitle" component="label" className="error mb-0 mt-4px" />
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-between mt-24px">
                <div className="col-12 mt-16px mt-md-0 ">
                    <div className="form-group p-0 m-0">
                        <div className="">
                            <label htmlFor="Salary" className=" font-size-12 font-weight-normal text-text_label mb-8px ">
                                {props.langs.NM_MonthlyIncome}<span className="required text-blue"> *</span>
                            </label>
                            <div className="d-flex align-items-center">
                                <Field type="number" placeholder="0" name="Salary" className="outline-none position-relative border-radius-10 border-solid-1 border-gray bg-white w-100 px-12px col-4 col-md-6" />
                                <div className="switcher-box d-flex align-items-center border border-gray-transparent border-radius-10 ml-12px">
                                    <button type="button" className={`btn d-flex align-items-center justify-content-center  h-100 border-radius-10 font-size-10 font-medium-caps font-weight-normal ${props.values.SalaryType === 0 ? 'active text-white' : 'text-blue-gray'}`} 
                                        onClick={() => props.setFieldValue('SalaryType', 0)}>
                                        {props.langs.NM_SalaryTypeCash}
                                    </button>
                                    <button type="button" className={`btn d-flex align-items-center justify-content-center  h-100 border-radius-10 font-size-10 font-medium-caps font-weight-normal ${props.values.SalaryType === 1 ? 'active text-white' : 'text-blue-gray'}`}  
                                        onClick={() => props.setFieldValue('SalaryType', 1)}>
                                        {props.langs.NM_SalaryTypeBank}
                                    </button>
                                </div>
                            </div>
                            <ErrorMessage name="Salary" component="label" className="error mb-0 mt-4px" />
                        </div>
                    </div>
                </div>
            </div>
            <hr className="mb-20px mt-28px mx-n20px text-gray-transparent opacity-1" />
            <div className="form-group pb-3px my-0 p-0 mt-20px">
                <div className="custom-control custom-checkbox custom-checkbox-2 d-inline-flex">
                    <Field type="checkbox" id="AgreeTerms" name="AgreeTerms" value="1"
                        className={`custom-control-input ${props.values.AgreeTerms === 1 ? 'active' : ''}`}
                        checked={props.values.AgreeTerms === 1}
                        onChange={() => props.setFieldValue("AgreeTerms", props.values.AgreeTerms === 1 ? 0 : 1)}
                    />
                    <label className="custom-control-label  text-dark font-base font-size-14" htmlFor="AgreeTerms">
                        {`${props.langs.Agree} `}
                        <span className="cursor-pointer text-blue" onClick={() => props.setAgreementModal({
                            values: props.values,
                            text: props.langs.PrivacyPolicyText,
                            name: 'AgreeTerms'
                        })}>
                            {props.langs.SiteRulesAndTerms}
                        </span>
                        {` ${props.langs.NM_And} `}
                        <span className="cursor-pointer text-blue" onClick={() => props.setAgreementModal({
                            values: props.values,
                            text: props.langs.PrivacyPolicyText,
                            name: 'AgreeConfidentiality'
                        })}>
                            {props.langs.PrivacyPolicy}
                        </span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default Component