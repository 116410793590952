import React, {useRef, useState, useEffect} from 'react'
import { Link } from 'react-router-dom';

const Avatar = (props) => {
	const {logo, link, photo, iClassName, className, gender, isLoggedIn, isHeaderDropdown} = props;
	const logoImage                     = useRef(null)
	const [logoType, setLogoType]       = useState('')

	useEffect(() => {
		if (logoImage.current !== null) {
			let rate = parseFloat(logoImage.current.naturalWidth / logoImage.current.naturalHeight).toFixed(2)
			let type = 'rectangle'
			
			if (rate > 0.98 && rate < 1.02) {
				type = 'square'
			}
			setLogoType(type)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [logoImage.current])

	return (
		<>
			{logo ?
				link !== null ? 
					<Link to={link} >
						<img src={logo} className={logoType} ref={logoImage} alt="" />
					</Link>
				:
					<img src={logo} className={logoType} ref={logoImage} alt="" />
			: photo ?
				<img src={photo} className={`max-width-24px max-height-24px border-radius-xl user-profile-photo ${className}`} alt="" />
				// <div className="border rounded-circle w-40px h-40px px-4px">
				// 	<i className="svg-i  logo-i w-100 h-100"> </i>
				// </div>
			:
					!isLoggedIn ? <i className={'svg-i svg-i-lg auth-i'} style={{width: '18px', height: '24px'}}></i> :
				<i className={`svg-i ${iClassName ? iClassName: 'svg-i-xlg'} ${parseInt(gender) === 1 ? 'user-male-i w-24px h-24px' : 'user-female-i w-24px h-24px'} ${isHeaderDropdown ? 'h-40px w-40px' : ''}`}></i>
			}
		</>
	)
}

export default Avatar;