import React from 'react'

const Component = (props) => {
    return (
        <div className="col-12 col-md-6 px-0 mt-12px">
            <div className="border-radius-lg bg-rgba-middle-gray-50 px-24px py-24px mt-6px">
                <h5 className="font-bold-caps font-size-16 font-weight-bold text-dark mb-12px">
                    {props.langs.Summarize}
                </h5>
                <div className="d-flex justify-content-between py-12px">
                    <span className="font-medium font-size-14 font-weight-normal text-blue-gray d-table">
                        {props.langs.NM_LoanMonthly}
                    </span>
                    <span className="d-flex justify-content-between font-medium font-size-14 font-weight-normal text-dark ml-auto">
                        {props.values.Amount ? (parseFloat(props.values.Amount) / props.values.Period).toFixed(2) : 0}
                    <span className="ml-8px"> {props.currency()} </span>
                    </span>
                </div>
                <div className="d-flex justify-content-between py-12px">
                    <span className="font-medium font-size-14 font-weight-normal text-blue-gray">
                        {props.langs.Period}
                    </span>
                    <span className="font-medium font-size-14 font-weight-normal text-dark ml-auto">
                        {props.values.Period} {props.langs.Month}
                    </span>
                </div>
                <div className="d-flex justify-content-between py-12px">
                    <span className="font-medium font-size-14 font-weight-normal text-blue-gray d-table">
                        {props.langs.Sum}
                    </span>
                    <span className="d-flex justify-content-between font-medium font-size-14 font-weight-normal text-dark ml-auto">
                        {props.values.Amount}
                    <span className="ml-8px"> {props.currency()} </span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Component