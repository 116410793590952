const Storage = () => {
	const get = (key) => {
		return localStorage.getItem(key);
	}
	const exists = (key) => {
		return get(key) !== null;
	}

	const length = () => {
		return localStorage.length;
	}

	const getJson = (key) => {
		return JSON.parse(get(key));
	}

	const tryParse = (key) => {
		return JSON.parse(get(key) ?? null);
	}

	const set = (key, val) => {
		return localStorage.setItem(key, val);
	}

	const remove = (key) => {
		return localStorage.removeItem(key);
	}
	const clear = () => {
		return localStorage.clear();
	}
	const dispatch = () => {
		window.dispatchEvent(new Event("storage"))
	}
	return {
		ls: localStorage,
		get, 
		exists, 
		length, 
		getJson, 
		set, 
		remove, 
		clear,
		dispatch,
		tryParse,
	};
}
export default Storage;