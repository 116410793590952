import { useEffect, useContext } from 'react';

import { animateScroll as scroll } from 'react-scroll';
import { HistoryContext } from 'contexts/history';

const ScrollToTop = () => {
	const {history} = useContext(HistoryContext);

	useEffect(() => {
		let shouldScroll = true
   	let pathname = history.pathname
   	let search = history.search
		if (
			pathname.indexOf("/search") > -1 || 
			pathname.indexOf("/Faq") > -1 || 
			pathname.indexOf("/tradein") > -1 || 
			pathname.indexOf("/myproducts") > -1
		) {
			shouldScroll = false
		}
		if (pathname.indexOf("/pr/") !== -1){
      if (
				search.indexOf("Page")!== -1 || 
				search.indexOf("Sort")!== -1 || 
				search.indexOf("WithPhoto")!== -1 || 
				search.indexOf("Reviews")!== -1
			) {
       	shouldScroll = false
      }
   	}
		shouldScroll && scroll.scrollToTop({
			smooth: true,
			duration: 100,
			delay: 0,
		});
	}, [history])

	return null;
}

export default ScrollToTop;
