import { Brands } from '../models/_Brand';

export class BrandsParser {
    data;
    convertedData;

    constructor(data) {
        this.data = data;
        return this;
    }

    convert() {
        this.convertedData = {}
        const BrandsList = []
        const BrandsHierarchy = {}
        for (let i in this.data) {
            BrandsHierarchy[i] = this.data[i].map((e) => {
                if (!BrandsList.find(o => o.BrandID === e.brand_id)) {
                    BrandsList.push(new Brands(e))
                }
                return new Brands(e)
            })
        }
        this.convertedData = {'List': BrandsList, 'Hierarchy': BrandsHierarchy};

        return this;
    }

    convertItem(rawItem) {
        // 
    }

    getRaw() {
        return this.data || null;
    }

    get() {
        return this.convertedData || null;
    }
}
