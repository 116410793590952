import React, { useState, useEffect } from 'react';
import * as yup from "yup";
import InputMask from 'react-input-mask';
import Icons from 'components/icons/Icons';
import { Parser } from 'components';
const CallRequestWindow = (props) => {
  const { getLang, langs, api, Functions, CONFIG, Close, liveCallerData } = props;
  const [data, setData] = useState({
    name: '',
    phone: '',
    comment: '',
    site_id: CONFIG.COMMON.SITE_ID
  });
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const getValidationSchema = () => {
    return yup.object().shape({
      name: yup.string().required(langs.NM_WriteName),
      phone: yup.string(langs.NM_WriteYourPhone)
        .required(langs.NM_WriteYourPhone)
        .matches(/[0-9]{3} [0-9]{2}-[0-9]{2}-[0-9]{2}/, langs.NM_WriteYourPhone)
    });
  }

  const validate = () => {
    return new Promise(serve => {
      getValidationSchema().validate(data, { abortEarly: false }).then(() => {
        setErrors({});
        serve(true);
      }).catch(errorMessages => {
        let errors = {};
        for (let error of errorMessages.inner) {
          errors[error.path] = error.message;
        }
        setErrors(errors);
        serve(false);
      })
    });
  }

  const submit = () => {
    if (loading) return;
    !showErrors && setShowErrors(true);
    validate().then(isValid => {
      if (isValid) {
        setLoading(true);
        api.callRequest({
          ...data,
          phone: Functions.formatPhoneNumber(data.phone)
        }).then(response => {
          setLoading(false);
          if (response?.data?.StatusCode) {
            setSuccess(true);
            setTimeout(() => {
              Close();
            }, CONFIG.LIVE_CALLER.SUCCESS_MESSAGE_TIMEOUT * 1000);
          }
        })
        .catch(({response}) => {
          if (response?.data?.message?.phone) {
            setErrors({ phone: response?.data?.message?.phone });
          }
          setLoading(false);
        });
      }
    })
  }

  useEffect(() => {
    showErrors && validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    document.body.classList.add('overflow-hidden','position-relative');
    return () => {
      document.body.classList.remove('overflow-hidden','position-relative');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
  <div className={`livecaller bottom-md-100px position-fixed d-flex flex-column right-md-32px right-0   bottom-0 top-md-auto top-0 left-md-auto left-0 w-basis-md-390px min-width-md-390px max-width-md-390px w-100 rounded-md-desk bg-white livecaller-shadow overflow-hidden ${!liveCallerData ? 'z-index-1000' : 'z-index-99999999999'}`}>
    {/* popup header start */}
    <div className="pt-40px px-16px pb-24px bg-gray-650 text-white">
      <h4 className="text-center mb-15px font-bold font-size-24">{langs.NM_CallOrderTitle[0]}</h4>
      <p className="text-center mb-15px font-size-18">{langs.NM_CallOrderTitle[1]}</p>
      <p className="text-center mb-0 font-size-18">{langs.NM_CallOrderTitle[2]}</p>
      <div onClick={Close} title={langs.Close} className="position-absolute right-8px top-8px z-inex-9 cursor-pointer align-items-center justify-content-center">
        <Icons icon="liveCaller_minimize" className="d-flex" />
      </div>
    </div>
    {/* popup header end */}
    {/* popup body start */}
    <div className="flex-grow-1">
      <div className="pt-20px h-100 d-flex flex-column px-20px pb-15px bg-white">
        <div className={"mb-18px " + (showErrors && errors.name ? 'has-error' : '')}>
          <input type="text"
            placeholder={`${langs.Name}*`}
            value={data.name || ''}
            onChange={e => {
              const name = e.target.value;
              setData(data => ({ ...data, name }));
            }}
            className='w-100 h-40px rounded-s outline-none border-solid-1 border-gray-250 text-gray-150 font-size-14 d-block px-15px' />
          {showErrors && errors.name ? <div className="error-text font-size-12 mt-5px">{errors.name}</div> : ''}
        </div>
        <div className={"mb-18px " + (showErrors && errors.phone ? 'has-error' : '')}>
          <InputMask
            mask="999 99-99-99"
            type="text"
            placeholder={`${langs.PhoneNumber}*`}
            value={data.phone || ''}
            onChange={e => {
              const phone = e.target.value;
              setData({ ...data, phone });
            }}
          >{(inputProps) => (<input {...inputProps}
            type="text"
            className='w-100 h-40px rounded-s outline-none border-solid-1 border-gray-250 text-gray-150 font-size-14 d-block px-15px' />)}</InputMask>
          {showErrors && errors.phone ? <div className="error-text font-size-12 mt-5px">{errors.phone}</div> : ''}
        </div>
        <div className="mb-12px">
          <textarea placeholder={langs.Comment}
            className='py-5px px-15px w-100 outline-none border-solid-1 border-gray-250 rounded-s h-90px resize-none text-gray-150 font-size-14' />
        </div>
        <p className=" font-size-13 font-medium m-0">
          <Parser html={langs.IAgreeWithSitePolicy.replace('%s', 'https://auth.tnet.ge/'+getLang+'/usageagreement')}/>
        </p>
        <div className="mt-md-25px mt-auto">
          <button onClick={submit} className='h-40px text-white font-size-16 font-bold rounded bg-gray-650 border-solid-0 w-100'>{langs.Accept}</button>
        </div>
      </div>
    </div>
    {/* popup body end */}
    {/* popup overlay start */}
    {success ?
      <div className="position-absolute bg-white right-0 top-0 bottom-0 left-0 ">
        <div onClick={Close} title={langs.Close} className="position-absolute right-8px top-8px z-inex-9 cursor-pointer align-items-center justify-content-center">
          <Icons icon="liveCaller_minimize" className="d-flex" />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <h4 className="text-center font-size-24 font-bold">{langs.NM_Thanks},</h4>
          <h5 className="text-center font-size-20 font-bold">{langs.NM_WeWillContactSoon}</h5></div>
      </div> : ''}
    {/* popup overlay end */}
  </div>
  )
}

export default CallRequestWindow;
