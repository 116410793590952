import React from 'react'
import { Parser } from 'components'

const InfoUsageAgreement = (props) => {

	const success = (e) => {
		props.callback(e, 'success')
		props.callback(e, 'destroy')
	}

	return (
    <>
      <div className="custom-modal-wrapper content-scroll">
				<div className="modal-content-box mb-24px px-md-64px">
					<h3 className="blocked-reason-text font-size-14 font-size-md-16 font-bold-caps text-dark text-left text-market-color mb-24px pt-32px pt-md-80px">
						{props.data.title ? props.data.title : props.langs.NM_InfoUsageAgreement}
					</h3>
					<div className="font-size-14 text-dark font-base scrolled-modal">
						<Parser html={props.data.text ? props.data.text : props.langs.NM_InfoUsageAgreementText} />
					</div>
				</div>
			</div>
      <div className={`custom-modal-actions`}>
				<div className="d-flex justify-content-center mt-5px mb-48px">
					<button type="button" className="font-size-14 font-bold-caps px-48px px-lg-100-px py-16px btn bg-gradient text-white d-block mx-auto "
						onClick={success}>
						{props.data.button ? props.data.button : props.langs.NM_ReadAgree}
					</button>
				</div>
      </div>
    </>
  )
}

export default InfoUsageAgreement