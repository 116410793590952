import React, { useContext, useEffect, useState } from 'react';
import { Loader } from 'components';
import { AppDataContext } from 'contexts/appData';

const AddCard = ({ langs, callback, Functions, data, ...props }) => {
  const { api, CONFIG: config, getLang } = useContext(AppDataContext);
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('');

  useEffect(() => {
    api
      .addUserCard({ ReturnURL: config.HOST.appUrl + getLang + '/process-card' })
      .then((res) => {
        const data = res.data;
        if (data) {
          setUrl(data.PaymentURL + `?trans_id=${data.PaymentData.trans_id}`);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="custom-modal-wrapper">
      <div className="popup-content">
        {loading ? (
          <div className="h-600px d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        ) : (
          <iframe title="addCard" className="mt-40px w-100 border-0 h-600px" src={url} />
        )}
      </div>
    </div>
  );
};

export default AddCard;
