import React from 'react';

const UploadArrow = () => {
	return (
			<span>
				<svg width="26" height="42" viewBox="0 0 26 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd"
			d="M21.625 41.125L4.375 41.125C3.23271 41.1203 2.13856 40.6644 1.33083 39.8567C0.523104 39.0489 0.0672343 37.9548 0.0625042 36.8125L0.062507 5.1875C0.0672373 4.04521 0.523107 2.95106 1.33083 2.14333C2.13856 1.3356 3.23272 0.879732 4.37501 0.875002L21.625 0.875003C22.7673 0.879734 23.8614 1.3356 24.6692 2.14333C25.4769 2.95106 25.9328 4.04521 25.9375 5.1875L25.9375 36.8125C25.9328 37.9548 25.4769 39.0489 24.6692 39.8567C23.8614 40.6644 22.7673 41.1203 21.625 41.125ZM14.198 31.4259C13.8434 31.189 13.4265 31.0625 13 31.0625C12.4281 31.0625 11.8797 31.2897 11.4753 31.6941C11.0709 32.0984 10.8438 32.6469 10.8438 33.2187C10.8438 33.6452 10.9702 34.0621 11.2071 34.4167C11.4441 34.7713 11.7808 35.0477 12.1748 35.2109C12.5688 35.3741 13.0024 35.4168 13.4207 35.3336C13.8389 35.2504 14.2231 35.045 14.5247 34.7434C14.8263 34.4419 15.0316 34.0577 15.1148 33.6394C15.198 33.2211 15.1553 32.7876 14.9921 32.3936C14.8289 31.9996 14.5525 31.6628 14.198 31.4259ZM17.4576 17.3051C17.8005 17.3051 18.1296 17.1679 18.3733 16.9235C18.6146 16.6766 18.75 16.3433 18.75 15.9959C18.75 15.6485 18.6146 15.3151 18.3733 15.0683L13.875 10.5117L13.859 10.5117L13.7787 10.4303L13.7305 10.3978L13.6662 10.3489L13.618 10.3164L13.5698 10.2838L13.5055 10.2838L13.4574 10.2513L13.3931 10.2513L13.3288 10.2188L12.5898 10.2188L12.5256 10.2513L12.4613 10.2513L12.4131 10.2838L12.3489 10.2838L12.3007 10.3164L12.2525 10.3489L12.1882 10.3978L12.14 10.4303L12.0597 10.5117L12.0436 10.5117L7.5454 15.0683C7.34046 15.3212 7.23572 15.6424 7.25157 15.9692C7.26741 16.296 7.40271 16.6052 7.63114 16.8366C7.85956 17.068 8.16479 17.2051 8.48744 17.2211C8.8101 17.2372 9.12711 17.1311 9.37682 16.9235L11.6741 14.5801L11.6741 21.8544C11.6741 22.1996 11.8095 22.5308 12.0506 22.7749C12.2916 23.0191 12.6185 23.1562 12.9593 23.1562C13.3002 23.1562 13.6271 23.0191 13.8681 22.7749C14.1091 22.5308 14.2445 22.1996 14.2445 21.8544L14.2445 14.5801L16.5419 16.9235C16.7855 17.1679 17.1146 17.3051 17.4576 17.3051Z"
			fill="#10181F"/>
</svg>
			</span>
	);
};

export default UploadArrow;