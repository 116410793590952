import React, { useContext } from 'react';
import { AppDataContext } from 'contexts/appData';
import { BannersContext } from 'contexts/banners';
import CONFIG from 'core/config'
import Icons from 'components/icons/Icons';
import auto from 'resources/img/icons/auto.svg';
import home from 'resources/img/icons/home.svg';
import market from 'resources/img/icons/market.svg';
import parts from 'resources/img/icons/parts.svg';
import swoop from 'resources/img/icons/swoop.svg';
import tkt from 'resources/img/icons/tkt.svg';
import livo from 'resources/img/icons/livo.svg';
import saba from 'resources/img/icons/saba.svg';
import myjobs from 'resources/img/icons/myjobs.svg'
import superapp from 'resources/img/icons/supaerapp.svg'
import TnetLogo from 'components/icons/TnetLogo'

const Tnets = (props) => {
	const { langs, tnet, setTnet } = props;
	const { isIos } = useContext(AppDataContext);
	const { resolution } = useContext(BannersContext);
	let mobileLinks = isIos ? 'https://apps.apple.com/us/app/super-app-tnet/id6444474250' : 'https://play.google.com/store/apps/details?id=ge.tnet.superapp&pli=1'
	let detectDevice =  resolution[0] <= CONFIG.TOSPLIT.RESPONSIVE_RESOLUTION ?  mobileLinks : 'https://superapp.tnet.ge/'

	const TNetLinks = [
		{ icon: auto, to: 'https://www.myauto.ge/' },
		{ icon: home, to: 'https://www.myhome.ge/' },
		{ icon: market, to: 'https://www.mymarket.ge/', selected: true },
		{ icon: parts, to: 'https://www.myparts.ge/', },
		{ icon: myjobs, to: 'https://myjobs.ge/' },
		{ icon: swoop, to: 'https://www.swoop.ge/' },
		{ icon: tkt, to: 'https://tkt.ge/' },
		{ icon: livo, to: 'https://livo.ge/' },
		{ icon: saba, to: 'https://saba.com.ge/' },
		{ icon: superapp, to: detectDevice },
	];

	return (
		<div className={`ml-md-auto d-flex align-items-center  justify-content-between`}>
			<div>
				<div className="position-relative ml-sm-16px ml-8px">
					<div onClick={() => setTnet(!tnet)} className={`tnet-logo w-basis-32px  w-basis-md-40px hidden-toggler h-32px  h-md-40px cursor-pointer rounded-circle d-flex align-items-center justify-content-center 
					${tnet ? 'active' : ''}`}>
						{!tnet ?
							<div className="position-relative z-index-1 d-flex">
								<TnetLogo />
							</div>
							:
							<div className="position-relative z-index-1 d-flex">
								<TnetLogo />
							</div>
						}
					</div>
					{tnet ?
						<>
							<div className="position-absolute top-100 mt-12px rounded shadow bg-white right-0 w-370px z-index-1000 d-sm-block d-none">
								<div className="px-30px pt-20px ">
									<div className="font-size-18 font-bold mb-8px">TNET</div>
									<div className="font-size-12 font-medium text-blue-gray">
										{langs.NM_LargestPlatformUnite}
									</div>
								</div>
								<div className="p-12px d-flex row mx-n2px">

									{TNetLinks.map((item, key) => (
										<div className="col-4 p-2px"  key={key} >
											<a href={item.to} target='_blank' rel="noopener noreferrer" className={(item.selected ? 'bg-light-gray' : '') + " cursor-pointer w-110px  h-80px px-8px hover-bg-gray-450 d-flex align-items-center justify-content-center rounded transition-all"}>
												<img src={item.icon} alt="" className='max-height-26px w-100 object-fit-contain' />
											</a>
										</div>
									))}

								</div>
							</div>
							<div className="bg-drop opacity-60 d-sm-none z-index-1001" onClick={() => setTnet(!tnet)} />

							<div className="position-fixed tnet-modal tnet-modal-mobile bottom-0 shadow bg-white right-0 left-0 p-24px z-index-1002 d-sm-none d-block">

								<div className="mb-20px">
									<div className="d-flex justify-content-center position-relative">
										<div className="font-size-22 text-center font-bold ">TNET</div>
										<div onClick={() => setTnet(!tnet)} className="position-absolute w-32px h-32px d-flex align-items-center justify-content-center cursor-pointer bottom-0  right-0">
											<Icons icon='close_bold' className='d-flex svg-i w-32px h-32px close-black-i' />
										</div>
									</div>
									<div className="font-size-14 text-center font-base text-blue-gray mt-16px">
										{langs.NM_LargestPlatformUnite}
									</div>
								</div>
								<div className=" d-flex flex-wrap justify-content-center rounded border-solid-1 border-gray ">
									{TNetLinks.map((item, key) => {
										const isOdd = key % 2 === 0;
										return <a href={item.to} target='_blank' key={key} rel="noopener noreferrer" className={[
											(TNetLinks.length - 1 === key ? '' : 'border-bottom border-gray'),
											(item.selected ? 'bg-gray' : ''),
											(isOdd ? '' : 'border-left border-gray'),
											"cursor-pointer w-50 h-90px d-flex align-items-center justify-content-center  transition-all",
										].join(' ')} >
											<img src={item.icon} alt="" />
										</a>;
									})}
								</div>
							</div>
						</> : ''}
				</div>
			</div>
		</div>
	)
}
export default Tnets
