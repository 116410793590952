import React from 'react';

const RateStarIcon = (props) => {
  const { fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M11.1853 0.768493L13.6878 6.18061L18.8142 6.73768C19.0761 6.76562 19.3237 6.87128 19.525 7.04106C19.7263 7.21085 19.8723 7.43699 19.9441 7.6904C20.019 7.94439 20.0184 8.21469 19.9423 8.46833C19.8662 8.72197 19.7179 8.94798 19.5156 9.11878L15.429 12.5697L16.6931 18.3604C16.7502 18.6165 16.732 18.8837 16.6408 19.1298C16.5496 19.3759 16.3892 19.5903 16.1789 19.7473C15.9713 19.9011 15.7223 19.9888 15.4642 19.999C15.2061 20.0092 14.9509 19.9414 14.7319 19.8045L10.0011 16.8549L5.2675 19.8002C5.0485 19.9371 4.79332 20.0049 4.53522 19.9947C4.27713 19.9845 4.02809 19.8968 3.82056 19.743C3.61041 19.5862 3.44998 19.372 3.35851 19.1263C3.26705 18.8805 3.24844 18.6136 3.30492 18.3575L4.57331 12.5697L0.488159 9.12306C0.285799 8.95226 0.137527 8.72625 0.0614354 8.47262C-0.0146563 8.21898 -0.015278 7.94867 0.0596463 7.69469C0.131043 7.4421 0.276133 7.21654 0.476372 7.04684C0.676612 6.87713 0.922906 6.77098 1.18378 6.74196L6.30879 6.18489L8.81273 0.768493C8.91525 0.539609 9.08179 0.34527 9.29228 0.208912C9.50276 0.0725538 9.7482 0 9.999 0C10.2498 0 10.4952 0.0725538 10.7057 0.208912C10.9162 0.34527 11.0827 0.539609 11.1853 0.768493Z"
        fill={fill}
      />
    </svg>
  );
};

export default RateStarIcon;
