import React from "react";
import { Footer, Header } from "modules";

const MainLayout = ({ children, ...props }) => {
	return (
			<>
				<Header {...props}/>
				<main>
					{children}
				</main>
				<Footer {...props}/>
			</>
	)
}

export default MainLayout;