import React from 'react'
import { Parser } from 'components';
const ReturnConditionsModal = (props) => {
  const {
    langs,
    data
  } = props
  return (
    <div className="custom-modal-wrapper">
      <div className="position-relative">
        <Parser html={
          data.text ? data.text : langs.NM_ReturnConditionsText
        } />
      </div>
    </div>
  )
}
export default ReturnConditionsModal