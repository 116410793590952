import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';

import * as Yup from 'yup';
import { API } from 'services';

const FeedbackOnline = (props) => {
	const { langs, callback, data } = props;
	const api = API();

	const config = {
		initialValues: {
			TypeID: data.type ? data.type : 2,
			Answer: ''
		},
		validationSchema: Yup.object().shape({
			Answer: Yup.string().required(langs.WriteMessageText)
		}),
		enableReinitialize: false,
		validateOnBlur: false,
		validateOnChange: false,
	}

	const handleSubmit = async (values, { resetForm }) => {
		let apiData = await api.addFeedBack(values);
		if (apiData.statusCode) {
			resetForm();
			callback(null, "success")
			callback(null, 'destroy');
			return;
		}
		callback(null, "fail")
		callback(null, 'destroy');
	}

	return (
		<div className="custom-modal-wrapper">
			<Formik {...config} onSubmit={handleSubmit}>
				{({ errors, touched }) => (
					<Form autoComplete="off">
						<div className="position-relative ">
							<div className="popup-content  px-8px  content-scroll pt-44px">
								<h3 className="font-bold-caps font-size-18 pb-8px text-left">
									{data.customText ? data.customText : langs.NM_Online_Feedback_Title}
								</h3>
								{!data.customText ?
									<h5 className="font-base font-size-14  text-left">
										{langs.NM_Online_Feedback_Sub_Title}
									</h5>
									: null
								}
								<div className="form-group">
									<Field as="textarea" name="Answer"
										className={`form-control pb-sm-200px text-dark font-size-14 font-base ${(touched.Answer && errors.Answer ? "border-danger" : "")}`}
										placeholder={langs.EnterText} />
									<ErrorMessage name="Message" component="label" className="error text-left" />
								</div>
							</div>
							<button type="submit" className="btn bg-blue font-bold-caps font-size-13 text-white w-100 mt-24px  px-40px py-16px">
								{langs.Send}
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	)
}

export default FeedbackOnline