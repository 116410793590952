import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';
// import ReactGA from 'react-ga';
// import { hotjar } from 'react-hotjar';
import moment from 'moment';
import { dps } from 'tnet-dps'
// import { dataHubService, EventType } from 'tnet-clickstream-connector';
import { Functions } from 'libs';
import {Storage} from 'services';
import { CONFIG } from 'core';

const checkPaq = () => {
  return window._paq ? true : false;
}

const callPixel = () => {
  ReactPixel.init('356872496037034', {}, { autoConfig: true, debug: false });
}
const callTagManager = () => {
  TagManager.initialize({ gtmId: 'GTM-P365JPL' })
}
const callGA = () => {
  // ReactGA.initialize('UA-1140272-4', {debug: false});
  // ReactGA.plugin.require('ec');
}

// const callHotJar = () => {
//   hotjar.initialize(999958, 6);
// }

const callClickStream = () => {
  const consent = {
    mouseTracking: true,
    fingerprintTracking: true
  }

  dps.activate(true)
  dps.setConsent(consent)
}

const gaView = () => {
  // ReactGA.pageview(Functions.currentPage())
}

const tagView = () => {
  // console.log('view')
  // TagManager.dataLayer({page: Functions.currentPage()})
}

const gaAddProduct = (product) => {
  // if (!product) {
  //   return false;
  // }
  // ReactGA.plugin.execute('ec', 'addProduct', {
  //   id: product.PrID,
  //   name: product.PrName,
  //   sku: product.PrCode,
  //   category: product.PrCategory,
  //   price: product.PrPrice,
  //   quantity: 1,
  //   currency: 'GEL',
  // });
}

const gaPurchase = (data) => {
  // if (!data) {
  //   return false;
  // }
  // ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
  //   id: data.order_id,
  //   affiliation: data.shop?.title ? data.shop.title : '',
  //   revenue: data.total_amount,
  //   shipping: '',
  //   tax: '',
  // });
  // gaClear()
}

const gaCustom = (eventProps) => {
  // if (!eventProps) return false;
  // ReactGA.event({
  //   category: eventProps.category || '',
  //   action: eventProps.action || '',
  //   label: eventProps.label || '',
  //   value: eventProps.value || '',
  // });
}

const gaClear = () => {
  // ReactGA.plugin.execute('ec', 'clear');
}

const pixelView = () => {
  ReactPixel.pageView();
}

const pixelViewContent = (product) => {
  if (!product) {
    return false;
  }
  ReactPixel.track('ViewContent',
    {
      content_type: 'product',
      content_ids: [product.product_id],
      content_name: product.title,
      value: parseFloat(product.price).toFixed(2),
      source: window.location.href
    }
  );
}

const pixelAddToCart = (product) => {
  if (!product) {
    return false;
  }
  ReactPixel.track('AddToCart', {
    content_type: 'product',
    content_ids: [product.product_id],
    content_name: product.title,
    currency: 'USD',
    value: parseFloat(product.price).toFixed(2),
  });
}

const pixelInitiateCheckout = (product) => {
  if (!product) {
    return false;
  }
  ReactPixel.track('InitiateCheckout', {
    content_type: 'product',
    content_ids: [product.product_id],
    content_name: product.title,
    currency: 'USD',
    value: parseFloat(product.price).toFixed(2),
  });
}

const pixelPurchase = (products = []) => {
  if (products.length <= 0) {
    return false;
  }
  let prIDs = [];
  let price = 0;
  products.map(product => {
    prIDs.push(product.product_id);
    price += parseFloat(product.price);
    return product;
  })
  ReactPixel.track('Purchase', {
    content_type: 'product',
    content_ids: prIDs,
    currency: 'USD',
    value: parseFloat(price),
  });
}

const connectStart = () => {
  const _paq = window._paq = window._paq || [];
  _paq.push(['enableLinkTracking']);
  let u = "//360.tbcconnect.ge/";
  let i = 6;
  _paq.push(['setTrackerUrl', u + 'service']);
  _paq.push(['setSiteId', i]);
  let d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
  g.type = 'text/javascript';
  g.async = true;
  g.src = u + 'hoard.js';
  s.parentNode.insertBefore(g, s);
  let pd = document, pg = pd.createElement('script'), ps = pd.getElementsByTagName('script')[0];
  pg.type = 'text/javascript';
  pg.async = true;
  pg.src = u + 'collector.js?id=' + i;
  s.parentNode.insertBefore(pg, ps);
}

const connectPageView = (props = false) => {
  if (!checkPaq()) { return false; }
  if (!props.categoryName && !props.itemView && !props) {
    // console.log('pageView')
    window._paq.push(['pageView']);
  } else if (props.categoryName) {
    // console.log('categoryName')
    window._paq.push(['categoryView', `${props.categoryName}`, `${props.mainCategoryName}`]);
  } else if (props.itemView) {
    // console.log(props)
    window._paq.push(['itemView', {
      item_title: props.title,
      id: props.product_id,
      price: props.price ? parseFloat(props.price) : 0,
      discount_price: props.offer_price ? parseFloat(props.offer_price) : 0,
      location: props.location,
      img_url: props.images[0].large,
      views: props.views,
      category_name: props.categoryN,
      item_condition: props.conditionType,
      main_category_name: props.mainCategoryN
    }]);
  }
  return false
}

const connectSearch = (props = false) => {
  // console.log('search')
  if (!props || !checkPaq()) {
    return false;
  }
  window._paq.push(['search', props.keyword, props.categoryName, props.searchCount]);
}

const connectAddToWishList = (props = false) => {
  // console.log('addToWishList')
  if (!props || !checkPaq()) {
    return false;
  }
  let productSKU = props.PrID,
      productName = props.PrName,
      categoryName = props.PrCategory,
      productPrice = props.PrPrice ? parseFloat(props.PrPrice) : 0,
      productPriceInUsd = props.PrPriceInUsd;
  window._paq.push(['addWishlistItem', productSKU, productName, categoryName, productPrice, productPriceInUsd]);
}

const connectRemoveFromWishList = (props = false) => {
  // console.log('removeWishList')
  if (!props || !checkPaq()) {
    return false;
  }
  let productSKU = props.PrID
  window._paq.push(['removeWishlistItem', productSKU]);
}

const connectAddToCart = (props = false) => {
  // console.log('addCartItem')
  if (!props || !checkPaq()) {
    return false;
  }
  let productSKU = props.PrID,
      productName = props.PrName,
      categoryName = props.PrCategory,
      quantity = props.PrQuantity,
      productPrice = props.PrPrice ? parseFloat(props.PrPrice) : 0,
      productPriceInUsd = props.PrPriceInUsd;
  window._paq.push(['addCartItem', productSKU, productName, categoryName, quantity, productPrice, productPriceInUsd]);
}

const connectRemoveFromCart = (props = false) => {
  // console.log('removeCartItem')
  if (!props || !checkPaq()) {
    return false;
  }
  let productSKU = props.PrID
  window._paq.push(['removeCartItem', productSKU]);
}

const connectOrder = (props = false) => {
  if (!props || !checkPaq()) {
    return false;
  }
  let orderID = props.Order?.OrderID,
    grandTotal = props.Amount ? parseFloat(props.Amount) : 0,
    paymentMethod = props.Order?.Operator,
    subTotal = 0,
    tax = 0,
    shipping = 0,
    discount = 0
  window._paq.push(['order', orderID, grandTotal, paymentMethod, subTotal, tax, shipping, discount]);
}

const connectPurchase = () => {
  if (!checkPaq()) {return false}
  window._paq.push(['purchase', true]);
}

const connectCustom = (props = false) => {
  if (!props || !checkPaq()) {
    return false;
  }
  window._paq.push([
    'event', 
    props.eventKey, 
    props.eventValue
  ]);
}

const connectSetCustomer = (props) => {
  if (!props || !checkPaq() || !props.user) {
    return false;
  }
  let pushData = ['setCustomer', props.user.Email];
  pushData.push({
      first_name: props.user.Name,
      last_name: props.user.LastName,
      phone_number: props.user.Phone,
      second_phone_number: props.data.userData?.SecondPhoneNumber,
      email: props.user.Email,
      gender: props.data.userData?.Gender, 
      date_of_birth: props.data.userData?.BirthYear
  });
  pushData.push(props.data.agreed === 1);
  window._paq.push(pushData);
}

const connectLogin = (props = false) => {
  if (!props || !checkPaq()) {
    return false;
  }
  window._paq.push(['userLogin', props.userData, props.consent, props.expiresIn]);
}

const connectLogOut = (props = false) => {
  if (!props || !checkPaq()) {
    return false;
  }
  window._paq.push(['logout']);
}

const connectGetHash = async () => {
  if (!checkPaq() || !window || !window.TBCConnectFingerPrint) {
    return false;
  }
  return window.getTBCConnectFingerPrintHash(await window.TBCConnectFingerPrint.getPromise())
}

const queryClickStream = (query = '') => {
  if (!query) {
    return false
  }
  dps.sendClickStream(query, dps.EventType.SearchQuery)
}

const pageViewClickStream = () => {
  dps.sendClickStream(window.location.href, dps.EventType.SearchQuery);
}

const addToCartClickStream = (props = false) => {
  // if (!props) {
  //   return false
  // }
  // dataHubService.sendClickStream(
  //   {
  //     product: {
  //       title: props.PrName,
  //       price: props.PrPrice,
  //       currency: 'GEL'
  //     }
  //   },
  //   EventType.AddToCart
  // )
}

const itemViewClickStream = (id = false) => {
  if (!id) {
    return false
  }
  dps.sendClickStream(id, dps.EventType.ItemView)
}

const signInClickStream = (user = false) => {
  // if (!user) {
  //   return false
  // }
  // dataHubService.sendClickStream({ 
  //   firstName: user.Name,
  //   lastName: user.Lastname,
  //   email: user.Email, 
  // }, EventType.SignIn)
}

const signOutClickStream = (user = false) => {
  // if (!user) {
  //   return false
  // }
  // dataHubService.sendClickStream({ 
  //   firstName: user.Name,
  //   lastName: user.Lastname,
  //   email: user.Email, 
  // }, EventType.SignOut)
}

const transactionClickStream = (props = false) => {
  // if (!props) {
  //   return false
  // }
  // dataHubService.sendClickStream(
  //   {
  //     product: {
  //       title: props.product.title,
  //       price: props.price,
  //       currency: 'GEL'
  //     }
  //   },
  //   EventType.Transaction
  // )
}

const processAdoDependances = () => {
  // console.log('ado process')
  if (window.ado === undefined) {
    return false
  }
  if (typeof window.ado !== "object") {
    window.ado = {};
    window.ado.config = window.ado.preview = window.ado.placement = window.ado.master = window.ado.slave=function(){};
  }
  window.ado.config({
    mode: "new", 
    xml: false, 
    consent: true, 
    characterEncoding: true
  });
  window.ado.preview({
    enabled: true
  });
  return true
}

const topGeRunner = () => {
  let el = document.getElementById("top-ge-counter-container")
  if (!el) {
    return setTimeout(() => {
      topGeRunner()
    }, 500);
  } else {
    let d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.src = '//counter.top.ge/counter.js';
    s.parentNode.insertBefore(g, s);
  }
}

let liveCallerCloseEventCallback = null;
const liveCallerInit = (lang) => {
  (function(w,t,c,p,s,e,l,k){
    p=new Promise(function(r){w[c]={client:function(){return p}};
    l=document.createElement('div');l.setAttribute("id", "live-caller-widget");
    s=document.createElement(t);s.async=1;s.setAttribute("data-livecaller", 'script');
    k=document.body || document.documentElement;k.insertBefore(l, k.firstChild);
    l.setAttribute("data-livecaller", 'mount-el');s.src='https://cdn.livecaller.io/js/app.js';
    e=document.getElementsByTagName(t)[0];e ? e.parentNode.insertBefore(s,e) : k.insertBefore(s, l.nextSibling);
    s.onload=function(){r(w[c]);};});return p;
  })(window,'script','LiveCaller').then(function(){
    try{
      window.LiveCaller.config.merge({"widget":{"id":CONFIG.LIVE_CALLER.ID},"app":{"locale":lang}});
      window.LiveCaller.$on('ui.widget.close', e => {
        liveCallerCloseEventCallback && liveCallerCloseEventCallback(e);
      });
      // window.LiveCaller.liftOff();
    } catch(e) {}
  });
}

const liveCallerCheck = (timeOut = 15) => {
  const storage = Storage();
  if (storage.exists(CONFIG.CACHE.TAGS.AUTH)){
    if (storage.exists(CONFIG.CACHE.TAGS.LIVE_CHAT)) {
      let mins = moment(new Date()).diff(new Date(storage.get(CONFIG.CACHE.TAGS.LIVE_CHAT)), 'minutes');
		  if (mins > timeOut) {
        liveCallerLogin(storage)
      }
    } else {
      liveCallerLogin(storage)
    }
  }
}

const liveCallerLogin = async (storage) => {
  let link = `https://accounts.tnet.ge/api/ka/livecaller/token?SiteID=${CONFIG.COMMON.SITE_ID}`
  const headers = { 
    'Authorization': storage.get('Auth'), 
    'Content-Type': 'application/json'
  }
  return await fetch(link, { headers }).then((response) => response.json()).then((response) => {
    if (response.data.success) {
        // console.log(response.data.token)
        window.LiveCaller.$emit('user.login', response.data.token);
        storage.set(CONFIG.CACHE.TAGS.LIVE_CHAT, JSON.stringify(new Date()));
    } else {
      throw new Error('LiveChat');
    }
  })
  .catch((error) => {
    // console.log(error)
    return false
  });
}

const liveCallerClose = (callback) => {
  liveCallerCloseEventCallback = callback;
}

const initialStart = (lang) => {
  // callHotJar();
	callPixel()
	callTagManager()
	callGA()
  // connectStart();
  topGeRunner();
  liveCallerInit(lang);
  callClickStream();
}

const Trackers = {
  initialStart,
  gaView,
  tagView,
  gaAddProduct,
  gaPurchase,
  gaCustom,
  gaClear,
  pixelView,
  pixelViewContent,
  pixelAddToCart,
  pixelInitiateCheckout,
  pixelPurchase,
  connectStart,
  connectPageView,
  connectSearch,
  connectAddToWishList,
  connectRemoveFromWishList,
  connectAddToCart,
  connectRemoveFromCart,
  connectOrder,
  connectPurchase,
  connectCustom,
  connectSetCustomer,
  connectLogin,
  connectLogOut,
  connectGetHash,
  liveCallerCheck,
  liveCallerClose,
  queryClickStream,
  pageViewClickStream,
  addToCartClickStream,
  itemViewClickStream,
  signInClickStream,
  signOutClickStream,
  transactionClickStream,
  processAdoDependances,
};

export default Trackers;