export class TransactionsParser {
    data;
    convertedData;

    constructor(data) {
        this.data = data;
        return this;
    }

    convert() {
        
        this.convertedData = [];
        Object.entries(this.data).map(([k,v], i) => {
            this.convertedData.push({id: parseInt(k), title: v})
            return 0;
        })
        return this;
    }

    convertItem(rawItem) {
        // 
    }

    getRaw() {
        return this.data || null;
    }

    get() {
        return this.convertedData || null;
    }
}
