import {useState, useContext} from 'react';

import { HistoryContext }   from 'contexts/history';
import { AppDataContext } from 'contexts/appData';

const PaginationState = () => {
    // possible props
    // totalPages
    // limit
    // onPageChange
    // otherParams
    const { params, assignParam, push, getPathname }  = useContext(HistoryContext);
    const { CONFIG } = useContext(AppDataContext);
    const [paginate, setPaginate]  = useState(null);

    const changePaginationState = (props) => {
        let totalPages = props.totalPages ? props.totalPages : 0;
        let forcePage = (params.Page !== null && params.Page > 0) ? params.Page - 1 : 0;
        let limitPerPage = props.limit ? props.limit : CONFIG.COMMON.PAGINATION_LIMIT;

        setPaginate({
            pageCount: totalPages,
            forcePage: forcePage,
            onPageChange: (data) => {
                if (props.onPageChange) {
                    props.onPageChange() 
                }
                let defaultParams = {
                    Page: parseInt(data.selected) + 1, 
                    Limit: params.Limit ? params.Limit : limitPerPage
                }
                if (props.otherParams) {
                    defaultParams = {...defaultParams, ...props.otherParams }
                }
                // assignParam(defaultParams, true)
                push(getPathname(), defaultParams)
            }
        });
    }
    return {paginate, setPaginate, changePaginationState}
}

export default PaginationState;