import React from 'react'
import {Form} from 'formik';
import { MainStep, FinalStep, Actions } from '../components';

const Component = (props) => {
    return (
        <Form autoComplete="off">
            <MainStep {...props} />
            <FinalStep {...props} />
            <Actions {...props} />
        </Form>
    )
}
export default Component

