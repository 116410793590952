/* eslint no-unused-vars:0, react-hooks/exhaustive-deps:0, eqeqeq:0 */
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Trackers} from 'libs';
import { AppDataContext } from 'contexts/appData';
import { UserContext } from 'contexts/user';
import { Link } from 'react-router-dom';
import { CallRequestWindow, ConfirmDialog, Feedback } from './components';
import Icons from 'components/icons/Icons';
const LiveCallerWindow = {
	None: 1,
	CallRequest: 2,
	Chat: 3,
};

const LiveCaller = (props) => {
  const { getLang, langs, api, Functions, CONFIG } = props;
	const { liveCallerData, setLiveCallerData } = useContext(AppDataContext);
	const { user  } = useContext(UserContext);
	// const history = useHistory();
	const [data, setData] = useState({
		show: true,
		open: false,
		activeWindow: LiveCallerWindow.None,
		liftOff: false
	});
	const [confirm, showConfirm] = useState(false);
	const [checkLiveChat, setCheckLiveChat] = useState(false);

	const hidden = useRef(!!api.cookie._get(CONFIG.CACHE.TAGS.LIVE_CALLER_HIDE_KEY) || false);
	const [ref, refresh] = useState(0);
	const hide = (value) => {
		hidden.current = value;
		refresh(ref => ref + 1);
	}

	const CallRequest = () => setData(state => ({ ...state, open: true, activeWindow: LiveCallerWindow.CallRequest }));
	const Open = () => setData(state => ({ ...state, open: true, activeWindow: LiveCallerWindow.None }));
	const Close = () => {
		if (data.activeWindow == LiveCallerWindow.Chat) {
			try {
				window.LiveCaller.$emit('ui.widget.close');
			} catch (ex) { }
		}
		setData(state => ({ ...state, open: false, activeWindow: LiveCallerWindow.None }));
		if (liveCallerData) {
			setLiveCallerData(null);
		}
	}
	const Chat = () => {

		try {
			!data.liftOff && window.LiveCaller.liftOff();
			// noinspection JSUnresolvedFunction
			window.LiveCaller.$emit('ui.widget.open');
			setData(state => ({ ...state, open: true, liftOff: true, activeWindow: LiveCallerWindow.Chat }));

		} catch (ex) { }
	}

	const confirmCallback = useRef(null);
	const confirmHideCaller = () => {
		return new Promise(serve => {
			confirmCallback.current = (response) => {
				showConfirm(false);
				serve(response);
			};
			showConfirm(true);
		});
	}

	const hideCaller = () => {
		confirmHideCaller().then(confirm => {
			if (confirm) {
				api.cookie._set(CONFIG.CACHE.TAGS.LIVE_CALLER_HIDE_KEY, '1', { maxAge: CONFIG.LIVE_CALLER.HIDE_KEY_EXPIRE });
				hide(true);
				setTimeout(() => {
					onRouteChange();
				}, (CONFIG.LIVE_CALLER.HIDE_KEY_EXPIRE * 60 * 1000) + 3000);
			}
		});
	}

	const onRouteChange = () => {
		const isHidden = !!api.cookie._get(CONFIG.CACHE.TAGS.LIVE_CALLER_HIDE_KEY) || false;
		if (isHidden != hidden.current) {
			hide(isHidden);
		}
	}

	useEffect(() => {
		// noinspection JSUnresolvedFunction
		Trackers.liveCallerClose(() => {
			setData(state => ({ ...state, open: false, activeWindow: LiveCallerWindow.None }));
		});
		// const clear = history.listen(() => {
		// 	onRouteChange();
		// });
		return () => {
			// clear && clear();
			Trackers.liveCallerClose(null);
		}
	}, []);

	useEffect(() => {
		if (user && !checkLiveChat) {
			setCheckLiveChat(true)
			Trackers.liveCallerCheck(CONFIG.LIVE_CALLER.LOGIN_CHECK_INTERVAL);
			const interval = setInterval(() => {
				Trackers.liveCallerCheck(CONFIG.LIVE_CALLER.LOGIN_CHECK_INTERVAL);
			}, 60000);
			return () => interval && clearInterval(interval);
		}
		return () => { };
	}, [user]);

	useEffect(() => {
		if (liveCallerData) {
			if (liveCallerData === 'chat') {
				Chat();
			} else if (liveCallerData === 'phone') {
				CallRequest();
			}
		}
	}, [liveCallerData]);

	if (hidden.current) return null;

	return (
		<div className="font-TBCX-reg livecaller-wrapper">
			{/* popup start */}
			{data.activeWindow == LiveCallerWindow.CallRequest ?
				<CallRequestWindow {...{
					Close,
					getLang,
					langs,
					api,
					Functions,
					CONFIG
				}} />

				: null}
			{data.activeWindow == LiveCallerWindow.CallRequest2 ?
				<Feedback {...{
					Close,
					langs,
				}} />

				: null}
			{/* popup end */}
			{/* buttons start */}
			<div className={`bottom-32px position-fixed right-24px right-m-32px bottom-lg-35px cursor-pointer d-none d-md-flex ${!liveCallerData ? 'z-index-998 live-caller-outer' : ''}`}>
				<div className="btns">
					{!data.open ? <div onClick={hideCaller} className=" d-md-none align-items-center justify-content-center ml-auto d-flex w-basis-24px h-24px bg-gray-650 mb-4px rounded-circle shadow position-relative z-index-1000" >
						<Icons icon='liveCaller_x' className='d-flex' />
					</div> : ''}

					<div className="d-flex align-items-center justify-content-center">
						{!data.open ? <i onClick={Open} className="d-flex align-items-center justify-content-center w-basis-48px h-48px bg-gray-650 rounded-circle shadow">
							<Icons icon='liveCaller_open' className='d-flex' />
						</i> :
							<i onClick={Close} className="d-flex align-items-center justify-content-center w-basis-48px h-48px bg-gray-650 rounded-circle shadow">
								<Icons icon='liveCaller_close' className='d-flex' />
							</i>}
					</div>
				</div>
				<div className={(data.open && data.activeWindow == LiveCallerWindow.None ? 'opacity-1 scaleIn' : 'opacity-0 scaled pointer-events-none') + " position-absolute right-4px bottom-48px transition-all"}>
					<div onClick={CallRequest} className="d-flex align-items-center justify-content-end mb-8px">
						<span className="d-flex align-items-center font-size-12 px-16px h-28px rounded text-white no-wrap bg-gray-650 mr-8px">
							{langs.CallRequest}
						</span>
						<i className="w-40px h-40px d-flex align-items-center justify-content-center shadow rounded-circle bg-gray-650">
							<Icons icon='liveCaller_call' className='d-flex ' />
						</i>
					</div>
					<div onClick={Chat} className="d-flex align-items-center justify-content-end mb-8px">
						<span className="d-flex align-items-center font-size-12 px-16px h-28px rounded text-white no-wrap bg-gray-650 mr-8px">
							{langs.OnlineChat}
						</span>
						<i className="w-40px h-40px d-flex align-items-center justify-content-center shadow rounded-circle bg-gray-650">
							<Icons icon='liveCaller_chat' className='d-flex' />
						</i>
					</div>
					<a
						href='https://www.surveymonkey.com/r/83DHMLK'
						target="_blank"
						className="d-flex align-items-center justify-content-end mb-8px">
						<span className="d-flex align-items-center font-size-12 px-16px h-28px rounded text-white no-wrap bg-gray-650 mr-8px">
							{langs.NM_ReviewCount}
						</span>
						<i className="w-40px h-40px d-flex align-items-center justify-content-center shadow rounded-circle bg-gray-650">
							<Icons icon='liveCaller_feedback' className='d-flex ' />
						</i>
					</a>
				</div>
			</div>
			{/* buttons end */}
			{confirm ?
				<ConfirmDialog {...{
					onClose: (response) => confirmCallback.current && confirmCallback.current(response),
					langs
				}} />
				: null}
		</div>
	);
}

export default LiveCaller;
