import React, {useRef} from 'react';
import { Slider, SliderNav } from 'components';
import LazyImage from 'components/common/suspense/LazyImage'
import { getSwiperConfig } from 'core';

const ProductSlider = (props) => {
  const sliderRef = useRef(null);
  // console.log(props)
  return (
    <div className={`card-image overflow-hidden border-radius-xs position-relative w-100   
      ${props.isSearch ? (props.activeStyle  ? 'w-lg-216px h-132px h-md-180px max-width-lg-216px' : 'w-120px h-126px w-md-216px h-md-180px max-width-216px') : ''}
      ${props.favorites ? 'h-132px h-md-180px' : ''} `}>
      {(props.product?.photos?.length > 1 && !props.noSwiper) ?
        <>
          <Slider {...getSwiperConfig('productSlider')} ref={sliderRef}>
            {props.product.photos.map((image, i) => (
                <div key={`product-similar-slider-images-${props.product.product_id}-${i}`} className="h-100">
                  <LazyImage {...{
                    src: image.thumbs,
                    alt: props.product.title,
                    className: 'w-100 h-100 object-fit-cover',
                  }} />
                </div>
            ))}
          </Slider>
          {props.product.photos.length > 1 &&
            <SliderNav ref={sliderRef} {...{
                loop : true,
                mainClassName: "card-arrows d-none d-m-flex",
                leftClassName: "btn btn-no-outline  card-arr p-0 left-arr ml-5px",
                leftIconClassName: "svg-i left-arr",
                rightClassName: "btn btn-no-outline  card-arr p-0 right-arr mr-5px",
                rightIconClassName: "svg-i right-arr"
            }} />
          }
        </>
      : <LazyImage {...{
        src: props.product?.photos[0]?.thumbs,
        alt: props.product.title,
        className: 'w-100 h-100 object-fit-cover',
      }} />
      }
    </div>
  )
}

export default ProductSlider;