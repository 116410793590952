import React, { useState } from 'react'

import { API } from 'services';
import { Phone, Header } from './verification';

const PhoneVerification = (props) => {
	const api                                               = API();
	
	const [data, setData]                                   = useState(props.data);
	const [codeSendTimer, setCodeSendTimer]                 = useState(false);

	const success = (e) => {
		props.callback(e, 'success')
		props.callback(null, 'destroy')
	}

	const productActivationSMS = async (values) => {
		const apiData = await api.sendProductActivationCode(data.productID, { Phone: values.Phone })
		if (apiData.statusCode) {
			setCodeSendTimer(60)
		}
		// setData(values)
	}

	const productFormSMS = async (values) => {
		const apiData = await api.sendUserSMS({ Phone: values.Phone })
		if (apiData.statusCode) {
			setCodeSendTimer(60)
			let newData = {...data}
			newData["smsData"] = apiData.data
			if (values.Phone !== data.finalData.Phone) {
				newData.finalData.Phone = values.Phone
			}
			setData(newData)
		}
	}

	const sendVerificationSMS = (e, values) => {
		e && (e.preventDefault() || e.stopPropagation());
		if (!codeSendTimer) {
			if (data.productID) {
				productActivationSMS(values)
			} else if (data.action === 'sendUserSMS') {
				productFormSMS(values)
			}
		}
		
	}

	const handlePhoneSubmit = async (values) => {
		if (data.action === 'sendUserSMS') {
			let newData = {...data}
			newData["SmsCode"] = values.SmsCode
			setData(newData)
			success(newData)
		}
	}

	const ActiveComponent = parentProps => {
		switch (props.data.component) {
			case 'Phone':
				return <Phone {...parentProps} />
			default:
				props.callback(null, 'destroy')
			return <></>
		}
	}

	return (
		<div className="custom-modal-wrapper">
			<Header {...{
				langs: props.langs, 
				title: props.data.title
			}} />
			<ActiveComponent {...{
				langs: props.langs,
				data,
				handlePhoneSubmit,
				sendVerificationSMS,
				codeSendTimer,
				setCodeSendTimer
			}} />
		</div>
	)
}

export default PhoneVerification;

