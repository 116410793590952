import React from 'react'
const VipsIcon = props => (
    <span className={`svg-icon svg-md-icon d-flex align-items-center justify-content-center ${props.className ? props.className : ''}`}>
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.9868 10.8857C16.968 10.8057 16.9298 10.7315 16.8755 10.6698C16.8212 10.6081 16.7526 10.5607 16.6756 10.5318L13.0737 9.18112L13.9903 4.59805C14.0116 4.49137 13.9976 4.38065 13.9503 4.28268C13.903 4.18471 13.8251 4.10483 13.7283 4.05516C13.6315 4.00549 13.5212 3.98874 13.414 4.00745C13.3068 4.02616 13.2087 4.07931 13.1345 4.15884L6.13447 11.6588C6.07839 11.7189 6.03798 11.7919 6.01683 11.8714C5.99568 11.9508 5.99445 12.0342 6.01323 12.1143C6.03201 12.1943 6.07024 12.2684 6.12453 12.3302C6.17881 12.3919 6.24748 12.4393 6.32445 12.4682L9.92635 13.8189L9.00972 18.4019C8.98838 18.5086 9.00241 18.6193 9.04969 18.7173C9.09697 18.8153 9.17492 18.8952 9.27171 18.9449C9.3685 18.9945 9.47885 19.0113 9.58602 18.9926C9.69319 18.9739 9.79133 18.9207 9.86556 18.8411L16.8656 11.3411C16.9216 11.2811 16.962 11.2081 16.9832 11.1286C17.0043 11.0492 17.0056 10.9658 16.9868 10.8857V10.8857Z" fill="#6F7383" />
        </svg>


    </span>
)

export default VipsIcon