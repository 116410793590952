import React from 'react'
import PropTypes from 'prop-types'

SearchIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
}

export default function SearchIcon({ className = '', width = 14, height = 14, fill }) {
  return (
    <span className={className}>
            <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 20 20' fill={fill}>
      <path
        d='M13.3138 12.0596H12.6555L12.4221 11.8346C13.4221 10.668 13.9388 9.07631 13.6555 7.38464C13.2638 5.06798 11.3305 3.21798 8.99713 2.93464C5.47213 2.50131 2.50546 5.46798 2.93879 8.99298C3.22213 11.3263 5.07213 13.2596 7.38879 13.6513C9.08046 13.9346 10.6721 13.418 11.8388 12.418L12.0638 12.6513V13.3096L15.6055 16.8513C15.9471 17.193 16.5055 17.193 16.8471 16.8513C17.1888 16.5096 17.1888 15.9513 16.8471 15.6096L13.3138 12.0596ZM8.31379 12.0596C6.23879 12.0596 4.56379 10.3846 4.56379 8.30964C4.56379 6.23464 6.23879 4.55964 8.31379 4.55964C10.3888 4.55964 12.0638 6.23464 12.0638 8.30964C12.0638 10.3846 10.3888 12.0596 8.31379 12.0596Z' />
    </svg>
        </span>
  )
}









